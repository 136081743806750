import {
  Paper,
  createStyles,
  TextInput,
  PasswordInput,
  Button,
  Title,
  Text,
  PinInput,
  Anchor,
  Image,
  Group,
  rem, Flex, Center, useMantineTheme, Stack, Grid,
} from "@mantine/core";

import { Notification } from '@mantine/core';

import React, { useState, useEffect } from "react";

import { IconBrandAzure, IconCheck, IconX } from "@tabler/icons-react";

import { notifications } from "@mantine/notifications";

import { useSupabaseClient } from "@supabase/auth-helpers-react";

import { useNavigate } from "react-router-dom";

const useStyles = createStyles((theme) => ({
  anchor: {
    width: "100%",
  },
  wrapper: {
  },
  patternContainer: {
    minHeight: "calc(100vh - 10px)",
    backgroundSize: "500px 500px",
    backgroundRepeat: "repeat",
    backgroundPosition: "center",
    backgroundColor: theme.colorScheme === "dark" ? "rgba(7,12,16,0.95)" : "rgba(251,253,255,0.95)",
    backgroundBlendMode: theme.colorScheme === "dark" ? "darken" : "lighten",
    backgroundImage: "url(https://www.cccfacilcontrole.com.br/c-color.svg)",
  },

  form: {
    maxWidth: rem(450),
    minWidth: rem(450),
    [theme.fn.smallerThan("sm")]: {
      maxWidth: "100%",
    },
    [theme.fn.smallerThan("sm")]: {
      minWidth: "100%",
    },
  },

  apps: {
    maxWidth: rem(450),
    minWidth: rem(450),
    [theme.fn.smallerThan("sm")]: {
      maxWidth: "100%",
    },
    [theme.fn.smallerThan("sm")]: {
      minWidth: "100%",
    },
  },

  title: {
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },
}));

function AuthMfa() {
  const { classes } = useStyles();
  const [loading, setLoading] = useState(false);

  const supabase = useSupabaseClient();

  const [verifyCode, setVerifyCode] = useState("");
  const navigate = useNavigate();

  const handleLogin = async () => {
    setLoading(true);

    try {
      const factors = await supabase.auth.mfa.listFactors()
      if (factors.error) {
        throw factors.error
      }

      const totpFactor = factors.data.totp[0]

      const factorId = totpFactor.id

      const challenge = await supabase.auth.mfa.challenge({ factorId });

      if (challenge.error) {
        notifications.show({
          withCloseButton: true,
          autoClose: 3000,
          message: challenge.error.message,
          color: "red",
          icon: <IconX />,
          loading: false,
        });

        return;
      }

      const challengeId = challenge.data.id

      const verify = await supabase.auth.mfa.verify({
        factorId,
        challengeId,
        code: verifyCode,
      });

      if (verify.error) {
        notifications.show({
          withCloseButton: true,
          autoClose: 3000,
          message: verify.error.message,
          color: "red",
          icon: <IconX />,
          loading: false,
        });
      }
      else {
        await supabase.auth.refreshSession();
      }
    }
    finally {
      setLoading(false);
    }
  }

  const handleLogout = async () => {
    const { } = await supabase.auth.signOut();
    window.location.replace('https://cccfacilcontrole.com.br');
  };

  return <Group position="center">
    <Text
      variant="gradient"
      gradient={{ from: 'indigo', to: 'cyan', deg: 45 }}
      ta="center"
      fw={700}
    >
      Insira o código gerado pelo seu aplicativo de autenticação.
    </Text>
    <PinInput length={6} value={verifyCode} onChange={setVerifyCode} onComplete={handleLogin} />
    
    <Anchor component="button" type="button" align="center" onClick={handleLogout} mt="md" className={classes.anchor}>
      Ou clique aqui para voltar para o login!
    </Anchor>
  </Group>
}

function EnrollMfa() {
  const supabase = useSupabaseClient();

  const [factorId, setFactorId] = useState('')
  const [qr, setQR] = useState('') // holds the QR code image SVG
  const [verifyCode, setVerifyCode] = useState('') // contains the code entered by the user

  const handleMFA = async () => {
    try {
      const challenge = await supabase.auth.mfa.challenge({ factorId });

      if (challenge.error) {
        notifications.show({
          withCloseButton: true,
          autoClose: 3000,
          title: "Login Error",
          message: challenge.error.message,
          color: "red",
          icon: <IconX />,
          loading: false,
        });

        return;
      }

      const challengeId = challenge.data.id

      const verify = await supabase.auth.mfa.verify({
        factorId,
        challengeId,
        code: verifyCode,
      });

      if (verify.error) {
        notifications.show({
          withCloseButton: true,
          autoClose: 3000,
          message: verify.error.message,
          color: "red",
          icon: <IconX />,
          loading: false,
        });
      }
      else {
        await supabase.auth.refreshSession();
      }
    }
    finally {

    }
  }

  useEffect(() => {
    async function enroll(tries = 0) {
      if (tries > 3)
        return;

      const { data, error } = await supabase.auth.mfa.enroll({
        factorType: 'totp',
      });

      if (error) {
        const { data: factors } = await supabase.auth.mfa.listFactors();

        await Promise.all(factors.all.map(factor => supabase.auth.mfa.unenroll({
          factorId: factor.id,
        })));

        enroll(tries + 1);
      }

      setFactorId(data.id);

      // Supabase Auth returns an SVG QR code which you can convert into a data
      // URL that you can place in an <img> tag.
      setQR(data.totp.qr_code)
    }

    enroll(0);
  }, [])
  return <Group position="center">
    <Text
      variant="gradient"
      gradient={{ from: 'indigo', to: 'cyan', deg: 45 }}
      ta="center"
      fw={700}
    >
      Leia o QR code com o seu aplicativo de autenticação favorito (ex: google authenticator)
    </Text>
    <Image src={qr} width={240} style={{ border: "16px solid white" }} />
    <Text
      variant="gradient"
      gradient={{ from: 'indigo', to: 'cyan', deg: 45 }}
      ta="center"
      fw={700}
    >
      Após leitura, digite o codigo gerado
    </Text>
    <PinInput length={6} value={verifyCode} onChange={setVerifyCode} onComplete={handleMFA} />
  </Group>
}

export default function AuthUI({ showMFAScreen, enrollMFA }) {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [mode, setMode] = useState("Login");

  const navigate = useNavigate();

  const url = new URL(window.location.href);
  const params = url.searchParams;

  const confirmation_url = params.get("confirmation_url");
  const confirmation_text = params.get("confirmation_text");
  const error_description = params.get("error_description");

  useEffect(() => {
    if (error_description) {
      setError(error_description);
    }
  }, [error_description]);

  const supabase = useSupabaseClient();

  const handleLogin = async () => {
    try {
      setLoading(true);

      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });

      if (error) {
        notifications.show({
          withCloseButton: true,
          autoClose: 3000,
          title: "Login Inválido",
          message: "Usuário ou senha inválido",
          color: "red",
          icon: <IconX />,
          loading: false,
        });
      } else {
        navigate("/");
      }
    } catch (ex) {
    } finally {
      setLoading(false);
    }
  };

  const handleLoginAzure = async () => {
    const { data, error } = await supabase.auth.signInWithOAuth({
      provider: 'azure',
      options: {
        scopes: 'email'
      },
    })
  };

  const handleMagicLink = async () => {
    try {
      setLoading(true);

      const stripTrailingSlash = (str) => {
        return str.endsWith('/') ?
          str.slice(0, -1) :
          str;
      };

      const { data, error } = await supabase.auth.signInWithOtp({
        email,
        options: {
          shouldCreateUser: false,
          emailRedirectTo: stripTrailingSlash(window.location.href),
        },
      });

      if (error) {
        notifications.show({
          withCloseButton: true,
          autoClose: 3000,
          message: error.message,
          color: "red",
          icon: <IconX />,
          loading: false,
        });
      }
      else {
        notifications.show({
          withCloseButton: true,
          autoClose: 3000,
          title: "E-mail enviado com sucesso!",
          message: "Caso o e-mail não esteja na sua caixa de entrada, favor verificar a caixa de spam.",
          color: "green",
          icon: <IconCheck />,
          loading: false,
        });
      }
    }
    finally {
      setLoading(false);
    }
  }

  const handleClickConfirmation = () => {
    document.location.href = confirmation_url;
  }

  return (
    <Center className={classes.patternContainer} sx={{ maxWidth: "100vw", }}>
      {/*<Grid.Col  className={classes.wrapper} span="content" pr={0}>*/}
      <Stack spacing={10}>
        <Paper shadow="md" withBorder className={classes.apps} radius={"md"} p={0}>
          <Grid grow gutter={0} >
            <Grid.Col span={5}>
              <Stack>
                <Center>
                  <img style={{ zIndex: 9999, position: "absolute" }} src={"./monitoramento.svg"} width="36" />
                </Center>
                <Button px={0} py={4} fullWidth style={{ fontWeight: 700, color: "rgb(75, 75, 75)" }} variant="subtle" component="a" href="https://www.cccfacil.com.br" >Monitoramento Fácil </Button>

              </Stack>
            </Grid.Col>
            {!window.location.href.includes("cccambientalfacil.com.br") &&
              <Grid.Col span={4}>
                <Stack>
                  <Center>
                    <img style={{ zIndex: 9999, position: "absolute" }} src={"./ambiental.svg"} width="38" />
                  </Center>
                  <Button px={0} py={4} fullWidth style={{ fontWeight: 700, color: "rgb(75, 75, 75)" }} variant="subtle" component="a" href="https://www.cccambientalfacil.com.br" >Ambiental Fácil</Button>

                </Stack>
              </Grid.Col>}
            {!window.location.href.includes("ccccontrolefacil.com.br") && !window.location.href.includes("cccfacilcontrole.com.br") &&
              <Grid.Col span={3}>
                <Stack>
                  <Center >
                    <img style={{ zIndex: 9999, position: "absolute" }} src={"./controle.svg"} width="30" />
                  </Center>
                  <Button px={0} py={4} fullWidth style={{ fontWeight: 700, color: "rgb(75, 75, 75)" }} variant="subtle" component="a" href="https://www.ccccontrolefacil.com.br" >Controle Fácil</Button>
                </Stack>
              </Grid.Col>}
            {!window.location.href.includes("cccajfacil.com.br") && <Grid.Col span={3}>
              <Stack>
                <Center>
                  <img style={{ zIndex: 9999, position: "absolute" }} src={"./aj.svg"} width="30" />
                </Center>
                <Button px={0} py={4} fullWidth style={{ fontWeight: 700, color: "rgb(75, 75, 75)" }} variant="subtle" component="a" href="https://www.cccajfacil.com.br" >AJ Fácil</Button>
              </Stack>
            </Grid.Col>}
          </Grid>
        </Paper>
        <Paper shadow="md" withBorder className={classes.form} radius={"md"} p={30}>
          <Flex py={5} sx={{ justifyContent: "center" }}>
            {window.location.href.includes("cccambientalfacil.com.br") && <img src={"./ccc-amb.svg"} width="330" />}
            {window.location.href.includes("cccajfacil.com.br") && <img src={"./ccc-aj.svg"} width="330" />}
            {window.location.href.includes("ccccontrolefacil.com.br") && <img src={"./ccc-controle.svg"} width="330" />}
            {window.location.href.includes("cccfacilcontrole.com.br") && <img src={"./ccc-controle.svg"} width="330" />}
          </Flex>

          <Title order={4} className={classes.title} ta="center" mb={20} style={{ color: "rgb(75, 75, 75)" }} >
            {"Bem - vindo ao CCC " +
              (window.location.href.includes("cccambientalfacil.com.br") ?
                "Ambiental" :
                (window.location.href.includes("cccajfacil.com.br") ?
                  "AJ" :
                  "Controle"))
              + " Fácil!"}
          </Title>

          {confirmation_url && <Button
            fullWidth
            mt="xl"
            size="md"
            onClick={handleClickConfirmation}
          >
            {confirmation_text || 'Confirmar email'}
          </Button>}

          {!confirmation_url && showMFAScreen && enrollMFA && (<EnrollMfa />)}

          {!confirmation_url && showMFAScreen && !enrollMFA && (<AuthMfa />)}

          {!confirmation_url && !showMFAScreen && mode == "Login" && (
            <>
              <TextInput
                placeholder="Usuário"
                size="lg"
                value={email}
                onChange={(event) => setEmail(event.currentTarget.value)}
              />
              <PasswordInput
                placeholder="Senha"
                mt="md"
                size="lg"
                value={password}
                onKeyDown={(event) => event.key === 'Enter' ? handleLogin() : ""}
                onChange={(event) => setPassword(event.currentTarget.value)}
              />
              <Button
                loading={loading}
                fullWidth
                mt="xl"
                size="md"
                onClick={handleLogin}
              >
                Login
              </Button>

              <Anchor component="button" type="button" align="center" onClick={() => {
                setMode("MagicLink");
                setEmail("");
              }} mt="md" className={classes.anchor}>
                Esqueceu a senha? Clique para fazer login sem senha!
              </Anchor>

              {<Button
                loading={loading}
                fullWidth
                mt="xl"
                size="md"
                variant="default"
                leftIcon={<IconBrandAzure color="#0080ff" size="1.5rem" />}
                onClick={handleLoginAzure}
              >
                Login com Azure
              </Button>}

              {error && <Notification mt="xl" withBorder icon={<IconX size="1.1rem" />} onClose={() => setError("")} color="red">
                {error}
              </Notification>}
            </>
          )}

          {!confirmation_url && !showMFAScreen && mode == "MagicLink" && (
            <>
              <TextInput
                label="Email"
                placeholder="usuario@email.com"
                size="md"
                value={email}
                onChange={(event) => setEmail(event.currentTarget.value)}
              />
              <Button
                loading={loading}
                fullWidth
                mt="xl"
                size="md"
                onClick={handleMagicLink}
              >
                Enviar link de acesso por email
              </Button>

              <Anchor component="button" type="button" align="center" onClick={() => {
                setMode("Login");
                setEmail("");
              }} mt="md" className={classes.anchor}>
                Voltar para login com senha
              </Anchor>
            </>
          )}
        </Paper>
      </Stack >
      {/*</Grid.Col>*/}
      {/*<Grid.Col span="auto" className={classes.patterncontainer}>*/}

      {/*</Grid.Col>*/}
    </Center>
  );
}
