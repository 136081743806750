import {
  UnstyledButton,
  Group,
  Image,
  Text,
  createStyles,
} from "@mantine/core";

import { forwardRef } from 'react';

import { IconChevronRight } from "@tabler/icons-react";

const useStyles = createStyles((theme) => ({
  user: {
    display: 'block',
    width: '100%',
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
    paddingTop: theme.spacing.xs,
    paddingBottom: theme.spacing.xs,
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[2],
    },
  },
}));

const UserButton = forwardRef(function UserButton({ user, name, email, icon, collapsed, ...others }, ref) {
  const { classes } = useStyles();

  return (
    <UnstyledButton {...others} ref={ref} className={classes.user}>
      <Group spacing={6} style={{ flex: 1, flexWrap: "nowrap" }}>
        <Image
          radius="xl"
          color="primary" src={"./logo/" + user?.user_metadata?.tenant_id + ".png"}
          withPlaceholder
          placeholder={<Image radius="xl" color="primary" src={"./c60.png"}></Image>}
          height={40}
          width={40}
        />

        {!collapsed && <><div style={{ flex: 1, flexWrap: "nowrap" }}>
          <Text size="sm" weight={500}>
            {name}
          </Text>

          <Text color="dimmed" size="xs" style={{
            "maxWidth": "160px",
            "whiteSpace": "nowrap",
            "overflow": "hidden",
            "textOverflow": "ellipsis"
          }} >
            {email}
          </Text>
        </div>

          {icon || <IconChevronRight size="0.9rem" stroke={1.5} />}
        </>
        }
      </Group>
    </UnstyledButton>
  );
})

export { UserButton }