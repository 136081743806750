import { createClient } from '@supabase/supabase-js'

const getBaseUrl = (href: string) => {
  const url = new URL(href);
  return `${url.protocol}//${url.hostname}`;
};

const supabaseClient = createClient(
  window.location.href.includes("localhost") ? "https://cccfacilcontrole.com.br" : getBaseUrl(window.location.href),
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiYW5vbiIsImlzcyI6InN1cGFiYXNlLWRlbW8iLCJpYXQiOjE2NDE3NjkyMDAsImV4cCI6MTc5OTUzNTYwMH0.VzlJ1lOmLMKsHs5Nq61ZNRjbsPh3RLgDFXeGjSLPlHY"
);

export default supabaseClient;
