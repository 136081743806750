import {
  Paper,
  createStyles,
  TextInput,
  Button,
  Title,
  rem, Flex, Center,  Grid, Divider, Box,  Switch, Select, MultiSelect, NumberInput, Textarea,
} from "@mantine/core";

import React from "react";

import { useForm } from "@mantine/form";

import { Input } from '@mantine/core';
import { useId } from '@mantine/hooks';
import { IMaskInput } from 'react-imask';

const useStyles = createStyles((theme) => ({
  anchor: {
    width: "100%",
  },
  wrapper: {
  },
  patternContainer: {
    minHeight: "calc(100vh - 10px)",
    backgroundSize: "500px 500px",
    backgroundRepeat: "repeat",
    backgroundPosition: "center",
    backgroundColor: theme.colorScheme === "dark" ? "rgba(7,12,16,0.95)" : "rgba(251,253,255,0.95)",
    backgroundBlendMode: theme.colorScheme === "dark" ? "darken" : "lighten",
    backgroundImage: "url(https://www.cccfacilcontrole.com.br/c-color.svg)",
  },

  form: {
    maxWidth: rem(800),
    [theme.fn.smallerThan("sm")]: {
      maxWidth: "100%",
    },
  },

  title: {
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },
}));

export default function AuthUI({ showMFAScreen, enrollMFA }) {
  const { classes } = useStyles();

  const cpfId = useId();
  const brazil_states = [
    'AC',  // Acre
    'AL',  // Alagoas
    'AP',  // Amapá
    'AM',  // Amazonas
    'BA',  // Bahia
    'CE',  // Ceará
    'DF',  // Distrito Federal
    'ES',  // Espírito Santo
    'GO',  // Goiás
    'MA',  // Maranhão
    'MT',  // Mato Grosso
    'MS',  // Mato Grosso do Sul
    'MG',  // Minas Gerais
    'PA',  // Pará
    'PB',  // Paraíba
    'PR',  // Paraná
    'PE',  // Pernambuco
    'PI',  // Piauí
    'RJ',  // Rio de Janeiro
    'RN',  // Rio Grande do Norte
    'RS',  // Rio Grande do Sul
    'RO',  // Rondônia
    'RR',  // Roraima
    'SC',  // Santa Catarina
    'SP',  // São Paulo
    'SE',  // Sergipe
    'TO'   // Tocantins
  ]
  const debitor = [
      {
        label: '[123 Milhas] 123 VIAGENS E TURISMO LTDA. - CNPJ: 26.669.170/0001-57',
          value: '26669170000157'
      },
      {
        label: '[Hot Milhas] ART VIAGENS E TURISMO LTDA - EPP - CNPJ: 11.442.110/0001-20',
          value: '11442110000120'
      },
      {
        label: 'NOVUM INVESTIMENTOS PARTICIPACOES S/A - CNPJ: 26.941.940/0001-79',
          value: '26941940000179'
      },
  ]

  const creditClass = [
      {
        label: 'Classe 1 - Trabalhista',
          value: 1
      },
      {
        label: 'Classe 2 - Garantia Real',
          value: 2
      },
      {
        label: 'Classe 3 - Quirografário',
          value: 3
      },
      {
        label: 'Classe 4 - ME/EPP',
          value: 4
      },
      {
        label: 'Extraconcursal',
          value: 5
      },
  ]

  const currency = [
      {
        label: 'Real (R$)',
          value: 'BRL'
      },
      {
        label: 'Dólar (U$)',
          value: 'USD'
      },
      {
        label: 'Euro (€)',
          value: 'EUR'
      },
      {
        label: 'Outra',
          value: 'OUTRA'
      },
  ]

  const attachmentSample = [
    'Anexo 1',
    'Anexo 2',
    'Anexo 3',
    'Anexo 4',
    'Anexo 5',
  ]

  const form = useForm({
    initialValues: {
      creditorName: '',
      creditorDoc: '',
      creditorEmail: '',
      creditorPhone: '',
      creditorAddress: '',
      creditorAddressNumber: '',
      creditorState: '',
      creditorCity: '',
      creditorZipcode: '',
      creditorHaveAttorney: false,

      representativeName: '',
      representativeDoc: '',
      representativeEmail: '',
      representativePhone: '',
      representativeAddress: '',
      representativeAddressNumber: '',
      representativeState: '',
      representativeCity: '',
      representativeZipcode: '',
      representativeAttachment: [],

      attorneyName: '',
      attorneyDoc: '',
      attorneyClassNumber: '',
      attorneyEmail: '',
      attorneyPhone: '',
      attorneyAddress: '',
      attorneyAddressNumber: '',
      attorneyState: '',
      attorneyCity: '',
      attorneyZipcode: '',
      attorneyAttachment: [],

      debitor: '',
      creditClass: '',
      creditCurrency: '',
      creditAmount: '',
      disputeType: '',
      disputeDescription: '',
      disputeAttachment:[],
    },

    validate: {

    },
  });

  return (
    <Center className={classes.patternContainer} sx={{ maxWidth: "100vw", }}>
      <Paper shadow="md" withBorder className={classes.form} radius={"md"} px={30} py={5}>
        <Flex py={30} sx={{ justifyContent: "center" }}
              wrap="wrap" >
          <img src={"./japur.jpg"} width="330" />
        </Flex>
        <div>
        <Divider
            my="xs"
            mx="g"
            variant="dashed"
            labelPosition="center"
            label={
              <>
                <Box sx={{ fontWeight: 700, fontSize: 15 }} >Informações do Credor</Box>
              </>
            }
        />
        <Grid>
          <Grid.Col xs={12} sm={8} md={8} lg={8}>
            <TextInput

              required
              label="Nome/Razão Social"
              {...form.getInputProps("creditorName")}
            />
          </Grid.Col>

          <Grid.Col  xs={12} sm={4} md={4} lg={4}>
            <Input.Wrapper id={cpfId} label="CPF/CNPJ" required>
              <Input
                 {...form.getInputProps("creditorDoc")}
                component={IMaskInput}
                mask={[
                  {
                    mask: '000.000.000-00',
                    regex: /^(\d{0,11})$/,
                  },
                  {
                    mask: '00.000.000/0000-00',
                    regex: /^(\d{11})(\d{0,14})$/,
                  },
                ]}
                definitions={{
                  '0': /[0-9]/,
                }}
                id={cpfId}
              />
            </Input.Wrapper>
          </Grid.Col>

          <Grid.Col xs={12} sm={6} md={6} lg={6}>
            <TextInput
                required
                label="Email"
                {...form.getInputProps("creditorEmail")}
            />
          </Grid.Col>

          <Grid.Col xs={12} sm={3} md={3} lg={3}>
            <TextInput
                required
                label="Telefone"
                {...form.getInputProps("creditorPhone")}
            />
          </Grid.Col>

          <Grid.Col xs={12} sm={3} md={3} lg={3}>
            <TextInput
                required
                label="CEP"
                {...form.getInputProps("creditorZipcode")}
            />
          </Grid.Col>

          <Grid.Col xs={12} sm={4} md={4} lg={4}>
            <TextInput
                required
                label="Endereço"
                {...form.getInputProps("creditorAddress")}
            />
          </Grid.Col>

          <Grid.Col xs={12} sm={2} md={2} lg={2}>
            <TextInput
                required
                label="Número"
                {...form.getInputProps("creditorAddressNumber")}
            />
          </Grid.Col>

          <Grid.Col xs={12} sm={2} md={2} lg={2}>
            <Select
                label="Estado"
                searchable
                nothingFound="Não Encontrado"
                data={brazil_states}
                {...form.getInputProps("creditorState")}
            />
          </Grid.Col>

          <Grid.Col xs={12} sm={4} md={4} lg={4}>
            <TextInput
                required
                label="Cidade"
                {...form.getInputProps("creditorCity")}
            />
          </Grid.Col>
        </Grid>
          <Flex  sx={{ justifyContent: "right" }}>
            <Switch
              my={"sm"}
              size="sm"
              sx={{fontWeight:600}}
              labelPosition="left"
              label="Possui Procurador?"
              onLabel={<Box sx={{ fontWeight: 700, fontSize: 12 }} >Sim</Box>}
              offLabel={<Box sx={{ fontWeight: 700, fontSize: 12 }} >Não</Box>}
              {...form.getInputProps("creditorHaveAttorney")}
          />
          </Flex>

        </div>
        { form.getInputProps("creditorDoc").value.length > 14 && <div>
          <Divider
              mt={40}
              variant="dashed"
              sx={"bold"}
              labelPosition="center"
              label={
                <>
                  <Box sx={{fontWeight: 700, fontSize: 15}}>Informações do Representante Legal</Box>
                </>
              }

          />
          <Grid>
            <Grid.Col xs={12} sm={8} md={8} lg={8}>
              <TextInput

                  required
                  label="Nome"
                  {...form.getInputProps("representativeName")}
              />
            </Grid.Col>

            <Grid.Col xs={12} sm={4} md={4} lg={4}>
              <Input.Wrapper id={cpfId} label="CPF" required>
                <Input
                    component={IMaskInput}
                    mask={[
                      {
                        mask: '000.000.000-00',
                        regex: /^(\d{0,11})$/,
                      },
                      {
                        mask: '00.000.000/0000-00',
                        regex: /^(\d{11})(\d{0,14})$/,
                      },
                    ]}
                    definitions={{
                      '0': /[0-9]/,
                    }}
                    id={cpfId}
                />
              </Input.Wrapper>
            </Grid.Col>


            <Grid.Col xs={12} sm={6} md={6} lg={6}>
              <TextInput
                  required
                  label="Email"
                  {...form.getInputProps("representativeEmail")}
              />
            </Grid.Col>

            <Grid.Col xs={12} sm={3} md={3} lg={3}>
              <TextInput
                  required
                  label="Telefone"
                  {...form.getInputProps("representativePhone")}
              />
            </Grid.Col>

            <Grid.Col xs={12} sm={3} md={3} lg={3}>
              <TextInput
                  required
                  label="CEP"
                  {...form.getInputProps("representativeZipcode")}
              />
            </Grid.Col>

            <Grid.Col xs={12} sm={4} md={4} lg={4}>
              <TextInput
                  required
                  label="Endereço"
                  {...form.getInputProps("representativeAddress")}
              />
            </Grid.Col>

            <Grid.Col xs={12} sm={2} md={2} lg={2}>
              <TextInput
                  required
                  label="Número"
                  {...form.getInputProps("representativeAddressNumber")}
              />
            </Grid.Col>

            <Grid.Col xs={12} sm={2} md={2} lg={2}>
              <Select
                  label="Estado"
                  searchable
                  nothingFound="Não Encontrado"
                  data={brazil_states}
                  {...form.getInputProps("representativeState")}
              />
            </Grid.Col>

            <Grid.Col xs={12} sm={4} md={4} lg={4}>
              <TextInput
                  required
                  label="Cidade"
                  {...form.getInputProps("representativeCity")}
              />
            </Grid.Col>

            <Grid.Col xs={12} sm={12} md={12} lg={12}>
              <MultiSelect
                  data={attachmentSample}
                  label="Documentação Comprobatória"
                  placeholder="Clique para selecionar os arquivos"
                  {...form.getInputProps("representativeAttachment")}
              />
            </Grid.Col>
          </Grid>
        </div>}
        {form.getInputProps("creditorHaveAttorney").value && <div>
          <Divider
              mt={40}
              variant="dashed"
              sx={"bold"}
              labelPosition="center"
              label={
                <>
                  <Box sx={{fontWeight: 700, fontSize: 15}}>Informações do Procurador</Box>
                </>
              }

          />
          <Grid>
            <Grid.Col xs={12} sm={6} md={6} lg={6}>
              <TextInput

                  required
                  label="Nome"
                  {...form.getInputProps("attorneyName")}
              />
            </Grid.Col>

            <Grid.Col xs={12} sm={3} md={3} lg={3}>
              <Input.Wrapper id={cpfId} label="CPF" required>
                <Input
                    component={IMaskInput}
                    mask={[
                      {
                        mask: '000.000.000-00',
                        regex: /^(\d{0,11})$/,
                      },
                      {
                        mask: '00.000.000/0000-00',
                        regex: /^(\d{11})(\d{0,14})$/,
                      },
                    ]}
                    definitions={{
                      '0': /[0-9]/,
                    }}
                    id={cpfId}
                />
              </Input.Wrapper>
            </Grid.Col>

            <Grid.Col xs={12} sm={3} md={3} lg={3}>
              <TextInput
                  required
                  label="OAB"
                  {...form.getInputProps("attorneyClassNumber")}
              />
            </Grid.Col>

            <Grid.Col xs={12} sm={6} md={6} lg={6}>
              <TextInput
                  required
                  label="Email"
                  {...form.getInputProps("attorneyEmail")}
              />
            </Grid.Col>

            <Grid.Col xs={12} sm={3} md={3} lg={3}>
              <TextInput
                  required
                  label="Telefone"
                  {...form.getInputProps("attorneyPhone")}
              />
            </Grid.Col>

            <Grid.Col xs={12} sm={3} md={3} lg={3}>
              <TextInput
                  required
                  label="CEP"
                  {...form.getInputProps("attorneyZipcode")}
              />
            </Grid.Col>

            <Grid.Col xs={12} sm={4} md={4} lg={4}>
              <TextInput
                  required
                  label="Endereço"
                  {...form.getInputProps("attorneyAddress")}
              />
            </Grid.Col>

            <Grid.Col xs={12} sm={2} md={2} lg={2}>
              <TextInput
                  required
                  label="Número"
                  {...form.getInputProps("attorneyAddressNumber")}
              />
            </Grid.Col>

            <Grid.Col xs={12} sm={2} md={2} lg={2}>
                <Select
                    label="Estado"
                    searchable
                    nothingFound="Não Encontrado"
                    data={brazil_states}
                    {...form.getInputProps("attorneyState")}
                />
            </Grid.Col>

            <Grid.Col xs={12} sm={4} md={4} lg={4}>
              <TextInput
                  required
                  label="Cidade"
                  {...form.getInputProps("attorneyCity")}
              />
            </Grid.Col>

            <Grid.Col xs={12} sm={12} md={12} lg={12}>
              <MultiSelect
                  data={attachmentSample}
                  label="Documentação Comprobatória"
                  placeholder="Clique para selecionar os arquivos"
                  {...form.getInputProps("attorneyAttachment")}
              />
            </Grid.Col>
          </Grid>
        </div>}
        <div>
        <Divider
            mt={40}
            variant="dashed"
            sx={"bold"}
            labelPosition="center"
            label={
              <>
                <Box sx={{ fontWeight: 700, fontSize: 15 }} >Solicitação de Habilitação</Box>
              </>
            }

        />
        <Grid>
          <Grid.Col xs={12} sm={12} md={12} lg={12}>
            <Select
                label="Recuperanda"
                required
                data={debitor}
                {...form.getInputProps("debitor")}
            />
          </Grid.Col>

          <Grid.Col xs={12} sm={4} md={4} lg={4}>
            <Select
                required
                label="Classe"
                data={creditClass}
                {...form.getInputProps("creditClass")}
            />
          </Grid.Col>

          <Grid.Col xs={12} sm={4} md={4} lg={4}>
            <Select
                label="Moeda"
                required
                data={currency}
                {...form.getInputProps("creditCurrency")}
            />
          </Grid.Col>

          <Grid.Col xs={12} sm={4} md={4} lg={4}>
            <NumberInput
                required
                hideControls
                decimalSeparator=","
                thousandsSeparator="."
                precision={2}
                parser={(value) => value.replace(/\s?|(\.*)/g, '')}
                formatter={(value) =>
                    !Number.isNaN(parseFloat(value))
                        ? `${value}`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, '.')
                        : ''
                }
                label="Valor"
                {...form.getInputProps("creditAmount")}
            />
          </Grid.Col>

          <Grid.Col xs={12} sm={12} md={12} lg={12}>
            <Textarea
                required
                minRows={2}
                maxRows={20}
                autosize
                label="Descrição da Solicitação"
                {...form.getInputProps("disputeDescription")}
            />
          </Grid.Col>

          <Grid.Col xs={12} sm={12} md={12} lg={12}>
            <MultiSelect
                data={attachmentSample}
                label="Documentação Comprobatória"
                placeholder="Clique para selecionar os arquivos"
                {...form.getInputProps("disputeAttachment")}
            />
          </Grid.Col>

        </Grid>
        </div>
        <Flex pt={30} sx={{ justifyContent: "right" }}>
          <Button>Enviar</Button>
        </Flex>
        <Flex sx={{ justifyContent: "center" }}>
          <Title size={11} weight={600} >- Desenvolvido por CCC Monitoramento -</Title>
        </Flex>
      </Paper>
    </Center>
  );
}