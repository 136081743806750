import React, { forwardRef, useEffect, useState } from 'react';
import { Card, Autocomplete, ActionIcon, Group, Text, Center, Loader } from '@mantine/core';
import {IconHistory, IconSearch, IconAffiliateFilled} from "@tabler/icons-react";
import { layerProperties } from "./layerProperties";
import L from "leaflet";
import useSWR from "swr";
import {useSupabaseClient, useUser} from "@supabase/auth-helpers-react";



function identifyInputType(input) {
  //verifica se o input é um array
  if (!Array.isArray(input)) {
    //se não for, transforma em array e em seguida em string
    input = [input];
  }
  //intera sobre o array de input
    if (!isNaN(input[0])) {
      input[0] = input[0].toString();
    }
    const inputTrimmed = input[0].replace(/[\s.,\-/]/g, ""); // Remove espaços em branco
    const regexLatLong = /^-?\d+\.\d+[,; ]\s*-?\d+\.\d+$/; // Regex para coordenadas latLong
    if (inputTrimmed.length === 11) {
      return 'CPF';
    } else if (inputTrimmed.length === 14 || inputTrimmed.length === 8) {
      return 'CNPJ';
    } else if (/^[a-zA-Z]{2}/.test(inputTrimmed)) {
      return 'CAR';
    } else if (regexLatLong.test(inputTrimmed)) {
      return 'latLong';
    } else {
      return 'INCRA';
    }
}

export function GlobalSearch({ codRef, query, layerRef, map, setSearchData, searchDataRef, hideButtons }) {
  const [tempSearchCod, setTempSearchCod] = useState("");
  const [searchCod, setSearchCod] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isHistory, setIsHistory] = useState(false);
  const [isRelated, setIsRelated] = useState(false);
  const [listData, setListData] = useState([]);
  const [searchInfo, setSearchInfo] = useState(null);
  const supabase = useSupabaseClient();
  const user = useUser();

  const { data: registryData, error: error, isLoading: IsLoading } = useSWR(!query && {
    tableName: 'esg_registry',
    columns: "cod_list,name",
    orders: [["id", { ascending: true }]],
  });

  useEffect(() => {
    if (query) {
      setTempSearchCod(query);
      handleSearch();
    }
  }, []);

  useEffect(() => {
    if (registryData && !IsLoading) {
      const data = registryData.filter(f => f.cod_list.length > 0).map((item) => {
        return { value: item.cod_list[0].cod, label: item.name };
      });
      setListData(data);
    }

  }, [registryData, IsLoading]);

  useEffect(() => {
    const fetchData = async () => {
      const inputType = identifyInputType(searchCod);
      if (inputType === "CPF" || inputType === "CNPJ" || inputType === 'doc') {
        const inputTrimmed = searchCod.replace(/[\s.,\-/]/g, "");
        const { data, error } = await supabase.rpc("f_search_info", {
          _doc: ((inputType === "CNPJ" || inputType === 'doc') && inputTrimmed.length === 14) ? inputTrimmed.substring(0,8) : inputTrimmed,
          _history: isHistory
        });
        if (data) {
          setSearchInfo(data);
        }
      }
    };
    fetchData();
  }, [searchCod, isHistory]);

  async function handleSearch() {
    let cod = tempSearchCod || query
    setSearchCod(cod);
    const inputType = identifyInputType(cod);
    let cqlFilter = '';
    let sources = [];
    if (inputType === 'latLong') {
      const latLong = cod.split(/[,; ]/);
      const lat = parseFloat(latLong[0]);
      const long = parseFloat(latLong[1]);
      if (lat && long) {
        cqlFilter = `intersects(geom, SRID=4326;POINT(${long} ${lat}))`;
      }
    } else if (inputType === 'CPF' || inputType === 'CNPJ' || inputType === 'doc') {
      if (!Array.isArray(cod)) {
        cod = [cod];
      }
      cod.forEach((item, index) => {
        item = item.toString().replace(/[\s.\-/]/g, "")
        if (inputType === 'CNPJ' || inputType === 'doc') {
          item = item.substring(0, 8);
        }
        cod[index] = `'${item}'`;
      });
      cod = cod.join(",");
      cqlFilter = `doc IN (${cod})`;
    } else {
      if (!Array.isArray(cod)) {
        cod = [cod];
      }
      cod.forEach((item, index) => {
        cod[index] = `'${item}'`;
      });
      cod = cod.join(",");
      cqlFilter = `cod IN (${cod})`;
    }
    sources = [isHistory ? "v_rural_property_history" : "v_rural_property"];
    setIsLoading(true);

    if (layerRef?.current.customGeoJSON) {
      map.removeLayer(layerRef?.current.customGeoJSON);
      delete layerRef?.current.customGeoJSON;
    }

    if (layerRef?.current.customMarkers) {
      map.removeLayer(layerRef?.current.customMarkers);
      delete layerRef?.current.customMarkers;
    }

    async function callUpdateOverlap() {
      if (inputType === "doc" && (tempSearchCod.length === 11 || tempSearchCod.length === 14 || tempSearchCod.length === 8)) {
        const {data, error} = await supabase.rpc('f_update_overlap', {
          _cod_list: [{cod: tempSearchCod, codType: inputType}]
        });
        if (error) {
          console.error('Error:', error);
        } else {
        }
      }
    }

    await callUpdateOverlap();

    let fetchPromises = sources.map(source => {

      const wfsUrl = supabase.supabaseUrl + '/geoserver/v2/ccc/ows';
      const wfsParams = {
        service: 'WFS',
        version: '2.0.0',
        request: 'GetFeature',
        typeName: 'ccc:' + source,
        count: 1990,
        outputFormat: 'application/json',
        cql_filter: cqlFilter
      };

      if (source === "v_car_prop") source = "car";
      const wfsQueryString = new URLSearchParams(wfsParams).toString();
      return fetch(`${wfsUrl}?${wfsQueryString}`)
        .then(response => response.json())
        .then(data => {
          setSearchData({ data: data, source: source });
          searchDataRef.current = { data: data, source: source };
        })
        .catch(error => {
          console.error('Error:', error);
        })
        .finally(() => {
          setIsLoading(false);
        });;

    });

    Promise.all(fetchPromises).then(() => {
    });
  }

  const AutocompleteItem = forwardRef(({ value, label, ...others }, ref) => (
    <div ref={ref} {...others}>
      <Text>{label}</Text>
      <Text size="xs" color="dimmed">{value}</Text>
    </div>
  ));

  if (hideButtons) return null;

  return (
    <Card w={400} shadow={"sm"} p={4} m={2} style={{ position: "absolute", left: "40px", bottom: "50%", zIndex: 999, transform: "translateY(50%)", backgroundColor: "rgba(227,227,227,0.85)" }}>
      {searchInfo && <Center>
        <Card p={"xs"} w={"100%"}>
          <Center>
            {searchInfo?.name && <Text fw={700} size={14}>{searchInfo.name}</Text>}
            {!searchInfo?.name && <Group><Text fw={700} size={14}>Sem resultados</Text></Group>}
          </Center>
          <Center>
            {searchInfo?.name && <Group>
              <div>
                <Text size="xs" color="dimmed">
                  Qtd.
                </Text>
                <Text fw={600} size={"xs"}>
                  {searchInfo.qtd}
                </Text>
              </div>
              <div>
                <Text size="xs" color="dimmed">
                  Área (ha)
                </Text>
                <Text fw={600} size={"xs"}>
                  {searchInfo.area}
                </Text>
              </div>
            </Group>}
          </Center>
        </Card>
      </Center>}
       <Autocomplete
        withinPortal
        zIndex={800}
        maxDropdownHeight={500}
        itemComponent={AutocompleteItem}
        maxLength={545}
        onPaste={(event) => {
          const pastedText = event.clipboardData.getData('text');
          setTempSearchCod(pastedText);
        }}
        onChange={(value) => {
          setTempSearchCod(value)
        }}
        placeholder={'Latitude; Longitude ex: -10.1234, 30.1234'}
        limit={30}
        onItemSubmit={(value) => {
          setTempSearchCod(value.value);
        }}
        defaultValue={codRef.current || ""}
        data={listData}
        rightSectionWidth={70}
        rightSection={
          <>
            {/*<ActionIcon onClick={() => {*/}
            {/*  setIsRelated(!isRelated);*/}
            {/*}} color={isRelated ? "primary" : "gray.5"} variant="subtle"  >*/}
            {/*  <IconAffiliateFilled />*/}
            {/*</ActionIcon>*/}
            <ActionIcon onClick={() => {
              setIsHistory(!isHistory);
            }} color={isHistory ? "primary" : "gray.5"} variant="subtle">
              <IconHistory/>
            </ActionIcon>
            <ActionIcon
              disabled={!tempSearchCod}
              onClick={() => {
              if (tempSearchCod && (user.user_metadata.is_admin || listData.map(item => item.value).includes(tempSearchCod))) {
                handleSearch();
              }
            }} color="primary" variant="subtle">{isLoading ? <Loader/> : <IconSearch/>} </ActionIcon>
          </>
        }/>
    </Card>
  )
}
