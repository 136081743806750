import React, { useState } from 'react';
import { MultiSelect, ScrollArea, Table, Pagination, Card, Text, Grid, Center, Group, Input, Timeline, Container } from "@mantine/core";
import { translate, group, format } from '../lib/bigdata';
import GenericTableComponent from "./GenericTableComponent";

function InfoText({ label, value }) {
  return (
    <div>
      <Text size="xs" color="dimmed">
        {label}
      </Text>
      <Text fw={600} size={"xs"}>
        {value}
      </Text>
    </div>
  )
}


function RenderCard({ title, data }) {
  return (
    <Center style={{ flexGrow: 1 }}>
      <Card withBorder p={"xs"} maw={900} style={{ width: '100%' }}>
        <Text pb={"xs"} size="sm" weight={700}>{title}</Text>
        <Group position={"apart"}>
          {data?.items?.filter(vc => vc.value != "" && vc.value != null && vc.value != 0).map((dt) => (
            <InfoText label={dt.title} value={dt.value} />
          ))}
        </Group>
      </Card>
    </Center>
  );
}


function RenderTable({ data, schema, title }) {
  return (
    <Center style={{ flexGrow: 1, width: '100%' }}>
      <Table striped highlightOnHover withBorder withColumnBorders style={{ width: '100%' }} p={"xs"}>
        <thead>
          <tr>
            <th colSpan={schema.length}>{title}</th>
          </tr>
          <tr>
            {schema.map(si => <th>{si.label}</th>)}
          </tr>
        </thead>
        <tbody>
          {data.map(dr => <tr>
            {schema.map(si => <td>{dr[si.key]}</td>)}
          </tr>)}
        </tbody>
      </Table>
    </Center>
  )
}

function OpExtraInfoComponent({ className, data }) {


  return (
    <Container fluid my="md" className={className}  >
      <Group position={"apart"} maw={900}>
        {data?.extrainfo?.cards
          ?.filter(val => val?.items?.some(vc => vc.value && !["Valor inválido", "Sem adesão a seguro", "Não se aplica", "Sem complemento"].includes(vc.value)))
          .map(di => <RenderCard title={di.title} data={di} />)}
        {data?.extrainfo?.tables.map(di => <RenderTable title={di.title} data={di.data} schema={di.schema} />)}
      </Group>
    </Container>
  );
}

export default OpExtraInfoComponent;