import { ActionIcon } from "@mantine/core";
import { IconDownload } from "@tabler/icons-react";

export let default_pessoas = [
  'basic_data',
  'addresses_extended',
  'emails_extended',
  'phones_extended',
  'occupation_data',
  'social_assistance_extended',
  'processes',
  'government_debtors',
  'lawsuits_distribution_data',
  'licenses_and_authorizations',
  'media_profile_and_exposure',
  'class_organization',
  // 'family_political_history',
  'related_people',
  // 'political_relationships',
  'business_relationships',
  'kyc',
  'first_level_relatives_kyc',
  'family_social_assistance',
  // 'ondemand_comprot_processes_person',
  'ondemand_cert_labor_debt_absence_person',
  'ondemand_nada_consta_person',
  'ondemand_pf_antecedente_person',
  'ondemand_pgfn_person'
];
export let default_empresas = [
  'basic_data',
  'addresses_extended',
  'emails_extended',
  'phones_extended',
  'government_debtors',
  'kyc',
  // 'owners_kyc',
  'economic_group_kyc',
  'lawsuits_distribution_data',
  'processes',
  'syndicate_agreements',
  'licenses_and_authorizations',
  'media_profile_and_exposure',
  'economic_group_relationships',
  // 'ondemand_comprot_processes_company',
  // 'ondemand_cdcit_company',
  'ondemand_fgts_company',
  'ondemand_cert_labor_debt_absence_company',
  'ondemand_ibama_cert_negativa_company',
  'ondemand_pgfn_company',
  'ondemand_cert_siproquim_company',
  // 'ondemand_sintegra_company'
];
export function format(value) {
  if (value === null || value === undefined || value === "" ) {
    return "-";
  }

  if (!isNaN(Date.parse(value)) && value.length >= 10 && value.match(/^[\d]{4}-[\d]{2}-[\d]{2}/)) {
    let dateArr = (value.split("T")[0]).split("-");
    if (dateArr[0] === '9999' || dateArr[0] === '0001') {
      return "-";
    } else {
      return dateArr[2] + "/" + dateArr[1] + "/" + dateArr[0];
    }
  }

  if (typeof value === 'boolean') {
    return value ? "Sim" : "-";
  }

  if (Array.isArray(value) ) {
    return value.map((value) => translate(value)).join(", ");
  }


  if(typeof value === 'string' && value?.includes("1/1/0001")){
    return "-"
  }


  if(typeof value === 'string' && value.startsWith("http")){
    return (
      <ActionIcon  onClick={() => window.open(value, '_blank')}>
        <IconDownload  stroke={1.5} size={18}/>
      </ActionIcon>)
  }

  if (typeof value === 'number' || value.match(/^\d{4,12}\.\d\d$/)) {
    if (value === 0 || value === -1)  return "-";
    if (typeof value !== 'number') value = parseFloat(value);
    return value.toLocaleString('pt-BR', {minimumFractionDigits: 0, maximumFractionDigits: 2});
  }

  if (typeof value === 'string'  && value.match(/^[\d-.,]{8,20}$/)) {
    if (value.length === 8) {
      return value.replace(/(\d{2})(\d{3})(\d{3})/, "$1.$2-$3");
    }

    if ((value.length === 12 || value.length === 11) && value.match(/^(\d{2})-(\d{4,5})(\d{4})$/)) {
      return value.replace(/(\d{2})-(\d{4,5})(\d{4})/, "($1) $2-$3");
    }

    if (value.length === 11) {
      return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    }

    if ((value.length === 12 || value.length === 11)) {
      return value.replace(/(\d{2})-(\d{4,5})(\d{4})/, "($1) $2-$3");
    }

    if (value.length === 14) {
      return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
    }
  }

  return translate(value);

}
export function translate(key){
  let translations = {
    TRABALHISTA: 'Trabalhista',
    CIVIL: 'Cívil',
    CIVEL: 'Cível',
    CRIMINAL: 'Criminal',
    FEDERAL: 'Federal',
    TRIBUTARIA: 'Tributária',
    ELEITORAL: 'Eleitoral',
    MILITAR: 'Militar',
    AMBIENTAL: 'Ambiental',
    ADMINISTRATIVA: 'Administrativa',
    FAZENDA: 'Fazenda',
    TRABALHO: 'Trabalho',
    "ESPECIAL CRIMINAL": 'Especial Criminal',
    "ESPECIAL FAZENDA": 'Especial Fazenda',
    "ESPECIAL TRABALHO": 'Especial Trabalho',
    "ESPECIAL AMBIENTAL": 'Especial Ambiental',
    PREVIDENCIARIA: 'Previdenciária',
    INDEFINIDO: 'Indefinido',
    ARQUIVADO: 'Arquivado',
    ATIVO: 'Ativo',
    REDISTRIBUIDO: 'Redistribuído',
    SUSPENSO: 'Suspenso',
    JULGADO: 'Julgado',
    "EM GRAU DE RECURSO": 'Em Grau de Recurso',
    "TRANSITADO EM JULGADO": 'Transitado em Julgado',
    EXTINTO: 'Extinto',
    BAIXADO: 'Baixado',
    ENCERRADO: 'Encerrado',
    CANCELADO: 'Cancelado',
    AUTUADO: 'Autuado',
    DISTRIBUIDO: 'Distribuído',
    "ARQUIVADO TEMPORARIAMENTE": 'Arquivado Temporariamente',
    PASSIVE: 'Passivo',
    basic_data: 'Dados Básicos',
    addresses_extended: 'Endereços',
    emails_extended: 'Emails',
    phones_extended: 'Telefones',
    social_assistance: 'Assistência Social',
    social_assistance_extended: 'Assistência Social',
    lawsuits_distribution_data: 'Distribuição de Ações',
    licenses_and_authorizations: 'Licenças e Autorizações',
    family_political_history: 'Histórico Político da Família',
    related_people: 'Pessoas Relacionadas',
    political_relationships: 'Relacionamentos Políticos',
    business_relationships: 'Relacionamentos Empresariais',
    kyc: 'Conheça Seu Cliente',
    profession_data: 'Cargos Públicos',
    occupation_data: 'Dados Profissionais',
    family_social_assistance: 'Assistência Social da Família',
    ondemand_pf_antecedente_person: "Antecedentes Criminais - PF",
    ondemand_nada_consta_person: "Nada Consta - Ações Judiciais",
    ondemand_pgfn_person: "Dívida Ativa - PGFN",
    ondemand_cert_labor_debt_absence_person: "Débitos Trabalhistas",
    government_debtors: 'Devedores do Governo',
    syndicate_agreements: 'Acordos Sindicais',
    media_profile_and_exposure: 'Perfil e Exposição na Mídia',
    political_involvement: 'Envolvimento Político',
    relationships: 'Relacionamentos',
    economic_group_relationships: 'Relacionamentos de Grupo Econômico',
    ondemand_cdcit_company: 'CDCIT - Contratação de Pessoas com Deficiência ou Reabilitadas',
    ondemand_fgts_company: 'FGTS',
    ondemand_cert_labor_debt_absence_company: 'Certidão de Débitos Trabalhistas',
    ondemand_ibama_cert_negativa_company: 'IBAMA',
    ondemand_pgfn_company: 'PGFN',
    ondemand_cert_siproquim_company: 'SIPROQUIM',
    Process: "Processo",
    DeforestedAreaHa: "Área Desmatada (ha)",
    PropertyUF: "UF",
    Infraction: "Infração",
    TadNumber: "Número do TAD",
    Age: "Idade",
    Name: "Nome",
    OfficialName: "Razão Social",
    BirthDate: "Data de Nascimento",
    MotherName: "Nome da Mãe",
    FatherName: "Nome do Pai",
    MaritalStatus: "Estado Civil",
    TaxIdNumber: "CPF/CNPJ",
    TaxIdStatus: "Situação do CPF/CNPJ",
    VoterId: "Título de Eleitor",
    HasObitIndication: "Indicação de Óbito",
    BirthCountry: "Nacionalidade",
    FoundedDate: "Data de Fundação",
    TaxRegime: "Regime Tributário",
    CompanyType_ReceitaFederal: "Tipo de Empresa RF",
    CapitalRS: "Capital Social",
    HeadquarterState: "Estado da Matriz",
    interpol: "INTERPOL",
    fbi: "FBI",
    ofac: "OFAC - Agência de Controle de Ativos Estrangeiros dos EUA",
    uk: "UK",
    govuk: "GOVUK",
    ukgov: "UKGOV",
    eu: "EU",
    unsc: "UNSC",
    MTE: "MTE - Lista Suja",
    "United Nations Security Council": "UNSC - Conselho de Segurança das Nações Unidas",
    "Conselho Nacional de Justiça": "CNJ - Conselho Nacional de Justiça",
    "Tribunal de Contas da União": "TCU - Tribunal de Contas da União",
    "Bank Hindered Suppliers": "Bank Hindered Suppliers",
    Ibama: "Embargos do Ibama",
    ceis: "CEIS - Cadastro Nacional de Empresas Inidôneas e Suspensas",
    cnep: "CNEP - Cadastro Nacional de Empresas Punidas",
    cepim: "CEPIM - Cadastro de Entidades Privadas Sem Fins Lucrativos Impedidas",
    CEAF: "CEAF - Cadastro de Expulsões da Administração Federal",
    "Tribunal de Contas do Estado de São Paulo": "Tribunal de Contas do Estado de São Paulo",
    "Banco Central do Brasil": "Banco Central - Inabilitados",
    "Comissao de Valores Mobiliarios": "CVM",
    "Portal da Transparência": "Portal da Transparência",
    "ca-sanctions": "Lista de Sanções do Canadá",
    "Temporary Impossibility": "Impossibilidade Temporária",
    "1st-LEVEL": "1º Grau",
    "2nd-LEVEL": "2º Grau",
    "3rd-LEVEL": "3º Grau",
    "4th-LEVEL": "4º Grau",
    "5th-LEVEL": "5º Grau",
    "6th-LEVEL": "6º Grau",
    "7th-LEVEL": "7º Grau",
    "8th-LEVEL": "8º Grau",
    SourceName: "Fonte",
    Title: "Título",
    PublicationDate: "Data de Publicação",
    SentimentAnalysis: "Análise de Sentimento",
    Url: "Link",
    UNDEFINED: "Indefinido",
    SLIGHTLY_NEGATIVE: "Negativo",
    SLIGHTLY_POSITIVE: "Positivo",
    POSITIVE: "Positivo",
    NEGATIVE: "Negativo",
    NEUTRAL: "Neutro",
    POLARIZED: "Polarizado",
    Value: "Valor",
    CourtName: "Tribunal",
    CourtType: "Tipo da Côrte",
    CourtLevel: "Instância",
    JudgingBody: "Órgão",
    CourtDistrict: "Comarca",
    CourtState: "UF",
    NumberOfParties: "Partes",
    NumberOfUpdates: "Atualizações",
    LastMovementDate: "Última Movimentação",
    MainSubject: "Assunto Principal",
    OtherSubjects: "Outros Assuntos",
    ProcessNumber: "Número do Processo",
    JudgementType: "Tipo de Decisão",
    RuralPropertyQtd: "Propr. Rurais",
    RuralPropertyQtdHistory: "Propr. Rurais [Histórico]",
    RuralPropertyArea: "Área Total (ha)",
    RuralPropertyAreaHistory: "Área Total (ha) [Histórico]",
    Neighborhood: "Bairro",
    ZipCode: "CEP",
    City: "Cidade",
    State: "UF",
    Address: "Endereço",
    Type: "Tipo",
    type: "Tipo",
    date: "Data",
    number: "Número",
    url: "Link",
    region: "Região",
    process: "Processo",
    proc: "Processo",
    HOME: "Residêncial",
    WORK: "Trabalho",
    PERSONAL: "Pessoal",
    Direct: "Direto",
    CORPORATE: "Corporativo",
    COLLEGECLASS: "Colega de Classe",
    NEIGHBOR: "Vizinho",
    EMPLOYMENT: "Empregado",
    EmailAddress: "Email",
    Priority: "#",
    MOBILE: "Celular",
    Number: "Número",
    PhoneNumber: "Telefone",
    HOUSEHOLD:"Mesmo Domicílio",
    SON: "Filho",
    BROTHER: "Irmão",
    DAUGHTER: "Filha",
    FATHER: "Pai",
    UNCLE: "Tio",
    NEPHEW: "Sobrinho",
    MOTHER: "Mãe",
    SPOUSE: "Cônjuge",
    GRANDSON: "Neto",
    GRANDPARENT: "Avô/Avó",
    COUSIN: "Primo",
    OWNERSHIP: "Proprietário",
    Ownership: "Proprietário",
    SELF: "Próprio",
    AmountDonated: "Doações Eleitorais",
    AmountReceived: "Doações Eleitorais Recebidas",
    IsPep: "PEP",
    IsSanctioned: "Cadastros Restr.",
    IsCurrentlyInOffice: "Atualmente em Cargo Público",
    WasFormerlyElected: "Exerceu Cargo Público",
    DonationsCount: "Quantidade de Doações",
    RelationshipType: "Tipo de Rel.",
    RelationshipName: "Relacionamento",
    RelatedEntityName: "Relacionado",
    RelatedEntity: "Nome do Relacionado",
    "Judged Sanctions": "Sanções Judiciais",
    "undefined": "Indefinido",
    EmbargoAreaTxt: "Área Embargada",
    PublicLandOverlap: "Sobrep. Terra Pública",
    PublicLandOverlapTxt: "Sobrep. Terra Pública",
    EntityName: "Nome",
    title: "Nome",
    RelationshipLevel: "Nível",
    RelationshipStartDate: "Início",
    RelationshipEndDate: "Término",
    RelatedEntityTaxIdNumber: "CPF/CNPJ",
    PreviousLevelRelatedEntityTaxIdNumber: "CPF/CNPJ",
    PreviousLevelRelatedEntityName: "Nome",
    sobrep: "Sobreposições",
    car: "CAR",
    embargo: "Embargo",
    assentamento: "Assentamento",
    indigena: "Terra Indígena",
    quilombola: "Terra Quilombola",
    floresta: "Floresta Pública",
    floresta_b: "Floresta Pública Tipo B",
    ucs:  "UCs",
    publica: "Terra Pública",
    Role: "Função",
    InfoURL: "Link",
    Categories: "Categorias",
    RegistryNumber: "Registro",
    AgreementStartDate: "Início",
    AgreementEndDate: "Término",
    SolicitationNumber: "Número de Solicitação",
    AgreementDurationInMonths: "Duração do Acordo (Meses)",
    TerritorialScope: "Escopo Territorial",
    TypeOfInstrument: "Instrumento",
    DIRECT: "Direto",
    INDIRECT: "Indireto",
    INACTIVE: "Inativo",
    ACTIVE: "Ativo",
    ActionYear: "Ano da Ação",
    Establishment: "Estabelecimento",
    IrrecorribilityDate: "Data de Inclusão",
    AmountOfWorkersInvolved: "Qtd. de Trabalhadores",
    SanctioningBody: "Sanção",
    SanctioningBodyState: "UF",
    Slavery: "Trabalho Escravo",
    PARTNER: "Parceiro de Negócios",
    LAWSUITS_DEFENDANT: "Réu",
    LAWSUITS_AUTHOR: "Autor",
    LastPoliticalRole: "Último Cargo",
    LastPoliticalPartyName: "Último Partido",
    LastWonElectionYear: "Última Eleição Vencida",
    Sector: "Setor",
    Source: "Fonte",
    finalidade: "Finalidade",
    atividade: "Atividade",
    instrumento_credito: "Instrumento de Crédito",
    DebtOrigin: "Origem da Dívida",
    DebtorType: "Tipo de Devedor",
    FiledIndicator: "Indicador de Arquivamento",
    RegistrationDate: "Data de Registro",
    ResponsibleUnityUF: "UF",
    ConsolidatedValue: "Valor Consolidado",
    RegistrationNumber: "Número de Registro",
    RegistrationSituation: "Situação",
    RegistrationSituationType: "Tipo",
    FAMILY: "Familiar",
    PRINCIPAL: "Principal",
    CompanyName: "Nome da Empresa",
    CompanyIdNumber: "CNPJ",
    StartDate: "Início",
    EndDate: "Término",
    Income: "Renda Estimada",
    SocialSecurityNumber: "NIS",
    ProgramName: "Programa",
    ProgramState: "UF",
    AssistanceAmount: "Valor",
    TotalAssistanceAmount: "Valor Total",
    Beneficiaries: "Beneficiários",
    TotalInstallments: "Parcelas",
    AssistanceTotalAmount: "Valor Total",
    AssistanceStartDate: "Início",
    AssistanceEndDate: "Término",
    Origin: "Origem",
    BaseStatus: "Situação",
    ProtocolNumber: "Protocolo",
    QueryDate: "Data da Consulta",
    RawResultFile: " ",
    PFAntecedente: "Antecedentes Criminais - PF",
    NadaConsta: "Nada Consta - Ações Judiciais",
    PGFN: "Dívida Ativa - PGFN",
    "SELF-EMPLOYED": "Autônomo",
    FREELANCE: "Autônomo",
    "Freelancer - Truck Driver": "Autônomo - Caminhoneiro",
    "OFFICIAL REGISTRATION": "Registro Oficial",
    "Certidao Negativa de Debitos Trabalhistas": "Débitos Trabalhistas",
    "LEGAL REPRESENTATIVE": "Representante Legal",
    "ENTREPRENEUR | BUSINESS OWNER": "Empresário | Proprietário",
    "PUBLIC SERVANT": "Servidor Público",
    "EMPLOYEE": "Empregado",
    "Employee": "Empregado",
    "PUBLIC COMPANY EMPLOYEE": "Funcionário Público",
    "OWNERSHIP AT PUBLIC SECTOR": "Participação Setor Público",
    PEERS: "Pares",
    numeroContrato: "Contrato",
    dataContratacao: "Data",
    valorContratacao: "Valor Contratado",
    liquidada: "Situação",
    tipoOperacao: "Tipo",
    descricaoProjeto: "Descrição",
    tope: "Forma de Apoio",
    fonteRecursos: "Fonte de Recursos",
    valorDesembolsado: "Valor Desembolsado",
    "NÃO AUTOMÁTICA": "Não Automática",
    "AUTOMÁTICA": "Automática",
    "DIRETA": "Direta",
    agenteFinanceiro: "Agente Financeiro",
    custoFinanceiro: "Custo Financeiro",
    taxaJuros: "Taxa de Juros",
    prazoCarencia: "Carência",
    prazoAmortizacao: "Amortização",
    setorApoiado: "Setor BNDES",
    subsetorApoiado: "Subsetor BNDES",
    cnae: "CNAE-IBGE",
    instrumentoBndes: "Instrumento Financeiro",
    inovacao: "Inovação",
    modalidadeOperacao: "Modalidade",
    operacaoDireta: "Forma",
    uf: "UF",
    municipio: "Município",
    tipoGarantia: "Tipo de Garantia",
    porteCliente: "Porte",
    naturezaCliente: "Natureza",
    reembolsavel: "Modalidade",
    produtoBndes: "Produto BNDES",
    Category: "Categoria",
    OrganizationChapter: "UF",
    OrganizationName: "Nome",
    RegistrationId: "Registro",
    MembershipStartDate: "Início",
    MembershipEndDate: "Término",
    Ineligibility: "Inelegibilidade",
    Level: "Nível",
    Motive: "Motivo",
    JobTitle: "Posição",
    Department: "Local",
    "CITY EMPLOYEE": "Cargo Público Municipal",
    "FEDERAL EMPLOYEE": "Cargo Público Federal",
    "STATE EMPLOYEE": "Cargo Público Estadual",
    BIGDATACORP: " ",
    "Law Enforcement": "Aplicação da Lei",
    ImprisonmentKind: "Tipo de Prisão",
    Decision: "Decisão",
    FAMILIAR: "Familiar",
    ref_bacen: "Ref. Bacen",
    nu_ordem: "Ordem",
    dt_emissao: "Emissão",
    dt_vencimento: "Vencimento",
    if: "Inst. Financeira",
    balance: "Saldo",
    vl_liberated: "Valor Liberado",
    "THE SYSTEM CANNOT ISSUE THE CERTIFICATE BECAUSE IT WAS DETECTED AS POSITIVE OR A CASE OF HOMOMYNY OCCURRED": "Não foi possivel emitir a certidão porque foi detectado como positivo ou ocorreu um caso de homônimo",
  }

  const ufs = ["AC", "AL", "AP", "AM", "BA", "CE", "DF", "ES", "GO", "MA", "MT", "MS", "MG", "PA", "PB", "PR", "PE", "PI", "RJ", "RN", "RS", "RO", "RR", "SC", "SP", "SE", "TO"];
  const stateRegistrations = ufs.reduce((acc, uf) => ({
    ...acc,
    [`StateRegistration-${uf}`]: `Inscrição Estadual [${uf}]`,
    [`StateRegistration-${uf}-`]: `Inscrição Estadual [${uf}]`,
    [`StateRegistration-${uf}(CCC)-`]: `Inscrição Estadual [${uf}]`
  }), {});

  translations = {...translations, ...stateRegistrations};

  return translations[key] || key;
}
export function group(item){
  switch (item) {
    case "BROTHER":
    case "NEPHEW":
    case "MOTHER":
    case "SON":
    case "GRANDSON":
    case "SPOUSE":
    case "GRANDPARENT":
    case "UNCLE":
    case "COUSIN":
    case "FATHER":
      return "Familiar";

    case "COWORKER":
    case "NEIGHBOR":
    case "HOUSEHOLD":
    case "RELATIVE":
    case "PARTNER":
    case "RELATED":
      return "Não Familiar";

    case "Matriz":
    case "Pessoa Física":
      return "Sem Filial";

    case "Familiar":
    case "Não Familiar":
    case "Sem Filial":
      return "Grupos";
    default:
      return item;

  }
}
