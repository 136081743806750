
import {
  Container,
  createStyles,
} from '@mantine/core';
import React, { useState, useCallback, useEffect } from "react";
import { useUser, useSessionContext } from '@supabase/auth-helpers-react';
import * as echarts from 'echarts/core';
import {
  // LineChart,
  BarChart,
  // PieChart,
  // ScatterChart,
  // GraphChart,
  // LinesChart,
  // HeatmapChart,
} from 'echarts/charts';

// import components, all suffixed with Component
import {
  GridComponent,
  // PolarComponent,
  // RadarComponent,
  // GeoComponent,
  // SingleAxisComponent,
  TooltipComponent,
  // AxisPointerComponent,
  TitleComponent,
  // MarkPointComponent,
  // MarkLineComponent,
  // MarkAreaComponent,
  // LegendComponent,
  // LegendScrollComponent,
  // LegendPlainComponent,
} from 'echarts/components';
import {
  SVGRenderer,
} from 'echarts/renderers';
import MapLeaflet from "../components/MapLeaflet";

echarts.use(
  [TitleComponent, TooltipComponent, GridComponent, BarChart, SVGRenderer]
);

const useStyles = createStyles((theme) => ({
  mapDiv: {
    height: "calc(100vh - 56px)",
    position: "relative"
  },
  legend: {
    position: "absolute",
    right: "10px",
    bottom: "25px"
  },
  header: {
    position: "sticky",
    top: "0",
    backgroundColor: "white",
    transition: "box-shadow 150ms ease"
  },
  "header::after": {
    content: "''",
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    borderBottom: "rem(1px) solid light-dark(var(--mantine-color-gray-2), var(--mantine-color-dark-3))"
  }
}));
export default function ConsultaPageWrapper(props) {
  const { isLoading, session, error } = useSessionContext();

  if (!session)
    return undefined;

  return <ConsultaPage {...props} />
}


function ConsultaPage({ className, query, queryCodType, tabId }) {
  const classes = useStyles();

  return <Container fluid p={"0"} className={className}>
    <div style={{ height: "calc(100vh - 56px)", }}>
      <MapLeaflet
        tabId={tabId}
        interactive
        edgeBufferTiles
        uf
        fullScreen
        search
        cities
        extraLayers
        legend
        measure
        history
        minimap
        query={query}
      >
      </MapLeaflet>
    </div>
  </Container>
}