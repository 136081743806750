import { useState } from "react";
import { useUser, useSupabaseClient } from "@supabase/auth-helpers-react";

import { ColorToggle } from "./ColorToggle";

import {
  createStyles,
  Header,
  rem,
} from "@mantine/core";

import Notifications from "./Notifications";

import { useTabs } from "./lib/Tabs";

import { useDisclosure } from "@mantine/hooks";

import openCreateEvent from "./pages/CreateEvent";

const useStyles = createStyles((theme) => ({
  header: {
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
    backgroundColor: theme.fn.variant({
      variant: "filled",
      color: theme.primaryColor,
    }).background,
  },

  inner: {
    height: rem(10),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    maxWidth: "1300px",
    marginLeft: "auto",
    marginRight: "auto",
  },

  links: {
    [theme.fn.smallerThan("md")]: {
      display: "none",
    },
  },

  user: {
    color: theme.white,
    padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
    borderRadius: theme.radius.sm,
    transition: "background-color 100ms ease",

    "&:hover": {
      backgroundColor: theme.fn.lighten(
        theme.fn.variant({ variant: "filled", color: theme.primaryColor })
          .background,
        0.1
      ),
    },

    [theme.fn.smallerThan("xs")]: {
      display: "none",
    },
  },

  userActive: {
    backgroundColor: theme.fn.lighten(
      theme.fn.variant({ variant: "filled", color: theme.primaryColor })
        .background,
      0.1
    ),
  },

  link: {
    display: "block",
    lineHeight: 1,
    padding: `${rem(8)} ${rem(12)}`,
    borderRadius: theme.radius.sm,
    textDecoration: "none",
    color: theme.white,
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,

    "&:hover": {
      backgroundColor: theme.fn.lighten(
        theme.fn.variant({ variant: "filled", color: theme.primaryColor })
          .background,
        0.1
      ),
    },
  },
}));

export function HeaderSection() {
  const user = useUser();

  const supabase = useSupabaseClient();

  const { addTab } = useTabs();

  const [opened, { toggle }] = useDisclosure(false);
  const { classes, theme, cx } = useStyles();
  const [userMenuOpened, setUserMenuOpened] = useState(false);


  return (
    <Header height={10} className={classes.header} mb={0}>
      <div className={classes.inner}>
        <Notifications />
      </div>
    </Header>
  );
}

