import React, {useEffect, useState} from "react";

import { Grid,  Container, Card } from "@mantine/core";

import useSWR  from "swr";

import {useTabs} from "../lib/Tabs";
import ReactEChartsCore from 'echarts-for-react/lib/core';
// Import the echarts core module, which provides the necessary interfaces for using echarts.
import * as echarts from 'echarts/core';
// Import charts, all with Chart suffix
import {
    // LineChart,
    BarChart,
    // PieChart,
    // ScatterChart,
    // RadarChart,
    // MapChart,
    // TreeChart,
    // TreemapChart,
    // GraphChart,
    // GaugeChart,
    // FunnelChart,
    // ParallelChart,
    // SankeyChart,
    // BoxplotChart,
    // CandlestickChart,
    // EffectScatterChart,
    // LinesChart,
    // HeatmapChart,
    // PictorialBarChart,
    // ThemeRiverChart,
    // SunburstChart,
    // CustomChart,
} from 'echarts/charts';
// import components, all suffixed with Component
import {
    // GridSimpleComponent,
    GridComponent,
    // PolarComponent,
    // RadarComponent,
    // GeoComponent,
    // SingleAxisComponent,
    // ParallelComponent,
    // CalendarComponent,
    // GraphicComponent,
    // ToolboxComponent,
    TooltipComponent,
    // AxisPointerComponent,
    // BrushComponent,
    TitleComponent,
    // TimelineComponent,
    // MarkPointComponent,
    // MarkLineComponent,
    // MarkAreaComponent,
    // LegendComponent,
    // LegendScrollComponent,
    // LegendPlainComponent,
    // DataZoomComponent,
    // DataZoomInsideComponent,
    // DataZoomSliderComponent,
    // VisualMapComponent,
    // VisualMapContinuousComponent,
    // VisualMapPiecewiseComponent,
    // AriaComponent,
    // TransformComponent,
} from 'echarts/components';
// Import renderer, note that introducing the CanvasRenderer or SVGRenderer is a required step
import {
    // CanvasRenderer,
    SVGRenderer,
} from 'echarts/renderers';

// Register the required components
echarts.use(
  [TitleComponent, TooltipComponent, GridComponent, BarChart, SVGRenderer]
);


export default function SummaryPage({ className, refreshRequested, onRefresh, projectId, rows }) {

    const [mapReady, setMapReady] = useState(null);
    useEffect(() => {
        fetch('https://cccfacil-sp.s3-sa-east-1.amazonaws.com/mapas/br.json') // "map.svg" is in the public directory
          .then((response) => response.text())
          .then((svgText) => {
              echarts.registerMap('Brasil',  svgText );
              setMapReady(true)
          })
          .catch(e => console.error('fetch error', e));
    });


    const { addTab } = useTabs();

    let docs = rows?.map((r) => r.original.doc)

    const { data: chartData } = useSWR({
        functionName: "f_summary_info",
        args: {
            _project_id: 49
        }
    });


    let sumCredit = chartData?.creditor?.slice(1).reduce((accumulator, currentValue) => accumulator + currentValue[1], 0);

    function baseOptions(){
        return {
            title: [
              {
                id: 1,
                top: "0px",
                left: "left",
                show: true,
                text: "",
                textStyle: {
                    color: "#0f4c7f",
                    fontSize: 16,
                    fontWeight: 800,
                },
            },
                {
                id: 2,
                top: "52%",
                left: "34%",
                show: true,
                textAlign: "center",
                textVerticalAlign: "middle",
                text: "teste3",
                textStyle: {
                    color: "#0f4c7f",
                    fontSize: 34,
                    fontWeight: 800,
                },
            },
                {
                id: 3,
                top: "62%",
                left: "34%",
                show: true,
                textAlign: "center",
                textVerticalAlign: "middle",
                text: "teste2",
                textStyle: {
                    color: "#0f4c7f",
                    fontSize: 18,
                    fontWeight: 800,
                },
            },{
                id: 4,
                top: "67%",
                left: "34%",
                show: true,
                textAlign: "center",
                textVerticalAlign: "middle",
                text: "Qtd.",
                textStyle: {
                    color: "#bebebe",
                    fontSize: 8,
                },
            },{
                id: 5,
                top: "43%",
                left: "34%",
                show: true,
                textAlign: "center",
                textVerticalAlign: "middle",
                text: "Valor",
                textStyle: {
                    color: "#bebebe",
                    fontSize: 8,
                },
            }],
            tooltip: {
                trigger: 'item'
            },
            legend: {
                formatter: function (name) {
                    return name.length > 20 ? (name.substring(19,20) === " " ? name.substring(0,19) : name.substring(0,20)) +"..." : name;
                },
                pageIconSize:10,
                pageButtonGap:8,
                orient:'vertical',
                type: 'scroll',
                left: 'right',
                top: 'center',
            },
            series: [
                {
                    type: 'pie',
                    radius: ['50%', '75%'],
                    center: ["35%", "54%"],
                    avoidLabelOverlap: false,
                    emphasis: {
                        focus: "self",
                        minShowLabelAngle: 0,
                    },
                    labelLayout: {
                        hideOverlap: false,
                    },
                    itemStyle: {
                        borderRadius: 0,
                        borderColor: '#fff',
                        borderWidth: 0
                    },
                    label: {
                        show: true,
                        emphasis: {
                            fontSize: 18,
                            minShowLabelAngle: 0,
                        },
                        fontWeight: 700,
                        fontSize: 12,
                        position: "outside",
                        formatter: function (params) {
                            let x;
                            x = parseFloat(params.value[1]) / 1000;
                            if (x === 0) {
                                x = "";
                            } else {
                                x = x.toLocaleString("pt-BR", {
                                    style: "decimal",
                                    maximumFractionDigits: 1,
                                    minimumFractionDigits: 1,
                                });
                            }
                            return x;
                        },

                    },
                    labelLine: {
                        show: true,
                        length: 5,
                    },
                    minAngle: 2,
                    minShowLabelAngle: 5,
                },
                {
                    type: 'pie',
                    radius: ['50%', '75%'],
                    center: ["35%", "54%"],
                    avoidLabelOverlap: false,
                    emphasis: {
                        focus: "self",
                        minShowLabelAngle: 0,
                    },
                    labelLayout: {
                        hideOverlap: false,
                    },
                    label: {
                        show: true,
                        emphasis: {
                            fontSize: 18,
                            minShowLabelAngle: 0,
                        },
                        fontWeight: 500,
                        fontSize: 12,
                        position: "inside",
                        formatter: function (params) {
                            let x;
                            x = (parseFloat(params.value[1]) * 100)/ sumCredit;
                            if (x === 0) {
                                x = "";
                            } else {
                                x = x.toLocaleString("pt-BR", {
                                    style: "decimal",
                                    maximumFractionDigits: 0,
                                    minimumFractionDigits: 0,
                                });
                            }
                            return x+"%";
                        },

                    },
                    labelLine: {
                        show: true,
                        length: 5,
                    },
                    minAngle: 2,
                    minShowLabelAngle: 6,
                }
            ],
        }
    }


    let optionsCompany= baseOptions()
    optionsCompany.title[0].text = "Saldo por Recuperanda";
    optionsCompany.dataset = {
        source : chartData?.company || []
    }

    optionsCompany.title[1].text = (sumCredit/1000)?.toLocaleString("pt-BR", {
        maximumFractionDigits: 0,
    });
    optionsCompany.title[2].text = (chartData?.company.slice(1).length)?.toLocaleString("pt-BR", {
        maximumFractionDigits: 0,
    });



    let optionsClass= baseOptions()
    optionsClass.dataset = {
        source : chartData?.class || []
    }
    optionsClass.title[1].text = (sumCredit/1000)?.toLocaleString("pt-BR", {
        maximumFractionDigits: 0,
    });
    optionsClass.title[2].text = (chartData?.class.slice(1).length)?.toLocaleString("pt-BR", {
        maximumFractionDigits: 0,
    });
    optionsClass.title[0].text = "Saldo por Classe";




    let optionsState= {
        title: {
            top: "0px",
            left: "left",
            show: true,
            text: "Saldo por Estado",
            textStyle: {
                color: "#0f4c7f",
                fontSize: 16,
                fontWeight: 800,
            },
        },
        grid:{
            bottom: 32,
            left: 0,
            right: 0,
            top: 50,
        },
        xAxis: {
            axisLabel:{
                interval:0,
                formatter: params => {
                    let lines = [];
                    let line = "";
                    for (const item of params.replaceAll("/", "/ ").split(" ")) {
                        if (line.length > 0) {
                            if (line.length + item.length > 8) {
                                lines.push(line);
                                line = item;
                                continue;
                            }
                            line += " ";
                        }
                        line += item;
                    }
                    if (line.length > 0) {
                        lines.push(line);
                    }
                    return lines.join("\n");
                }},
            type: 'category',
        },
        yAxis: {
            axisLabel:{show:false},
            type: 'value'
        },
        series: [
            {
                encode: {
                    x: "name",
                    y: "saldo",
                    label:"saldo"
                },
                type: 'bar',
                label:{
                    show:true,
                    position: "top",
                    formatter: function (params) {
                        let x;
                        x = parseFloat(params.value[params.encode.y]) / 1000;
                        if (x === 0) {
                            x = "";
                        } else {
                            x = x.toLocaleString("pt-BR", {
                                style: "decimal",
                                maximumFractionDigits: 1,
                                minimumFractionDigits: 1,
                            });
                        }
                        return x;
                    },
                }
            }
        ],
        dataset:{
            source: chartData?.state || []
        },

    };




    let optionsCreditorType= baseOptions()
    optionsCreditorType.title[0].text = "Saldo por Tipo de Credor";
    optionsCreditorType.dataset = {
        source : chartData?.creditor_type || []
    }
    optionsCreditorType.series[0].radius = ['0%', '75%'];
    optionsCreditorType.series[0].label.fontSize = 16;


    let optionsCreditor= baseOptions()
    optionsCreditor.title[0].text = "Saldo por Credor";
    optionsCreditor.dataset = {
        source : chartData?.creditor || []
    }

    optionsCreditor.title[1].text = (sumCredit/1000)?.toLocaleString("pt-BR", {
        maximumFractionDigits: 0,
    });
    optionsCreditor.title[2].text = (chartData?.creditor.slice(1).length)?.toLocaleString("pt-BR", {
        maximumFractionDigits: 0,
    });

    let seriesclass2 = []

    for (let i = 1; i < chartData?.class.length; i++) {
       seriesclass2.push ({
           name: chartData?.class[i][0],
            type: 'bar',
           // barWidth: '40%',
             data:[{value: chartData?.class[i][1], qtd: chartData?.class[i][2]}],
            label:{
                show:true,
                    position: 'top',
                    formatter: function (params) {
                    let x;
                    x = parseFloat(params.value) / 1000;
                    if (x === 0) {
                        x = "";
                    } else {
                        x = x.toLocaleString("pt-BR", {
                            style: "decimal",
                            maximumFractionDigits: 1,
                            minimumFractionDigits: 1,
                        });
                    }
                        return x + " | " + params.data.qtd;
                },
            }
        })

    }
    let optionsClass2= {

        legend: {
            bottom: "0px",
            itemGap: 5,
            itemWidth: 20,
        },
        title: {
            top: "0px",
            left: "left",
            show: true,
            text: "Saldo por Classe",
            textStyle: {
                color: "#0f4c7f",
                fontSize: 16,
                fontWeight: 800,
            },
        },
        grid:{
            bottom: 50,
            left: 0,
            right: 0,
            top: 40,
        },
        xAxis: {
            show: false,
            type: 'category',
        },
        yAxis: {
            axisLabel:{show:false},
            type: 'value'
        },
        series: seriesclass2,

    };

    let seriesCover = []
    for (let i = 1; i < chartData?.cover.length; i++) {
        seriesCover.push ({

            showBackground: true,
            backgroundStyle:{
                color: 'rgba(222,222,222,0.8)'
            },
           name: chartData?.cover[i][0],
            type: 'bar',
           // barWidth: '40%',
             data:[{value: chartData?.cover[i][3], total: chartData?.cover[i][1]/1000, verified: chartData?.cover[i][2]/1000}],
            label:{
                show:true,
                    position: 'top',
                    formatter: function (params) {
                    let x;
                    x = parseFloat(params.value)*100;
                    if (x === 0) {
                        x = "";
                    } else {
                        x = x.toLocaleString("pt-BR", {
                            style: "decimal",
                            maximumFractionDigits: 0,
                            minimumFractionDigits: 0,
                        });
                    }
                        return (x||0) + "% | " + params.data.verified.toLocaleString("pt-BR", {
                            style: "decimal",
                            maximumFractionDigits: 1,
                            minimumFractionDigits: 1,
                        });
                },
            }
        })

    }
    let optionsCover= {

        legend: {
            bottom: "0px",
            itemGap: 5,
            itemWidth: 20,
        },
        title: {
            top: "0px",
            left: "left",
            show: true,
            text: "Cobertura por Classe (Crédito Analisado)",
            textStyle: {
                color: "#0f4c7f",
                fontSize: 16,
                fontWeight: 800,
            },
        },
        grid:{
            bottom: 30,
            left: 0,
            right: 0,
            top: 45,
        },
        xAxis: {
            show: false,
            type: 'category',
        },
        yAxis: {
            axisLabel:{show:false},
            type: 'value'
        },
        series: seriesCover,

    };

    let seriescreditor2 = []
    for (let i = 1; i < chartData?.creditor_type.length; i++) {
        seriescreditor2.push ({
           name: chartData?.creditor_type[i][0],
            type: 'bar',
            encode: {
               y : 0,
            },
           // barWidth: '40%',
           // barMinHeight: 100,
             data:[{value: chartData?.creditor_type[i][1], qtd: chartData?.creditor_type[i][2]}],
            label:{
                show:true,
                    position: "top",
                    formatter: function (params) {
                        let x;
                        x = parseFloat(params.value) / 1000;
                        if (x === 0) {
                            x = "";
                        } else {
                            x = x.toLocaleString("pt-BR", {
                                style: "decimal",
                                maximumFractionDigits: 1,
                                minimumFractionDigits: 1,
                            });
                        }
                        return x + " | " + params.data.qtd;
                    },
            }
        })

    }
    let optionsCreditor2= {

        legend: {
            bottom: "0px",
            itemGap: 5,
            itemWidth: 20,
            left:'center',
            orient:'horizontal'
        },
        title: {
            top: "0px",
            left: "left",
            show: true,
            text: "Saldo por Tipo Credor",
            textStyle: {
                color: "#0f4c7f",
                fontSize: 16,
                fontWeight: 800,
            },
        },
        grid:{
            bottom: 30,
            left: 0,
            right: 0,
            top: 40,
        },
        xAxis: {
            show: false,
            type: 'category',
        },
        yAxis: {
            axisLabel:{show:false},
            type: 'value'
        },
        series: seriescreditor2,

    };

    let optionsMap = {
        title:{
            top: "0px",
            left: "left",
            show: true,
            text: "Quantidade de Credores por Estado",
            textStyle: {
                color: "#0f4c7f",
                fontSize: 16,
                fontWeight: 800,
            },
        },
        visualMap: {
            min:0,
            max:100,
            show: false,
            realtime: false,
            calculable: true,
            inRange: {
                color: ["#b3c9e3","#0a4272"]
            }
        },
        geo: {
            nameProperty: "sigla",
            map: "Brasil",
            label: {
                show: true,
                fontSize: 8,
                color: "#95a995",
            },
            roam: true,
            show: true,
            silent: true,
            itemStyle: {
                normal: {
                    areaColor: "rgb(219,236,219)",
                    borderColor: "rgb(111,148,111)",
                },
                emphasis: {
                    areaColor: "rgba(220,230,220,1)",
                },
            },
            scaleLimit: {
                min: 1.2,
            },
            aspectScale: 0.9,
        },
            series: [{
                show: true,
                type: "scatter",
                label: {
                    show: true,
                    fontSize: 12,
                    fontWeight: 700
                },
                encode: {
                    lng: "longitude",
                    lat: "latitude",
                    value: "qtd",
                },
                // tooltip: {
                //     confine: true,
                //     trigger: "item",
                //     formatter: (params) => {
                //         return params.data[0];
                //     },
                //     renderMode: "html",
                // },
                coordinateSystem: "geo",
                symbol: "pin",
                symbolSize: 40,
                itemStyle: {
                    borderColor: "#588ec2",
                }
            }],
            dataset:{
                source: chartData?.state   || []
            },


    timeline: {
                show: false,
            },
            textStyle: {
                fontFamily: "roboto",
            },
    };

    let bridgeOptions = {
        title: {
            top: "0px",
            left: "left",
            show: true,
            text: "Variação QGC",
            textStyle: {
                color: "#0f4c7f",
                fontSize: 16,
                fontWeight: 800,
            },
        },
            grid: {
                top: 40,
                left: 0,
                right: 0,
                bottom: 20
            },
            xAxis: {
                type: "category",
                axisLine: {
                    lineStyle: {
                        color: ["rgb(150,150,150,0.5)"],
                    },
                },
                axisTick: {
                    lineStyle: {
                        color: ["rgb(150,150,150,0.5)"],
                    },
                },
                axisLabel: {
                    margin: 8,
                    interval: 0,
                    textStyle: {
                        color: "rgb(100,100,100,1)",
                    },
                    fontFamily: "helvetica",
                    lineHeight: 8,
                },
            },
            yAxis: {
                type: "value",
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: ["rgb(150,150,150,0.5)"],
                    },
                },
                axisTick: {
                    show: true,
                    lineStyle: {
                        color: ["rgb(150,150,150,0.5)"],
                    },
                },
                axisLabel: {
                    interval: 0,
                    textStyle: {
                        color: "rgb(100,100,100,1)",
                    },
                    fontFamily: "helvetica",
                    lineHeight: 8,
                },
                splitLine: {
                    lineStyle: {
                        color: ["rgb(220,220,220,0.5)"],
                    },
                },
                "splitNumber:": 3,
            },
            legend: {
                show: false,
            },
            tooltip: {
                trigger: "item",
                position: "left",
            },
            timeline: {
                show: false,
            },
            textStyle: {
                fontFamily: "roboto",
            },
    };

   if ( chartData?.bridge) {

      let  tranposedArr = []
       for (let j = 0; j < chartData?.bridge[0].length; j++)
       {
           tranposedArr.push([]);
       for (let i = 0; i < chartData?.bridge.length; i++)
       {
           tranposedArr[j].push(chartData?.bridge[i][j]);

       }

       }

       let datasource = tranposedArr[1];
       let labelsource = tranposedArr[0];
       let data = [];
       let line = [];
       let offset = datasource[1];
       data.push([
           labelsource[1],
           offset,
           Math.min(0, offset),
           Math.max(0, offset),
       ]);
       line.push([labelsource[1], offset]);
       for (let i = 2; i < labelsource.length - 1; ++i) {
           let val = datasource[i];
           let label = labelsource[i];
           let y1;
           let y2;
           if (
             ((label.startsWith("Saldo") ||
                 label.startsWith("QGC") ||
                 label.startsWith("Resultado")) &&
               !label.includes("Bloqueado"))
           ) {
               y1 = 0;
               y2 = offset;
               line.push([labelsource[i], offset]);
               if (i != 2) offset -= val;
           } else {
               y1 = offset;
               y2 = offset + val;
               line.push([labelsource[i], y1]);
           }
           data.push([label, val, Math.min(y1, y2), Math.max(y1, y2)]);
           offset += val;
       }
       data.push([
           labelsource[labelsource.length - 1],
           datasource[labelsource.length - 1],
           Math.min(datasource[labelsource.length - 1], 0),
           Math.max(datasource[labelsource.length - 1], 0),
       ]);
       line.push([
           labelsource[labelsource.length - 1],
           datasource[labelsource.length - 1],
       ]);
       const CAT_GAP = 0.4; // gap between categories
       function renderItem(params, api) {
           var yValue = api.value(3);
           var start = api.coord([params.dataIndex, yValue]);
           var size = api.size([api.value(0), api.value(3) - api.value(2)]);
           var style = api.style();
           var visual = api.visual();
           var styleEmphasis = api.styleEmphasis();
           let coordX = params.coordSys.height;
           const barWidth = size[0] * (1 - CAT_GAP);
           if (api.value(1) < 0) {
               style.textPosition =
                 start[1] + size[1] >= coordX + 15 ? "top" : "bottom";
               styleEmphasis.textPosition = "bottom";
           }
           let col = [
             '#289d18',
             '#c00c0c',
             '#a6a6a6',
           ];
           let label = labelsource[params.dataIndex + 1];
           let y = start[1];
           if (
             ((label.startsWith("Saldo") ||
                 label.startsWith("QGC Inicial") ||
                 label.startsWith("Resultado")) &&
               !label.includes("Bloqueado"))
           ) {
               style.textFill = col[2];
               style.fill = col[2];
               styleEmphasis.fill = col[2];
               styleEmphasis.textFill = col[2];
           } else if (api.value(1) > 0) {
               style.fill = col[0];
               style.textFill = col[0];
               styleEmphasis.fill = col[0];
               styleEmphasis.textFill = col[0];
           } else {
               style.fill = col[1];
               styleEmphasis.fill = col[1];
               style.textFill = col[1];
               styleEmphasis.textFill = col[1];
           }
           return {
               type: "rect",
               shape: {
                   x: start[0] - barWidth / 2,
                   y: y,
                   width: barWidth,
                   height: Math.max(size[1], 1),
               },
               style: style,
           };
       }

       bridgeOptions.series = [
           {
               zlevel: 1,
               type: "custom",
               renderItem: renderItem,
               label: {
                   show: true,
                   position: "top",
                   fontWeight: "700",
                   formatter: function (params) {
                       let x = parseFloat(params.value[1]) / 1000;
                       // if (x === 0) {
                       //     x = "";
                       // } else {
                       //     x = x.toLocaleString("pt-BR", {
                       //         style: "decimal",
                       //         maximumFractionDigits: 1,
                       //         minimumFractionDigits: 1,
                       //     });
                       // }
                       return x.toLocaleString("pt-BR", {
                           style: "decimal",
                           maximumFractionDigits: 1,
                           minimumFractionDigits: 1,
                       });
                   },
               },
               encode: {
                   x: [0],
                   y: [2, 3],
                   text: 1,
               },
               data: data,
           },
           {
               name: "Step Start",
               type: "line",
               step: "start",
               data: line,
               color: "#808080",
               showSymbol: false,
               lineStyle: {width: 0.3},
               label: {
                   show: false,
                   position: "top",
                   color: "black",
               },
           },
       ];
   }
    return (
        <Container fluid my="md" className={className}>
            <Grid>
                <Grid.Col span={8} >
                <Grid>
                <Grid.Col span={6} >
                    <Card
                        withBorder padding="xs"
                        style={{height: "calc(((100vh - 80px)/3) - 14px)"}}>
                        <ReactEChartsCore
                          echarts={echarts}
                            style={{height: "100%"}}
                            option={optionsCreditor}
                        />
                    </Card>
                </Grid.Col>
                <Grid.Col span={6} >
                    <Card
                        withBorder padding="xs"
                        style={{height: "calc(((100vh - 80px)/3) - 14px)"}}>
                        <ReactEChartsCore
                          echarts={echarts}
                            style={{height: "100%"}}
                            option={optionsClass2}
                        />
                    </Card>
                </Grid.Col>
                {/*<Grid.Col span={3} >*/}
                {/*    <Card*/}
                {/*        withBorder padding="xs"*/}
                {/*        style={{height: "calc(((100vh - 80px)/3) - 14px)"}}>*/}
                {/*        <ReactEChartsCore*/}
                {/*          echarts={echarts}*/}
                {/*            style={{height: "100%"}}*/}
                {/*            option={optionsCreditor2}*/}
                {/*        />*/}
                {/*    </Card>*/}
                {/*</Grid.Col>*/}
                    </Grid>
                </Grid.Col>
                <Grid.Col  span={4}>
                    <Card withBorder padding="xs"
                          style={{height: "calc(((100vh - 80px)/3) - 14px)"}}>
                        <ReactEChartsCore
                          echarts={echarts}
                            style={{height: "100%"}}
                            option={optionsCompany}
                        />
                    </Card>
                </Grid.Col>
                <Grid.Col  span={4}>
                    <Card withBorder padding="xs">
                        <ReactEChartsCore
                          echarts={echarts}
                            style={{height: "calc(((100vh - 80px)/3) - 14px)"}}
                            option={optionsCover}
                        />
                    </Card>
                </Grid.Col>
                <Grid.Col  span={4}>
                    <Card withBorder padding="xs">
                        <ReactEChartsCore
                          echarts={echarts}
                            style={{height: "calc(((100vh - 80px)/3) - 14px)"}}
                            option={bridgeOptions}
                        />
                    </Card>
                </Grid.Col>
                <Grid.Col  span={4}>
                    <Card withBorder padding="xs"
                          style={{height: "calc((((100vh - 80px)/3)*2) - 10px)"}}>
                        {mapReady &&
                          <ReactEChartsCore
                          echarts={echarts}
                          style={{height: "100%"}}
                          option={optionsMap}
                        />}
                    </Card>
                </Grid.Col>
            </Grid>
        </Container>
    );
};


