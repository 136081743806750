import {
  IconAlertTriangle, IconAnchor, IconBorderNone,
  IconBow, IconBuildingArch, IconBuildingWarehouse,
  IconCircleDashed, IconCircleFilled, IconDropletFilled,
  IconFlame, IconLayersIntersect, IconLeafOff, IconLock, IconMapPinFilled,
  IconPick,
  IconPlane,
  IconPlant,
  IconPlant2,
  IconRoad,
  IconSeeding,
  IconShield,
  IconShip,
  IconSquareFilled,
  IconStack2,
  IconTrack,
  IconTrain,
  IconTrees,
  IconUsersGroup,
  IconWall
} from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import { ActionIcon, Card, Group, Popover, Stack, Text, ThemeIcon, Tooltip, UnstyledButton } from "@mantine/core";
import '../plugin/leaflet.tileWithHeader';

import L from 'leaflet';
import { useSessionContext, useSupabaseClient } from "@supabase/auth-helpers-react";


export const ExtraLayers = ({ map, layerRef, activeTab }) => {
  const { session } = useSessionContext();
  const supabaseClient = useSupabaseClient();

  let layers = {
    br_municipios_ibge: {
      label: "Municípios IBGE",
      description: "Malhas territoriais do IBGE",
      color: "#2b2b2b",
      icon: IconBorderNone,
      child: {}
    },
    areas_bioma: {
      label: "Bioma",
      description: "Bioma",
      color: "#8e4fd2",
      searchable: false,
      icon: IconPlant2,
      child: {}
    },
    areas_amazonia_legal: {
      label: "Amazônia Legal",
      description: "Área de abrangência da Amazônia Legal",
      color: "#027000",
      icon: IconSeeding,
      child: {}
    },
    areas_matopiba_microrregioes: {
      label: "Matopiba",
      description: "Área composta por partes dos estados do Maranhão, Tocantins, Piauí e Bahia",
      color: "#7fbc30",
      icon: IconPlant,
      child: {}
    },
    v_areas_florestas_publicas: {
      label: "Florestas Públicas",
      description: "Florestas Públicas",
      searchable: true,
      color: "#40bf8a",
      icon: IconTrees,
      child: {}
    },
    v_areas_ucs: {
      label: "Unidade de Conservação",
      description: "Áreas de Conservação",
      searchable: true,
      icon: IconLock,
      color: "#00ce03",
      child: {}
    },

    v_areas_indigena: {
      label: "Indígena",
      description: "Área Indígena",
      searchable: true,
      color: "#FF9740",
      icon: IconBow,
      child: {}
    },
    v_areas_quilombola: {
      label: "Quilombola",
      description: "Territórios Quilombolas",
      searchable: true,
      color: "#70380c",
      icon: IconSquareFilled,
      child: {}
    },
    v_areas_sitio_arqueologico: {
      label: "Sítios Arqueológicos",
      description: "Sítios Arqueológicos",
      searchable: true,
      color: "#888eee",
      icon: IconBuildingArch,
      child: {}
    },
    v_assentamento_brasil: {
      label: "Assentamento Brasil",
      description: "Assentamentos de reforma agrária",
      searchable: true,
      color: "#ba70c9",
      icon: IconUsersGroup,
      child: {}
    },

    v_areas_ibama_embargo: {
      label: "Ibama Embargo",
      description: "Áreas com embargo do Ibama",
      searchable: true,
      color: "#ff0000",
      icon: IconAlertTriangle,
      child: {}
    },

    mapbiomas_alertas_desmatamento: {
      label: "Desmatamento",
      description: "Alertas de desmatamento",
      color: "#F8AF05",
      icon: IconLeafOff,
      child: {}
    },
    v_nasa_firms: {
      label: "Anomalia Térmica",
      searchable: true,
      description: "Áreas com anomalias térmicas",
      color: "#af1200",
      icon: IconFlame,
      child: {}
    },

    v_car: {
      label: "CAR",
      description: "Áreas do Cadastro Ambiental Rural",
      color: "#c8b644",
      searchable: true,
      icon: IconCircleDashed,
      child: {}
    },
    v_sigef: {
      label: "SIGEF",
      description: "Áreas de imóveis rurais cadastrados no Sistema de Gestão Fundiária",
      icon: IconMapPinFilled,
      searchable: true,
      color: "#15a9be",
      child: {}
    },
    v_snci: {
      label: "SNCI",
      description: "Áreas de imóveis rurais certificados",
      icon: IconMapPinFilled,
      searchable: true,
      color: "#228ae5",
      child: {}
    },

    v_infra_aero_aerodromos: {
      label: "Aeródromos",
      description: "Localização de aeródromos",
      icon: IconPlane,
      color: "#575757",
      child: {}
    },
    v_infra_ferro_ferrovias: {
      label: "Ferrovias",
      description: "Infraestrutura ferroviária",
      icon: IconTrack,
      color: "#575757",
      child: {}
    },
    v_infra_ferro_patios: {
      label: "Pátio Ferroviário",
      description: "Pátios Ferroviários",
      searchable: true,
      icon: IconTrain,
      color: "#575757",
      child: {}
    },
    v_infra_ferro_terminais: {
      label: "Terminais Ferroviários",
      description: "Terminais Ferroviários",
      searchable: true,
      icon: IconTrain,
      color: "#575757",
      child: {}
    },
    v_infra_hidro_hidrovias: {
      label: "Hidrovias",
      description: "Malha hidroviária",
      icon: IconShip,
      color: "#575757",
      child: {}
    },
    v_infra_hidro_portos: {
      label: "Portos",
      description: "Infraestruturas Portuárias",
      searchable: true,
      icon: IconAnchor,
      color: "#0097e8",
      child: {}
    },
    v_infra_rodo_rodovias_federais: {
      label: "Rodovias Federais",
      description: "Infraestrutura Rodoviária Federal",
      color: "#575757",
      icon: IconRoad,
      child: {}
    },
    v_infra_rodo_rodovias_estaduais: {
      label: "Rodovias Estaduais",
      description: "Infraestrutura Rodoviária Estadual",
      color: "#575757",
      icon: IconRoad,
      child: {}
    },
    v_infra_rodo_postos_prf: {
      label: "Postos PRF",
      description: "Postos da Polícia Rodoviária Federal",
      color: "#575757",
      icon: IconShield,
      child: {}
    },
    v_infra_armazem: {
      label: "Armazéns e Silos",
      description: "Infraestruturas de armazenagem",
      searchable: true,
      color: "#b5c623",
      colorOutline: "#000000",
      icon: IconBuildingWarehouse,
      child: {}
    },
    v_infra_mineracao: {
      label: "Mineração",
      description: "Infraestruturas de mineração",
      searchable: true,
      color: "#ff6200",
      child: {},
      icon: IconPick
    },
    v_infra_barragem: {
      label: "Barragem",
      description: "Barragens e reservatórios",
      color: "#ff5700",
      searchable: true,
      icon: IconWall,
      child: {}
    },
    v_sobrep: {
      label: "Sobreposição CAR - AM e PA",
      description: "Sobreposição CAR - AM e PA",
      color: "#ff5700",
      searchable: true,
      icon: IconLayersIntersect,
      child: {}
    },
    v_ana_outorgas_federais: {
      label: "Outorgas de Água - Federais",
      description: "Outorgas de Água - Federais",
      color: "#00b49f",
      searchable: true,
      icon: IconDropletFilled,
      child: {}
    },
    v_ana_outorgas_estaduais_superficiais: {
      label: "Outorgas de Água Superficiais - Estaduais",
      description: "Outorgas de Água Superficiais - Estaduais",
      color: "#007fff",
      searchable: true,
      icon: IconDropletFilled,
      child: {}
    },
    v_ana_outorgas_estaduais_subterraneas: {
      label: "Outorgas de Água Subterrâneas - Estaduais",
      description: "Outorgas de Água Subterrâneas - Estaduais",
      color: "#001ccc",
      searchable: true,
      icon: IconDropletFilled,
      child: {}
    }
  }
  const [listOpen, setListOpen] = useState(false);
  const [layerVisibility, setLayerVisibility] = useState(Object.keys(layers).reduce((acc, key) => {
    acc[key] = false;
    return acc;
  }, {}));

  const toggleLayerVisibility = (source) => {
    setLayerVisibility((prevVisibility) => ({
      ...prevVisibility,
      [source]: !prevVisibility[source],
    }));
  };

  useEffect(() => {
    if (activeTab && !activeTab.includes("Home") && !activeTab.includes("Map")) {
      setListOpen(false);
    }
  }, [activeTab])

  return (
    <>
      <Popover
        withinPortal
        closeOnClickOutside={false}
        zIndex={9999999}
        withArrow
        position={"right-start"}
        opened={listOpen} onChange={setListOpen}
      >
        <Popover.Target>
          <ActionIcon
            className={"leaflet-bar"}
            size={34}
            sx={{ backgroundColor: (listOpen ? "#185e9f" : "#fff") + " !important" }} // Add this line
            onClick={() => setListOpen((o) => !o)}
          >
            <Tooltip withArrow disabled={listOpen} color={"#676767"} label={"Camadas Adicionais"} position={"right"}>
              <IconStack2 color={listOpen ? "#fff" : "#303030"} size={28} strokeWidth={1.5} />
            </Tooltip>
          </ActionIcon>
        </Popover.Target>
        <Popover.Dropdown p={0}>
          <Card shadow={"sm"} p={4} m={2}>
            <Stack spacing={1}>
              {Object.keys(layerVisibility)?.map((layer, index) => {
                let layerInfo = layers[layer];
                let active = layerVisibility[layer];
                let LayerIcon = layerInfo.icon || IconCircleFilled
                return (
                  <UnstyledButton
                    key={index}
                    onClick={() => {
                      let state = !layerVisibility[layer]
                      toggleLayerVisibility(layer);

                      if (layerRef.current?.["extraLayers-" + layer]) {
                        map.removeLayer(layerRef.current?.["extraLayers-" + layer]);
                        delete layerRef.current?.["extraLayers-" + layer];
                      }
                      if (state && !layerRef.current?.["extraLayers-" + layer]) {
                        let baseLayer = L.tileLayer.wms(
                          supabaseClient.supabaseUrl.replace("www.", "").replace('https://', 'https://{s}.') + "/geoserver/v2/gwc/service/wms",
                          {
                            minNativeZoom: layerInfo.minNativeZoom ?? 5,
                            layers: "ccc:" + layer,
                            subdomains: ['www', 'www1', 'www2', 'www3'],
                            crossOrigin: true,
                            searchable: !!layerInfo.searchable,
                            mapVersion: "1.0.0",
                            tiled: true,
                            zIndex: 9999,
                            format: 'image/png',
                            transparent: true,
                          },
                          [
                            { header: 'Authorization', value: `Bearer ${session.access_token}` },
                            {
                              header: 'apikey',
                              value: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiYW5vbiIsImlzcyI6InN1cGFiYXNlLWRlbW8iLCJpYXQiOjE2NDE3NjkyMDAsImV4cCI6MTc5OTUzNTYwMH0.VzlJ1lOmLMKsHs5Nq61ZNRjbsPh3RLgDFXeGjSLPlHY"
                            },
                          ]
                        )
                        layerRef.current["extraLayers-" + layer] = baseLayer.addTo(map);
                      }
                    }}
                  >
                    <Tooltip withinPortal zIndex={9999} disabled={!layerInfo.description} offset={10} color={"#676767"} withArrow label={layerInfo.description}
                      position={"right"}>
                      <Group p={2} noWrap spacing={6}>
                        <ThemeIcon size={18} color={active ? (layerInfo.color || "#7e340a") : "#bbb"}>
                          <LayerIcon strokeWidth={1.5} color={"white"} />
                        </ThemeIcon>
                        <Text fw={600} size={12} color={active ? "#000000" : "#9a9a9a"}>{layerInfo.label}</Text>
                      </Group>
                    </Tooltip>
                  </UnstyledButton>
                )
              })}
            </Stack>
          </Card>
        </Popover.Dropdown>
      </Popover>

    </>
  );
};
