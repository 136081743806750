import React, { useState, useEffect, memo } from 'react';
import {
  MultiSelect,
  ScrollArea,
  Table,
  Pagination,
  Card,
  Text,
  Center,
  Group,
  Input,
  ActionIcon,
  LoadingOverlay,
  Modal,
  Tooltip,
  List,
  Badge
} from "@mantine/core";
import { IconChevronDown, IconChevronRight, IconInfoCircle, IconSearch } from "@tabler/icons-react";
import { translate, group, format } from '../lib/bigdata';
import { useTabs } from "../lib/Tabs";

function GenericTableComponent({ error, columns, data, title, fullHeight, filterField, sumColumns, hideIndex, search, hideEmpty, ExtraInfo, pagination, isLoading, actions }) {
  const [selectedValues, setSelectedValues] = useState([]);
  const [filteredData, setFilteredData] = useState(data);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(pagination || 12);
  const [expandedRows, setExpandedRows] = useState({});
  const [loaded, setLoaded] = useState(false);
  const { addTab } = useTabs();
  const [modalData, setModalData] = useState(null);


  function TextList(props) {
    let limit = props.limit || 1
    if (!props.text) return "";
    return (
      <>
        {props.text.filter((a, i) => i < limit)
          .map((t, i) =>
            props.link ? <Text fontSize={12}>
                <a
                  href={props.link[i]}
                  target={props.onClick ? undefined : "_blank"}
                  title={props.linkTooltip}
                  rel="noopener noreferrer"
                  onClick={props.onClick && props.onClick[i]}
                >
                  {format(t)}
                </a>
              </Text> :
              <Text fontSize={12}>  {format(t)}    </Text>
          )}

        {props.text.length - 1 === limit && <Text fontSize={12}>{format(props.text[limit])}</Text>}
        {props.text.length > limit && props.text.length - 1 > limit &&
          <Tooltip
            withArrow style={{ fontSize: 8 }}
            label={
              <List fw={300} size="xs" fontSize={8} style={{ color: "white" }}>
                {props.text.filter((a, i) => i >= limit).map((t) => {
                  return <List.Item>{format(t)}</List.Item>
                })}
              </List>
            }
            withinPortal>
            <Badge size={"xs"}>
              <Text fontSize={8}>+{props.text.length - limit}</Text>
            </Badge>
          </Tooltip>}
      </>
    )
  }

  function renderRow(item, index, level = 0) {
    const isExpanded = expandedRows[index];
    const hasChildren = item.children && item.children.length > 0;
    const fontWeights = isExpanded ? 700 : 400;
    const backgroundColor = (level === 0 && !isExpanded) ? 'rgba(255,255,255,0)' : level === 1 ? '#f3f5ff' : level === 2 ? '#e3edff' : '#fff';

    return (
      <React.Fragment key={index}>
        <tr key={index} style={item.color ? { backgroundColor: colors[item.color] } : {}} onClick={() => {
          item.children?.length > 0 && toggleRowExpansion(index)
        }}>
          <td key={index + "td"} style={{ paddingLeft: `${level * 8}px`, paddingRight: 0, fontWeight: fontWeights, backgroundColor: backgroundColor }}>
            <Center>
              {hasChildren ? (isExpanded ? <IconChevronDown p={0} size={14} stroke={1.5} /> : <IconChevronRight p={0} size={14} stroke={1.5} />) : ""}
            </Center>
          </td>
          {!hideIndex && <td key={index + "h"} style={{ paddingLeft: 0, paddingRight: 8, fontWeight: fontWeights, backgroundColor: backgroundColor }}>{level === 0 ? item.index : '> ' + ('> ').repeat(level)}</td>}
          {columns.map((column, index2) => (
            <td
              style={{ paddingLeft: `${8 + (level * 8)}px`, paddingRight: 8, fontWeight: fontWeights, backgroundColor: backgroundColor }}
              key={index2}>

              {
                //check if column is array, if so, render as list
                Array.isArray(item[column]) ?
                  <TextList text={item[column]} limit={2} /> :
                  format(item[column])
              }
            </td>
          ))}
          {ExtraInfo &&
            <td
              style={{ paddingLeft: `${8 + (level * 8)}px`, paddingRight: 8, backgroundColor: backgroundColor }}
              key={index + "ei"}>
              <Center>
                <ActionIcon onClick={() => {
                  setModalData({
                    id: `${title}/${index}`,
                    label: `${title}/${index}`,
                    props: {
                      data: item
                    }
                  })
                }}>
                  <IconInfoCircle stroke={1.5} size={18} />
                </ActionIcon>
              </Center>
            </td>}
          {actions &&
            actions.map((action, actionIndex) => (
            <td
              style={{ paddingLeft: `${8 + (level * 8)}px`, paddingRight: 8, backgroundColor: backgroundColor }}
              key={index + "a"}>
              <Center>
                <Tooltip
                  label={action.tooltip}
                  disabled={!action.tooltip}
                  withArrow
                  withinPortal>
                <ActionIcon onClick={item[action.action]}>
                  <action.icon stroke={1.5} size={18} />
                </ActionIcon>
                </Tooltip>
              </Center>
            </td>
            ))
          }
        </tr>
        {isExpanded && hasChildren && item.children.map((childItem, childIndex) => renderRow(childItem, `${index}-${childIndex}-${level + 1}+"sr"`, level + 1))}
      </React.Fragment>
    );
  }

  const toggleRowExpansion = (index) => {
    setExpandedRows({
      ...expandedRows,
      [index]: !expandedRows[index]
    });
  };

  columns = [...new Set(columns)];

  if (hideEmpty) {
    columns = columns.filter((column) => data?.some((item) => item[column]));
  }

  let colors = {
    green: "",
    yellow: "#ffffd9",
    red: "#ffd6d6"
  }
  const handleChange = (value) => {
    setSelectedValues(value);
  }

  useEffect(() => {
    if (data && data.length > 0 && filterField && data[0][filterField]) {
      //check if filterField is array, if not, convert to array
      if (!Array.isArray(data[0][filterField])) {
        data.forEach((item) => {
          item[filterField] = [item[filterField]];
        });
      }
      if (groups.includes("Familiar") && !selectedValues.includes("Familiar")) {
        setSelectedValues(["Familiar"]);
      }
      if (groups.includes("Sem Filial") && !selectedValues.includes("Sem Filial")) {
        setSelectedValues(["Matriz", "Pessoa Física"]);
      }
    }
  }, [data]);

  useEffect(() => {
    if (data && data.length > 0 && filterField && data[0][filterField]) {
      if (selectedValues.length === 0 && searchTerm === "") {
        setFilteredData(data);
      } else {
        setFilteredData(data.filter(item => selectedValues.some(value =>
          Array.isArray(item[filterField]) &&
          (item[filterField].includes(value) ||
            (item[filterField]).map(i => group(i)).includes(value))) &&
          JSON.stringify(item).toLowerCase().includes(searchTerm.toLowerCase())
        ));
      }
    } else if (data && data?.length > 0) {
      setFilteredData(data.filter(item => JSON.stringify(item).toLowerCase().includes(searchTerm.toLowerCase())));
    }
  }, [selectedValues, data, searchTerm, filterField]);

  useEffect(() => {
    if (data && data?.length > 0) {
      if (data?.length > pagination) {
        setItemsPerPage(pagination || 10); // Se os registros forem mais que 12, defina itemsPerPage como 10
      }
      setFilteredData(data);
    }
  }, [data]);

  useEffect(() => {
    if (!isLoading && loaded === false) {
      setLoaded(true);
      setTimeout(() => {
        setLoaded(false);
      }, 5000);
    }
  }, [isLoading]);


  const groups = filterField && [...(new Set(data?.flatMap(item => item[filterField]).map(item => group(item))))];

  const distinctValues = filterField && [...new Set(data?.flatMap(item => item[filterField])), ...groups].map((item) => { return { label: translate(item), value: item, group: group(item) } });

  const sumColumn = (column) => {
    return filteredData?.reduce((sum, row) => sum + (row[column] || 0), 0);
  }
  const countRows = (item) => {
    let counter = 0;
    if (item?.length > 0) {
      item?.forEach((i) => {
        counter += 1;
        if (i.children && i.children.length > 0) {
          counter += countRows(i.children);
        }
      })
    }
    return counter;
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setExpandedRows({});
  };

  const displayedItems = filteredData?.map((f, i) => { return { ...f, index: i + 1 } }).slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
  const pages = Math.ceil(filteredData?.length / itemsPerPage);
  const emptyRows = filteredData?.length > itemsPerPage ? (pages * itemsPerPage) - filteredData?.length : 0;

  return (
    <>
      <Modal size={1200} zIndex={9999} opened={!!modalData} onClose={() => {
        setModalData(null);
      }} title={modalData?.label}>
        {modalData && <ExtraInfo {...modalData?.props} />}
      </Modal>

      <div key={title || columns.join(",")} style={{ paddingBottom: 8, paddingTop: 8, height: "100%" }} >
        <Card withBorder p={"xs"} h={"100%"}>
          <LoadingOverlay visible={isLoading && !error} overlayBlur={2} />
          <Group position={"apart"} align={"start"}>
            <Group>
              {title && <Text size={"s"} fw={700}>{title} {(filteredData && filteredData?.length > 0) ? "[" + countRows(filteredData) + "]" : ""}
              </Text>}
            </Group>
            {filterField &&
              <MultiSelect
                size="xs"
                w={220}
                withinPortal
                data={distinctValues}
                value={selectedValues}
                onChange={handleChange}
                placeholder="Selecione um ou mais filtros"
              />
            }
            {search && <Input
              size={"xs"}
              icon={<IconSearch size={16} />}
              placeholder="Pesquisar"
              value={searchTerm}
              onChange={(event) => setSearchTerm(event.currentTarget.value)}
            />}

          </Group>
          <ScrollArea.Autosize mah={typeof fullHeight === "boolean" ? "100%" : 160 * (fullHeight || 1)}>
            <Table captionSide={"bottom"} highlightOnHover fontSize={11} horizontalSpacing={8} verticalSpacing={4}>
              {(!displayedItems || displayedItems?.length === 0) &&
                <caption key={"caption"} style={{ ...{ margin: 2, padding: 2, fontSize: 12, fontWeight: 600 }, ...(error ? { color: "#cc0000" } : {}) }}>{error ? "Erro ao realizar a consulta" : "Sem Ocorrências"}</caption>
              }
              <thead key={"head"} style={{ position: 'sticky', top: 0, backgroundColor: '#fff' }}>
                <tr key={"expandr"}>
                  <th style={{ paddingRight: 0, paddingLeft: 0 }} key={"expandh"}></th>
                  {!hideIndex && <th style={{ paddingRight: 2, paddingLeft: 0 }} key={"#"}>#</th>}
                  {columns.map((column, index) => (
                    <th style={{ paddingLeft: 8, paddingRight: 8 }} key={index + "th"}>{translate(column)}</th>
                  ))}
                </tr>
              </thead>
              <tbody key={"body"}>
                {displayedItems && displayedItems?.length > 0 && displayedItems?.map((item, index) => renderRow(item, index))}
                {emptyRows > 0 && currentPage === pages && [...Array(emptyRows)].map((_, index) => (
                  <tr key={index + "empty"} style={{ backgroundColor: '#fff', borderColor: '#fff' }}>
                    <td style={{ backgroundColor: '#fff', borderColor: '#fff' }} key={index + "emptytd"}>{'\u00A0'}</td>
                  </tr>
                ))}

                {displayedItems && displayedItems?.length > 0 && sumColumns &&
                  <tr key={"tr sum"} style={{ position: 'sticky', bottom: 0, backgroundColor: '#fff' }}>
                    <td key={"idx"} style={{ paddingLeft: 0, paddingRight: 0 }}></td>
                    {!hideIndex && <td key={"hideindex"}></td>}
                    {columns.map(column => (
                      <td key={column + "sum"}>
                        {sumColumns.includes(column) ? `${format(sumColumn(column))}` : ''}
                      </td>
                    ))}
                  </tr>}
              </tbody>
            </Table>
          </ScrollArea.Autosize>
          {filteredData?.length > itemsPerPage &&
            <Pagination
              style={{ justifyContent: "right" }}
              py={2}
              size={"xs"}
              page={currentPage}
              total={pages}
              onChange={handlePageChange}
            />}

        </Card>
      </div>
    </>
  );
}

export default memo(GenericTableComponent);