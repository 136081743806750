import React, {useEffect} from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import {useState} from "react";
import Auth from "./Auth";

import {
  SessionContextProvider,
  useUser,
  useSessionContext,
  useSupabaseClient,
} from "@supabase/auth-helpers-react";

import {getCookie, setCookie} from "cookies-next";

import {
  MantineProvider,
  ColorSchemeProvider,
  LoadingOverlay,
} from "@mantine/core";
import {Notifications} from "@mantine/notifications";
import {Layout} from "./Layout";
import {Pdf} from "./Pdf";

import RVCPdf from "./pages/RVC/Pdf";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import dayjs from "dayjs";
import {ModalsProvider} from "@mantine/modals";

import {Provider} from "react-supabase";

import {SWRConfig} from "swr";

import "dayjs/locale/pt-br";

import {DatesProvider} from "@mantine/dates";

import {AnimatePresence, motion} from "framer-motion";

import JapurPage from "./pages/JapurPage";
import RequestPage from "./pages/RequestPage";

import supabaseClient from "./supabase";

import utc from "dayjs/plugin/utc";

dayjs.locale('pt-br');

dayjs.extend(utc);

// Create a single supabase client for interacting with your database

// document.addEventListener("contextmenu", (event) => {
//   event.preventDefault();
// });

const root = ReactDOM.createRoot(document.getElementById("root"));

function Protected() {
  const user = useUser();
  const supabase = useSupabaseClient();
  const {isLoading} = useSessionContext();

  const [readyToShow, setReadyToShow] = useState(false);
  const [showMFAScreen, setShowMFAScreen] = useState(false);
  const [enrollMFA, setEnrollMFA] = useState(false);

  const [isExpired, setIsExpired] = useState(false);

  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        setIsExpired(true);
      }, 500);
    }
  }, []);

  useEffect(() => {
    if (user) {
      ;(async () => {
        try {
          const {data, error} = await supabase.auth.mfa.getAuthenticatorAssuranceLevel();

          if (error) {
            throw error
          }

          if ((data.nextLevel === 'aal2' && data.nextLevel !== data.currentLevel) || (data.nextLevel === "aal1")) {
            setShowMFAScreen(true);
            setEnrollMFA(data.nextLevel === "aal1");
          } else {
            setShowMFAScreen(false);
            setEnrollMFA(false);
          }
        } finally {
          setReadyToShow(true)
        }
      })();
    }
  }, [user]);

  return (
    <>
      <AnimatePresence mode={"wait"} initial={false}>
        {user && readyToShow && !showMFAScreen ? (
          <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.3}}
            key="other"
          >
            <Outlet/>
          </motion.div>
        ) : (
          !isLoading && (
            <motion.div
              key="auth"
              initial={{opacity: 0}}
              animate={{opacity: 1}}
              exit={{opacity: 0}}
              transition={{duration: 0.3}}
            >
              <Auth showMFAScreen={showMFAScreen} enrollMFA={enrollMFA}/>
            </motion.div>
          )
        )}
      </AnimatePresence>

      <LoadingOverlay
        visible={isLoading || !isExpired}
        transitionDuration={200}
        overlayBlur={2}
      />
    </>
  );
}

const App = () => {
  const [colorScheme, setColorScheme] = useState(
    getCookie("mantine-color-scheme") || "light"
  );

  const toggleColorScheme = (value) => {
    const nextColorScheme =
      value || (colorScheme === "dark" ? "light" : "dark");
    setColorScheme(nextColorScheme);
    // when color scheme is updated save it to cookie
    setCookie("mantine-color-scheme", nextColorScheme, {
      maxAge: 60 * 60 * 24 * 30,
    });
  };

  useEffect(() => {
    if (colorScheme == "dark") {
      document.getElementsByTagName("html")[0]
        .classList
        .add("Dark-Theme");
    } else {
      document.getElementsByTagName("html")[0]
        .classList
        .remove("Dark-Theme");
    }
  }, [colorScheme]);

  return (
    <SessionContextProvider supabaseClient={supabaseClient}>
      <SWRConfig
        value={{
          dedupingInterval: 300,
          revalidateIfStale: true,
          revalidateOnFocus: false,
          revalidateOnReconnect: true,
          fetcher: ({
                      tableName,
                      functionName,
                      args,
                      filters,
                      columns,
                      orders,
                      delay
                    }) => {
            const timeout = new Promise((resolve, reject) => {
              setTimeout(resolve, delay || 100);
            });

            if (tableName) {
              let result = supabaseClient.from(tableName);

              if (columns) {
                result = result.select(columns);
              } else {
                result = result.select();
              }

              if (filters) {
                for (const row of filters) {
                  result = result.filter(row[0], row[1], row[2]);
                }
              }

              if (orders) {
                for (const row of orders) {
                  result = result.order(row[0], row[1]);
                }
              }

              return result.then(async (result) => {
                await timeout;

                if (result.error) throw result.error;

                return result.data;
              });
            } else if (functionName) {
              return supabaseClient.rpc(functionName, args).then(async (result) => {
                await timeout;

                if (result.error) throw result.error;

                return result.data;
              });
            }
          },
        }}
      >
        <ColorSchemeProvider
          colorScheme={colorScheme}
          toggleColorScheme={toggleColorScheme}
        >
          <DatesProvider settings={{locale: "pt-br"}}>
            <MantineProvider
              theme={{
                colorScheme: colorScheme,
                colors: {
                  "ccc-blue": [
                    "#e1f4ff",
                    "#b7dbfa",
                    "#8dc3f2",
                    "#61abeb",
                    "#3893e4",
                    "#2279cc",
                    "#185e9f",
                    "#0d4372",
                    "#032847",
                    "#000e1c",
                  ],
                },
                primaryColor: "ccc-blue",
                primaryShade: {light: 6, dark: 7},
              }}
              withNormalizeCSS
              withGlobalStyles
            >
              <ModalsProvider>
                <Router>
                  <Routes>
                    <Route path="/japur" element={<JapurPage/>}>
                    </Route>
                    <Route path="/requisicoes" element={<RequestPage/>}>
                    </Route>

                    <Route element={<Protected/>}>
                      <Route path="/pdf-internal-test-pdf-internal" element={<Pdf/>}/>
                      <Route path="/rvc/pdf" element={<RVCPdf/>}/>
                      <Route path="*" element={<Layout/>}/>
                    </Route>
                  </Routes>
                </Router>
              </ModalsProvider>
              <Notifications position="bottom-right" zIndex={2077}/>
            </MantineProvider>
          </DatesProvider>
        </ColorSchemeProvider>
      </SWRConfig>
    </SessionContextProvider>
  );
};

root.render(
    <Provider value={supabaseClient}>
        <App/>
    </Provider>
);

document.addEventListener("keydown", function (event) {
  if (event.ctrlKey == true && (event.which == '61' || event.which == '107' || event.which == '173' || event.which == '109' || event.which == '187' || event.which == '189')) {
    event.preventDefault();
  }
  // 107 Num Key  +
  // 109 Num Key  -
  // 173 Min Key  hyphen/underscore key
  // 61 Plus key  +/= key
}, {passive: false});

document.addEventListener("mousewheel", function (event) {
  if (event.ctrlKey == true) {
    event.preventDefault();
  }
}, {passive: false});


document.addEventListener("DOMMouseScroll", function (event) {
  if (event.ctrlKey == true) {
    event.preventDefault();
  }
}, {passive: false});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
