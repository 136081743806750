import React, {useEffect, useState} from 'react';
import {Card, Accordion, Grid, Box, ActionIcon, Group, Text, ScrollArea, Badge, Menu} from "@mantine/core";
import {
  IconArrowsLeftRight,
  IconDots,
  IconDotsVertical,
  IconEyeSearch, IconLayersIntersect,
  IconPhoto,
  IconReportAnalytics, IconTextPlus,
  IconTrash,
  IconX,
  IconZoomInArea,
  IconZoomScan
} from "@tabler/icons-react";
import useSWR from "swr";
import { useSupabaseClient } from "@supabase/auth-helpers-react";
import Control from "react-leaflet-custom-control";
import {Marker, Popup} from "react-leaflet";
import {IconMessageCircle, IconSettings} from "@supabase/ui";
import {useTabs} from "../lib/Tabs";


export const PointInfo = React.memo(({point, map, layerRef, latLong}) => {
  const { activeTab, addTab } = useTabs();
  const [pointData, setPointData] = useState([]);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (point.length > 0) {
      setShow(true)
      setPointData(point);
    } else{
      setShow(false)
      setPointData([]);
    }
    }, [point]);


  useEffect(() => {
    if (map) {
      //remove the marker when show is false
      if (layerRef?.current.pointInfoMarker && map.hasLayer(layerRef.current.pointInfoMarker)) {
        map.removeLayer(layerRef.current.pointInfoMarker)
        delete layerRef.current.pointInfoMarker;
      }
      //add a marker to the map when show is true
      if (show && point.length > 0 && latLong) {
        let newIcon = L.icon({
          iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png',
          shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
          iconSize: [25, 41],
          iconAnchor: [12, 36],
          popupAnchor: [1, -34],
          shadowSize: [41, 41]
        });
        layerRef.current.pointInfoMarker = L.marker(latLong,{icon:newIcon, interactive:false}).addTo(map);
        map.addLayer(layerRef.current.pointInfoMarker);
      }
    }
  }, [show,latLong]);


  function setColor(sobrep)  {
    let color = '#000000';
    if (sobrep?.embargo) {
      color = '#ff2929';
    } else if (sobrep?.desmatamento) {
      color = '#ff9f32';
    } else if (sobrep?.car) {
      color = '#906cff';
    } else {
      color = '#8bff00';
    }
    return color;
  }

  function formatNumber(value, fixedDecimal) {
    let decimal = fixedDecimal ? (parseFloat(value) === 100 ? 0 : fixedDecimal) : (parseFloat(value) < 1 ? 1 : 0);
    return parseFloat(value).toLocaleString('pt-BR', {
      style: 'decimal',
      maximumFractionDigits: decimal,
      minimumFractionDigits: decimal,
      currency: 'BRL',
      signDisplay: "auto"
    })
  }

  function highlightLayer(layerName, source, id) {
    let layer = layerRef.current?.[layerName];
      layer.eachLayer(featureLayer => {
        if (featureLayer.feature.properties.id === id && featureLayer.feature.properties.source === source) {
          featureLayer.setStyle({
            weight: 4,
            color: '#ffffff',
            fillColor: '#ffffff',
            dashArray: '',
            fillOpacity: 0.5
          });

          featureLayer.bringToFront();
        }
      });
  }

  function resetHighlight(layerName,source,id) {
    let layer = layerRef.current?.[layerName];
    layer.eachLayer(featureLayer => {
      if (featureLayer.feature.properties.id === id && featureLayer.feature.properties.source === source) {
        featureLayer.setStyle(featureLayer.options.style);
      }
    });
  }
  function AccordionList() {
      return pointData.map((point) => {
        let props = point.properties
        return (
          <Accordion.Item
            style={{border: "4px solid "+setColor(props.sobrep)}}
            onMouseOver={() => highlightLayer(point.layerName, point.source, point.id)}
            onMouseOut={() => resetHighlight(point.layerName, point.source, point.id)}
            value={point.id.toString()}>
            <Box
              p={0}
              style={{padding: "0px !important"}}
              sx={{display: 'flex', alignItems: 'center'}}>
              <Accordion.Control   value={point.id.toString()}>
                <Grid columns={20} >
                  <Grid.Col span={18}>
                    <Group align={"start"} p={0} spacing={2} position={"apart"} >
                      <Text fw={600} style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                        {point.title}
                      </Text>
                        <Group style={{width:"100%"}}  p={0} spacing={2} position={"apart"}>
                          {props.sobrep?.embargo && <Badge color={"red"} variant={"filled"} m={0}>Embargo</Badge>}
                          {props.sobrep?.desmatamento && <Badge color={"orange"} variant={"filled"} m={0}>Desmatamento</Badge>}
                          {props.sobrep?.car && <Badge color={"violet"} variant={"filled"} m={0}>Sobreposição</Badge>}
                          {point.subtitle && !props.sobrep?.car && !props.sobrep?.desmatamento && !props.sobrep?.embargo &&
                            <Text size="xs" color="dimmed">{point.subtitle}</Text>}
                          <Badge m={0} >{point.source}</Badge>
                        </Group>
                    </Group>
                  </Grid.Col>
                  <Grid.Col span={2}>
                    <Menu
                      withinPortal
                      withArrow
                      zIndex={9999}
                      position={"bottom-end"}
                      withPositioning
                      trigger={"hover"}
                      offset={-5}
                      arrowOffset={14}
                      shadow="md" >
                      <Menu.Target>
                        <ActionIcon
                          onClick={(e) => {
                            e.stopPropagation()
                          }}
                          size="lg">
                          <IconDotsVertical size={"20"} />
                        </ActionIcon>
                      </Menu.Target>
                      <Menu.Dropdown>
                        <Menu.Item
                          onClick={(e) => {
                            e.stopPropagation()
                            let layer = layerRef.current?.[point.layerName];
                            layer.eachLayer(featureLayer => {
                              if (featureLayer.feature.properties.id === point.id && featureLayer.feature.properties.source === point.source) {
                                map.fitBounds(featureLayer.getBounds());
                              }
                            });
                          }}
                          icon={<IconZoomScan size={20} />}>Focar</Menu.Item>

                        <Menu.Item
                          onClick={(e) => {
                            e.stopPropagation()
                            addTab({
                              label: `Rel./${point.title}`,
                              id: `ESG/Report/${point.subtitle}`,
                              component: "PdfPrinter",
                              subcomponent: "EsgReport",
                              disableRefresh: true,
                              props: {
                                id: point.title,
                                landscape: true,
                                inputList: [{ cod: point.subtitle, codType: point.source === 'sigef' || point.source === 'snci' ? 'incra' : point.source}],
                                name: "",
                                input: point.subtitle,
                                inputType: point.source === 'sigef' || point.source === 'snci' ? 'incra' : point.source,
                              }
                            });
                          }}
                          icon={<IconReportAnalytics size={20} />}>Abrir Relatório</Menu.Item>

                        <Menu.Item onClick={(e) => {e.stopPropagation()}}
                          icon={<IconLayersIntersect size={20} />}>Visualizar Sobreposições</Menu.Item>

                        <Menu.Item onClick={(e) => {e.stopPropagation()}}
                          icon={<IconTextPlus  size={20} />}>Adicionar ao Cadastro</Menu.Item>
                      </Menu.Dropdown>
                    </Menu>
                  </Grid.Col>

                </Grid>
              </Accordion.Control>
            </Box>
            <Accordion.Panel
              style={{width:"100%", display: 'flex', alignItems: 'center'}}>
              <Group>

                {point.subtitle && (props.sobrep?.car || props.sobrep?.desmatamento || props.sobrep?.embargo) &&
                  <div>
                    <Text size="xs" color="dimmed">
                      Cod:
                    </Text>
                    <Text fw={600} size={"xs"}>
                      {point.subtitle}
                    </Text>
                  </div>}
                {Object.keys((props)).map((key) => {
                  if (key === "sobrep") return null;
                  return (
                    <div>
                      <Text size="xs" color="dimmed">
                        {key}
                      </Text>
                      <Text fw={600} size={"xs"}>
                        {props[key]}
                      </Text>
                    </div>
                  )
                })}
                {props?.sobrep?.embargo &&
                  <div>
                  <Text fw={700} size="xs" color="red">
                    Área Embargada
                  </Text>
                  <Text fw={600} size={"xs"}>
                    {formatNumber(props.sobrep?.embargo?.total_area / 10000,1)+" ha"} - {formatNumber(props.sobrep?.embargo?.total_sobrep * 100,1)+"%"}
                  </Text>
                </div>}
                {props?.sobrep?.desmatamento &&
                  <div>
                  <Text fw={700} size="xs" color="orange">
                    Área Desmatada
                  </Text>
                  <Text fw={600} size={"xs"}>
                    {formatNumber(props.sobrep?.desmatamento?.total_area / 10000,1)+" ha"} - {formatNumber(props.sobrep?.desmatamento?.total_sobrep * 100,1)+"%"}
                  </Text>
                </div>}
                {props?.sobrep?.car &&
                  <div>
                  <Text fw={700} size="xs" color="violet">
                    Área Sobreposta
                  </Text>
                  <Text fw={600} size={"xs"}>
                    {formatNumber(props.sobrep?.car?.total_area / 10000,1)+" ha"} - {formatNumber(props.sobrep?.car?.total_sobrep * 100,1)+"%"}
                  </Text>
                </div>}
              </Group>
            </Accordion.Panel>
          </Accordion.Item>
        );
      });
  }

  return (
    show &&
    <>
      <Card
        p={"xs"}
        pt={24}
        onMouseMove={(e) => e.stopPropagation()}
        style={{backgroundColor: "rgba(255,255,255,0.9)"}}
        withBorder shadow h={400} w={500}>
          <ActionIcon
            style={{position: 'absolute', right: 0, top: 0}}
            onClick={() => setShow(false)}>
            <IconX
              size={16}
              stroke={4}
              color={"rgba(182,53,53,0.68)"} />
          </ActionIcon>
        <ScrollArea  h={360} >
        <Accordion
          w={478}
          variant="contained"
          chevronPosition="left" >
          <AccordionList/>
        </Accordion>
        </ScrollArea>
      </Card>
      </>
  );
});
