import React, { useState } from 'react';
import {MultiSelect, ScrollArea, Table, Pagination, Card, Text, Grid, Center, Group, Input, Timeline, Container} from "@mantine/core";
import { translate, group, format} from '../lib/bigdata';
import GenericTableComponent  from "./GenericTableComponent";

function InfoText({label, data}) {
  if ((typeof data[label] !== 'boolean' && !data[label] && data[label] !== 0)) {
    return null;
  }
  return (
    <div>
      <Text size="xs" color="dimmed">
        {translate(label)}
      </Text>
      <Text fw={600} size={"xs"}>
        {format(data[label])}
      </Text>
    </div>
  )
}

function OpBndesComponent({className, data}) {
  let header= ["numeroContrato", "liquidada", "dataContratacao",  "tipoOperacao","tope","fonteRecursos", "valorContratacao","valorDesembolsado", "descricaoProjeto"]
  let apoio = ["operacaoDireta","reembolsavel","produtoBndes"]
  let porte =["porteCliente", "naturezaCliente"]
  let garantias = ["tipoGarantia"]
  let uf = ["uf","municipio"]
  let agente = ["operacaoDireta","agenteFinanceiro"]
  let modalidade = ["modalidadeOperacao","produtoBndes"]
  let inovacao = ["inovacao"]
  let instrumento = ["instrumentoBndes"]
  let cnae = ["cnae"]
  let setor = ["setorApoiado","subsetorApoiado"]
  let condicoes = ["agenteFinanceiro","custoFinanceiro","taxaJuros","prazoCarencia","prazoAmortizacao"]

  function RenderCard({keys, title,...props}) {
    return (
      <Center {...props}>
        <Card withBorder p={"xs"}  maw={900}>
          <Text pb={"xs"} size="sm"  weight={700}>{title}</Text>
          <Group position={"apart"}>
            {keys.map((key) => (
              <InfoText label={key} data={data}/>
            ))}
          </Group>
        </Card>
      </Center>
    );
  }

  return (
    <Container fluid my="md" className={className}  >
      <Center pb={20}>
        <Text size="xl" weight={700}>Operação BNDES</Text>
      </Center>
        <RenderCard mb={"sm"} title={"Informações Básicas"} keys={header}/>
      <Center>
        <Group position={"apart"}  maw={900}>
          <RenderCard title={"Formas de Apoio"} keys={apoio}/>
          <RenderCard title={"Porte e Natureza"} keys={porte}/>
          <RenderCard title={"Garantias"} keys={garantias}/>
          <RenderCard title={"UF e Município"} keys={uf}/>
          <RenderCard title={"Agente Financeiro"} keys={agente}/>
          <RenderCard title={"Modalidade / Produto BNDES"} keys={modalidade}/>
          <RenderCard title={"Inovação"} keys={inovacao}/>
          <RenderCard title={"Instrumento Financeiro"} keys={instrumento}/>
          <RenderCard title={"CNAE-IBGE"} keys={cnae}/>
          <RenderCard title={"Setor Apoioado"} keys={setor}/>
          <RenderCard title={"Condições Financeiras Contratadas"} keys={condicoes}/>
        </Group>

      </Center>
    </Container>
  );
}

export default OpBndesComponent;