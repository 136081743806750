import React, {useId} from "react";
import {
    Flex,
    Text,
    Tooltip,
    Select,
    Stack,
    FileInput,
    Button, Grid, TextInput, Input
} from "@mantine/core";

import { notifications } from "@mantine/notifications";

import { useForm } from "@mantine/form";

import { modals } from "@mantine/modals";

import useSWR from "swr";

import {
    IconCheck,
    IconHelp, IconIdBadge2,
    IconX,
} from '@tabler/icons-react';
import { useSupabaseClient } from "@supabase/auth-helpers-react";
import {IMaskInput} from "react-imask";
import {useTabs} from "../lib/Tabs";
import {DatePickerInput} from "@mantine/dates";
import dayjs from "dayjs";

const TablePage = React.lazy(() => import("../TablePage"));

function CustomLabel({ labelText, tooltipText, withAsterisk }) {
    return (
        <Flex align="center" style={{ whiteSpace: "nowrap" }}>
            <Text mr={2}>
                {labelText}
                {withAsterisk && (
                    <Text as="span" ml={1} color="red" fontWeight="bold" display="inline-block">
                        *
                    </Text>
                )}
            </Text>
            <Tooltip withArrow style={{ fontSize: 10 }} label={tooltipText} withinPortal>
                <IconHelp color="#aaa" size="0.8rem" strokeWidth="2" />
            </Tooltip>
        </Flex>
    );
}

function SelectEdit(props) {
    const { value, onChange } = props;

    const { data, error, isLoading } = useSWR(props.disabled ? null : {
        tableName: props.tablename,
        columns: props.columns,
        functionName: props.functionName,
        args: props.args,
        filters: props.filters,
        orders: [["id", { ascending: true }]],
    });

    return (
        <Select
            {...props}
            searchable
            withinPortal
            error={error?.message || props.error}
            loading={isLoading.toString()}
            nothingFound="Não encontrado"
            data={data || []}
            value={value}
            onChange={onChange}
        />
    );
}

function CreateNew() {
    const supabase = useSupabaseClient();

    const form = useForm({
        initialValues: {
            project_id: null,
            qgc_date: null,
            file: null
        },
        validate: {
            project_id: (value) =>
                !value ? "O projeto precisa ser selecionado." : null,
            qgc_date: (value) =>
                !value ? "A data  precisa ser selecionada." : null,
            file: (value) =>
                !value ? "O arquivo precisa ser selecionado." : null,
        },
    });

    const handleSubmit = (values) => {
        let file = values.file;

        const reader = new FileReader();

        reader.onload = (e) => {
            import(/* webpackChunkName:"xlsx" */ "xlsx").then(async (XLSX) => {
                /* Parse data */
                const bstr = e.target.result;
                const wb = XLSX.read(bstr, { type: "binary" });
                /* Get first worksheet */
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                /* Convert array of arrays */

                try {
                    const excel_data = XLSX.utils.sheet_to_json(ws, {
                        header: 1,
                        raw: true,
                    });

                    const fields_names = excel_data[1];

                    const rows = [];

                    for (let i = 3; i < excel_data.length; ++i) {
                        if (excel_data[i].length == 0 || excel_data[i] == null)
                            break;

                        const row = {
                            project_id: values.project_id,
                            qgc_date: values.qgc_date
                        };

                        for (let j = 0; j < fields_names.length; ++j) {
                            if (fields_names[j] == "creditor_credit_amount") {
                                row[fields_names[j]] = parseFloat(excel_data[i][j]);
                            }
                            else {
                                row[fields_names[j]] = excel_data[i][j];
                            }
                        }

                        rows.push(row);
                    }


                    const { data, error } = await supabase
                        .from(`v_rj_input`)
                        .insert(rows);

                    if (error) {
                        notifications.show({
                            withCloseButton: true,
                            autoClose: 3000,
                            message: error.message,
                            color: "red",
                            icon: <IconX />,
                            loading: false,
                        });
                    }
                    else {
                        notifications.show({
                            withCloseButton: true,
                            autoClose: 3000,
                            title: "Valores inseridos com sucesso!",
                            color: "green",
                            icon: <IconCheck />,
                            loading: false,
                        });

                        modals.closeAll();
                    }

                }
                finally {

                }
            });
        };

        reader.readAsBinaryString(file);
    }

    return <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
        <Stack justify="flex-start" spacing="sm">
            <SelectEdit
                tablename="project"
                columns="value:id,label:name"
                label={
                    <CustomLabel
                        labelText="Recuperação Judicial"
                        withAsterisk
                        tooltipText="Indique a qual RJ o QGC pertence."
                    />
                }
                {...form.getInputProps("project_id")}
            />

            {form.values.project_id && <DatePickerInput

                valueFormat={"DD/MM/YYYY"}
                hideWeekdays
                popoverProps={{
                    withinPortal: true
                }}
                dateParser={(input) => {
                    return dayjs(input, 'DD/MM/YYYY').toDate();
                }}
                label={
                    <CustomLabel
                        labelText="Data QGC"
                        withAsterisk
                        tooltipText="Selecione a database do QGC"
                    />
                }
                {...form.getInputProps("qgc_date")}
            />}

            {form.values.qgc_date && <FileInput
                label={
                    <CustomLabel
                        labelText="Arquivo"
                        withAsterisk
                        tooltipText="Selecione o arquivo excel."
                    />
                }
                accept=".xlsx"
                {...form.getInputProps("file")}
            />}
            <div>
                <Button type="submit" mt="md">
                    Salvar
                </Button>
            </div>
        </Stack>
    </form>
}

const QGCPage = (props) => {
    const { addTab } = useTabs();
    return (
        <TablePage
             {...props}
            tableName={"v_qgc"}
            // initialState={{
            //      expanded: true, //expand all groups by default
            //     grouping: ['rj_creditor_doc'], //an array of columns to group by by default (can be multiple)
            //     // sorting: [{ id: 'name', desc: false }], //sort by state by default
            // }}
            // extendColumns={"file_path"}

            createNew={() => {
                modals.open({
                    size: "xl",
                    title: "Importar QGC",
                    modalId: "new-rj",
                    withinPortal: true,
                    children: <CreateNew />,
                });
            }}
            canCreate={"all"}
            canEdit={"none"}
            extraActions={[
                {
                    Icon: IconIdBadge2,
                    label: "Informações do Credor",
                    async handler(row) {
                        addTab({
                            label: `Credor/${row.id}`,
                            id: `Event/${row.id}`,
                            component: "CreditorInfo",
                            disableRefresh: false,
                            props: {
                                creditorId: row.id
                            }
                        });
                    }
                },
            ]}
            schema={[
                {
                    header: "Credor",
                    required: true,
                    size:120,
                    accessorKey: "name",
                    enableEditing: false,
                },
                {
                    header: "CPF/CNPJ",
                    accessorKey: "doc",
                    size:100,
                    type:"doc",
                    enableEditing: true
                },
                {
                    header: "Informações",
                    accessorKey: "icons",
                    type: 'icon',
                    size:80,
                },
                {
                    header: "Créditos",
                    accessorKey: "credits",
                    enableEditing: false,
                    required: true,
                    maxSize: 220,
                    filterFn: (row, id, filterValue) => {
                        return (row.getValue(id)[0].formatted.toLowerCase().includes(filterValue.toLowerCase()));
                    },
                    format(val) {
                        return `${val.rj_company_name} - ${val.rj_credit_class_name} - ${parseFloat(val.start_balance_after_request ?? 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`;
                    },
                    type: "json",
                    EditEl({ initialValues, handleSubmit }) {
                        const zipcodeId = useId();

                        const form = useForm({
                            initialValues: initialValues || {
                                class: "",
                                amount: 0
                            }
                        });

                        return <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
                            <Grid>
                                <Grid.Col span={12}>
                                    <TextInput
                                        required
                                        label="Endereço"
                                        {...form.getInputProps("address")}
                                    />
                                </Grid.Col>

                                <Grid.Col span={12}>
                                    <TextInput
                                        required
                                        label="Cidade"
                                        {...form.getInputProps("city")}
                                    />
                                </Grid.Col>

                                <Grid.Col span={12}>
                                    <TextInput
                                        required
                                        label="Estado"
                                        {...form.getInputProps("state")}
                                    />
                                </Grid.Col>

                                <Grid.Col span={12}>
                                    <Input.Wrapper id={zipcodeId} label="CEP" required>
                                        <Input
                                            {...form.getInputProps("zipcode")}
                                            component={IMaskInput}
                                            mask={'00.000-000'}
                                            id={zipcodeId}
                                        />
                                    </Input.Wrapper>
                                </Grid.Col>
                                <Grid.Col span={12}>
                                    <Button type="submit" mt="md">
                                        Salvar
                                    </Button>
                                </Grid.Col>
                            </Grid>
                        </form>
                    }
                },
                {
                    header: "Saldo Total",
                    accessorKey: "actual_balance",
                    type: "number",
                    size: 90,
                    minSize: 90,
                    enableEditing: true,
                },
            ]}
            sort={[["name", { ascending: true }]]}
        />
    );
};

export default QGCPage;