import { MRT_Localization_PT_BR } from "mantine-react-table/locales/pt-BR";
import { MantineReactTable } from "mantine-react-table";
import useSWR from "swr";
import React, { useState, useRef, useEffect } from "react";
import {
    Flex,
    Button,
    Textarea,
    MultiSelect, createStyles, Title, TextInput, NumberInput, Select
} from "@mantine/core";

const useStyles = createStyles((theme) => ({
    fit: {
        width: "100%",
    },
    badge: {
        textTransform: "none",
    },
}));


function useEdit(props) {
    if (!props?.createMode) {
        const { cell, column, table } = props;
        
        const [value, setValue] = useState(() => cell.getValue());

        const { setEditingRow, setEditingCell, getState } = table;

        const { editingRow } = getState();

        const handleOnChange = (newValue) => {
            setValue(newValue);

            if (editingRow) {
                setEditingRow({
                    ...editingRow,
                    _valuesCache: { ...editingRow._valuesCache, [column.id]: newValue },
                });
            }
        };

        const handleBlur = (event) => {
            setEditingCell(null);
        };

        return { value, handleOnChange, handleBlur };
    } else {
        const value = props.value;

        const handleOnChange = props.onChange;

        const handleBlur = (event) => { };

        return { value, handleOnChange, handleBlur };
    }
}

function MultiSelectEdit(props) {
    const { classes } = useStyles();

    const { value, handleOnChange, handleBlur } = useEdit(props);

    const { data, error, isLoading } = useSWR({
        tableName: props.tableName,
        filters: props.filters,
        columns: props.columns,
        orders: [["id", { ascending: true }]],
    });

    return (
        <MultiSelect
            {...props}
            searchable
            withinPortal
            error={error?.message}
            loading={isLoading}
            classNames={{
                root: classes.fit,
            }}
            nothingFound="Não encontrado"
            data={data || []}
            value={value}
            onBlur={handleBlur}
            onChange={handleOnChange}
        />
    );
}
function NumberEdit(props) {
    const { classes } = useStyles();

    const { value, handleOnChange, handleBlur } = useEdit(props);

    return (
        <NumberInput
            {...props}
            classNames={{
                root: classes.fit,
            }}
            value={value}
            onBlur={handleBlur}
            onChange={handleOnChange}
        />
    );
}
function SelectEdit(props) {
    const { classes } = useStyles();

    const { value, handleOnChange, handleBlur } = useEdit(props);

    return (
        <Select
            {...props}
            classNames={{
                root: classes.fit,
            }}
            value={value}
            data={[
                { value: 'business_day', label: 'Sim' },
                { value: 'calendar_day', label: 'Não' }
            ]}
            onBlur={handleBlur}
            onChange={handleOnChange}
        />
    );
}

function MultiSelectView({ value, tableName, columns }) {
    const { classes } = useStyles();

    const { data, error, isLoading } = useSWR({
        tableName: tableName,
        columns: columns
    });

    return (
        <MultiSelect
            searchable
            withinPortal
            error={error?.message}
            loading={isLoading}
            classNames={{
                root: classes.fit,
            }}
            variant="unstyled"
            nothingFound="Não encontrado"
            data={data || []}
            value={value}
            readOnly
        />
    );
}
function TextEdit(props) {
    const { classes } = useStyles();

    const { value, handleOnChange, handleBlur } = useEdit(props);

    return (
      <Textarea
        {...props}
        classNames={{
            root: classes.fit,
        }}
        minRows={2}
        maxRows={5}
        value={value}
        onBlur={handleBlur}
        onChange={handleOnChange}
      />
    );
}


export default function Checklist({ form, readOnly }) {
    const { classes } = useStyles();

    const [editLast, setEditLast] = useState(false);

    const tableInstanceRef = useRef(null);

    const [validationErrors, setValidationErrors] = useState({});

    const columns = [
        {
            accessorKey: 'description', //access nested data with dot notation
            header: 'Descrição',
            size: 250,

            Cell: (props) => {
                let index = props.row.index;

                return <TextInput
                  {...form.getInputProps(`checklist.${index}.description`)}
                  readOnly
                  variant="unstyled"
                  value={props.renderedCellValue} />
            },
            mantineEditTextInputProps: {
                error: !!validationErrors.description, //highlight Mantine text field red error color
                helperText: validationErrors.description, //show error message in helper text.
                required: true,
                onChange: (event) => {
                    const value = event.target.value;
                    //validation logic
                    if (!value ) {
                        setValidationErrors((prev) => ({ ...prev, description: 'A descrição é obrigatória.' }));
                    }
                    else {
                        delete validationErrors.description;
                        setValidationErrors({ ...validationErrors });
                    }
                },
            },
        },
        {
            accessorKey: 'source',
            header: 'Fontes',
            size: 100,
            Edit: (props) => {
                return (
                    <MultiSelectEdit
                        {...props}
                        fieldName="name"
                        label={"Fontes"}
                        columns="value:id,label:name,group:type"
                        filters={[["project_id", "eq", form.values.project_id]]}
                        tableName="source_part"
                    />
                );
            },


            Cell: ({ renderedCellValue }) => {
                return <MultiSelectView
                    value={renderedCellValue}
                    fieldName="name"
                    columns="value:id,label:name,group:type"
                    tableName="source_part" />
            },
        },
        {
            accessorKey: 'groups', //normal accessorKey
            header: 'Grupos/Usuarios Relacionados',
            size: 100,

            Edit: (props) => {
                return (
                    <MultiSelectEdit
                        {...props}
                        fieldName="name"
                        label={"Grupos/Usuarios Relacionados"}
                        columns="value:id,label:name,group:type"
                        tableName="v_groups_extended"
                        filters={[["project_id", "ov", `{ ${form.values.project_id} }`]]}
                    />
                );
            },

            Cell: ({ renderedCellValue }) => {
                return <MultiSelectView
                    value={renderedCellValue}
                    fieldName="name"
                    columns="value:id,label:name,group:type"
                    tableName="v_groups_extended" />
            },
        },
        {
            accessorKey: 'interval', //normal accessorKey
            header: 'Dias de Antecedência',
            size: 80,

            Edit: (props) => {
                return (
                    <NumberEdit
                        {...props}
                        label={"Dias de Antecedência"}
                    />
                );
            },

            Cell: ({ renderedCellValue }) => {
                return renderedCellValue
            },
        },

        {
            accessorKey: 'interval_type', //normal accessorKey
            header: 'Dias úteis?',
            size: 80,

            Edit: (props) => {
                return (
                    <SelectEdit
                        {...props}
                        label={"Dias úteis?"}
                    />
                );
            },

            Cell: ({ renderedCellValue }) => {
                return  <Select
                          value={renderedCellValue}
                            readOnly
                          variant={"unstyled"}
                          data={[
                              { value: 'business_day', label: 'Sim' },
                              { value: 'calendar_day', label: 'Não' }
                          ]}
                        />
            },
        },
    ];

    const handleCreateNewRow = async () => {
        form.insertListItem("checklist", {
            description: "",
            source: [],
            groups: [],
            interval: 0
        });

        setEditLast(true);
    };

    useEffect(() => {
        if (editLast && tableInstanceRef.current) {

            const models = tableInstanceRef.current.getRowModel();

            const row = tableInstanceRef.current.getRow(models.rows[models.rows.length - 1]["id"]);

            tableInstanceRef.current.setEditingRow(row);

            setEditLast(false);
        }
    }, [editLast]);

    const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
        const checklist = [...form.values.checklist];

        checklist[row.index] = { ...row.original, ...values };
        if(values.description?.length < 1){
            setValidationErrors((prev) => ({ ...prev, description: 'A descrição é obrigatória.' }))
            return
        }
        form.setFieldValue("checklist", checklist);

        exitEditingMode(); //required to exit editing mode and close modal
    };

    const handleCancelRowEdits = () => {

        const filteredItems = form.values.checklist.filter((item) => item.description && item.description !== "");
        form.setFieldValue("checklist", filteredItems);

    };

    return (
        <>
            <Title order={3} weight={600} mb="md" align="center">
                Checklist
            </Title>
            <MantineReactTable
                tableInstanceRef={tableInstanceRef}
                columns={columns}
                data={form.getInputProps("checklist")["value"] || []}
                enableRowSelection={!readOnly} //enable some features
                enableColumnOrdering={false}
                enableColumnDragging={false}
                enableColumnActions={false}
                enableMultiSort={false}
                enablePinning
                enableGrouping={false}
                editingMode="modal"
                enableFullScreenToggle={false}
                enableStickyHeader
                enableSorting={false}
                enableFilters={false}
                enableTopToolbar={!readOnly}
                enableEditing={!readOnly}
                enableClickToCopy
                enableColumnFilterModes={false}
                enablePagination={false}
                positionToolbarAlertBanner="bottom" //show selected rows count on bottom toolbar
                enableRowVirtualization={false}
                mantineTableContainerProps={{ sx: { maxHeight: "600px" } }}
                mantinePaperProps={{
                    shadow: "none",
                    withBorder: false,
                    sx: {
                        background: "transparent",
                    },
                }}
                renderTopToolbarCustomActions={({ table }) => {
                    const { rows } = table.getSelectedRowModel();

                    return (
                        <Flex gap="md">
                            {!readOnly && <Button
                                color="primary"
                                onClick={() => handleCreateNewRow({ table })}
                                variant="filled"
                            >
                                Novo
                            </Button>}

                            {!readOnly && <Button
                                color="red"
                                disabled={rows.length === 0}
                                onClick={() => {
                                    const indexList = rows.map(row => row.index);

                                    form.setFieldValue("checklist", form.values.checklist.filter((val, index) => !indexList.includes(index)));

                                    table.resetRowSelection();
                                }}
                                variant="filled"
                            >
                                Excluir {rows.length > 0 ? ` (${rows.length})` : ``}
                            </Button>}

                        </Flex>
                    );
                }}
                localization={MRT_Localization_PT_BR}
                onEditingRowSave={handleSaveRowEdits}
                onEditingRowCancel={handleCancelRowEdits}
                positionActionsColumn="last"
                enableDensityToggle={false}
                initialState={{ density: "xs" }}
                displayColumnDefOptions={{
                    "mrt-row-actions": {
                        header: "", //change header text
                        size: 80, //make actions column wider
                    },
                }}
            />
        </>
    )
}