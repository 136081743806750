import {
  createStyles,
  Text,
  Grid,
  rem,
  Card,
  SimpleGrid,
  Space,
} from "@mantine/core";


import React from "react";
import { useSearchParams } from "react-router-dom";

const useStyles = createStyles((theme) => ({
  progressLabel: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    lineHeight: 1,
    fontSize: theme.fontSizes.sm,
  },

  stat: {
    borderBottom: `${rem(3)} solid`,
    paddingBottom: rem(5),
  },

  statCount: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    lineHeight: 1.3,
  },

  wrapper: {
    padding: `calc(${theme.spacing.xl} * 2) ${theme.spacing.xl}`,
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontSize: rem(36),
    fontWeight: 900,
    lineHeight: 1.1,
    marginBottom: theme.spacing.md,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
  },
}));

export function Pdf() {
  const { classes } = useStyles();
  const [searchParams] = useSearchParams();

  return <>
    <table className="pdf">
      <thead>
        <tr>
          <td>
            { JSON.stringify(searchParams.get("id")) }
          </td>
        </tr>
        <tr>
          <td>
            <div class="header-space">&nbsp;</div>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <div class="content">
              <Grid>
                <Grid.Col span={12}>
                  <Card withBorder shadow="sm" radius="md" >
                    <SimpleGrid cols={2}>
                      <Text align="left" size="xs" fw={700}>Credor: AGOSTINI & BOTTEGA LTDA</Text>
                      <Text align="right" size="xs" fw={700}>Classe: ME/EPP</Text>
                    </SimpleGrid>

                    <SimpleGrid cols={2}>
                      <Text align="left" size="xs" fw={700}>Natureza: Divergência de valor   </Text>
                      <Text align="right" size="xs" fw={700}>Valor condo no edital do art. 52, § 1º: R$ 999.999.999.999,99</Text>
                    </SimpleGrid>

                    <SimpleGrid cols={2}>
                      <Text align="left" size="xs" fw={700}>Devedor: INDUSTRIA DE SUCOS 4 LEGUA LTDA – ME</Text>
                      <Text align="right" size="xs" fw={700}>Pretensão: Majoração do Crédito</Text>
                    </SimpleGrid>
                  </Card>
                </Grid.Col>

                <Grid.Col span={12}>
                  <Card withBorder shadow="sm" radius="md" >
                    <Text align="left" size="xs" fw={700}>Documentos Apresentados: (01) peção de divergência; (02) nota scal; (03) duplicata; (04) documentação societária; (05) carteira nacional de habilitação de Cesar Fausto Agosni.</Text>
                  </Card>
                </Grid.Col>

                <Grid.Col span={12}>
                  <Card withBorder shadow="sm" radius="md" style={{ backgroundColor: "#FFF9DB" }} >
                    <Text align="left" size="xs" fw={700}>Conclusão: minorar a importância do crédito em reais de R$ 4.604.365,42 para R$ 1.764.800,41 e incluir os créditos em moeda estrangeira de € 217.810,71 e US$ 318.272,19 em favor de BANCO ABC DO BRASIL S/A, mantendo-o dentre os tulares de créditos quirografários (art. 41, III, da LRF).</Text>
                  </Card>
                </Grid.Col>

                <Grid.Col span={6}>
                  <Card withBorder shadow="sm" radius="md" >
                    <Text align="center" size="xs" fw={700}>Crédito Apresentado pela Recuperanda</Text>

                    <Text align="left" size="xs" fw={700}>Credor: AGOSTINI & BOTTEGA LTDA</Text>
                    <Text align="left" size="xs" fw={700}>Devedor: INDUSTRIA DE SUCOS 4 LEGUA LTDA – ME</Text>
                    <Text align="left" size="xs" fw={700}>Classe: ME/EPP </Text>
                    <Text align="left" size="xs" fw={700}>Valor BRL: R$ 999.999.999.999,9 </Text>


                  </Card>
                </Grid.Col>

                <Grid.Col span={6}>
                  <Card withBorder shadow="sm" radius="md" >
                    <Text align="center" size="xs" fw={700}>Crédito Apresentado pela Recuperanda</Text>

                    <Text align="left" size="xs" fw={700}>Credor: AGOSTINI & BOTTEGA LTDA</Text>
                    <Text align="left" size="xs" fw={700}>Devedor: INDUSTRIA DE SUCOS 4 LEGUA LTDA – ME</Text>
                    <Text align="left" size="xs" fw={700}>Classe: ME/EPP </Text>
                    <Text align="left" size="xs" fw={700}>Valor BRL: R$ 999.999.999.999,9 </Text>


                  </Card>
                </Grid.Col>


                <Grid.Col span={12}>
                  <Card withBorder shadow="sm" radius="md" style={{ backgroundColor: "#FFF9DB" }} >
                    <Text align="center" size="xs" fw={700}>Análise do Administrador Judicial</Text>

                    <Text align="left" size="xs">Análise do Administrador Judicial - O crédito em favor do credor ALDAIR DA SILVA decorre de Reclamatória Trabalhista em discussão no Tribunal Regional do Trabalho da 12ª Região processo número 0000188-15.2018.5.12.0030, da qual se encontra em fase de execução. No referido processo, o Magistrado vericou existência de outras demandas trabalhistas em face da Devedora na fase de execução, razão pela qual fez os autos conclusos e determinou a habilitação dos créditos em execução junto aos autos número 0000919-26.2017.5.12.0004, para a execução conjunta;  “Para o m de submissão aos efeitos da recuperação judicial, considera-se que a existência do crédito é determinada pela data em que ocorreu o seu fato gerador.” - Nesse contexto, cumpre destacar que a manutenção do crédito trabalhista em valor inferior ao eventualmente devido não acarreta maiores prejuízos aos credores. Isso porque, para os tulares de créditos derivados da legislação do trabalho, decorrentes de acidente de trabalho ou equiparados, o valor do crédito em nada inuencia na proporção de seu voto, uma vez que a aprovação do plano perante a Classe I dependerá apenas de maioria simples dos credores trabalhistas presentes no conclave, os quais terão o cômputo de seus votos registrados “por cabeça”; </Text>
                  </Card>
                </Grid.Col>
              </Grid>
            </div>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td>
            <div class="footer-space">&nbsp;</div>
          </td>
        </tr>
      </tfoot>
    </table>
    <div class="header">
      <Grid>
        <Grid.Col span={6}>
          <Text fw={700}>Relatório de Verificação de Créditos</Text>
          <Space h="sm" />
          <Text fw={700}>Processo: 5025460-77.2021.8.21.0010</Text>
        </Grid.Col>
        <Grid.Col span={3} />
        <Grid.Col span={3}>
          <Card withBorder shadow="sm" radius="md" >
            <Text align="center">LOGOMARCA</Text>
          </Card>
        </Grid.Col>
      </Grid>
      <div class="separator" />
    </div>
    <div class="footer">
      <div class="separator" />
    </div>

  </>

}
