import { ActionIcon, Container, Tooltip } from "@mantine/core";
import { IconPdf } from "@tabler/icons-react";
import { useTabs } from "./lib/Tabs";
import dayjs from "dayjs";

export default function PdfPrinterPage({ children, className, subcomponent, ...props }) {
    const { addTab } = useTabs();


    return <Container className={className}>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", flexWrap: "nowrap" }}>
            <Tooltip label="Gerar PDF" withinPortal>
                <ActionIcon onClick={() => {
                    addTab({
                        id: `PDF - ${dayjs(new Date()).format("DD/MM/YYYY HH:mm:ss")}`,
                        label: `PDF - ${dayjs(new Date()).format("DD/MM/YYYY HH:mm:ss")}`,
                        component: "PdfViewer",
                        disableRefresh: true,
                        props: {
                            url: `${window.location.origin}/api/print?url=${encodeURIComponent(`${window.location.origin}?tab=${JSON.stringify(
                                {
                                    id: props.tabId,
                                    subcomponent: subcomponent,
                                    props
                                }
                            )}`)}&waitFor=${encodeURIComponent(props.waitFor || "")}&landscape=${props.landscape || true}&pageWidth=${props.pageWidth || 1122}px&pageHeight=${props.pageHeight || 794}px`,
                            internal: true
                        }
                    });
                }}>
                    <IconPdf size={28} />
                </ActionIcon>
            </Tooltip>
        </div>
        <div className="pdf-container">
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", flexWrap: "nowrap" }}>
                {children}
            </div>
        </div>
    </Container>
}