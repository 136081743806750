import { ActionIcon, Badge, Card, Container, Divider, Grid, Group,  Table, Text, TextInput, TypographyStylesProvider, MultiSelect, Tooltip, Box } from '@mantine/core';
import { IconAlertTriangle, IconPdf, IconSelectAll, IconX } from "@tabler/icons-react";
import useSWR from "swr";
import React, { useState } from "react";
import dayjs from "dayjs";
import { useTabs } from '../lib/Tabs';




function MultiSelectEdit(props) {
  const { tableName, filters, columns, transform, ...rest } = props;

  const { data, error, isLoading } = useSWR({
    tableName: tableName,
    filters: filters,
    columns: columns,
  });

  const { addTab } = useTabs();

  const items = (transform ? transform(data) : data) || [];

  return (
    <MultiSelect
      {...rest}
      searchable
      withinPortal
      error={error?.message}
      loading={isLoading}
      nothingFound="Não encontrado"
      data={
        items
      }
      rightSectionWidth={props.value.length > 0 ? 70 : 40}
      rightSection={
        <Box display="flex">
          <Tooltip key={props.value.length > 0} label={props.value.length > 0 ? "Limpar seleção" : "Selecionar todos"} withinPortal>
            <ActionIcon variant="light" color="gray" onClick={() => {
              props.onChange(props.value.length > 0 ? [] : items?.map(val => val.value))
            }}>
              {props.value.length > 0 ? <IconX size={20} /> : <IconSelectAll size={20} />}
            </ActionIcon>
          </Tooltip>

          {props.value.length > 0 && <Tooltip label="Gerar PDF" withinPortal>
            <ActionIcon variant="light" color="gray" onClick={() => {
              Notification.requestPermission();

              addTab({
                id: `PDF - ${dayjs(new Date()).format("DD/MM/YYYY HH:mm:ss")}`,
                label: `PDF - ${dayjs(new Date()).format("DD/MM/YYYY HH:mm:ss")}`,
                component: "PdfViewer",
                disableRefresh: true,
                props: {
                  url: `${new URL(window.location.href).protocol}//${new URL(window.location.href).hostname}/api/print?url=${encodeURIComponent(`${new URL(window.location.href).protocol}//${new URL(window.location.href).hostname}/rvc/pdf?id=${props.value.join(",")}`)}`,
                  internal: true
                }
              });
            }}>
              <IconPdf size={20} />
            </ActionIcon>
          </Tooltip>}
        </Box>
      } />
  );
}

export default function RVC({ className, refreshRequested, onRefresh, projectId }) {
  const [rvcId, setRvcId] = useState([]);


  const { data: defaultValue, error, isLoading } = useSWR({
    tableName: 'v_rvc',
    filters: [["id", "in", `(${rvcId.join(",")})`]],
    // orders: [["id", { ascending: true }]],
  });


  const analyseData = defaultValue && defaultValue;

  function formatNumber(value, currency, plusSignal) {
    return parseFloat(value).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      signDisplay: plusSignal ? "exceptZero" : "auto"
    })
  }

  function docMask(doc) {
    return doc.length === 11 ?
      doc.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4') :
      doc.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
  }

  function distinct(rows, rowId) {
    // Use a Set to keep track of unique row identifiers
    const uniqueRowIds = new Set();

    // Filter the rows array to include only distinct rows based on the row identifier
    const distinctRows = rows.filter(row => {
      if (uniqueRowIds.has(row[rowId])) {
        // If the row identifier is already in the Set, it's a duplicate
        return false;
      } else {
        // Add the row identifier to the Set and include the row in the result
        uniqueRowIds.add(row[rowId]);
        return true;
      }
    });

    return distinctRows;
  }

  return (
    <Container fluid my="md" className={className}>
      <MultiSelectEdit clearable
        tableName="v_rvc"
        value={rvcId}
        transform={data => {
          return data && distinct(data, "id")?.map(row => ({
            value: row.id,
            label: `${row.rj_creditor_doc} - ${row.rj_creditor_name}`
          }))
        }}
        onChange={setRvcId}
        columns="id,rj_creditor_doc,rj_creditor_name"
      />

      <Text align="center" size="xl" fw={700}>Relatório de Verificação de Créditos</Text>
      {analyseData?.map((a) =>
        <Card withBorder m={"xs"}>

          <Group spacing={40} sx={{ backgroundColor: a.analysed ? "#ced4da" : "rgb(255,222,0)" }} position="center" mb={10}>
            <Group ml={-20} spacing={4}>
              <Text mt={8} size="xs">Credor:</Text>
              <Text my={6} size="xl" fw={700}>{a.rj_creditor_name}</Text>
            </Group>
            <Group ml={-20} spacing={4}>
              <Text mt={8} size="xs">CPF/CNPJ:</Text>
              <Text my={6} size="xl" fw={700}>{docMask(a.rj_creditor_doc)}</Text>
            </Group>
            {!a.analysed && <IconAlertTriangle color={"red"} />}
            {!a.analysed && <Text fw={700} color={"red"}>Análise Pendente</Text>}
          </Group>

          <Divider mt={20} size="lg" label={<Text mb={10} align="center" size="lg" fw={700}>Resumo da Análise Realizada</Text>}></Divider>
          <Table mb={10} >
            <thead>
              <tr style={{ backgroundColor: "#e2eee3" }}>
                <th>Recuperanda</th>
                <th style={{ width: 110 }}>Classe</th>
                <th style={{ width: 70 }}>Moeda</th>
                <th>Valor Inicial</th>
                <th>Variação</th>
                <th>Valor pós Análise</th>
              </tr>
            </thead>
            <tbody>
              {a.credit_summary?.map((e) => (
                <tr key={e.company_name + e.class_name + e.currency}>
                  <td>{e.company_name}</td>
                  <td>{e.class_name}</td>
                  <td>{e.currency}</td>
                  <td>{formatNumber(e.initial_amount)}</td>
                  <td style={{
                    color:
                      parseFloat(e.var_amount) > 0 ?
                        'green' : parseFloat(e.var_amount) !== 0 ?
                          'red' : ''
                  }}>{formatNumber(parseFloat(e.var_amount), 'BRL', true)}</td>
                  <td>{formatNumber(e.result_amount)}</td>
                </tr>))}
            </tbody>
          </Table>

          {a.request && <>
            <Divider mt={40} size="lg"
              label={<Text mb={10} align="center" size="lg" fw={700}>Solicitação do Credor - {a.request.type} - Em {dayjs(a.request.request_at).format("DD/MM/YY")}</Text>}></Divider>
            <Table mb={10}>
              <thead>
                <tr style={{ backgroundColor: "#fff4db" }}>
                  <th>Recuperanda</th>
                  <th style={{ width: 110 }}>Classe</th>
                  <th style={{ width: 70 }}>Moeda</th>
                  <th style={{ width: 130 }}>Valor Solicitado</th>
                  <th>Origem</th>
                  <th>Anexos</th>
                </tr>
              </thead>
              <tbody>
                {a.request?.request_info_full?.map((r) => (
                  <tr key={r.rj_company_name + r.rj_credit_class_name + r.currency}>
                    <td>{r.rj_company_name}</td>
                    <td>{r.rj_credit_class_name}</td>
                    <td>{r.creditCurrency}</td>
                    <td>{formatNumber(r.creditAmount)}</td>
                    <td>{r.source}</td>
                    <td>{r.attachment?.map((at) => <Badge mr={5} radius="md" size="xs">{at.match(/\/([^\/]+)$/)[1]}</Badge>)} </td>
                  </tr>))}
              </tbody>
            </Table>
          </>}

          <Divider mt={40} size="lg" label={<Text mb={10} align="center" size="lg" fw={700}>Detalhamento da Análise</Text>}></Divider>

          {a.credit_analyse?.map((ca, idx) =>
            <Card withBorder m={"xs"}>
              <Group sx={{ backgroundColor: "#e2f3ff" }} mb={10} position="center" >
                <Text align="center" size="md" fw={700}>Análise {idx + 1}</Text>
              </Group>
              <Grid justify={"center"}>
                <Grid.Col span="auto">
                  <Text align="center" size="sm" fw={700}>Inicial</Text>
                  <Grid gutter="xs">
                    <Grid.Col span={6}>
                      <TextInput size={"xs"} readOnly label="Recuperanda" defaultValue={ca.initial.company_name} />
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <TextInput size={"xs"} readOnly label="Credor" defaultValue={ca.initial.creditor_name} />
                    </Grid.Col>
                    <Grid.Col span={ca.request?.company_id ? 6 : 4}>
                      <TextInput size={"xs"} readOnly label="Classe" defaultValue={ca.initial.class_name} />
                    </Grid.Col>
                    <Grid.Col span={ca.request?.company_id ? 6 : 4}>
                      <TextInput size={"xs"} readOnly label="Moeda" defaultValue={ca.initial.currency} />
                    </Grid.Col>
                    <Grid.Col span={ca.request?.company_id ? 12 : 4}>
                      <TextInput size={"xs"} readOnly label="Valor" defaultValue={formatNumber(ca.initial.amount)} />
                    </Grid.Col>
                    <Grid.Col span={12}>
                      <TextInput size={"xs"} readOnly label="Descrição Origem" defaultValue={ca.initial.source} />
                    </Grid.Col>
                  </Grid>
                </Grid.Col>

                {ca.request?.company_id && <Grid.Col span={4}>
                  <Text align="center" size="sm" fw={700}>Solicitado Credor</Text>
                  <Grid gutter="xs">
                    <Grid.Col span={6}>
                      <TextInput size={"xs"} readOnly label="Recuperanda"
                        defaultValue={ca.request.company_name} />
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <TextInput size={"xs"} readOnly label="Credor"
                        defaultValue={ca.request.creditor_name} />
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <TextInput size={"xs"} readOnly label="Classe" defaultValue={ca.request.class_name} />
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <TextInput size={"xs"} readOnly label="Moeda" defaultValue={ca.request.currency} />
                    </Grid.Col>
                    <Grid.Col span={12}>
                      <TextInput size={"xs"} readOnly label="Valor"
                        defaultValue={formatNumber(ca.request.amount)} />
                    </Grid.Col>
                    <Grid.Col span={12}>
                      <TextInput size={"xs"} readOnly label="Descrição Origem"
                        defaultValue={ca.request.source} />
                    </Grid.Col>
                  </Grid>

                </Grid.Col>}
                
                <Grid.Col span="auto">
                  <Text align="center" size="sm" fw={700}>Resultado pós Análise</Text>
                  <Grid gutter="xs">
                    <Grid.Col span={6}>
                      <TextInput size={"xs"} readOnly label="Recuperanda" defaultValue={ca.result.company_name} />
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <TextInput size={"xs"} readOnly label="Credor" defaultValue={ca.result.creditor_name} />
                    </Grid.Col>
                    <Grid.Col span={ca.request?.company_id ? 6 : 4}>
                      <TextInput size={"xs"} readOnly label="Classe" defaultValue={ca.result.class_name} />
                    </Grid.Col>
                    <Grid.Col span={ca.request?.company_id ? 6 : 4}>
                      <TextInput size={"xs"} readOnly label="Moeda" defaultValue={ca.result.currency} />
                    </Grid.Col>
                    <Grid.Col span={ca.request?.company_id ? 12 : 4}>
                      <TextInput size={"xs"} readOnly label="Valor" defaultValue={formatNumber(ca.result.amount)} />
                    </Grid.Col>
                    <Grid.Col span={12}>
                      <TextInput size={"xs"} readOnly label="Descrição Origem" defaultValue={ca.result.source} />
                    </Grid.Col>
                  </Grid>

                </Grid.Col>
              </Grid>

              <Divider mt={20} size="md" label={<Text mb={10} align="center" size="md" fw={700}>Alterações</Text>}></Divider>
              <Group position="center">
                <Text fw={500}>{ca.conclusion}</Text>
              </Group>

              {ca.contradictory && <>
                <Divider mt={20} size="md" label={<Text mb={10} align="center" size="md" fw={700}>Contraditório
                  Recuperanda</Text>}></Divider>
                <TypographyStylesProvider>
                  <div dangerouslySetInnerHTML={{ __html: `${ca.contradictory}` }} />
                </TypographyStylesProvider>
              </>}

              <Divider mt={20} size="md" label={<Text mb={10} align="center" size="md" fw={700}>Análise Administrador Judicial</Text>}></Divider>
              <TypographyStylesProvider>
                <div dangerouslySetInnerHTML={{ __html: `${ca.report}` }} />
              </TypographyStylesProvider>
            </Card>)}
        </Card>)}
    </Container>
  );
}
