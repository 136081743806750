import React, { useState } from 'react';
import {MultiSelect, ScrollArea, Table, Pagination, Card, Text, Grid, Center, Group, Input, Timeline, Container} from "@mantine/core";
import { translate, group, format} from '../lib/bigdata';
import GenericTableComponent  from "./GenericTableComponent";

function InfoText({label, data}) {
  if ((typeof data[label] !== 'boolean' && !data[label])) {
    return null;
  }
  return (
    <div>
      <Text size="xs" color="dimmed">
        {translate(label)}
      </Text>
      <Text fw={600} size={"xs"}>
        {format(data[label])}
      </Text>
    </div>
  )
}

function LawsuitComponent({className, data}) {
  let keys =["Type", "CourtType", "CourtName", "CourtLevel", "JudgingBody", "CourtDistrict", "State", "MainSubject", "Status", "Value", "NumberOfUpdates", "PublicationDate","LastMovementDate", "OtherSubjects"]
  let partyKeys =["Name","Doc", "Type","Polarity"]
  function renderTimelineItem(item, index) {
    return (
      <Timeline.Item  title={format(item.PublishDate)}>
        <Text  size="sm">{item.Content}</Text>
      </Timeline.Item>
    );
  }

  return (
    <Container my="md" fluid className={className} >
      <Grid>
        <Grid.Col span={4}>
          <Center pb={20}><Text size="xl" weight={700}>Informações do Processo {data.Number}</Text></Center>
          <Card withBorder  p={"xs"}>
            <Group position={"apart"}>
              {keys.map((key) => (
                <InfoText label={key} data={data}/>
              ))}
            </Group>
          </Card>
          <Card p={0}>
            <GenericTableComponent
              pagination={30}
              fullHeight
              search
              title={"Partes"}
              columns={["Polarity","Name","Doc", "Type"]}
              data={data.Parties
                .reduce((acc, party) => {
                const existingParty = acc.find(p => p.Name === party.Name);
                if (!existingParty) {
                  return [...acc, party];
                }
                if (party.Doc && !existingParty.Doc) {
                  return [...acc.filter(p => p.Name !== party.Name), party];
                }
                return acc;
              }, [])
                .map((party) => {
                return {...party, Type: (party.PartyDetails.SpecificType || party.Type)}
              }).sort((a, b) => {
                if (a.Polarity === 'ACTIVE' && b.Polarity !== 'ACTIVE') {
                  return -1;
                }
                if (b.Polarity === 'ACTIVE' && a.Polarity !== 'ACTIVE') {
                  return 1;
                }
                if (a.Polarity === 'PASSIVE' && b.Polarity !== 'PASSIVE') {
                  return -1;
                }
                if (b.Polarity === 'PASSIVE' && a.Polarity !== 'PASSIVE') {
                  return 1;
                }
                return a.Name.localeCompare(b.Name);
              })
              }/>
          </Card>

        </Grid.Col>
        <Grid.Col span={8}>
          <Center pb={20}><Text size="xl" weight={700}>Últimas Movimentações</Text></Center>
          <Card withBorder shadow="xs" padding="md" >
            <ScrollArea style={{height: "calc(100vh - 190px)"}}>
              <Timeline>
                {data.Updates.map((item, index) => renderTimelineItem(item, index))}
              </Timeline>
            </ScrollArea>
          </Card>
        </Grid.Col>
      </Grid>
    </Container>
  );
}
export default LawsuitComponent;