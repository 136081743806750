import {
  IconAlertTriangle,
  IconBow,
  IconBuildingArch,
  IconBuildingWarehouse,
  IconCircleDashed,
  IconFlame,
  IconLeafOff,
  IconLock,
  IconPick,
  IconSquareFilled,
  IconTrees,
  IconUsersGroup,
  IconMapPinFilled,
  IconWall
} from "@tabler/icons-react";

export function layerProperties(legend) {
  switch (legend?.toUpperCase()) {
    case 'SIGEF':
    case 'V_SIGEF':
    case 'SIGEF_BRASIL':
      return {
        clip: true,
        layer: 'sigef_brasil',
        id: 'sigef',
        opacity: 0.3,
        popupTemplate: {
          title: "{nome}",
          content:
            "<b>Estado:</b> {uf}<br>" +
            "<b>Município:</b> {municipio}<br>" +
            "<b>Área:</b> {area_texto}<br>" +
            "<b>Código Parcela:</b> {cod_parcela}<br>" +
            "<b>Código INCRA:</b> {cod_imovel}<br>" +
            "<b>Status:</b> {status}<br>" +
            "<b>Situação:</b> {situacao}<br>" +
            "<b>Data Aprovação:</b> {data_aprov}<br>" +
            "<b>Data Submissão:</b> {data_sub}<br>" +
            "<b>Data Registro:</b> {data_reg}<br>" +
            "<b>Registro Matrícula:</b> {mat_reg}<br>" +
            "<b>ART:</b> {art}<br>"
        },
        label: "SIGEF - Imóveis Rurais",
        color: "#15a9be",
        colorOutline: "#04333a",
        icon: IconMapPinFilled,
        path: "M18.364 4.636a9 9 0 0 1 .203 12.519l-.203 .21l-4.243 4.242a3 3 0 0 1 -4.097 .135l-.144 -.135l-4.244 -4.243a9 9 0 0 1 12.728 -12.728zm-6.364 3.364a3 3 0 1 0 0 6a3 3 0 0 0 0 -6z"
      }
    case 'V_SNCI':
    case 'SNCI_BRASIL':
    case 'SNCI':
      return {
        clip: true,
        layer: 'snci_brasil',
        id: 'snci',
        opacity: 0.3,
        label: "SNCI - Imóveis Rurais",
        color: "#228ae5",
        colorOutline: "#052844",
        icon: IconMapPinFilled,
        path: "M18.364 4.636a9 9 0 0 1 .203 12.519l-.203 .21l-4.243 4.242a3 3 0 0 1 -4.097 .135l-.144 -.135l-4.244 -4.243a9 9 0 0 1 12.728 -12.728zm-6.364 3.364a3 3 0 1 0 0 6a3 3 0 0 0 0 -6z"
      }
    case 'V_CAR':
    case 'CAR':
      return {
        clip: true,
        id: 'car',
        minNativeZoom: 8,
        layer: 'car',
        popupTemplate: {
          title: "{cod_imovel}",
          actions:[{ title: "Abrir Relatório", id: "open-report" }],
          content:
            "<b>Módulos Fisicais:</b> {mod_fiscal}<br>" +
            "<b>Área:</b> {num_area}<br>" +
            "<b>Status:</b> {ind_status}<br>" +
            "<b>Tipo:</b> {ind_tipo}<br>" +
            "<b>Condição:</b> {des_condic}<br>"
        },
        label: "CAR",
        color: "#ffe99d",
        opacity: 0.3,
        colorOutline: "#ffe99d",
        icon: IconCircleDashed,
        path: ""
      }
    case 'SICAR':
      return {
        clip: true,
        id: 'sicar',
        layer: 'car',
        label: "CAR",
        color: "#ffe99d",
        opacity: 0.3,
        colorOutline: "#ffe99d",
        icon: IconCircleDashed,
        path: ""
      }
    case 'EMBARGOS':
      return {
        id: 'embargos_ibama',
        layer: 'car',
        label: "Embargos IBAMA e ICMBIO",
        color: "#ff0000",
        opacity: 0.5,
        colorOutline: "#ff0000",
        icon: IconAlertTriangle,
        path: "M10.24 3.957l-8.422 14.06a1.989 1.989 0 0 0 1.7 2.983h16.845a1.989 1.989 0 0 0 1.7 -2.983l-8.423 -14.06a1.989 1.989 0 0 0 -3.4 0z M12 9v4 M12 17h.01"
      }
    case 'EMBARGOS_IBAMA':
      return {
        id: 'embargos_ibama',
        popupTemplate: {
          title: "{numero_tad}",
          content:
            "<b>Número Tad:</b> {numero_tad} <br>" +
            "<b>Data do Embargo:</b> {dat_embargo} <br>" +
            "<b>Data da Última Alteração:</b> {dat_ult_alteracao} <br>" +
            "<b>Data da Impressão:</b> {dat_impressao} <br>" +
            "<b>UF:</b> {sig_uf} <br>" +
            "<b>Município:</b> {nom_municipio} <br>" +
            "<b>Nome da Propriedade:</b> {nom_propriedade} <br>" +
            "<b>Descrição da Localização:</b> {des_localizacao} <br>" +
            "<b>Nome da Pessoa:</b> {nom_pessoa} <br>" +
            "<b>CPF/CNPJ (sem formatação):</b> {cpf_cnpj_sem_formatacao} <br>" +
            "<b>Situação do Desmatamento:</b> {sit_desmatamento} <br>" +
            "<b>Número do Auto de Infração:</b> {num_auto_infracao} <br>" +
            "<b>Série do Auto de Infração:</b> {ser_auto_infracao} <br>" +
            "<b>Quantidade de Área Desmatada:</b> {qtd_area_desmatada} <br>" +
            "<b>Quantidade de Área Embargada:</b> {qtd_area_embargada} <br>" +
            "<b>Nome da Operação:</b> {nom_operacao} <br>" +
            "<b>Unidade de Controle do IBAMA:</b> {unid_ibama_controle} <br>" +
            "<b>Ordem de Fiscalização:</b> {ordem_fiscalizacao} <br>" +
            "<b>Ação Fiscalizatória:</b> {acao_fiscalizatoria} <br>" +
            "<b>Processo Tad:</b> {processo_tad} <br>" +
            "<b>Descrição Tad:</b> {des_tad} <br>"
        },
        label: "Embargos IBAMA e ICMBIO",
        color: "#ff0000",
        fill: "cross",
        colorOutline: "#000000",
        opacity: 0.2,
        icon: IconAlertTriangle,
        path: "M10.24 3.957l-8.422 14.06a1.989 1.989 0 0 0 1.7 2.983h16.845a1.989 1.989 0 0 0 1.7 -2.983l-8.423 -14.06a1.989 1.989 0 0 0 -3.4 0z M12 9v4 M12 17h.01"
      }
    case 'EMBARGOS_ICMBIO':
      return {
        id: 'embargos_icmbio',
        popupTemplate: {
          title: "{numero_emb}",
          content:
            "<b>Número do Embargo:</b> {numero_emb} <br>" +
            "<b>Série:</b> {serie} <br>" +
            "<b>Origem:</b> {origem} <br>" +
            "<b>Número do Auto de Infração:</b> {numero_ai} <br>" +
            "<b>Autuado:</b> {autuado} <br>" +
            "<b>Descrição da Infração:</b> {desc_infra} <br>" +
            "<b>Descrição da Sanção:</b> {desc_sanc} <br>" +
            "<b>Artigo 1:</b> {artigo_1} <br>" +
            "<b>Artigo 2:</b> {artigo_2} <br>" +
            "<b>Tipo de Infração:</b> {tipo_infra} <br>" +
            "<b>Nome da Unidade de Conservação:</b> {nome_uc} <br>" +
            "<b>Código da Unidade de Conservação:</b> {cnuc} <br>" +
            "<b>Município:</b> {municipio} <br>" +
            "<b>UF:</b> {uf} <br>" +
            "<b>Data:</b> {data} <br>" +
            "<b>Ano:</b> {ano} <br>" +
            "<b>Observações:</b> {obs} <br>" +
            "<b>Julgamento:</b> {julgamento} <br>" +
            "<b>Área:</b> {area} <br>" +
            "<b>Processo:</b> {processo} <br>" +
            "<b>CPF/CNPJ:</b> {cpf_cnpj} <br>"
        },
        label: "Embargos IBAMA e ICMBIO",
        color: "#ff0000",
        colorOutline: "#000000",
        opacity: 0.2,
        icon: IconAlertTriangle,
        fill: "cross",
        path: "M10.24 3.957l-8.422 14.06a1.989 1.989 0 0 0 1.7 2.983h16.845a1.989 1.989 0 0 0 1.7 -2.983l-8.423 -14.06a1.989 1.989 0 0 0 -3.4 0z M12 9v4 M12 17h.01"
      }
    case 'MAPBIOMAS_ALERTAS':
      return {
        id: 'mapbiomas_alertas',
        label: "Desmatamento",
        layer: "mapbiomas_alertas_desmatamento",
        popupTemplate: {
          title: "titulo",
          content:
            "<b><a href='https://plataforma.alerta.mapbiomas.org/alerta/{codealerta}'>abrir Alerta</a></b> <br>" +
            "<b>codealerta:</b> {codealerta} <br>" +
            "<b>fonte:</b> {fonte} <br>" +
            "<b>bioma:</b> {bioma} <br>" +
            "<b>estado:</b> {estado} <br>" +
            "<b>municipio:</b> {municipio} <br>" +
            "<b>areaha:</b> {areaha} <br>" +
            "<b>anodetec:</b> {anodetec} <br>" +
            "<b>datadetec:</b> {datadetec} <br>" +
            "<b>dtimgant:</b> {dtimgant} <br>" +
            "<b>dtimgdep:</b> {dtimgdep} <br>" +
            "<b>dtpubli:</b> {dtpubli} <br>" +
            "<b>vpressao:</b> {vpressao} <br>"
        },
        color: "#F8AF05",
        opacity: 0.2,
        colorOutline: "#f8af05",
        icon: IconLeafOff,
        path: "M5 21c.475 -4.27 2.3 -7.64 6.331 -9.683 M6.618 6.623c-1.874 1.625 -2.625 3.877 -2.632 6.377c0 1 0 3 2 5h3.014c2.733 0 5.092 -.635 6.92 -2.087m1.899 -2.099c1.224 -1.872 1.987 -4.434 2.181 -7.814v-2h-4.014c-2.863 0 -5.118 .405 -6.861 1.118 M3 3l18 18"
      }
    case 'ASSENTAMENTOS':
      return {
        id: 'assentamentos',
        layer:"assentamento_brasil",
        label: "Assentamentos",
        hide: true,
        popupTemplate: {
          title: "{nome_imove}",
          content:
            "<b>cd_sipra:</b> {cd_sipra}<br>" +
            "<b>uf:</b> {uf}<br>" +
            "<b>nome_proje:</b> {nome_proje}<br>" +
            "<b>municipio:</b> {municipio}<br>" +
            "<b>area_hecta:</b> {area_hecta}<br>" +
            "<b>capacidade:</b> {capacidade}<br>" +
            "<b>num_famili:</b> {num_famili}<br>" +
            "<b>fase:</b> {fase}<br>" +
            "<b>data_de_cr:</b> {data_de_cr}<br>"
        },
        color: "#ba70c9",
        opacity: 0.2,
        colorOutline: "#f0b0ff",
        icon: IconUsersGroup,
        path: "M10 13a2 2 0 1 0 4 0a2 2 0 0 0 -4 0 M8 21v-1a2 2 0 0 1 2 -2h4a2 2 0 0 1 2 2v1 M15 5a2 2 0 1 0 4 0a2 2 0 0 0 -4 0 M17 10h2a2 2 0 0 1 2 2v1 M5 5a2 2 0 1 0 4 0a2 2 0 0 0 -4 0 M3 13v-1a2 2 0 0 1 2 -2h2"
      }
    case 'UNIDADE_CONSERVACAO':
      return {
        id: 'unidade_conservacao',
        hide: true,
        label: "Unidade de Conservação",
        popupTemplate: {
          title: "{nome_uc}",
          content:
            "<b>Grupo:</b> {grupo}<br>" +
            "<b>Esfera:</b> {esfera}<br>" +
            "<b>Código:</b> {cd_cnuc}<br>" +
            "<b>Categoria:</b> {categoria}<br>" +
            "<b>Categoria IUCN:</b> {cat_iucn}<br>" +
            "<b>Data Criação:</b> {cria_ano}<br>" +
            "<b>Ato de Criação:</b> {cria_ato}<br>" +
            "<b>Precisão Poligono:</b> {quali_pol}<br>"
        },
        color: "#00ce03",
        opacity: 0.2,
        colorOutline: "#04b905",
        icon: IconLock,
        path: "M5 13a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v6a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-6z M11 16a1 1 0 1 0 2 0a1 1 0 0 0 -2 0 M8 11v-4a4 4 0 1 1 8 0v4"
      }
    case 'FLORESTA':
      return {
        id: 'floresta',
        layer:"areas_florestas_publicas",
        hide: true,
        label: "Florestas Públicas",
        popupTemplate: {
          title: "{nome}",
          content:
            "<b>nome:</b> {nome}<br>" +
            "<b>orgao:</b> {orgao}<br>" +
            "<b>classe:</b> {classe}<br>" +
            "<b>estagio:</b> {estagio}<br>" +
            "<b>governo:</b> {governo}<br>" +
            "<b>codigo:</b> {codigo}<br>" +
            "<b>protecao:</b> {protecao}<br>" +
            "<b>tipo:</b> {tipo}<br>" +
            "<b>comunitari:</b> {comunitari}<br>" +
            "<b>categoria:</b> {categoria}<br>"
        },
        color: "#40bf8a",
        opacity: 0.2,
        colorOutline: "#53fa9b",
        icon: IconTrees,
        path: ""}
    case 'INDIGENA':
      return {
        id: 'indigena',
        layer:"areas_indigena",
        hide: true,
        label: "Terra Indígena",
        popupTemplate: {
          title: "{nome_imove}",
          content:
            "<b>fid:</b> {fid}<br>" +
            "<b>terrai_cod:</b> {terrai_cod}<br>" +
            "<b>terrai_nom:</b> {terrai_nom}<br>" +
            "<b>etnia_nome:</b> {etnia_nome}<br>" +
            "<b>municipio_:</b> {municipio_}<br>" +
            "<b>uf_sigla:</b> {uf_sigla}<br>" +
            "<b>superficie:</b> {superficie}<br>" +
            "<b>fase_ti:</b> {fase_ti}<br>" +
            "<b>modalidade:</b> {modalidade}<br>" +
            "<b>reestudo_t:</b> {reestudo_t}<br>" +
            "<b>cr:</b> {cr}<br>" +
            "<b>faixa_fron:</b> {faixa_fron}<br>" +
            "<b>undadm_cod:</b> {undadm_cod}<br>" +
            "<b>undadm_nom:</b> {undadm_nom}<br>" +
            "<b>undadm_sig:</b> {undadm_sig}<br>" +
            "<b>dominio_un:</b> {dominio_un}<br>" +
            "<b>data_atual:</b> {data_atual}<br>"
        },
        color: "#FF9740",
        opacity: 0.2,
        colorOutline: "#ffab64",
        icon: IconBow,
        path: "M17 3h4v4 M21 3l-15 15 M3 18h3v3 M16.5 20c1.576 -1.576 2.5 -4.095 2.5 -6.5c0 -4.81 -3.69 -8.5 -8.5 -8.5c-2.415 0 -4.922 .913 -6.5 2.5l12.5 12.5z"
      }
    case 'QUILOMBOLA':
      return {
        id: 'quilombola',
        layer:"areas_quilombola",
        hide: true,
        label: "Terra Quilombola",
        opacity: 0.2,
        popupTemplate: {
          title: "{cd_quilomb}",
          content:
            "<b>cd_quilomb:</b> {cd_quilomb}<br>" +
            "<b>cd_sr:</b> {cd_sr}<br>" +
            "<b>nr_process:</b> {nr_process}<br>" +
            "<b>nm_comunid:</b> {nm_comunid}<br>" +
            "<b>nm_municip:</b> {nm_municip}<br>" +
            "<b>cd_uf:</b> {cd_uf}<br>" +
            "<b>dt_publica:</b> {dt_publica}<br>" +
            "<b>dt_public1:</b> {dt_public1}<br>" +
            "<b>nr_familia:</b> {nr_familia}<br>" +
            "<b>dt_titulac:</b> {dt_titulac}<br>" +
            "<b>nr_area_ha:</b> {nr_area_ha}<br>" +
            "<b>nr_perimet:</b> {nr_perimet}<br>" +
            "<b>cd_sipra:</b> {cd_sipra}<br>" +
            "<b>ob_descric:</b> {ob_descric}<br>" +
            "<b>st_titulad:</b> {st_titulad}<br>" +
            "<b>dt_decreto:</b> {dt_decreto}<br>" +
            "<b>tp_levanta:</b> {tp_levanta}<br>" +
            "<b>nr_escalao:</b> {nr_escalao}<br>" +
            "<b>area_calc_:</b> {area_calc_}<br>" +
            "<b>perimetro_:</b> {perimetro_}<br>" +
            "<b>esfera:</b> {esfera}<br>" +
            "<b>fase:</b> {fase}<br>" +
            "<b>responsave:</b> {responsave}<br>"
        },
        color: "#70380c",
        colorOutline: "#ffd9bc",
        icon: IconSquareFilled,
        path: "M19 2h-14a3 3 0 0 0 -3 3v14a3 3 0 0 0 3 3h14a3 3 0 0 0 3 -3v-14a3 3 0 0 0 -3 -3z"
      }
    case 'FOGO':
      return {
        id: 'fogo',
        label: "Anomalia Térmica",
        color: "#d8480f",
        colorOutline: "#620202",
        opacity: 0.2,
        icon: IconFlame,
        path: "M12 12c2 -2.96 0 -7 -1 -8c0 3.038 -1.773 4.741 -3 6c-1.226 1.26 -2 3.24 -2 5a6 6 0 1 0 12 0c0 -1.532 -1.056 -3.94 -2 -5c-1.786 3 -2.791 3 -4 2z"
      }
    case 'SITIO':
      return {
        id: 'sitio',
        layer: 'areas_sitio_arqueologico',
        label: "Sítio Arqueológico",
        popupTemplate: {
          title: "{identifica}",
          content:
            "<b>id_bem:</b> {id_bem}<br>" +
            "<b>co_iphan:</b> {co_iphan}<br>" +
            "<b>ds_naturez:</b> {ds_naturez}<br>" +
            "<b>ds_classif:</b> {ds_classif}<br>" +
            "<b>ds_tipo_be:</b> {ds_tipo_be}<br>" +
            "<b>sintese_be:</b> {sintese_be}<br>" +
            "<b>dt_cadastr:</b> {dt_cadastr}<br>"
        },
        color: "#888eee",
        colorOutline: "#000000",
        opacity: 0.2,
        icon: IconBuildingArch,
        path: "M3 21l18 0 M4 21v-15a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v15 M9 21v-8a3 3 0 0 1 6 0v8"
      }
    case 'ARMAZEM':
      return {
        id: 'armazem',
        layer: "infra_armazem",
        label: "Armazem/Silo",
        popupTemplate: {
          title: "{armazenador}",
          content:
            "<b>Código:</b> {cda}<br>" +
            "<b>Tipo:</b> {tipo}<br>" +
            "<b>Capacidade (t):</b> {cap_t}<br>"
        },
        color: "#e6ff00",
        colorOutline: "#000000",
        opacity: 0.2,
        icon: IconBuildingWarehouse,
        path: "M3 21v-13l9 -4l9 4v13 M13 13h4v8h-10v-6h6 M13 21v-9a1 1 0 0 0 -1 -1h-2a1 1 0 0 0 -1 1v3"
      }
    case 'INFRA_BARRAGEM':
      return {
        id: "infra_barragem",
        layer: "infra_barragem",
        label: "Barragem de Mineração",
        popupTemplate: {
          title: "{nome_da_barragem}",
          content:
            "<b>Empreendedor</b> {nome_do_empreendedor}<br>" +
            "<b>CPF/CNPJ:</b> {cpf_cnpj}<br>" +
            "<b>Minério Principal:</b> {minerio_principal}<br>" +
            "<b>Volume Atual (m3):</b> {volume_atual_m3}<br>" +
            "<b>Altura Atual (m):</b> {altura_atual_m}<br>" +
            "<b>Método Construtivo:</b> {metodo_construtivo}<br>" +
            "<b>Dano Potencial Associado:</b> {dano_potencial_associado}<br>" +
            "<b>Nível de Emergência:</b> {nivel_de_emergencia}<br>"
        },
        color: "#ff5700",
        colorOutline: "#000000",
        icon: IconWall,
        opacity: 0.2,
        path: "" +
          "M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z M4 8h16 M20 12h-16 M4 16h16 M9 4v4 M14 8v4 M8 12v4 M16 12v4 M11 16v4"
      }
    case 'AREAS_BIOMA':
      return {
        id: "areas_bioma",
        layer: "areas_bioma",
        label: "Biomas",
        color: "#ff5700",
        colorOutline: "#000000",
        icon: IconWall,
        opacity: 0.2,
        path: "" +
          "M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z M4 8h16 M20 12h-16 M4 16h16 M9 4v4 M14 8v4 M8 12v4 M16 12v4 M11 16v4"
      }
    case 'AREAS_AMAZONIA_LEGAL':
      return {
        id: "areas_amazonia_legal",
        layer: "areas_amazonia_legal",
        label: "Amazônia Legal",
        color: "#ff5700",
        colorOutline: "#000000",
        icon: IconWall,
        opacity: 0.2,
        path: "" +
          "M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z M4 8h16 M20 12h-16 M4 16h16 M9 4v4 M14 8v4 M8 12v4 M16 12v4 M11 16v4"
      }
    case 'BR_MUNICIPIOS_IBGE':
      return {
        id: "br_municipios_ibge",
        layer: "br_municipios_ibge",
        label: "Municipios",
        color: "#ff5700",
        colorOutline: "#000000",
        icon: IconWall,
        opacity: 0.2,
        path: "" +
          "M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z M4 8h16 M20 12h-16 M4 16h16 M9 4v4 M14 8v4 M8 12v4 M16 12v4 M11 16v4"
      }
    case 'MINERACAO':
      return {
        id: 'infra_mineracao',
        layer: "infra_mineracao",
        hide: true,
        label: "Mineração",
        popupTemplate: {
          title: "{nome}",
          content:
            "<b>Processo</b> {processo}<br>" +
            "<b>Material:</b> {subs}<br>" +
            "<b>Uso:</b> {uso}<br>" +
            "<b>Fase:</b> {fase}<br>" +
            "<b>Ult. Evento:</b> {ult_evento}<br>"
        },
        color: "#ff6200",
        colorOutline: "#000000",
        icon: IconPick,
        opacity: 0.2,
        path: "M13 8l-9.383 9.418a2.091 2.091 0 0 0 0 2.967a2.11 2.11 0 0 0 2.976 0l9.407 -9.385 M9 3h4.586a1 1 0 0 1 .707 .293l6.414 6.414a1 1 0 0 1 .293 .707v4.586a2 2 0 1 1 -4 0v-3l-5 -5h-3a2 2 0 1 1 0 -4z"
      }
    case 'INFRA_RODO':
      return {
        id: 'infra_rodo',
        label: "Rodovias Federais",
        layer: "infra_rodo",
        color: "#040000",
        colorOutline: "#130202",
        opacity: 0.2,
        icon: IconFlame,
        path: "M12 12c2 -2.96 0 -7 -1 -8c0 3.038 -1.773 4.741 -3 6c-1.226 1.26 -2 3.24 -2 5a6 6 0 1 0 12 0c0 -1.532 -1.056 -3.94 -2 -5c-1.786 3 -2.791 3 -4 2z"
      }
    case 'INFRA_FERRO':
      return {
        id: 'infra_ferro',
        label: "Ferrovias",
        layer: "infra_ferro",
        color: "#d8480f",
        colorOutline: "#620202",
        opacity: 0.2,
        icon: IconFlame,
        path: "M12 12c2 -2.96 0 -7 -1 -8c0 3.038 -1.773 4.741 -3 6c-1.226 1.26 -2 3.24 -2 5a6 6 0 1 0 12 0c0 -1.532 -1.056 -3.94 -2 -5c-1.786 3 -2.791 3 -4 2z"
      }
    case 'INFRA_HIDRO':
      return {
        id: 'infra_hidro',
        label: "Hidrovias",
        layer: "infra_hidro",
        color: "#0f8bd8",
        colorOutline: "#0027ff",
        opacity: 0.2,
        icon: IconFlame,
        path: "M12 12c2 -2.96 0 -7 -1 -8c0 3.038 -1.773 4.741 -3 6c-1.226 1.26 -2 3.24 -2 5a6 6 0 1 0 12 0c0 -1.532 -1.056 -3.94 -2 -5c-1.786 3 -2.791 3 -4 2z"
      }
    case 'INFRA_AERO_AERODROMOS':
      return {
        id: 'infra_aero_aerodromos',
        label: "Aeródromos",
        layer: "infra_aero_aerodromos",
        color: "#0f8bd8",
        colorOutline: "#0027ff",
        opacity: 0.2,
        icon: IconFlame,
        path: "M12 12c2 -2.96 0 -7 -1 -8c0 3.038 -1.773 4.741 -3 6c-1.226 1.26 -2 3.24 -2 5a6 6 0 1 0 12 0c0 -1.532 -1.056 -3.94 -2 -5c-1.786 3 -2.791 3 -4 2z"
      }
    case 'AREAS_MATOPIBA_MICRORREGIOES':
      return {
        id: 'areas_matopiba_microrregioes',
        label: "Matopiba",
        layer: "areas_matopiba_microrregioes",
        color: "#a1ff5f",
        colorOutline: "#86e16d",
        opacity: 0.2,
        icon: IconFlame,
        path: "M12 12c2 -2.96 0 -7 -1 -8c0 3.038 -1.773 4.741 -3 6c-1.226 1.26 -2 3.24 -2 5a6 6 0 1 0 12 0c0 -1.532 -1.056 -3.94 -2 -5c-1.786 3 -2.791 3 -4 2z"
      }
    case "DESMATAMENTO_2008":
      return {
        id: "2008",
        popupTemplate: {
          title: "Desmatamento - 2008"
        },
        label: "Desmatamento - 2008",
        color: "#fab005",
        colorOutline: "#fab005",
        fill: "solid",
        opacity: 0.6,
      }
    case "DESMATAMENTO_2009":
      return {
        id: "2009",
        popupTemplate: {
          title: "Desmatamento - 2009"
        },
        label: "Desmatamento - 2009",
        color: "#fab005",
        colorOutline: "#fab005",
        fill: "solid",
        opacity: 0.6,
      }
    case "DESMATAMENTO_2010":
      return {
        id: "2010",
        popupTemplate: {
          title: "Desmatamento - 2010"
        },
        label: "Desmatamento - 2010",
        color: "#fab005",
        colorOutline: "#fab005",
        fill: "solid",
        opacity: 0.6,
      }
    case "DESMATAMENTO_2011":
      return {
        id: "2011",
        popupTemplate: {
          title: "Desmatamento - 2011"
        },
        label: "Desmatamento - 2011",
        color: "#fab005",
        colorOutline: "#fab005",
        fill: "solid",
        opacity: 0.6,
      }
    case "DESMATAMENTO_2012":
      return {
        id: "2012",
        popupTemplate: {
          title: "Desmatamento - 2012"
        },
        label: "Desmatamento - 2012",
        color: "#fab005",
        colorOutline: "#fab005",
        fill: "solid",
        opacity: 0.6,
      }
    case "DESMATAMENTO_2013":
      return {
        id: "2013",
        popupTemplate: {
          title: "Desmatamento - 2013"
        },
        label: "Desmatamento - 2013",
        color: "#fab005",
        colorOutline: "#fab005",
        fill: "solid",
        opacity: 0.6,
      }
    case "DESMATAMENTO_2014":
      return {
        id: "2014",
        popupTemplate: {
          title: "Desmatamento - 2014"
        },
        label: "Desmatamento - 2014",
        color: "#fab005",
        colorOutline: "#fab005",
        fill: "solid",
        opacity: 0.6,
      }
    case "DESMATAMENTO_2015":
      return {
        id: "2015",
        popupTemplate: {
          title: "Desmatamento - 2015"
        },
        label: "Desmatamento - 2015",
        color: "#fab005",
        colorOutline: "#fab005",
        fill: "solid",
        opacity: 0.6,
      }
    case "DESMATAMENTO_2016":
      return {
        id: "2016",
        popupTemplate: {
          title: "Desmatamento - 2016"
        },
        label: "Desmatamento - 2016",
        color: "#fab005",
        colorOutline: "#fab005",
        fill: "solid",
        opacity: 0.6,
      }
    case "DESMATAMENTO_2017":
      return {
        id: "2017",
        popupTemplate: {
          title: "Desmatamento - 2017"
        },
        label: "Desmatamento - 2017",
        color: "#fab005",
        colorOutline: "#fab005",
        fill: "solid",
        opacity: 0.6,
      }
    case "DESMATAMENTO_2018":
      return {
        id: "2018",
        popupTemplate: {
          title: "Desmatamento - 2018"
        },
        label: "Desmatamento - 2018",
        color: "#fab005",
        colorOutline: "#fab005",
        fill: "solid",
        opacity: 0.6,
      }
    case "DESMATAMENTO_2019":
      return {
        id: "2019",
        popupTemplate: {
          title: "Desmatamento - 2019"
        },
        label: "Desmatamento - 2019",
        color: "#fab005",
        colorOutline: "#fab005",
        fill: "solid",
        opacity: 0.6,
      }
    case "DESMATAMENTO_2020":
      return {
        id: "2020",
        popupTemplate: {
          title: "Desmatamento - 2020"
        },
        label: "Desmatamento - 2020",
        color: "#fab005",
        colorOutline: "#fab005",
        fill: "solid",
        opacity: 0.6,
      }
    case "DESMATAMENTO_2021":
      return {
        id: "2021",
        popupTemplate: {
          title: "Desmatamento - 2021"
        },
        label: "Desmatamento - 2021",
        color: "#fab005",
        colorOutline: "#fab005",
        fill: "solid",
        opacity: 0.6,
      }
    case "DESMATAMENTO_2022":
      return {
        id: "2022",
        popupTemplate: {
          title: "Desmatamento - 2022"
        },
        label: "Desmatamento - 2022",
        color: "#fab005",
        colorOutline: "#fab005",
        fill: "solid",
        opacity: 0.6,
      }
    case "DESMATAMENTO_2023":
      return {
        id: "2023",
        popupTemplate: {
          title: "Desmatamento - 2023"
        },
        label: "Desmatamento - 2023",
        color: "#fab005",
        colorOutline: "#fab005",
        fill: "solid",
        opacity: 0.6,
      }
    case "DESMATAMENTO_2024":
      return {
        id: "2024",
        popupTemplate: {
          title: "Desmatamento - 2024"
        },
        label: "Desmatamento - 2024",
        color: "#fab005",
        colorOutline: "#fab005",
        fill: "solid",
        opacity: 0.6,
      }
    case "DESMATAMENTO_2025":
      return {
        id: "2025",
        popupTemplate: {
          title: "Desmatamento - 2025"
        },
        label: "Desmatamento - 2025",
        color: "#fab005",
        colorOutline: "#fab005",
        fill: "solid",
        opacity: 0.6,
      }
    case "AREA_INFRAESTRUTURA_PUBLICA":
      return {
        id: "AREA_INFRAESTRUTURA_PUBLICA",
        popupTemplate: {
          title: "Área de Infraestrutura Pública"
        },
        label: "Área de Infraestrutura Pública",
        color: "#a8a8a8",
        colorOutline: "#a8a8a8",
        fill: "solid",
        opacity: "99",
        stripped: 45
      }
    case "AREA_UTILIDADE_PUBLICA":
      return {
        id: "AREA_UTILIDADE_PUBLICA",
        popupTemplate: {
          title: "Área de Utilidade Pública"
        },
        label: "Área de Utilidade Pública",
        color: "#9a9a9a",
        colorOutline: "#9a9a9a",
        fill: "solid",
        opacity: "99",
        stripped: 45
      }
    case "RESERVATORIO_ENERGIA":
      return {
        id: "RESERVATORIO_ENERGIA",
        popupTemplate: {
          title: "Reservatório para Abastecimento ou Geração de Energia"
        },
        label: "Reservatório para Abastecimento ou Geração de Energia",
        color: "#ffc351",
        colorOutline: "#ffc351",
        fill: "solid",
        opacity: "99",
        stripped: 45
      }
    case "AREA_ENTORNO_RESERVATORIO_ENERGIA":
      return {
        id: "AREA_ENTORNO_RESERVATORIO_ENERGIA",
        popupTemplate: {
          title: "Área de Entorno de Reservatório para Abastecimento ou Geração de Energia"
        },
        label: "Área de Entorno de Reservatório para Abastecimento ou Geração de Energia",
        color: "#ffc351",
        colorOutline: "#ffc351",
        fill: "solid",
        opacity: "99",
        stripped: 45
      }
    case "AREA_SERVIDAO_ADMINISTRATIVA_TOTAL":
      return {
        id: "AREA_SERVIDAO_ADMINISTRATIVA_TOTAL",
        popupTemplate: {
          title: "Área de Servidão Administrativa Total"
        },
        label: "Área de Servidão Administrativa Total",
        color: "#7957dd",
        colorOutline: "#7957dd",
        fill: "solid",
        opacity: "99",
        stripped: 45
      }
    case "APP_TOTAL" :
      return {
        id: "APP_TOTAL",
        popupTemplate: {
          "title": "Área de Preservação Permanente (APP)"
        },
        label: "Área de Preservação Permanente (APP)",
        color: "#00eaff",
        colorOutline: "#00eaff",
        fill: "cross",
        opacity: "99",
        stripped: 90
      }
    case "RIO_ATE_10":
      return {
        id: "RIO_ATE_10",
        popupTemplate: {
          "title": "Curso d’água natural de até 10 metros"
        },
        label: "Curso d’água natural de até 10 metros",
        color: "#000cff",
        colorOutline: "#000cff",
        fill: "vertical",
        opacity: "99",
        stripped: 45
      }
    case "RIO_10_A_50":
      return {
        id: "RIO_10_A_50",
        popupTemplate: {
          "title": "Curso d’água natural de 10 a 50 metros"
        },
        label: "Curso d’água natural de 10 a 50 metros",
        color: "#000cff",
        colorOutline: "#000cff",
        fill: "vertical",
        opacity: "99",
        stripped: 45
      }
    case "RIO_50_A_200":
      return {
        id: "RIO_50_A_200",
        popupTemplate: {
          "title": "Curso d’água natural de 50 a 200 metros"
        },
        label: "Curso d’água natural de 50 a 200 metros",
        color: "#000cff",
        colorOutline: "#000cff",
        fill: "vertical",
        opacity: "99",
        stripped: 45
      }
    case "RIO_200_A_600":
      return {
        id: "RIO_200_A_600",
        popupTemplate: {
          "title": "Curso d’água natural de 200 a 600 metros"
        },
        label: "Curso d’água natural de 200 a 600 metros",
        color: "#000cff",
        colorOutline: "#000cff",
        fill: "vertical",
        opacity: "99",
        stripped: 45
      }
    case "RIO_ACIMA_600":
      return {
        id: "RIO_ACIMA_600",
        popupTemplate: {
          "title": "Curso d’água natural acima de 600 metros"
        },
        label: "Curso d’água natural acima de 600 metros",
        color: "#000cff",
        colorOutline: "#000cff",
        fill: "vertical",
        opacity: "99",
        stripped: 45
      }
    case "NASCENTE_OLHO_DAGUA":
      return {
        id: "NASCENTE_OLHO_DAGUA",
        popupTemplate: {
          "title": "Nascente ou olho d’água perene"
        },
        label: "Nascente ou olho d’água perene",
        color: "#00158b",
        colorOutline: "#00158b",
        fill: "vertical",
        opacity: "99",
        stripped: 45
      }
    case "LAGO_NATURAL":
      return {
        id: "LAGO_NATURAL",
        popupTemplate: {
          "title": "Lago ou lagoa natural"
        },
        label: "Lago ou lagoa natural",
        color: "#457eff",
        colorOutline: "#457eff",
        fill: "vertical",
        opacity: "99",
        stripped: 45
      }
    case "RESERVATORIO_ARTIFICIAL_DECORRENTE_BARRAMENTO":
      return {
        id: "RESERVATORIO_ARTIFICIAL_DECORRENTE_BARRAMENTO",
        popupTemplate: {
          "title": "Reservatório artificial decorrente de barramento ou represamento de cursos d’água naturais"
        },
        label: "Reservatório artificial decorrente de barramento ou represamento de cursos d’água naturais",
        color: "#41c1e6",
        colorOutline: "#41c1e6",
        fill: "vertical",
        opacity: "99",
        stripped: 45
      }
    case "VEREDA":
      return {
        id: "VEREDA",
        popupTemplate: {
          "title": "Vereda"
        },
        label: "Vereda",
        color: "#ffc192",
        colorOutline: "#ffc192",
        fill: "backward-diagonal",
        opacity: "99",
        stripped: 45
      }
    case "BANHADO":
      return {
        id: "BANHADO",
        popupTemplate: {
          "title": "Banhado"
        },
        label: "Banhado",
        color: "#ffc192",
        colorOutline: "#ffc192",
        fill: "backward-diagonal",
        opacity: "99",
        stripped: 45
      }
    case "AREA_TOPO_MORRO":
      return {
        id: "AREA_TOPO_MORRO",
        popupTemplate: {
          "title": "Área de topo de morro"
        },
        label: "Área de topo de morro",
        color: "#ffa55c",
        colorOutline: "#ffa55c",
        fill: "backward-diagonal",
        opacity: "99",
        stripped: 45
      }
    case "AREA_DECLIVIDADE_MAIOR_45":
      return {
        id: "AREA_DECLIVIDADE_MAIOR_45",
        popupTemplate: {
          "title": "Área de declividade maior que 45 graus."
        },
        label: "Área de declividade maior que 45 graus.",
        color: "#fd8627",
        colorOutline: "#fd8627",
        fill: "backward-diagonal",
        opacity: "99",
        stripped: 45
      }
    case "AREA_DECLIVIDADE_25_A_45":
      return {
        id: "AREA_DECLIVIDADE_25_A_45",
        popupTemplate: {
          "title": "Área de declividade de 25 a 45 graus"
        },
        label: "Área de declividade de 25 a 45 graus",
        color: "#fd8627",
        colorOutline: "#fd8627",
        fill: "backward-diagonal",
        opacity: "99",
        stripped: 45
      }
    case "AREA_DECLIVIDADE_15_A_25":
      return {
        id: "AREA_DECLIVIDADE_15_A_25",
        popupTemplate: {
          "title": "Área de declividade de 15 a 25 graus"
        },
        label: "Área de declividade de 15 a 25 graus",
        color: "#fd8627",
        colorOutline: "#fd8627",
        fill: "backward-diagonal",
        opacity: "99",
        stripped: 45
      }
    case "AREA_DECLIVIDADE_10_A_15":
      return {
        id: "AREA_DECLIVIDADE_10_A_15",
        popupTemplate: {
          "title": "Área de declividade de 10 a 15 graus"
        },
        label: "Área de declividade de 10 a 15 graus",
        color: "#fd8627",
        colorOutline: "#fd8627",
        fill: "backward-diagonal",
        opacity: "99",
        stripped: 45
      }
    case "AREA_DECLIVIDADE_5_A_10":
      return {
        id: "AREA_DECLIVIDADE_5_A_10",
        popupTemplate: {
          "title": "Área de declividade de 5 a 10 graus"
        },
        label: "Área de declividade de 5 a 10 graus",
        color: "#fd8627",
        colorOutline: "#fd8627",
        fill: "backward-diagonal",
        opacity: "99",
        stripped: 45
      }
    case "AREA_DECLIVIDADE_0_A_5":
      return {
        id: "AREA_DECLIVIDADE_0_A_5",
        popupTemplate: {
          "title": "Área de declividade de 0 a 5 graus"
        },
        label: "Área de declividade de 0 a 5 graus",
        color: "#fd8627",
        colorOutline: "#fd8627",
        fill: "backward-diagonal",
        opacity: "99",
        stripped: 45
      }
    case "BORDA_CHAPADA":
      return {
        id: "BORDA_CHAPADA",
        popupTemplate: {
          "title": "Borda de chapada"
        },
        label: "Borda de chapada",
        color: "#d35e00",
        colorOutline: "#d35e00",
        fill: "backward-diagonal",
        opacity: "99",
        stripped: 45
      }
    case "RESTINGA":
      return {
        id: "RESTINGA",
        popupTemplate: {
          "title": "Restinga"
        },
        label: "Restinga",
        color: "#b85200",
        colorOutline: "#b85200",
        fill: "backward-diagonal",
        opacity: "99",
        stripped: 45
      }
    case "MANGUEZAL":
      return {
        id: "MANGUEZAL",
        popupTemplate: {
          "title": "Manguezal"
        },
        label: "Manguezal",
        color: "#8e3f00",
        colorOutline: "#8e3f00",
        fill: "backward-diagonal",
        opacity: "99",
        stripped: 45
      }
    case "AREA_ALTITUDE_SUPERIOR_1800":
      return {
        id: "AREA_ALTITUDE_SUPERIOR_1800",
        popupTemplate: {
          "title": "Área com altitude superior a 1.800 metros"
        },
        label: "Área com altitude superior a 1.800 metros",
        color: "#853900",
        colorOutline: "#853900",
        fill: "backward-diagonal",
        opacity: "99",
        stripped: 45
      }
    case "AREA_USO_RESTRITO_PANTANEIRA":
      return {
        id: "AREA_USO_RESTRITO_PANTANEIRA",
        popupTemplate: {
          "title": "Área de Uso Restrito para regiões pantaneiras"
        },
        label: "Área de Uso Restrito para regiões pantaneiras",
        color: "#e36000",
        colorOutline: "#e36000",
        fill: "horizontal",
        opacity: "99",
        stripped: 45
      }
    case "AREA_USO_RESTRITO_DECLIVIDADE_25_A_45":
      return {
        id: "AREA_USO_RESTRITO_DECLIVIDADE_25_A_45",
        popupTemplate: {
          "title": "Área de Uso Restrito para declividade de 25 a 45 graus"
        },
        label: "Área de Uso Restrito para declividade de 25 a 45 graus",
        color: "#e36000",
        colorOutline: "#e36000",
        fill: "horizontal",
        opacity: "99",
        stripped: 45
      }
    case "AREA_POUSIO":
      return {
        id: "AREA_POUSIO",
        popupTemplate: {
          "title": "Área de Pousio"
        },
        label: "Área de Pousio",
        color: "#00ff53",
        colorOutline: "#00ff53",
        fill: "horizontal",
        opacity: "99",
        stripped: 45
      }
    case "VEGETACAO_NATIVA":
      return {
        id: "VEGETACAO_NATIVA",
        popupTemplate: {
          "title": "Remanescente de Vegetação Nativa (VN)"
        },
        label: "Remanescente de Vegetação Nativa (VN)",
        color: "#00ff54",
        colorOutline: "#00ff54",
        fill: "horizontal",
        opacity: "80",
        stripped: 0
      }
    case "ARL_PROPOSTA":
      return {
        id: "ARL_PROPOSTA",
        popupTemplate: {
          "title": "Reserva Legal (RL) Proposta"
        },
        label: "Reserva Legal (RL) Proposta",
        color: "#ff2020",
        colorOutline: "#ff2020",
        fill: "solid",
        opacity: 0.6,
      }
    case "ARL_AVERBADA":
      return {
        id: "ARL_AVERBADA",
        popupTemplate: {
          "title": "Reserva Legal (RL) Averbada"
        },
        label: "Reserva Legal (RL) Averbada",
        color: "#ff2020",
        colorOutline: "#ff2020",
        fill: "solid",
        opacity: 0.6,
      }
    case "ARL_AVERBADA_OUTRO_IMOVEL":
      return {
        id: "ARL_AVERBADA_OUTRO_IMOVEL",
        popupTemplate: {
          "title": "Reserva Legal (RL) Averbada em outro imóvel"
        },
        label: "Reserva Legal (RL) Averbada em outro imóvel",
        color: "#ff2020",
        colorOutline: "#ff2020",
        fill: "solid",
        opacity: 0.6,
      }
    case "ARL_APROVADA_NAO_AVERBADA":
      return {
        id: "ARL_APROVADA_NAO_AVERBADA",
        popupTemplate: {
          "title": "Reserva Legal (RL) Aprovada e não Averbada"
        },
        label: "Reserva Legal (RL) Aprovada e não Averbada",
        color: "#ff2020",
        colorOutline: "#ff2020",
        fill: "solid",
        opacity: 0.6,
      }
    case "AREA_IMOVEL":
      return {
        id: "AREA_IMOVEL",
        popupTemplate: {
          "title": "Área do Imóvel"
        },
        label: "Área do Imóvel",
        color: "#acacac",
        colorOutline: "#acacac",
        fill: "none",
        opacity: 0.6,
      }
    case "ÁREA ATUAL":
      return {
        id: "Área Atual",
        popupTemplate: {
          "title": "Área do Imóvel"
        },
        label: "Área do Imóvel",
        color: "#f6ff00",
        colorOutline: "#f6ff00",
        fillOpacity: 0,
        opacity: 0.01,
        outlineOpacity: 1,
      }
    case "ÁREA HISTÓRICA":
      return {
        id: "Área Histórica",
        popupTemplate: {
          "title": "Área do Imóvel"
        },
        label: "Área do Imóvel",
        color: "#0079ff",
        colorOutline: "#0079ff",
        fillOpacity: 0,
        opacity: 0.01,
        outlineOpacity: 1,
      }
  }
}