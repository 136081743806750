import { useState, useEffect } from "react";
import {
  createStyles,
  Navbar,
  UnstyledButton,
  Badge,
  Loader,
  Group,
  rem,
  Flex,
  Menu,
  getStylesRef,
  MultiSelect,
  Divider,
  Collapse,
  Box,
  useMantineColorScheme,
  ActionIcon, Center,
} from "@mantine/core";

import dayjs from "dayjs";

import {
  IconUser,
  IconUsersGroup,
  IconBrandProducthunt,
  IconBuilding,
  IconBook2,
  IconGavel,
  IconSpeakerphone,
  IconTimelineEvent,
  IconPoint,
  IconLayoutDashboard,
  IconFileInfo,
  IconLogout,
  IconCalendarPlus,
  IconHelpCircle,
  IconUserQuestion,
  IconStack2,
  IconTable,
  IconCoin,
  IconFilter,
  IconCategory,
  IconSun,
  IconChevronLeft,
  IconChevronRight,
  IconMoonStars,
  IconVocabulary,
  IconPlusMinus,
  IconCheckupList,
  IconListDetails, IconUserCheck, IconMapSearch, IconList, IconIdBadge2, IconLayout2, IconMapPins, IconUsers, IconViewfinder, IconReportAnalytics,
} from "@tabler/icons-react";

import { MonthPickerInput } from "@mantine/dates";

import { openChangePassword } from "./ChangePassword";

import { UserButton } from "./UserButton";

import { useTabs } from "./lib/Tabs";

import useSWR, { useSWRConfig } from "swr";
import { forwardRef } from 'react';
import { useUser, useSupabaseClient } from "@supabase/auth-helpers-react";

import { ScrollArea } from '@mantine/core';

const Item = forwardRef(({ label, value, ...others }, ref) => {
  return (
    <div ref={ref} {...others} style={{ ...others.style, marginTop: "4px", marginBottom: "4px" }}>
      <Flex align="center">
        <div>{label}</div>
      </Flex>
    </div>
  );
});

function Value({
                 value,
                 label,
                 onRemove,
                 classNames,
                 ...others
               }) {
  return (
    <div {...others}>
      <Badge size="sm" sx={{ textTransform: "none" }} >
        {label}
      </Badge>
    </div>
  );
}


const useStyles = createStyles((theme) => ({
  navbar: {
    paddingTop: 0,
    paddingBottom: 0,
    borderRight: `${rem(1)} solid ${theme.colorScheme === "dark"
      ? theme.colors.dark[4]
      : theme.colors.gray[3]
    }`
  },
  toggleBtn: {
    position: "absolute",
    right: "-8px",
    top: "24px",
  },
  section: {
    marginLeft: `calc(${theme.spacing.md} * -1)`,
    marginRight: `calc(${theme.spacing.md} * -1)`,
    marginBottom: `${rem(1)}`
  },

  searchCode: {
    fontWeight: 700,
    fontSize: rem(10),
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[7]
        : theme.colors.gray[0],
    border: `${rem(1)} solid ${theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[2]
    }`,
  },

  mainLinks: {
    paddingLeft: `calc(${theme.spacing.md} - ${theme.spacing.xs})`,
    paddingRight: `calc(${theme.spacing.md} - ${theme.spacing.xs})`,
    paddingBottom: theme.spacing.xs,
  },

  mainLink: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    fontSize: theme.fontSizes.xs,
    padding: `${rem(6)} ${theme.spacing.xs}`,
    fontWeight: 500,
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[7],

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
      color: theme.colorScheme === "dark" ? theme.white : theme.black,
    },
  },

  chevron: {
    transition: 'transform 200ms ease',
  },

  mainLinkInner: {
    display: "flex",
    alignItems: "center",
    flex: 1,
  },

  mainLinkIcon: {
    ref: getStylesRef("icon"),
    marginRight: 6,
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[2]
        : theme.colors.gray[6],
  },

  mainLinkBadge: {
    padding: 0,
    width: rem(20),
    height: rem(20),
    pointerEvents: "none",
  },

  collections: {
    paddingLeft: `calc(${theme.spacing.md} - ${rem(6)})`,
    paddingRight: `calc(${theme.spacing.md} - ${rem(6)})`,
    paddingBottom: theme.spacing.md,
  },

  collectionsHeader: {
    paddingLeft: `calc(${theme.spacing.md} + ${rem(2)})`,
    paddingRight: theme.spacing.md,
  },

  link: {
    fontWeight: 500,
    display: 'block',
    textDecoration: 'none',
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,
    paddingLeft: rem(31),
    marginLeft: rem(30),
    fontSize: theme.fontSizes.sm,
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
    borderLeft: `${rem(1)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
      color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    },
  },

  control: {
    fontWeight: 500,
    display: 'block',
    fontSize: theme.fontSizes.xs,
    padding: `${rem(6)} ${theme.spacing.xs}`,

    width: '100%',
    color: theme.colorScheme === "dark"
      ? theme.colors.dark[0]
      : theme.colors.gray[7],


    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
      color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    },
  },

  mainLinkActive: {
    "&, &:hover": {
      backgroundColor: theme.fn.variant({
        variant: "light",
        color: theme.primaryColor,
      }).background,
      color: theme.fn.variant({ variant: "light", color: theme.primaryColor })
        .color,
      [`& .${getStylesRef("icon")}`]: {
        color: theme.fn.variant({ variant: "light", color: theme.primaryColor })
          .color,
      },
    },
  },

  subLink: {
    borderLeft: `${rem(1)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,

    paddingLeft: rem(10),
    marginLeft: rem(20),
    width: `calc(100% - ${rem(15)} - 5px)`
  },
}));

const links = [
  {
    icon: IconLayoutDashboard,
    label: "Painel",
    id: "Home",
    component: "Home",
    filters: ["project"]
  },
  {
    icon: IconPoint,
    label: "Menu Socioambiental",
    id: "esg",
    checkActive(user) {
      return user &&
        (user?.user_metadata?.apps || ["controle"]).includes("esg")
        ;
    },
    links: [
      {
        icon: IconViewfinder,
        label: "Monitorados",
        id: "esg_registry",
        component: 'EsgRegistry',
        indent: true,
        filters: []
      },
      {
        icon: IconReportAnalytics,
        label: "Relatório Individual",
        id: "esg_report",
        component: "PdfPrinter",
        subcomponent: "EsgReport",
        indent: true,
        disableRefresh: true,
        filters: [],
        checkActive(user) {
          return user && user?.user_metadata?.is_admin;
        },
      },
      {
        icon: IconMapSearch,
        label: "Mapa",
        id: "map",
        component: "Map",
        indent: true,
        disableRefresh: true,
        filters: []
      }
    ]
  },
  {
    icon: IconPoint,
    label: "Eventos",
    id: "events_root",
    component: "CreateEvent",
    disableRefresh: true,
    disabled: true,
    checkActive(user) {
      return user && (user?.user_metadata?.apps || ["controle"]).includes("controle");
    },
    links: [
      {
        icon: IconTimelineEvent,
        label: "Linha do Tempo",
        id: "timeline",
        component: "Timeline",
        indent: true,
        filters: ["project", "related", "status", "start_date", "end_date"]
      },

      {
        icon: IconTable,
        label: "Tabela de Eventos",
        id: "events",
        component: "EventsTable",
        indent: true,
        filters: ["project", "related", "status", "start_date", "end_date"]
      },

      // {
      //   icon: IconCalendarEvent,
      //   label: "Calendário",
      //   id: "calendar",
      //   component: "Events",
      //   indent: true,
      //   filters:["project","related","status","start_date","end_date"]
      // },

      {
        icon: IconStack2,
        label: "Grupos de Eventos",
        id: "events_collection",
        component: "EventsCollection",
        indent: true,
        filters: []
      },
      {
        icon: IconCalendarPlus,
        label: "Novo Evento",
        id: "newEvent",
        indent: true,
        disableRefresh: true,
        component: "CreateEvent",
        filters: []
      },
    ]
  },
  {
    icon: IconPoint,
    label: "Recuperação Judicial",
    id: "judicial_recovery",
    component: "company",
    disabled: true,
    checkActive(user) {
      return user && (
        (user?.user_metadata?.apps || ["controle"]).includes("controle") ||
        (user?.user_metadata?.apps || ["controle"]).includes("aj")
      );
    },
    links: [
      {
        icon: IconGavel,
        label: "Processo",
        id: "process",
        component: "Process",
        indent: true,
        filters: []
      },
      {
        icon: IconBook2,
        label: "Plano",
        id: "plan",
        component: "Plan",
        indent: true,
        filters: []
      },
      {
        icon: IconSpeakerphone,
        label: "Assembleias",
        id: "assembly",
        component: "Assembly",
        indent: true,
        filters: []
      },
      {
        icon: IconBuilding,
        label: "Recuperandas",
        id: "company",
        component: "Company",
        indent: true,
        filters: []
      },
      {
        icon: IconCoin,
        label: "Credores",
        id: "creditor",
        component: "Creditor",
        indent: true,
        filters: []
      },
    ]
  },
  {
    icon: IconPoint,
    label: "Créditos",
    id: "credits_menu",
    component: "credits_menu",
    checkActive(user) {
      return user && (
        (user?.user_metadata?.apps || ["controle"]).includes("aj")
      );
    },
    disabled: true,
    links: [
      {
        icon: IconLayout2,
        label: "Sumário",
        id: "summary",
        checkActive(user) {
          return user && user.user_metadata.tester;
        },
        component: "Summary",
        indent: true,
        filters: ["project"],
        menu:[
          {
            icon: IconListDetails,
            label: "Item 1",
            id: "qgc",
            checkActive(user) {
              return user && user.user_metadata.tester;
            },
            component: "QGC",
            indent: true,
            filters: ["project"]
          },
          {
            icon: IconListDetails,
            label: "Item 2",
            id: "qgc",
            checkActive(user) {
              return user && user.user_metadata.tester;
            },
            component: "QGC",
            indent: true,
            filters: ["project"]
          }
        ]
      },
      {
        icon: IconListDetails,
        label: "QGC",
        id: "qgc",
        checkActive(user) {
          return user && user.user_metadata.tester;
        },
        component: "QGC",
        indent: true,
        filters: ["project"]
      },
      // {
      //   icon: IconIdBadge2,
      //   label: "Credores",
      //   id: "rj_creditors",
      //   checkActive(user) {
      //     return user && user.user_metadata.tester;
      //   },
      //   component: "QGC",
      //   indent: true,
      //   filters: ["project"]
      // },
      {
        icon: IconPlusMinus,
        label: "Habilitações/Divergências",
        id: "request_hd",
        checkActive(user) {
          return user && user.user_metadata.tester;
        },
        component: "RequestHD",
        indent: true,
        filters: ["project"]
      },
      {
        icon: IconCheckupList,
        label: "RVC",
        id: "rvc",
        checkActive(user) {
          return user && user.user_metadata.tester;
        },
        component: "RVC",
        indent: true,
        filters: ["project"]
      },
      {
        icon: IconVocabulary,
        label: "Impugnações",
        id: "request_i",
        checkActive(user) {
          return user && user.user_metadata.tester;
        },
        component: "RequestI",
        indent: true,
        filters: ["project"]
      },
    ]
  },

  {
    icon: IconBrandProducthunt,
    label: "Projetos",
    id: "project",
    component: "Projects",
    filters: []
  },

  {
    icon: IconFileInfo,
    label: "Fontes",
    id: "source",
    component: "Source",
    filters: ["project"],
    checkActive(user) {
      return user && (
        (user?.user_metadata?.apps || ["controle"]).includes("controle")
      );
    },
  },

  {
    icon: IconUsersGroup,
    label: "Grupos de Usuários",
    id: "groups",
    component: "Groups",
    filters: []
  },

  {
    icon: IconUser,
    label: "Usuários",
    id: "users",
    component: "Users",
    filters: []
  },

  {
    icon: IconHelpCircle,
    label: "Ajuda",
    id: "help",
    component: "Help",
    filters: []
  },

];

function Link({ addTab, classes, activeTab, cx, theme, link, sublink }) {
  const hasLinks = Array.isArray(link.links) && link.links.length > 0;

  const user = useUser();

  const [opened, setOpened] = useState(false);

  const ChevronIcon = theme.dir === 'ltr' ? IconChevronRight : IconChevronLeft;

  useEffect(() => {
    if (hasLinks && link.links.map(link => link.id).includes(activeTab)) {
      setOpened(true);
    }
  }, [activeTab]);

  const subLinks = hasLinks && link.links.filter(link => !link.checkActive || link.checkActive(user)).map((link) => (
    <Link
      id={link.id}
      key={link.label}
      classes={classes}
      icon={link.icon}
      link={link}
      links={link.links}
      label={link.label}
      menu={link.menu}
      cx={cx}
      theme={theme}
      sublink={true}
      activeTab={activeTab}
      addTab={addTab}
    />
  ));

  return (hasLinks ? <>
      <UnstyledButton
        onClick={() => setOpened((o) => !o)}
        className={cx(classes.control)}
      >
        <Group position="apart" spacing={0}>
          <Box sx={{ display: 'flex', alignItems: 'center' }} >
            <link.icon size={20} className={classes.mainLinkIcon} stroke={1.5} />
            {link.label}
          </Box>
          {hasLinks && (
            <ChevronIcon
              className={classes.chevron}
              size="1rem"
              stroke={1.5}
              style={{
                transform: opened ? `rotate(${theme.dir === 'rtl' ? -90 : 90}deg)` : 'none',
              }}
            />
          )}
        </Group>
      </UnstyledButton>
      {hasLinks ? <Collapse in={opened}>{subLinks}</Collapse> : null}
    </> :  <Menu
      trigger="hover"
      withArrow
      position="right"
      withinPortal
      width={200}
      shadow="md">
      <Menu.Target>
        <UnstyledButton
          key={link.label}
          onClick={() => {
            addTab(link);
          }}
          className={cx(classes.mainLink, {
            [classes.mainLinkActive]: link.id === activeTab,
            [classes.subLink]: sublink
          })}
        >
          <div className={classes.mainLinkInner}>
            <link.icon size={20} className={classes.mainLinkIcon} stroke={1.5} />
            <span>{link.label}</span>
          </div>
        </UnstyledButton>
      </Menu.Target>
      {link.menu && link.menu.length > 0 && <Menu.Dropdown>
        {link.menu?.map((m) => {
          return (
            <Menu.Item icon={<m.icon size={rem(14)} />} onClick={() => {
              addTab(m);
            }}>
              {m.label}
            </Menu.Item>)
        })}
      </Menu.Dropdown>}
    </Menu>
  )
}

export function NavbarSection({ alwaysCollapsed }) {
  const { toggleColorScheme } = useMantineColorScheme();
  const { classes, cx, theme } = useStyles();
  const { activeTab, addTab } = useTabs();

  const [collapsed, setCollapsed] = useState(!!alwaysCollapsed);

  const user = useUser();
  const supabase = useSupabaseClient();

  const apps = user?.user_metadata?.apps || ["controle"];

  const [activeProjects, setActiveProjects] = useState([]);
  const [updateRequired, setUpdateRequired] = useState(false);

  const [activeRelateds, setActiveRelateds] = useState([]);

  const [activeStatus, setActiveStatus] = useState([]);

  const [activeDate, setActiveDate] = useState([dayjs(new Date()).add(-3, "month").toDate(), dayjs(new Date()).add(6, "month").toDate()]);

  async function updateDateRange(value) {
    const { } = await supabase
      .from("users")
      .update({ active_date_range: [dayjs(value[0]).startOf("month").format("YYYY-MM-DD"), dayjs(value[1]).endOf("month").format("YYYY-MM-DD")] })
      .eq("id", user.id);

    await supabase.auth.refreshSession();

    try {
      mutateGlobal(
        () => true,
        undefined,
        { revalidate: true }
      )
    }
    catch (ex) {
      console.log(ex);
    }
  }

  const { mutate: mutateGlobal } = useSWRConfig();


  const { data: projects, mutate: mutateProject } = useSWR({
    tableName: "v_project_date_range",
    columns: "id, name, start_date, end_date",
  });

  const { data: relateds, mutate: mutateRelateds } = useSWR({
    tableName: "groups",
    columns: "id, name, type",
  });

  const { data: status, mutate: mutateStatus } = useSWR({
    tableName: "event_status",
    columns: "id, name",
  });

  const onLogoutClick = async () => {
    const { } = await supabase.auth.signOut();
    window.location.replace('https://cccfacilcontrole.com.br');
  };

  const onChangePasswordClick = () => {
    openChangePassword(true);
  };

  const onRefreshUserClick = () => {
    supabase.auth.refreshSession();
  };

  useEffect(() => {
    if (user?.user_metadata?.active_projects) {
      setActiveProjects(user?.user_metadata?.active_projects);
    }

    if (user?.user_metadata?.active_relateds) {
      setActiveRelateds(user?.user_metadata?.active_relateds);
    }

    if (user?.user_metadata?.active_status) {
      setActiveStatus(user?.user_metadata?.active_status);
    }

    if (user?.user_metadata?.active_date_range) {
      setActiveDate([new Date(`${user?.user_metadata?.active_date_range[0]}T00:00`), new Date(`${user?.user_metadata?.active_date_range[1]}T00:00`)]);
    }
  }, [user?.user_metadata?.active_projects, user?.user_metadata?.active_date_range, user?.user_metadata?.active_relateds, user?.user_metadata?.active_status]);

  const subLinks = links.filter(link => !link.checkActive || link.checkActive(user)).map((link) => (
    <Link
      key={link.label}
      classes={classes}
      link={link}
      cx={cx}
      theme={theme}
      activeTab={activeTab}
      addTab={addTab}
    />
  ));
  function tabFilter(filter) {
    return !([].concat.apply([], links.filter((l) => Array.isArray(l.links) && l.links.length > 0).map((l) => l.links)).find((l) => l.id === activeTab)?.filters?.includes(filter) ||
      links.find((l) => l.id === activeTab)?.filters?.includes(filter));
  }
  const minDate =
    projects && dayjs(projects.filter((p) => (activeProjects.includes(p.id) || activeProjects.length === 0) && p.start_date)
      .map((p) => p.start_date).sort()[0]).toDate();

  const maxDate = projects && dayjs(projects.filter((p) => (activeProjects.includes(p.id) || activeProjects.length === 0) && p.end_date)
    .map((p) => p.end_date).sort().reverse()[0]).toDate();


  return (
    <Navbar width={{ sm: collapsed ? 65 : 250 }} pt={0} pb={6} p="sm" className={classes.navbar}>
      <Navbar.Section className={classes.section}>
        {apps.includes("esg") && apps.length === 1 &&  !collapsed && <Center py={"sm"}><img src={"./ccc-amb.svg"} width="180"/></Center>}
        <Menu position="right-start" shadow="lg" withArrow withinPortal trigger="click">
          <Menu.Target>
            <UserButton
              name={user?.user_metadata?.name}
              email={user?.email}
              user={user}
              collapsed={collapsed}
            />
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item icon={<IconUserQuestion size="0.9rem" stroke={1.5}/>} onClick={onChangePasswordClick}>
              Alterar senha
            </Menu.Item>
            <Menu.Item icon={<IconUserCheck size="0.9rem" stroke={1.5}/>} onClick={onRefreshUserClick}>
              Atualizar usuário
            </Menu.Item>
            <Menu.Item
              icon={
                theme.colorScheme === "dark" ?
                  (<IconSun size="1.2rem" stroke={1.5}/>) :
                  (<IconMoonStars size="1.2rem" stroke={1.5}/>)}
              onClick={() => toggleColorScheme()}>
              Alterar para modo {theme.colorScheme === "dark" ? "Claro" : "Escuro"}
            </Menu.Item>
            <Menu.Item
              icon={<IconLogout size="0.9rem" stroke={1.5}/>}
              onClick={onLogoutClick}
            >
              Sair
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
        {!alwaysCollapsed && <ActionIcon onClick={() => {
          if (collapsed) {
            setCollapsed(false);
          } else {
            setCollapsed(true);
          }
        }} variant="filled" size="18px" className={classes.toggleBtn} color="blue">
          {!collapsed && <IconChevronLeft/>}
          {collapsed && <IconChevronRight/>}
        </ActionIcon>}
      </Navbar.Section>
      {!collapsed && <>
        <Divider
          labelPosition="center"
          label={
            <>
              <IconFilter color={theme.colorScheme === "dark" ? "#8dc3f2" : "#495057"} size={12} stroke={1.5} />
              <Box sx={{ color: theme.colorScheme === "dark" ? "#8dc3f2" : "#495057", fontWeight: 600 }} ml={5}>Filtros</Box>
            </>
          }></Divider>
        <Navbar.Section className={classes.section}  >
          <div className={classes.collectionsHeader}>
            <MultiSelect
              disableSelectedItemFiltering
              onDropdownOpen={mutateProject}
              value={tabFilter("project") ? [] : activeProjects}
              itemComponent={Item}
              disabled={tabFilter("project")}
              sx={{ textAlign: 'center' }}
              valueComponent={Value}
              rightSection={
                activeProjects == null || projects == null ? (<Loader size="sm" />) : (<></>)
              }
              data={
                (projects &&
                  projects.map((p) => ({ value: p.id, label: p.name }))) ||
                []
              }
              onChange={(e) => {
                if (e !== activeProjects)
                  setActiveProjects(e);
                setUpdateRequired(true);
              }}

              onDropdownClose={async () => {
                if (updateRequired) {
                  const { } = await supabase
                    .from("users")
                    .update({ active_projects: activeProjects })
                    .eq("id", user.id);

                  let start_date = dayjs(projects.filter((p) => (activeProjects.includes(p.id) || activeProjects.length === 0) && p.start_date)
                    .map((p) => p.start_date).sort()[0]).toDate();

                  let end_date = dayjs(projects.filter((p) => (activeProjects.includes(p.id) || activeProjects.length === 0) && p.end_date)
                    .map((p) => p.end_date).sort().reverse()[0]).toDate();
                  if ((activeDate[0] < start_date || activeDate[0] > end_date) && (activeDate[1] > end_date || activeDate[1] < start_date)) {
                    setActiveDate([start_date, end_date]);
                    await updateDateRange([start_date, end_date]);
                  } else if (activeDate[0] < start_date) {
                    setActiveDate([start_date, activeDate[1]]);
                    await updateDateRange([start_date, activeDate[1]]);
                  } else if (activeDate[1] > end_date) {
                    setActiveDate([activeDate[0], end_date]);
                    await updateDateRange([activeDate[0], end_date]);
                  } else {
                    await supabase.auth.refreshSession();
                  }

                  try {
                    mutateGlobal(
                      () => true,
                      undefined,
                      { revalidate: true }
                    )
                    setUpdateRequired(false);
                  } catch (ex) {
                    console.log(ex);
                  }
                }
              }}
              placeholder="Todos os Projetos"
              label="Projetos"
              variant="filled"
              size="xs"
            />
          </div>
          {apps.includes("controle") && <>
            <div className={classes.collectionsHeader}>
              <Group grow spacing={"sm"}>
                <MonthPickerInput
                  label="Data Inicial"
                  variant="filled"
                  disabled={tabFilter("start_date")}
                  valueFormat={"DD/MMM/YY"}
                  value={tabFilter("start_date") ? minDate : activeDate[0]}
                  styles={{ input: { textAlign: 'center', textTransform: "capitalize" } }}
                  sx={{ textAlign: 'center' }}
                  minDate={minDate}
                  maxDate={maxDate}
                  onChange={value => {
                    let firstDay = dayjs(value).startOf("month").format("YYYY-MM-DD")
                    if (firstDay !== dayjs(activeDate[0]).format("YYYY-MM-DD")) {
                      let dates = [value, activeDate[1]];
                      if (firstDay > dayjs(activeDate[1]).format("YYYY-MM-DD")) {
                        dates = [value, value];
                      }
                      setActiveDate(dates);
                      updateDateRange(dates);
                    }
                  }}
                  size="xs"
                />
                <MonthPickerInput
                  label="Data Final"
                  variant="filled"
                  disabled={tabFilter("end_date")}
                  value={tabFilter("end_date") ? maxDate : activeDate[1]}
                  valueFormat={"DD/MMM/YY"}
                  styles={{ input: { textAlign: 'center', textTransform: "capitalize" } }}
                  sx={{ textAlign: 'center' }}
                  minDate={minDate}
                  maxDate={maxDate}
                  onChange={value => {
                    let lastDay = dayjs(value).endOf("month").format("YYYY-MM-DD")

                    if (lastDay !== dayjs(activeDate[1]).format("YYYY-MM-DD")) {
                      let dates = [activeDate[0], dayjs(value).endOf("month")];

                      if (lastDay < dayjs(activeDate[0]).format("YYYY-MM-DD")) {
                        dates = [dayjs(value).endOf("month"), dayjs(value).endOf("month")];
                      }

                      setActiveDate(dates);
                      updateDateRange(dates);
                    }
                  }}
                  size="xs"
                />
              </Group>
            </div>
            <div className={classes.collectionsHeader}>
              <MultiSelect
                disableSelectedItemFiltering
                onDropdownOpen={mutateRelateds}
                value={tabFilter("related") ? [] : activeRelateds}
                disabled={tabFilter("related")}
                itemComponent={Item}
                sx={{ textAlign: 'center' }}
                valueComponent={Value}
                rightSection={
                  activeRelateds == null || relateds == null ? (<Loader size="sm" />) : (<></>)
                }
                data={
                  (relateds &&
                    relateds.map((p) => ({ value: p.id, label: p.name, group: p.type }))) ||
                  []
                }
                onChange={(e) => {
                  if (e !== activeRelateds) {
                    setUpdateRequired(true);
                    setActiveRelateds(e);
                  }
                }}
                onDropdownClose={async () => {
                  if (updateRequired) {
                    const { } = await supabase
                      .from("users")
                      .update({ active_relateds: activeRelateds })
                      .eq("id", user.id);

                    await supabase.auth.refreshSession();

                    try {
                      mutateGlobal(
                        () => true,
                        undefined,
                        { revalidate: true }
                      )
                      setUpdateRequired(false);
                    } catch (ex) {
                      console.log(ex);
                    }
                  }
                }}
                placeholder="Todos os Relacionados"
                label="Relacionados"
                variant="filled"
                size="xs"
              />
            </div>
            <div className={classes.collectionsHeader}>
              <MultiSelect
                disableSelectedItemFiltering
                onDropdownOpen={mutateStatus}
                value={tabFilter("status") ? [] : activeStatus}
                itemComponent={Item}
                disabled={tabFilter("status")}
                sx={{ textAlign: 'center' }}
                valueComponent={Value}
                rightSection={
                  activeStatus == null || status == null ? (<Loader size="sm" />) : (<></>)
                }
                data={
                  (status &&
                    status.map((p) => ({ value: p.id, label: p.name, group: p.type }))) ||
                  []
                }
                onChange={(e) => {
                  if (e !== activeStatus) {
                    setUpdateRequired(true);
                    setActiveStatus(e);
                  }
                }}

                onDropdownClose={async () => {
                  if (updateRequired) {
                    const { } = await supabase
                      .from("users")
                      .update({ active_status: activeStatus })
                      .eq("id", user.id);

                    await supabase.auth.refreshSession();

                    try {
                      mutateGlobal(
                        () => true,
                        undefined,
                        { revalidate: true }
                      )
                      setUpdateRequired(false);
                    } catch (ex) {
                      console.log(ex);
                    }
                  }
                }}
                placeholder="Todos os Status"
                label="Status"
                variant="filled"
                size="xs"
              />
            </div>
          </>}
        </Navbar.Section>
        <Divider
          labelPosition="center"
          label={
            <>
              <IconCategory color={theme.colorScheme === "dark" ? "#8dc3f2" : "#495057"} size={12} stroke={1.5} />
              <Box sx={{ color: theme.colorScheme === "dark" ? "#8dc3f2" : "#495057", fontWeight: 600 }} ml={5}>Menu</Box>
            </>
          }></Divider>
        <Navbar.Section className={classes.section} grow component={ScrollArea} >
          <div className={classes.collections}>{subLinks}</div>
        </Navbar.Section>
        <Divider
          labelPosition="center"
          pb={4}
          label={
            <Box sx={{ color: theme.colorScheme === "dark" ? "#8dc3f2" : "#495057", fontWeight: 400, fontSize: `${rem(10)}` }}>Desenvolvido por:</Box>
          }>
        </Divider>
        <Navbar.Section sx={{ textAlign: "center" }} >
          <img src={theme.colorScheme === "dark" ? "./ccc-white.svg" : "./ccc-color.svg"} width="150" />
        </Navbar.Section>
      </>}
    </Navbar >
  );
}

