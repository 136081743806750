import {
  createStyles,
  Text,
  Grid,
  rem,
  Card,
  Space,
  Group,
  Table,
  Badge,
  TypographyStylesProvider, Divider, TextInput,
} from "@mantine/core";

import useSWR from "swr";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { IconAlertTriangle } from "@tabler/icons-react";
import dayjs from 'dayjs';

const useStyles = createStyles((theme) => ({
  progressLabel: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    lineHeight: 1,
    fontSize: theme.fontSizes.sm,
  },

  stat: {
    borderBottom: `${rem(3)} solid`,
    paddingBottom: rem(5),
  },

  statCount: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    lineHeight: 1.3,
  },

  wrapper: {
    padding: `calc(${theme.spacing.xl} * 2) ${theme.spacing.xl}`,
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontSize: rem(36),
    fontWeight: 900,
    lineHeight: 1.1,
    marginBottom: theme.spacing.md,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
  },
}));

export default function Pdf() {
  const { classes } = useStyles();
  const [searchParams] = useSearchParams();
  const ids = searchParams.get("id");

  const { data: analyseData, error, isLoading } = useSWR(ids && {
    tableName: 'v_rvc',
    filters: [["id", "in", `(${ids})`]],
    orders: [["id", { ascending: true }]],
  });

  if (error)
    return JSON.stringify(error);

  if (!analyseData)
    return undefined;

  function docMask(doc) {
    return doc.length === 11 ?
      doc.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4') :
      doc.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
  }

  function formatNumber(value, currency, plusSignal) {
    return parseFloat(value).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      signDisplay: plusSignal ? "exceptZero" : "auto"
    })
  }

  return <>
    <table className="pdf">
      <thead>
        <tr>
          <td>
            <div className="header-space">&nbsp;</div>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <div className="content">
              <Grid>

                {analyseData?.map((a) =>
                  <Card withBorder m={"xs"}>

                    <Group spacing={40} sx={{ backgroundColor: a.analysed ? "#ced4da" : "rgb(255,222,0)" }} position="center" mb={10}>
                      <Group ml={-20} spacing={4}>
                        <Text mt={8} size="xs">Credor:</Text>
                        <Text my={6} size="xl" fw={700}>{a.rj_creditor_name}</Text>
                      </Group>
                      <Group ml={-20} spacing={4}>
                        <Text mt={8} size="xs">CPF/CNPJ:</Text>
                        <Text my={6} size="xl" fw={700}>{docMask(a.rj_creditor_doc)}</Text>
                      </Group>
                      {!a.analysed && <IconAlertTriangle color={"red"} />}
                      {!a.analysed && <Text fw={700} color={"red"}>Análise Pendente</Text>}
                    </Group>

                    <Divider mt={20} size="lg" label={<Text mb={10} align="center" size="lg" fw={700}>Resumo da Análise Realizada</Text>}></Divider>
                    <Table mb={10} >
                      <thead>
                      <tr style={{ backgroundColor: "#e2eee3" }}>
                        <th>Recuperanda</th>
                        <th style={{ width: 110 }}>Classe</th>
                        <th style={{ width: 70 }}>Moeda</th>
                        <th>Valor Inicial</th>
                        <th>Variação</th>
                        <th>Valor pós Análise</th>
                      </tr>
                      </thead>
                      <tbody>
                      {a.credit_summary?.map((e) => (
                        <tr key={e.company_name + e.class_name + e.currency}>
                          <td>{e.company_name}</td>
                          <td>{e.class_name}</td>
                          <td>{e.currency}</td>
                          <td>{formatNumber(e.initial_amount)}</td>
                          <td style={{
                            color:
                              parseFloat(e.var_amount) > 0 ?
                                'green' : parseFloat(e.var_amount) !== 0 ?
                                  'red' : ''
                          }}>{formatNumber(parseFloat(e.var_amount), 'BRL', true)}</td>
                          <td>{formatNumber(e.result_amount)}</td>
                        </tr>))}
                      </tbody>
                    </Table>

                    {a.request && <>
                      <Divider mt={40} size="lg"
                               label={<Text mb={10} align="center" size="lg" fw={700}>Solicitação do Credor - {a.request.type} - Em {dayjs(a.request.request_at).format("DD/MM/YY")}</Text>}></Divider>
                      <Table mb={10}>
                        <thead>
                        <tr style={{ backgroundColor: "#fff4db" }}>
                          <th>Recuperanda</th>
                          <th style={{ width: 110 }}>Classe</th>
                          <th style={{ width: 70 }}>Moeda</th>
                          <th style={{ width: 130 }}>Valor Solicitado</th>
                          <th>Origem</th>
                          <th>Anexos</th>
                        </tr>
                        </thead>
                        <tbody>
                        {a.request?.request_info_full?.map((r) => (
                          <tr key={r.rj_company_name + r.rj_credit_class_name + r.currency}>
                            <td>{r.rj_company_name}</td>
                            <td>{r.rj_credit_class_name}</td>
                            <td>{r.creditCurrency}</td>
                            <td>{formatNumber(r.creditAmount)}</td>
                            <td>{r.source}</td>
                            <td>{r.attachment?.map((at) => <Badge mr={5} radius="md" size="xs">{at.match(/\/([^\/]+)$/)[1]}</Badge>)} </td>
                          </tr>))}
                        </tbody>
                      </Table>
                    </>}

                    <Divider mt={40} size="lg" label={<Text mb={10} align="center" size="lg" fw={700}>Detalhamento da Análise</Text>}></Divider>

                    {a.credit_analyse?.map((ca, idx) =>
                      <Card withBorder m={"xs"}>
                        <Group sx={{ backgroundColor: "#e2f3ff" }} mb={10} position="center" >
                          <Text align="center" size="md" fw={700}>Análise {idx + 1}</Text>
                        </Group>
                        <Grid justify={"center"}>
                          <Grid.Col span="auto">
                            <Text align="center" size="sm" fw={700}>Inicial</Text>
                            <Grid gutter="xs">
                              <Grid.Col span={6}>
                                <TextInput size={"xs"} readOnly label="Recuperanda" defaultValue={ca.initial.company_name} />
                              </Grid.Col>
                              <Grid.Col span={6}>
                                <TextInput size={"xs"} readOnly label="Credor" defaultValue={ca.initial.creditor_name} />
                              </Grid.Col>
                              <Grid.Col span={ca.request?.company_id ? 6 : 4}>
                                <TextInput size={"xs"} readOnly label="Classe" defaultValue={ca.initial.class_name} />
                              </Grid.Col>
                              <Grid.Col span={ca.request?.company_id ? 6 : 4}>
                                <TextInput size={"xs"} readOnly label="Moeda" defaultValue={ca.initial.currency} />
                              </Grid.Col>
                              <Grid.Col span={ca.request?.company_id ? 12 : 4}>
                                <TextInput size={"xs"} readOnly label="Valor" defaultValue={formatNumber(ca.initial.amount)} />
                              </Grid.Col>
                              <Grid.Col span={12}>
                                <TextInput size={"xs"} readOnly label="Descrição Origem" defaultValue={ca.initial.source} />
                              </Grid.Col>
                            </Grid>
                          </Grid.Col>

                          {ca.request?.company_id && <Grid.Col span={4}>
                            <Text align="center" size="sm" fw={700}>Solicitado Credor</Text>
                            <Grid gutter="xs">
                              <Grid.Col span={6}>
                                <TextInput size={"xs"} readOnly label="Recuperanda"
                                           defaultValue={ca.request.company_name} />
                              </Grid.Col>
                              <Grid.Col span={6}>
                                <TextInput size={"xs"} readOnly label="Credor"
                                           defaultValue={ca.request.creditor_name} />
                              </Grid.Col>
                              <Grid.Col span={6}>
                                <TextInput size={"xs"} readOnly label="Classe" defaultValue={ca.request.class_name} />
                              </Grid.Col>
                              <Grid.Col span={6}>
                                <TextInput size={"xs"} readOnly label="Moeda" defaultValue={ca.request.currency} />
                              </Grid.Col>
                              <Grid.Col span={12}>
                                <TextInput size={"xs"} readOnly label="Valor"
                                           defaultValue={formatNumber(ca.request.amount)} />
                              </Grid.Col>
                              <Grid.Col span={12}>
                                <TextInput size={"xs"} readOnly label="Descrição Origem"
                                           defaultValue={ca.request.source} />
                              </Grid.Col>
                            </Grid>

                          </Grid.Col>}

                          <Grid.Col span="auto">
                            <Text align="center" size="sm" fw={700}>Resultado pós Análise</Text>
                            <Grid gutter="xs">
                              <Grid.Col span={6}>
                                <TextInput size={"xs"} readOnly label="Recuperanda" defaultValue={ca.result.company_name} />
                              </Grid.Col>
                              <Grid.Col span={6}>
                                <TextInput size={"xs"} readOnly label="Credor" defaultValue={ca.result.creditor_name} />
                              </Grid.Col>
                              <Grid.Col span={ca.request?.company_id ? 6 : 4}>
                                <TextInput size={"xs"} readOnly label="Classe" defaultValue={ca.result.class_name} />
                              </Grid.Col>
                              <Grid.Col span={ca.request?.company_id ? 6 : 4}>
                                <TextInput size={"xs"} readOnly label="Moeda" defaultValue={ca.result.currency} />
                              </Grid.Col>
                              <Grid.Col span={ca.request?.company_id ? 12 : 4}>
                                <TextInput size={"xs"} readOnly label="Valor" defaultValue={formatNumber(ca.result.amount)} />
                              </Grid.Col>
                              <Grid.Col span={12}>
                                <TextInput size={"xs"} readOnly label="Descrição Origem" defaultValue={ca.result.source} />
                              </Grid.Col>
                            </Grid>

                          </Grid.Col>
                        </Grid>

                        <Divider mt={20} size="md" label={<Text mb={10} align="center" size="md" fw={700}>Alterações</Text>}></Divider>
                        <Group position="center">
                          <Text fw={500}>{ca.conclusion}</Text>
                        </Group>

                        {ca.contradictory && <>
                          <Divider mt={20} size="md" label={<Text mb={10} align="center" size="md" fw={700}>Contraditório
                            Recuperanda</Text>}></Divider>
                          <TypographyStylesProvider>
                            <div dangerouslySetInnerHTML={{ __html: `${ca.contradictory}` }} />
                          </TypographyStylesProvider>
                        </>}

                        <Divider mt={20} size="md" label={<Text mb={10} align="center" size="md" fw={700}>Análise Administrador Judicial</Text>}></Divider>
                        <TypographyStylesProvider>
                          <div dangerouslySetInnerHTML={{ __html: `${ca.report}` }} />
                        </TypographyStylesProvider>
                      </Card>)}
                  </Card>)}

              </Grid >
            </div>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td>
            <div className="footer-space">&nbsp;</div>
          </td>
        </tr>
      </tfoot>
    </table>
    <div className="header">
      <Grid>
        <Grid.Col span={6}>
          <Text fw={700}>Relatório de Verificação de Créditos</Text>
          <Space h="sm" />
          <Text fw={700}>Processo: 5025460-77.2021.8.21.0010</Text>
        </Grid.Col>
        <Grid.Col span={3} />
        <Grid.Col span={3}>
          <Card withBorder shadow="sm" radius="md" >
            <Text align="center">LOGOMARCA</Text>
          </Card>
        </Grid.Col>
      </Grid>
      <div className="separator" />
    </div>
    <div className="footer">
      <div className="separator" />
    </div>

  </>

}
