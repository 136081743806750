
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { MapContainer, Rectangle, TileLayer, useMap } from 'react-leaflet';
import L from 'leaflet';
// import '../plugin/L.TileLayer.NoGap';
import '../plugin/leaflet.mask';
import '../plugin/leaflet.tileWithHeader';
import '../plugin/leaflet.measure';
import '../plugin/leaflet.measure.css';
import '../plugin/leaflet.edgebuffer'
import '../plugin/Control.MiniMap.min.css'
import MiniMap from '../plugin/Control.MiniMap.min.js'
import 'leaflet-boundary-canvas';
import { vectorBasemapLayer} from "esri-leaflet-vector";

import 'leaflet.pattern';
import 'leaflet.fullscreen';
import 'leaflet.fullscreen/Control.FullScreen.css';
import * as PruneCluster from '@workingfamilies/prune-cluster'
import '@workingfamilies/prune-cluster/LeafletStyleSheet.css'
import { useSessionContext, useSupabaseClient, useUser } from "@supabase/auth-helpers-react";
import useSWR from "swr";
import {
  Accordion,
  ActionIcon,
  Autocomplete,
  Badge,
  Box,
  Card, Center,
  Grid,
  Group,
  Loader,
  Menu,
  rem,
  ScrollArea, Skeleton,
  Slider,
  Text,
  Tooltip
} from "@mantine/core";
import * as turf from '@turf/turf'
import { useTabs } from "../lib/Tabs";
import { ExtraLayers } from "../plugin/ExtraLayers";
import { PointInfo } from "../plugin/PointInfo";
import { GlobalSearch } from "../plugin/GlobalSearch";
import {
  IconChevronLeft,
  IconChevronRight, IconDimensions, IconDotsVertical,
  IconHistory, IconLayersIntersect, IconMapPinQuestion,
  IconMapSearch, IconReportAnalytics, IconRulerMeasure,
  IconSearch, IconTextPlus, IconTrash, IconX, IconZoomScan
} from "@tabler/icons-react";
import Control from "react-leaflet-custom-control";
import { useDebouncedValue } from "@mantine/hooks";
import { layerProperties } from "../plugin/layerProperties";


function fadeOutLayerLeaflet(lyr, startOpacity, finalOpacity, opacityStep, delay) {
  let opacity = startOpacity;
  return new Promise((resolve) => {
    let timer = setTimeout(function changeOpacity() {
      if (opacity > finalOpacity) {
        lyr.setOpacity(opacity);
        opacity = opacity - opacityStep;
        timer = setTimeout(changeOpacity, delay);
      } else {
        clearTimeout(timer);
        resolve();
      }
    }, delay);
  });
}

function setColor(sobrep) {
  let color = '#000000';
  if (sobrep?.embargo) {
    color = '#ff2929';
  } else if (sobrep?.desmatamento) {
    color = '#ff9f32';
  } else if (sobrep?.car) {
    color = '#9b89ff';
  } else {
    color = '#8bff00';
  }
  return color;
}

function getKms(zoom) {
  const minZoom = 5;
  const maxZoom = 17;
  const minValue = 30;
  const maxValue = 0.05;

  // Normaliza o zoom para um valor entre 0 e 1
  const normalizedZoom = (zoom - minZoom) / (maxZoom - minZoom);

  // Calcula o valor não linear usando a função exponencial inversa
  const nonLinearValue = minValue * Math.pow(maxValue / minValue, normalizedZoom);

  return nonLinearValue;
}

function ControlButton({ children, active, enabled, label, onClick, Icon, position }) {
  return (
    <Control appended position={'topleft'}>
      {enabled && <Tooltip disabled={!label || active} label={label} color={"#676767"} position={"right"}>
        <ActionIcon
          variant="filled"
          className={"leaflet-bar"}
          size={34}
          color={active ? "primary" : "gray.0"}
          sx={active ? {} : { backgroundColor: '#fff !important' }}
          onClick={onClick}>
          <Icon size={24}
            color={active ? "#fff" : "#303030"} strokeWidth={2}
          />
        </ActionIcon>
      </Tooltip>}
      {children}
    </Control>
  )
}

const useIsTabActive = () => {
  const [isTabVisible, setIsTabVisible] = useState(true);

  const handleVisibilityChange = useCallback(() => {
    setIsTabVisible(document.visibilityState === 'visible');
  }, []);

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return isTabVisible;
};

const MapLeaflet = React.memo(({ query, history, imageDate, highlight, layers, extent, minimap, measure, extraLayers, fullScreen, search, cities, uf, tabId, colorPallet, baseMap, hidded, hideButtons, hideCluster }) => {
  const { activeTab, addTab } = useTabs();
  const { session } = useSessionContext();
  const [map, setMap] = useState(null)
  const [mosaics, setMosaics] = useState([]);
  const [codType, setCodType] = useState(null);
  const [cod, setCod] = useState(null);
  const [mosaicsIndex, setMosaicsIndex] = useState(0);
  const [mosaicsMin, setMosaicsMin] = useState(0);
  const [pointInfoQuery, setPointInfoQuery] = useState([]);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [latLongClick, setLatLongClick] = useState(null);
  const [pagination, setPagination] = useState(8);
  const [showSlider, setShowSlider] = useState(false);
  const [pointSearch, setPointSearch] = useState(false);
  const [citySearch, setCitySearch] = useState(false);
  const [globalSearch, setGlobalSearch] = useState(false);
  const [historyMap, setHistoryMap] = useState(false);
  const [radius, setRadius] = useState(0); // Initial radius value
  const radiusRef = useRef(0); // Initial radius value
  const minimapRef = useRef(null); // Initial radius value
  const codRef = useRef(null);
  const [searchData, setSearchData] = useState(null);
  const actionsRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const markersRef = useRef([]);
  const mosaicRef = useRef(null);
  const toolsRef = useRef(null);
  const searchDataRef = useRef(null);
  const vectorBasemapLayerRef = useRef(null);
  const isBrowserTabActive = useIsTabActive();

  const supabaseClient = useSupabaseClient();
  const layerRef = useRef({});
  const user = useUser();
  const bounds = useMemo(() => (
    extent ? [[extent.ymax || 5.271, extent.xmax || -73.987], [extent.ymin || -33.751, extent.xmin || -34.729]] : [[5.271, -73.987], [-33.751, -34.729]]
  ), [extent?.xmin, extent?.ymax, extent?.xmax, extent?.ymin]);

  const { data: mosaicsData } = useSWR(history && {
    tableName: "planet_mosaics",
    orders: [["data_base", { ascending: true }]]
  });

  function nearestDate(date) {
    let closestIndex = 0;
    let minDiff = Infinity;
    for (let i = 0; i < mosaics.length; i++) {
      const diff = Math.abs(new Date(mosaics[i].data_base) - new Date(date));
      if (diff < minDiff) {
        closestIndex = i;
        minDiff = diff;
      }
    }
    let slicedMosaics = mosaics.slice(closestIndex, closestIndex + 1);
    return slicedMosaics[0]
  }

  function CitiesAutocomplete() {
    const supabase = useSupabaseClient();
    const [query, setQuery] = useState("");
    const [cities, setCities] = useState([]);
    const [debounced] = useDebouncedValue(query, 250);

    useEffect(() => {
      if (debounced && debounced.length > 2) {
        const fetchData = async () => {
          try {
            const { data, error } = await supabase
              .from('v_municipios')
              .select('nm_mun,sigla_uf')
              .filter('nm_mun', 'ilike', '%' + debounced + '%')
              .limit(12)
            if (data.length > 0) {
              setCities(data.map((city) => {
                return { value: city.nm_mun + " - " + city.sigla_uf, label: city.nm_mun + " - " + city.sigla_uf }
              }))
            } else {
              setCities([])
            }
          } catch (error) {
            console.error(error);
          }
        }
        fetchData();
      }
    }, [debounced]);

    const handleSelect = (selectedOption) => {
      //get the selected option, split the value and make a new requeste to get the city bounds(geom column)
      let parts = selectedOption.value.split(" - ");
      let city = parts[0];
      let uf = parts[1];
      const fetchData = async () => {
        try {
          const { data, error } = await supabase
            .from('v_municipios')
            .select('geom')
            .eq('nm_mun', city)
            .eq('sigla_uf', uf)
            .limit(1)
          if (data.length > 0) {
            //create a layer with the city bounds
            if (layerRef.current?.citySearch && map.hasLayer(layerRef.current?.citySearch)) {
              map.removeLayer(layerRef.current?.citySearch);
              delete layerRef.current?.citySearch;
            }
            layerRef.current.citySearch = L.geoJSON(data[0].geom, {
              weight: 4,
              interactive: false,
              fillColor: '#ffffff',
              color: '#fff',
              dashArray: '',
              fillOpacity: 0.0
            }).addTo(map).bindTooltip(selectedOption.value, { permanent: true, direction: "center" });
            map.fitBounds(layerRef.current?.citySearch.getBounds(), { maxZoom: 14 });
            setCitySearch(false)
          }
        } catch (error) {
          console.error(error);
        }
      }
      fetchData();
    };

    return (
      citySearch &&
      <Card w={280} shadow={"sm"} p={4} m={2} style={{ position: "absolute", left: "40px", bottom: "50%", transform: "translateY(50%)", zIndex: 999, backgroundColor: "rgba(227,227,227,0.85)" }}>
        <Autocomplete
          withinPortal
          zIndex={800}
          data={cities}
          placeholder="Digite o nome da Cidade"
          onChange={setQuery}
          onItemSubmit={handleSelect}
        />
      </Card>
    );
  }

  useEffect(() => {
    if (mosaicsData?.length >= 0 && mosaics.length === 0) {
      setMosaics(mosaicsData);
      setMosaicsMin(mosaicsData.length - pagination);
      setMosaicsIndex(mosaicsData.length);
    }
  }, [mosaicsData]);

  function MaskLayer({ layer }) {
    if (layer && !layerRef.current?.mask && map) {
      let options = {
        stroke: true,
        color: '#000000',
        fillColor: '#000000',
        clickable: false,
        fillOpacity: 0.7,
        fitBounds: false,
        restrictBounds: false
      }
      layerRef.current.mask = L.mask(layer, options).addTo(map);
    };
    return null;
  };

  function HistoricalImage({ imageDate }) {
    if (map && imageDate && mosaicRef?.current?.imageDate !== imageDate) {
      let newMap = L.tileLayer(
        supabaseClient.supabaseUrl + "/mapa/v1/planet_medres_normalized_analytic_" + imageDate + "_mosaic/gmap/{z}/{x}/{y}.png",
        {
          format: 'image/png',
          lockLayer: true,
          transparent: true,
        }, [
        { header: 'Authorization', value: `Bearer ${session.access_token}` },
        {
          header: 'apikey',
          value: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiYW5vbiIsImlzcyI6InN1cGFiYXNlLWRlbW8iLCJpYXQiOjE2NDE3NjkyMDAsImV4cCI6MTc5OTUzNTYwMH0.VzlJ1lOmLMKsHs5Nq61ZNRjbsPh3RLgDFXeGjSLPlHY"
        },
      ],
        null
      )
      if (showSlider) {
        newMap.on('loading', function (event) {
          setLoading(true)
        });

        newMap.on('load', function (event) {
          setLoading(false)
        });
      }

      newMap.addTo(map).bringToFront();

      if (showSlider) {
        if (mosaicRef.old && map.hasLayer(mosaicRef.old)) map.removeLayer(mosaicRef.old);
        mosaicRef.old = mosaicRef.current;
        mosaicRef.current = newMap
        mosaicRef.current.imageDate = imageDate
      }

    }
  }

  function CustomGeoJSON({ id, data, style, fitBounds }) {
    if (map && data && data.features && data.features.length > 0) {
      let source = (id || "customGeoJSON")
      if (source) {
        if (layerRef?.current[source] && map.hasLayer(layerRef?.current[source])) {
          map.removeLayer(layerRef.current[source]);
          delete layerRef.current[source];
        }

        let styleOptions = {
          color: style?.color,
          weight: 3,
          fillColor: style?.color,
          fillOpacity: style?.opacity || 0.3,
        }
        function highlightFeature(e) {
          var layer = e.target;
          layer.setStyle({
            weight: 6,
            dashArray: '',
            fillOpacity: 1
          });
        }

        function resetHighlight(e) {
          const { properties } = e.target.feature;
          const doc = properties.doc;
          const source = properties.source ?? e.target.feature.id.split(".")[0] ?? "default";
          const layerStyle = layerProperties(source);
          const sobrep = properties?.properties && properties.properties?.sobrep;
          const color = sobrep ? setColor(sobrep) : layerStyle?.color;
          const index = query?.indexOf("'"+doc+"'");

          e.target.setStyle({
            color: (colorPallet && colorPallet[index]) || style?.color || color,
            weight: 3,
            fillColor: (colorPallet && colorPallet[index]) || style?.color || color,
            fillOpacity:  (colorPallet && colorPallet[index]) ? 0.8 : (layerStyle?.opacity || 0.3)
          });
        }

        function onEachFeature(feature, layer) {
          let { properties } = feature;
          if (properties.source || feature?.id?.toString().includes(".")) {
            const source = properties.source ?? feature?.id?.split(".")[0] ?? "default";
            const layerStyle = layerProperties(source);
            if (typeof properties?.properties === "string") {
              properties.properties = JSON.parse(properties?.properties);
            }
            const sobrep = properties?.properties && properties.properties?.sobrep;
            const color = sobrep ? setColor(sobrep) : layerStyle?.color;
            const index = query?.indexOf("'"+properties?.doc+"'");
            layer.options = { ...layer.options,
              style: {
                color: (colorPallet && colorPallet[index]) || layerStyle?.colorOutline || style?.color || color,
                opacity: (colorPallet && colorPallet[index]) ? 0.8 : (layerStyle?.outlineOpacity || layerStyle?.opacity || 0.3),
                fillOpacity: (colorPallet && colorPallet[index]) ? 0.8 :( layerStyle?.color ? ((layerStyle?.opacity || 0.3)) : 0.0),
                fillColor: (colorPallet && colorPallet[index]) || style?.color || color,
                weight: 3
            }
            };
            layer.setStyle(layer.options.style);
            layer.on({
              mouseover: highlightFeature,
              mouseout: resetHighlight
            });
          }
        }

        if (layerRef.current && !layerRef.current[source]) {
          if (style?.stripped !== null && style?.stripped !== undefined) {
            let stripes = new L.StripePattern({ color: style?.color , opacity: 0.6, weight: 1, angle: style?.stripped });
            stripes.addTo(map);
            styleOptions.fillPattern = stripes;
          }

          map.createPane('geojsonPane');

          //iteract over all features and check if the feature colection is a point, if true, create a new geojson layer with a circle with 500m radius, preserve the original properties, and set tthe new geojson to data
          if (data.features[0].geometry.type === "Point") {
            let newFeatures = data.features.map((feature) => {
              if (feature.geometry.type === "Point") {
                let center = feature.geometry.coordinates;
                let kms = getKms(map.getZoom());
                let circle = turf.circle(center, kms / 2, { steps: 64, units: 'kilometers' });
                let newFeature = { ...feature, geometry: circle.geometry, properties: feature.properties }
                return newFeature
              }
              return feature
            });
            data.features = newFeatures;
          }

          layerRef.current[source] = L.geoJSON({ ...data, source: source },
            {
              name: source,
              pane: 'geojsonPane',
              style: styleOptions,
              onEachFeature: onEachFeature
            });

          layerRef.current[source].addTo(map);
          let layerBounds = layerRef.current[source].getBounds();
          if (fitBounds) map.fitBounds(layerBounds, { maxZoom: 16, padding: [10,10], animate: false, duration: 0 });

        }
      }
    }
    return null;
  }

  function customMarkers({ data }) {

    if (layerRef?.current?.customMarkers && map.hasLayer(layerRef?.current?.customMarkers)) {
      map.removeLayer(layerRef.current.customMarkers);
      delete layerRef.current.customMarkers;
      markersRef.current = [];
    }

    function createIcon(category) {
      let color = ["red", "orange", "violet", "green"]
      return L.icon({
        iconUrl: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-" + color[category] + ".png",
        shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
        iconSize: [25, 41],
        interactive: false,
        iconAnchor: [12, 36],
        popupAnchor: [1, -34],
        shadowSize: [41, 41]
      });
    }

    var leafletView = new PruneCluster.ForLeaflet();

    leafletView.BuildLeafletClusterIcon = function (cluster) {
      var e = new L.Icon.MarkerCluster();
      e.stats = cluster.stats;
      e.population = cluster.population;
      return e;
    };

    var colors = ['#EC1813', '#ff8959', '#886cff', '#66ff00'],
      pi2 = Math.PI * 2;

    L.Icon.MarkerCluster = L.Icon.extend({
      options: {
        iconSize: new L.Point(60, 60),
        className: 'prunecluster leaflet-markercluster-icon'
      },

      createIcon: function () {
        var e = document.createElement('canvas');
        this._setIconStyles(e, 'icon');
        var s = this.options.iconSize;
        e.width = s.x;
        e.height = s.y;
        this.draw(e.getContext('2d'), s.x, s.y);
        return e;
      },

      createShadow: function () {
        return null;
      },

      draw: function (canvas, width, height) {

        let diameter = 26
        var lol = 0;

        var start = 0;
        for (var i = 0, l = colors.length; i < l; ++i) {

          var size = this.stats[i] / this.population;

          if (size > 0) {
            canvas.beginPath();
            canvas.moveTo(diameter, diameter);
            canvas.fillStyle = colors[i];
            var from = start,
              to = start + size * pi2;

            if (to < from) {
              from = start;
            }
            canvas.arc(diameter, diameter, diameter, from, to);

            start = start + size * pi2;
            canvas.lineTo(diameter, diameter);
            canvas.fill();
            canvas.closePath();
          }

        }

        canvas.beginPath();
        canvas.fillStyle = 'white';
        canvas.arc(diameter, diameter, diameter - 8, 0, Math.PI * 2);
        canvas.fill();
        canvas.closePath();

        canvas.fillStyle = '#555';
        canvas.textAlign = 'center';
        canvas.textBaseline = 'middle';
        canvas.font = 'bold 14px sans-serif';

        canvas.fillText(this.population, diameter, diameter, diameter + 10);
      }
    });

    for (var i = 0; i < data.features.length; ++i) {
      let center = L.geoJSON(data.features[i]).getBounds().getCenter();
      let category
      var marker = new PruneCluster.Marker(center.lat, center.lng);
      // if (data.features[i].geometry.type === "Point") {
      //check if the data.features[i].properties.properties needs to be parsed
      if (typeof data.features[i].properties.properties === "string") {
        data.features[i].properties.properties = JSON.parse(data.features[i].properties.properties);
      }
      let props = data.features[i].properties.properties
      category = props?.sobrep?.embargo ? 0 : props?.sobrep?.desmatamento ? 1 : props?.sobrep?.car ? 2 : 3;
      // marker.data.embargo = props?.sobrep?.embargo;
      // marker.data.desmatamento = props?.sobrep?.desmatamento;
      // marker.data.sobreposicao = props?.sobrep?.car;
      marker.category = category;
      // }
      marker.data.icon = createIcon(category);
      // marker.data.ID = data.features[i].id;
      markersRef.current.push(marker);
      leafletView.RegisterMarker(marker);
    }

    leafletView.ProcessView();
    layerRef.current.customMarkers = leafletView;

    if (map.getZoom() <= 9) {
      map.addLayer(leafletView);
    }
  }

  useEffect(() => {
    if (searchData && map) {
      CustomGeoJSON({ data: searchData.data, id: searchData.layerName, fitBounds: true });
      (!hideCluster && customMarkers({ data: searchData.data }));
    }
  }, [searchData, map]);

  useEffect(() => {
    if (layers && map) {
      layers?.map((layerData, index) => {
        CustomGeoJSON({ data: layerData, id: layerData.source, style: layerProperties(layerData.source), fitBounds: true });
        // customMarkers({ data: layerData });
        return layerData
      })
    }
  }, [layers, map]);

  useEffect(() => {
    if (highlight && map) {
      highlight?.map((layerData, index) => {
        MaskLayer({ layer: layerData });
        customMarkers({ data: layerData });
        return layerData
      })
    }
  }, [highlight, map]);

  useEffect(() => {
    if (imageDate && history && map && mosaics) {
      HistoricalImage({ imageDate: nearestDate(imageDate)?.url })
    }
  }, [history, imageDate, map, mosaics]);

  useEffect(() => {
    if (map && user && !imageDate && !vectorBasemapLayerRef.current) {
      
      vectorBasemapLayerRef.current = vectorBasemapLayer(baseMap || "arcgis/imagery", {
        version: 2,
        lockLayer: true,
        token: user.user_metadata?.arcgisToken,
        subdomains: ['server', 'server2', 'services'],
        language: 'pt-BR',
      });
      vectorBasemapLayerRef.current.addTo(map);
      
    }
  }, [map, imageDate, user, isBrowserTabActive]);

  useEffect(() => {
    if (mosaicsData && map) {
      setMosaicsMin(mosaicsIndex - Math.floor(pagination))
    }
  }, [pagination]);

  useEffect(() => {
    async function removeLayerWithFade(layer) {
      if (map.hasLayer(layer)) {
        await fadeOutLayerLeaflet(layer, 1, 0, 0.1, 20).then(() => {
          map.removeLayer(layer);
        });
      }
    }
    if (map) {
      if (showSlider && mosaicsIndex && mosaics?.length > 0) {
        let mosaicLayer = mosaics[mosaicsIndex - 1]?.url;
        HistoricalImage({
          imageDate: mosaicLayer
        });
      }
      if (!showSlider) {
        if (mosaicRef.current) {
          if (map.hasLayer(mosaicRef.current)) {
            removeLayerWithFade(mosaicRef.current);
            mosaicRef.current = null;
          }
        }
        if (mosaicRef.old) {
          if (map.hasLayer(mosaicRef.old)) {
            removeLayerWithFade(mosaicRef.old);
            map.removeLayer(mosaicRef.old)
            mosaicRef.old = null;
          }
        }
      }
    }

  }, [mosaicsIndex, showSlider]);

  useEffect(() => {
    if (query && map) {
      codRef.current = query;
      setGlobalSearch(true)
    }
  }, [map && query]);

  useEffect(() => {
    if (map && mapLoaded) {
      if (!toolsRef.panes) {
        map.createPane('geojsonPane').style.zIndex = 300;
        map.createPane('searchPane');
        map.getPane('searchPane').style.zIndex = 500;
        map.createPane('boundary');
        map.getPane('boundary').style.zIndex = 200;
        toolsRef.panes = true;
      }
      if (fullScreen && !toolsRef.fullScreen) {
        L.control.fullscreen({
          position: 'topleft',
          title: 'Show me the fullscreen !',
          titleCancel: 'Exit fullscreen mode',
          content: null,
          forceSeparateButton: false,
          forcePseudoFullscreen: true,
          fullscreenElement: false
        }).addTo(map);

        map.on('enterFullscreen', function () {
          let fullPagination = 24
          setPagination(fullPagination)
        });

        map.on('exitFullscreen', function () {
          let defaultPagination = 8
          setPagination(defaultPagination)
        });

        toolsRef.fullScreen = true;
      }
      if (!toolsRef.mapClick) {
        map.on('click', function (e) {
          let cqlFilter = '';
          let sources = Object.keys(layerRef.current).filter((layer) => layer.includes("extraLayer") && layerRef.current[layer].options?.searchable)
          if (sources.length > 0) {

            const lat = parseFloat(e.latlng.lat);
            const long = parseFloat(e.latlng.lng);
            let kms = getKms(map.getZoom());
            cqlFilter = `DWithin(geom, SRID=4326;POINT(${long} ${lat}),${kms},kilometers)`;

            let fetchPromises = sources.map(source => {
              source = source.split("-")[1];

              const wfsUrl = supabaseClient.supabaseUrl + '/geoserver/v2/ccc/ows';
              const wfsParams = {
                service: 'WFS',
                version: '2.0.0',
                request: 'GetFeature',
                typeName: 'ccc:' + source,
                count: 1990,
                outputFormat: 'application/json',
                cql_filter: cqlFilter
              };

              const wfsQueryString = new URLSearchParams(wfsParams).toString();

              return fetch(`${wfsUrl}?${wfsQueryString}`)
                .then(response => response.json())
                .then(data => {
                  if (data.features.length > 0) {
                    CustomGeoJSON({ data: { ...data, source: "extraLayer-" + source }, id: "extraSearch-" + source, style: layerProperties(source) })
                  }
                  // setSearchData(data);
                  // searchDataRef.current = data;
                })
                .catch(error => {
                  console.error('Error:', error);
                })
                .finally(() => {
                  // Defina isLoading como false quando o fetch estiver concluído
                  // setIsLoading(false);
                });

            });

            Promise.all(fetchPromises).then(() => {
            });
          }

          if (actionsRef.pointSearch) {
            setCodType('latlong');
            setCod(`${e.latlng.lat}; ${e.latlng.lng}`);
            //add a circle to map at click location
            if (layerRef.current?.pointSearch) {
              map.removeLayer(layerRef.current?.pointSearch);
              map.removeLayer(layerRef.current?.pointSearchHalo);
            }

            if (layerRef.current?.tempPointGroup) {
              map.removeLayer(layerRef.current?.tempPointGroup);
              delete layerRef.current?.tempPointGroup;
            }

            layerRef.current.pointSearch =
              L.circle(e.latlng, {
                pane: 'searchPane',
                radius: 20,
                fillColor: 'rgba(255,66,66)',
                interactive: false,
                color: 'rgb(255,66,66)',
                weight: 10,
              }).addTo(map).bringToFront();

            layerRef.current.pointSearchHalo =
              L.circle(e.latlng, {
                pane: 'searchPane',
                radius: radiusRef.current * 500,
                color: 'rgb(255,66,66)',
                weight: 4,
                interactive: false,
                dashArray: '10, 10',
                fillColor: 'rgba(0,0,0,0)',
                fillOpacity: 0.0
              }).addTo(map).bringToFront();

            if (L.DomUtil.hasClass(map.getContainer(), "leaflet-measure-map")) {
              L.DomUtil.removeClass(map.getContainer(), "leaflet-measure-map");
            }

            delete actionsRef.pointSearch;
            setPointSearch(false);

            let sources = ["v_rural_property"];

            let fetchPromises = sources.map(source => {
              const bounds = layerRef.current.pointSearchHalo.getBounds();
              const wktPolygon = `POLYGON((${bounds.getWest()} ${bounds.getSouth()}, ${bounds.getEast()} ${bounds.getSouth()}, ${bounds.getEast()} ${bounds.getNorth()}, ${bounds.getWest()} ${bounds.getNorth()}, ${bounds.getWest()} ${bounds.getSouth()}))`;

              const wfsUrl = supabaseClient.supabaseUrl + '/geoserver/v2/ccc/ows';

              const wfsParams = {
                service: 'WFS',
                version: '2.0.0',
                request: 'GetFeature',
                typeName: 'ccc:' + source,
                name: source,
                count: 2000,
                outputFormat: 'application/json',
                cql_filter: `intersects(geom, SRID=4326;${wktPolygon}) AND cod IS NOT NULL`
              };

              const wfsQueryString = new URLSearchParams(wfsParams).toString();

              return fetch(`${wfsUrl}?${wfsQueryString}`)
                .then(response => response.json())
                .then(data => {
                  if (data.features.length > 0) {
                    setSearchData({ data: data, layerName: source });
                    searchDataRef.current = { data: data, layerName: source };
                  }
                })
                .catch(error => {
                  console.error('Error:', error);
                });
            });

            Promise.all(fetchPromises).then(() => {
              layerRef.current?.pointSearchHalo.bringToFront()
              layerRef.current?.pointSearch.bringToFront()
            });
          }

          let queryFeatures = [];
          map.eachLayer(function (layer) {
            if (!layer?.options?.name) {
              return;
            }
            let point = turf.point([e.latlng.lng, e.latlng.lat]);
            let feature = layer.feature;
            if (feature) {
              if (feature.geometry.type === 'MultiPolygon') {
                feature.geometry.coordinates.forEach(function (polygon) {
                  var multiPolygonFeature = {
                    type: 'Feature',
                    properties: feature.properties,
                    geometry: {
                      type: 'Polygon',
                      coordinates: polygon
                    }
                  };
                  if (turf.booleanContains(multiPolygonFeature, point)) {
                    let props = feature.properties;
                    queryFeatures.push({ ...props, layerName: layer?.options?.name })
                  }
                })
              } else if (turf.booleanContains(feature, point)) {
                let props = feature.properties;
                queryFeatures.push({ ...props, layerName: layer?.options?.name })
              }
            }

          });
          if (queryFeatures.length > 0) {
            setPointInfoQuery(queryFeatures);
            setLatLongClick(e.latlng);
          }
        });
        toolsRef.mapClick = true
      }
      if (!toolsRef.ufLayer) {
        if (!layerRef.current?.uf && uf   ) {
          let baseLayer = L.tileLayer.wms(
            supabaseClient.supabaseUrl.replace("www.", "").replace('https://', 'https://{s}.') + "/geoserver/v2/gwc/service/wms",
            {
              layers: "ccc:uf",
              styles: baseMap ? "ccc:uf_sld_black" : "ccc:uf_sld",
              mapVersion: "1.0.0",
              crossOrigin: true,
              subdomains: ['www', 'www1', 'www2', 'www3'],
              pane: 'tilePane',
              lockLayer: true,
              maxZoom: 7,
              opacity:0.4,
              format: 'image/png',
              transparent: true,
            },
            [
              { header: 'Authorization', value: `Bearer ${session.access_token}` },
              {
                header: 'apikey',
                value: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiYW5vbiIsImlzcyI6InN1cGFiYXNlLWRlbW8iLCJpYXQiOjE2NDE3NjkyMDAsImV4cCI6MTc5OTUzNTYwMH0.VzlJ1lOmLMKsHs5Nq61ZNRjbsPh3RLgDFXeGjSLPlHY"
              },
            ]
          )
          layerRef.current.uf = baseLayer.addTo(map);
        }
        toolsRef.ufLayer = true
      }
      if (!toolsRef.cluster) {
        map.on('zoomend', function () {
          var currentZoom = map.getZoom();
          if (currentZoom >= 9 && layerRef.current.customMarkers) {
            map.removeLayer(layerRef.current.customMarkers)
          }
          if (currentZoom < 9 && layerRef.current.customMarkers) {
            {
              map.addLayer(layerRef.current.customMarkers)
            }
          }
        })
        toolsRef.cluster = true
      }
      if (!toolsRef.initialBounds ) {
        setTimeout(() => {
          map.fitBounds(bounds, { maxZoom: 16, padding: [10,10], animate: false, duration: 0 })
        }, 0);
        toolsRef.initialBounds = true
      }
    }
  }, [map, mapLoaded]);

  useEffect(() => {
    if (minimapRef.current) {
      map.removeControl(minimapRef.current)
      delete minimapRef.current;
    }
    if (minimap && map && activeTab) {
      let minimapLayer = new L.TileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", { minZoom: 0, maxZoom: 13 });
      let options = {
        position: "topright",
        zoomLevelFixed: 2,
        height: 160,
        width: 120,
        centerFixed: [-14.729668469510635, -54.017610152549],
        aimingRectOptions: {
          weight: 4,
          fillOpacity: 0.1
        }
      }
      minimapRef.current = new L.Control.MiniMap(minimapLayer, options).addTo(map);
    }
  }, [map, activeTab]);

  useEffect(() => {
    if (map && bounds){
      setTimeout(() => {
        map.invalidateSize(false)
      }, 0);

      setTimeout(() => {
        if (!toolsRef.firstInvalidate2 && tabId === activeTab){
            map.fitBounds(bounds, { maxZoom: 16, padding: [10,10], animate: false, duration: 0 })
          toolsRef.firstInvalidate2 = true
        }
      }, 0);
    }
  }, [/*bounds, map,*/ activeTab]);

  useEffect(() => {
    if (hidded){
      if(layerRef?.current?.customGeoJSON){
        //iterract over all layers in customGeoJSON and check if the propertie "doc" is in the hidded array, if true, hidde the layer settig the opacity to 0 and store the original opacity, else restore the original opacity
        layerRef.current.customGeoJSON.eachLayer(function (layer) {
          console.log(layer.feature.properties?.doc)
          console.log(!layer.options.hidded && hidded.includes(layer.feature.properties?.doc))
          if (!layer.options.hidded && hidded.includes(layer.feature.properties?.doc)) {
            layer.options.originalOpacity = layer.options.opacity;
            layer.options.originalFillOpacity = layer.options.fillOpacity;
            layer.options.hidded = true;
            layer.setStyle({opacity: 0, fillOpacity: 0})
            layer.off('mouseover');
            layer.off('mouseout');
          } else if(layer.options.hidded && !hidded.includes(layer.feature.properties?.doc)) {
            layer.options.hidded = false;
            layer.setStyle({opacity: layer.options.originalOpacity, fillOpacity:layer.options.originalFillOpacity})
            layer.on('mouseover');
            layer.on('mouseout');
          }
        })
      }
    }
  }, [hidded]);

  return (
    <>
      {bounds &&
        <MapContainer
          style={{ height: "100%", width: "100%" }}
          // sx={{ cursor: "crosshair" }}
          preferCanvas
          zoomSnap={0.2}
          center={bounds[0]}
          zoom={5}
          minZoom={5.0}
          csr={L.CRS.EPSG4326}
          maxZoom={history && imageDate ? 16 : 19}
          ref={setMap}
          // bounds={bounds}
          // minBounds={bounds}
          maxBounds={bounds}
          whenReady={() => {
            setMapLoaded(true)
          }}
          // dragging={!lock}
          doubleClickZoom={false}
          // scrollWheelZoom={!lock}
          zoomControl={false}
          attributionControl={false}
        >
          <Control
            style={{color: "red"}}
            appended
            position='topleft'>
            {extraLayers && <ExtraLayers activeTab={activeTab} map={map} layerRef={layerRef} customMarkers={customMarkers} />}
          </Control>

          {search && <Control
            appended
            position="bottomright">
            {<PointInfo point={pointInfoQuery} map={map} layerRef={layerRef} latLong={latLongClick} />}
          </Control>}

          {/*<Control*/}
          {/*  appended*/}
          {/*  position={search ? "topleft" : ""}>*/}
          {/*  {<GlobalSearch query={query} setSearchData={setSearchData} searchDataRef={searchDataRef} codRef={codRef} layerRef={layerRef} map={map}/>}*/}
          {/*</Control>*/}

          <ControlButton
            enabled={search && !hideButtons}
            children={globalSearch &&  <GlobalSearch query={query} setSearchData={setSearchData} searchDataRef={searchDataRef} codRef={codRef} layerRef={layerRef} map={map} hideButtons={hideButtons} />}
            active={globalSearch}
            label={"Localizar Imóvel Rural"}
            onClick={() => {
              actionsRef.globalSearch = !globalSearch;
              setGlobalSearch(!globalSearch)
            }}
            Icon={IconSearch} />

          <ControlButton
            enabled={search && !hideButtons && user.user_metadata.is_admin }
            children={
              pointSearch && (
                <Slider
                  h={32}
                  style={{ position: "absolute", left: "40px", top: "0px" }}
                  p={2}
                  mb={2}
                  ml={5}
                  w={270}
                  defaultValue={0}
                  value={radius}
                  marks={[
                    { value: 0, label: '0km' },
                    { value: 5, label: '5km' },
                    { value: 10, label: '10km' },
                    { value: 15, label: '15km' },
                    { value: 20, label: '20km' },
                  ]}
                  onMouseDown={(val) => {
                    // val.stopPropagation()
                  }}
                  onMouseUp={(val) => {
                    // val.stopPropagation()
                  }}
                  onClick={(val) => {
                    val.stopPropagation()
                    val.nativeEvent.stopImmediatePropagation();
                  }}
                  onChange={(val) => {
                    setRadius(val)
                    radiusRef.current = val;
                    layerRef.current?.tempPointSearchHalo.setRadius(val * 500);
                  }}
                  onChangeEnd={(val) => {
                    // setEndDistance(val);
                    radiusRef.current = val;
                  }}
                  precision={1}
                  min={0}
                  max={20}
                  step={0.5}
                  label={(value) => `${value} Km`}
                  labelTransition="fade"
                  styles={(theme) => ({
                    // add shadow to marklabels
                    markLabel: { color: "#ffffff", fontWeight: 500, fontSize: 12 },
                    // markLabel: { color: "#ffffff",  fontWeight: 500, fontSize: 12 },

                  })}
                />
              )}
            active={pointSearch}
            label={"Consultar ponto no mapa"}
            onClick={() => {
              setPointSearch(!pointSearch);
              actionsRef.pointSearch = !pointSearch;

              if (!L.DomUtil.hasClass(map.getContainer(), "leaflet-measure-map")) {
                L.DomUtil.addClass(map.getContainer(), "leaflet-measure-map");
              } else {
                L.DomUtil.removeClass(map.getContainer(), "leaflet-measure-map");
              }
              if (!layerRef.current?.tempPointGroup) {
                map.on('mousemove', function (e) {
                  if (!layerRef.current?.tempPointGroup && actionsRef.pointSearch) {
                    layerRef.current.tempPointSearch =
                      L.circle(e.latlng, {
                        pane: 'searchPane',
                        radius: 20,
                        fillColor: 'rgba(255,66,66)',
                        interactive: false,
                        color: 'rgb(255,66,66)',
                        weight: 10,
                      })

                    layerRef.current.tempPointSearchHalo =
                      L.circle(e.latlng, {
                        pane: 'searchPane',
                        interactive: false,
                        radius: radiusRef.current * 500,
                        color: 'rgb(255,66,66)',
                        weight: 4,
                        dashArray: '10, 10',
                        fillColor: 'rgba(0,0,0,0)',
                        fillOpacity: 0.0
                      })

                    let group = L.layerGroup([layerRef.current?.tempPointSearch, layerRef.current?.tempPointSearchHalo]);
                    group.addTo(map)

                    layerRef.current.tempPointGroup = group;

                  } else if (actionsRef.pointSearch) {

                    layerRef.current?.tempPointSearch.setLatLng(e.latlng);
                    layerRef.current?.tempPointSearchHalo.setLatLng(e.latlng);
                  }
                });
              } else {
                map.removeLayer(layerRef.current?.tempPointGroup);
                delete layerRef.current?.tempPointGroup;
              }

            }}
            Icon={IconMapPinQuestion} />

          <ControlButton
            enabled={cities}
            active={citySearch}
            children={<CitiesAutocomplete />}
            label={"Localizar Cidade"}
            onClick={() => {
              actionsRef.citySearch = !citySearch;
              setCitySearch(!citySearch)
            }}
            Icon={IconMapSearch} />

          <ControlButton
            enabled={mosaics.length > 1 && !imageDate && history}
            active={historyMap}
            label={"Exibir linha do tempo de imagens de satélite"}
            onClick={() => {
              actionsRef.historyMap = !historyMap;
              setHistoryMap(!historyMap);
              setShowSlider(!showSlider);
            }}
            Icon={IconHistory} />

          <ControlButton
            enabled={measure}
            label={"Medir distância"}
            onClick={() => {
              new L.MeasureAction(map, {
                model: "distance",
              }).enable();
            }}
            Icon={IconRulerMeasure} />

          <ControlButton
            enabled={measure}
            label={"Medir área"}
            onClick={() => {
              new L.MeasureAction(map, {
                model: "area",
              }).enable();
            }}
            Icon={IconDimensions} />

          <ControlButton
            enabled={layerRef && layerRef.current && Object.keys(layerRef.current).length > 0 && search && !hideButtons}
            label={"Limpar Mapa"}
            onClick={() => {
              map.eachLayer(function (layer) {
                if (!layer.options.lockLayer && layer.options.pane !== 'tilePane') {
                  map.removeLayer(layer);
                }
              }
              );
              for (let key in layerRef.current) {
                delete layerRef.current[key];
              }
              layerRef.current = {};
              setPointInfoQuery([])
            }}
            Icon={IconTrash} />


          {history && <>
            {showSlider && <>
              {mosaics.length > 1 &&
                <ActionIcon variant="transparent" color={"gray.0"} style={{ zIndex: 999, position: "absolute", left: 11, bottom: 45 }} onClick={() => {
                  setMosaicsMin(Math.max(mosaicsMin - Math.floor(pagination / 2), 0));
                }}>
                  <IconChevronLeft />
                </ActionIcon>}

              {mosaics.length > 1 &&
                <ActionIcon variant="transparent" color={"gray.0"} style={{ zIndex: 999, position: "absolute", right: 11, bottom: 45 }} onClick={() => {
                  setMosaicsMin(Math.min(mosaicsMin + Math.floor(pagination / 2), mosaics.length - pagination));
                }}>
                  <IconChevronRight />
                </ActionIcon>}
              {mosaics.length > 1 &&
                <Slider
                  thumbChildren={loading ? <Loader color={"#fff"} style={{ zIndex: 1999 }} /> : <> </>}
                  withinPortal
                  style={{ zIndex: 999, width: "calc(100% - 100px)", position: "absolute", right: 50, left: 50, bottom: 50 }}
                  styles={{
                    mark: {
                      width: rem(12),
                      height: rem(12),
                      borderRadius: rem(12),
                      transform: `translateX(-${rem(3)}) translateY(-${rem(2)})`,
                    },
                    markFilled: {
                      borderColor: "rgb(94,94,94)",
                    },
                    thumb: {
                      backgroundColor: "rgb(24,94,158)",
                      borderWidth: rem(2),
                      borderColor: "rgb(255,255,255)",
                      display: mosaicsIndex > mosaicsMin && mosaicsIndex <= (mosaicsMin + pagination) ? "flex  " : "none"
                    },
                    bar: {
                      "backgroundColor": "rgba(0, 0, 0, 0)"
                    },
                    track: {
                      height: 2,
                    },
                    markLabel: {
                      color: "white",
                      textShadow: "-2px 0 #5E5E5E, 0 2px #5E5E5E, 2px 0 #5E5E5E, 0 -2px #5E5E5E",
                    }
                  }}
                  thumbSize={24}
                  value={mosaicsIndex}
                  label={null}
                  onChange={val => {
                    val = Math.max(val, mosaicsMin + 1)
                    val = Math.min(Math.min(val, mosaics.length), mosaicsMin + pagination);
                    setMosaicsIndex(val);
                  }}
                  min={mosaicsMin}
                  max={mosaics && Math.min(mosaics.length + 1, mosaicsMin + pagination + 1)}
                  marks={mosaics && mosaics.slice(mosaicsMin, mosaicsMin + pagination).map((val, index) => ({
                    label: val.label,
                    value: index + 1 + mosaicsMin
                  }))} />}
            </>}

          </>}

          {history && imageDate &&
            <Card shadow={"sm"} p={4} m={2} style={{ position: "absolute", top: 4, zIndex: 999, right: 4, backgroundColor: "rgba(227,227,227,0.85)" }}>
              <Text size={14} fw={700} color={"#0b4f7f"}>{nearestDate(imageDate)?.label}</Text>
            </Card>}

          {/*{minimap && <MinimapControl bounds={bounds}/>}*/}

        </MapContainer>}

    </>

  );
});

export default MapLeaflet;