import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Card,
  Center,
  ColorSwatch,
  createStyles,
  Divider,
  Flex,
  Grid,
  Group, Input,
  List,
  NavLink,
  Popover,
  Progress,
  rem,
  ScrollArea,
  Skeleton,
  Space,
  Stack,
  Table,
  Text,
  TextInput,
  ThemeIcon,
  Tooltip
} from "@mantine/core";
import useSWR from "swr";
import React, { lazy, useCallback, useEffect, useRef, useState } from "react";
import 'leaflet/dist/leaflet.css';

import { notifications } from "@mantine/notifications";

import { useNotifications } from "../Notifications";
import GenericTableComponent from '../components/GenericTableComponent';
import OpExtraInfoComponent
  from "../components/OpExtraInfoComponent";
import {
  IconActivity, IconAlertCircle,
  IconAlertTriangle,
  IconBow,
  IconCircleCheck,
  IconCircleOff,
  IconGauge,
  IconCoins,
  IconGrowth,
  IconInfoSquare,
  IconLeafOff,
  IconLinkOff,
  IconLock,
  IconMapPin,
  IconPlant2,
  IconReportAnalytics,
  IconResize,
  IconSearch,
  IconSquareFilled,
  IconTractor,
  IconTrees,
  IconUsersGroup,
  IconX,
  IconComponents,
  IconUserSearch,
  IconUser
} from "@tabler/icons-react";
import dayjs from 'dayjs';
import { DateInput } from "@mantine/dates";

import { useSessionContext, useSupabaseClient, useUser } from '@supabase/auth-helpers-react';

import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { BarChart, } from 'echarts/charts';
import { GridComponent, TitleComponent, TooltipComponent, } from 'echarts/components';
import MapLeaflet from '../components/MapLeaflet';
import BigDataComponent from '../components/BigDataComponent';

import { SVGRenderer } from 'echarts/renderers';
import { useTabs } from "../lib/Tabs";

// Register the required components
echarts.use(
  [TitleComponent, TooltipComponent, GridComponent, BarChart, SVGRenderer]
);

const useStyles = createStyles((theme) => ({
  progressLabel: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    lineHeight: 1,
    fontSize: theme.fontSizes.sm,
  },

  stat: {
    borderBottom: `${rem(3)} solid`,
    paddingBottom: rem(5),
  },

  statCount: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    lineHeight: 1.3,
  },

  wrapper: {
    padding: `calc(${theme.spacing.xl} * 2) ${theme.spacing.xl}`,
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontSize: rem(36),
    fontWeight: 900,
    lineHeight: 1.1,
    marginBottom: theme.spacing.md,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
  },
}));

function PesquisarDoc({ onClickDoc }) {
  const [birthdate, setBirhdate] = useState(null);
  const [name, setName] = useState("");
  const [motherName, setMotherName] = useState("");

  const [params, setParams] = useState(null);

  function handleSearch() {
    setParams({
      _name: name == "" ? undefined : name,
      _mother_name: motherName == "" ? undefined : motherName,
      _birthdate: birthdate == null ? undefined : dayjs(birthdate).utc().format("YYYY-MM-DD")
    });
  }

  const { data } = useSWR(params && {
    functionName: "f_bigdata_find_doc",
    args: params
  });

  return <>
    <TextInput label="Nome" size="xs" value={name} onChange={val => setName(val.target.value)} />
    <TextInput label="Nome da mãe" size="xs" mt="xs" value={motherName} onChange={val => setMotherName(val.target.value)} />
    <DateInput
      label="Data de nascimento"
      valueFormat={"DD/MM/YYYY"}
      hideWeekdays
      value={birthdate}
      dateParser={(input) => {
        return dayjs(input, 'DD/MM/YYYY').toDate();
      }}
      onChange={setBirhdate}
    />

    <Button mt="xs" onClick={handleSearch}>
      Pesquisar
    </Button>

    {data && <Box mt="xs">
      {data.map(item => <NavLink
        label={item.Name}
        description={item.MotherName}

        rightSection={dayjs(item.BirthDate).utc().format("DD/MM/YYYY")}
        variant="subtle"
        active
        onClick={() => {
          onClickDoc(item)
        }}
        icon={<IconUser size={16} stroke={1.5} />}
      />)
      }
    </Box>}
  </>
}

function DateInputISO(props) {
  const handleOnChange = useCallback((newValue) => {
    props.onChange(newValue && newValue.toISOString())
  }, [props.onChange]);

  return <DateInput
    size={"xs"}
    popoverProps={{
      withinPortal: true
    }}
    dateParser={(input) => {
      return input && dayjs(input, 'DD/MM/YYYY').toDate();
    }}
    valueFormat="DD/MM/YYYY"
    {...props}
    value={props.value && new Date(props.value)}
    onChange={handleOnChange}
  />
}
function getFormattedDate(monthsToReduce = 0, date) {
  let resultDate = new Date(date);
  let yearsToReduce = Math.floor(monthsToReduce / 12);

  let remainingMonths = monthsToReduce % 12;

  resultDate.setFullYear(resultDate.getFullYear() - yearsToReduce);
  resultDate.setMonth(resultDate.getMonth() - remainingMonths);

  let year = resultDate.getFullYear();
  let month = resultDate.getMonth() + 1; // getMonth() is zero-based

  // padStart will ensure that month and day are always two digits
  return `${year}-${String(month).padStart(2, '0')}-${String("01").padStart(2, '0')}`;
}
function formatNumber(value, fixedDecimal) {
  let decimal = fixedDecimal ? (parseFloat(value) % 1 === 0 ? 0 : fixedDecimal) : 0;
  return parseFloat(value).toLocaleString('pt-BR', {
    style: 'decimal',
    maximumFractionDigits: decimal,
    minimumFractionDigits: decimal,
    currency: 'BRL',
    signDisplay: "auto"
  })
}
function format(value) {
  let sigla = {
    CREG: "Certidão de registro",
    CCVE: "Contrato de compra e venda",
    EREG: "Em regularização",
    ESCR: "Escritura",
    AOCP: "Autorização de Ocupação",
    CANU: "Carta de Anuência",
    CRDU: "Concessão real de direito de uso",
    CATP: "Contrato de alienação de terras públicas",
    CCDP: "Contrato de concessão de domínio de terras públicas",
    CCTP: "Contrato de concessão de terras públicas",
    CTAF: "Contrato de transferência de aforramento",
    LOCP: "Licença de ocupação",
    TDOA: "Termo de doação",
    TPCR: "Título de propriedade sob condição resolutiva",
    TDRF: "Título definitivo, com reserva florestal, em condomínio",
    TDRR: "Título definitivo sujeito a re-ratificação",
    TDOF: "Título definitivo transferido, com anuência do Órgão Fundiário(Estadual ou Federal)",
    TDOM: "Título de domínio",
    TRDO: "Título de reconhecimento de domínio",
    TRAT: "Título de ratificação",
    CAOF: "Contrato de assentamento do Órgão Fundiário (Estadual ou Federal)",
    CPCV: "Contrato de promessa de compra e venda",
    DSRU: "Declaração do sindicato rural ou sindicato dos trabalhadores rurais",
    DAMU: "Declaração de assentamento Municipal",
    DCON: "Declaração dos confrontantes",
    ADEC: "Termo de autodeclaração",
    CDRU: "Concessão de Direito Real de Uso",
    DDIS: "Decreto de Declaração de Interesse Social expedido pela Presidência da República para Territórios Quilombolas",
    PRIN: "Portaria de Reconhecimento do INCRA",
    RTID: "Relatório Técnico de Identificação e Delimitação",
    TAUS: "Termo de Autorização de Uso Sustentável"
  }
  return sigla[value]
}
function PrintHeader({ isPrint, name, doc }) {
  return <div className={"header"} style={{ position: "relative" }}>
    <Card py={4} px={"xs"} withBorder>
      <Grid py={0} gutter={"xs"} justify="space-between">
        <Grid.Col py={0} span={2}>
          <Text size={22} fw={800}>Relatório de Análise Socioambiental</Text>
        </Grid.Col>
        <Grid.Col span={8} py={0} sx={{ textAlign: "center", justifyContent: "center" }}>
          <Text size={20} fw={700}>{doc}</Text>
          <Text size={18} fw={600}>{name}</Text>
        </Grid.Col>
        <Grid.Col py={0} span={2}>
          <Flex py={4} sx={{ justifyContent: "right" }}>
            <img src={"./ccc-amb.svg"} width="130" />
          </Flex>
        </Grid.Col>
      </Grid>
    </Card>
  </div>
}
function InfoCard(props) {
  let total_perc = (props.area / props.total_area) * 100;
  return (
    <Card withBorder p={6} >
      <Stack spacing={0} pb={4} justify={"space-between"} h={151}>
        {props.area >= 0 && <>
          <ThemeIcon
            size={24}
            style={{ position: "absolute" }}
            color={props.area === 0 ? "#ccc" : props.iconColor}>
            <props.icon
              size={20}
              color={"white"}
              stroke={1.5} />
          </ThemeIcon>
          <Text color={props.area === 0 ? "#aaa" : "#495057"} align={"center"} size={14} fw={600} ml={28}
            style={{ position: "relative", lineHeight: "16px" }}>{props.title}</Text>
          <Center><Text color={props.area === 0 ? "#aaa" : "#495057"} align={"center"} size={30} fw={700} style={{
            position: "absolute",
            top: "45%",
            verticalAlign: "center",
            lineHeight: "16px"
          }}>{formatNumber(props.area / (props.unit?.includes("Qtd") ? 1 : 10000))}</Text></Center>
          <Center><Text color={props.area === 0 ? "#aaa" : "#495057"} align={"center"} size={12} fw={700}
            style={{ position: "absolute", top: "58%", fontWeight: 400 }}>{props.unit || "ha"}</Text></Center>
          <Text color={props.area === 0 ? "#aaa" : "#495057"} align={"center"} size={12} fw={500}>Imóveis Rurais: {props.qtd}</Text>
        </>}
      </Stack>
        <div style={{
          position: 'relative',
        }}>
          <Progress
            value={props.total_area && total_perc}
            color={props.iconColor}
            size="xl"
            radius="sm"/>
          {(total_perc > 0) && <Text size={11} style={{
            position: 'absolute',
            width: '100%',
            left: (total_perc + (total_perc < 85 ? 2 : - 16)) + '%',
            color: total_perc < 85 ? "#495057" : "white",
            top: '50%',
            transform: 'translateY(-50%)',
            fontWeight: 'bold',
          }}>
            {formatNumber(total_perc,total_perc < 1 ? 2 : 0) + "%"}
          </Text>}
        </div>
    </Card>)
}
function chartPie(data, params) {
  let value = params.value ?? "value";
  let percentage = params.percentage != false;
  let showSum = params.showSum != false;
  data = data.map((d) => {
    return { "name": d.name, "value": d[value], "itemStyle": d.itemStyle }
  }
  );
  let sumValue = data.reduce((acc, val) => acc + val.value, 0);
  let sumValueText = formatNumber(sumValue / (value === "qtd" ? 1 : (params?.divBy || 10000)));

  return {
    title: [
      {
        id: 1,
        top: "52%",
        left: "31.5%",
        show: showSum,
        textAlign: "center",
        textVerticalAlign: "middle",
        text: sumValueText,
        textStyle: {
          color: sumValue === 0 ? "#aaa" : "#495057",
          fontSize: 26,
          fontWeight: 800,
        },
      },
      {
        id: 3,
        top: "61%",
        left: "31.5%",
        show: showSum,
        textAlign: "center",
        textVerticalAlign: "middle",
        text: value === "qtd" ? "Qtd" : (params?.sufix || "ha"),
        textStyle: {
          color: sumValue === 0 ? "#aaa" : "#495057",
          fontSize: 12,
          fontWeight: 400,
        },
      }],
    tooltip: {
      appendToBody: true,
      confine: true
    },
    legend: {
      textStyle: {
        lineHeight: 14,
        fontSize: 9
      },

      formatter: params => {
        let lines = [];
        let line = "";
        for (const item of params.replaceAll("/", "/ ").split(" ")) {
          if (line.length > 0) {
            if (line.length + item.length > 18) {
              lines.push(line);
              line = item;
              continue;
            }
            line += " ";
          }
          line += item;
        }
        if (line.length > 0) {
          lines.push(line);
        }
        return lines.join("\n");
      },
      pageIconSize: 10,
      pageButtonGap: 8,
      orient: 'vertical',
      type: 'scroll',
      right: 'right',
      left: '67%',
      top: "center",
      itemGap: 6,
      itemWidth: 16,
    },
    series: [
      {
        type: 'pie',
        radius: [showSum ? '60%' : "30%", '89%'],
        center: ["33%", "54%"],
        avoidLabelOverlap: false,
        emphasis: {
          focus: "self",
          minShowLabelAngle: 0,
          label: {
            fontSize: 18,
            minShowLabelAngle: 0,
          },
        },
        labelLayout: {
          hideOverlap: false,
        },
        itemStyle: {
          borderRadius: 0,
          borderColor: '#fff',
          borderWidth: 0
        },
        label: {
          show: true,
          fontWeight: 700,
          fontSize: 12,
          position: "inside",
          formatter: function (params) {
            let x;
            x = parseFloat(params.value);
            if (x === 0) {
              x = "";
            } else {
              x = formatNumber(percentage ? ((x / sumValue) * 100) : x);
            }
            return x + (percentage ? "%" : "");
          },
          // formatter: percentage ? '{d}%' : '{@value}',
        },
        labelLine: {
          show: true,
          length: 5,
        },
        minAngle: 2,
        minShowLabelAngle: 5,
        data: data
      }
    ],
    // dataset:{source: data}
  };
}
function chartBar(data, params) {

  if (!params?.series){
    return {}
  }

  let series = params?.series?.map((d) => {

    let serieData = data.map((v) => {
      return ({ name: v.name, value: v[d.value] / (params.divBy || 1), itemStyle: v.itemStyle || {} });
    });

    if (params.limit) {
      //slice data to limit, from the end to the start
      serieData = serieData.slice(-params.limit);
    }

    return {
      name: d.name,
      type: d.type,
      stack: !!params.stack,
      symbolSize: 10,
      itemStyle: { color: d.color },
      data: serieData,
      emphasis: {
        label: { fontSize: 18 },
      },
      label: {
        show: true,
        position: (d.labelPosition) || "inside",
        fontWeight: 700,
        fontSize: 12,
        formatter: function (params) {
          let x;
          x = parseFloat(params.value);
          if (x === 0) {
            x = "";
          } else {
            x = formatNumber(d.format === "percentage" ? (x * 100) : x, 1);
          }
          return x + (d.format === "percentage" ? "%" : "");
        },
      },
      markLine: { ...d.markLine }
    }
      ;
  })

  let dimensao = data.map((d) => d.name)
  if (params.limit) {
    //slice data to limit, from the end to the start
    dimensao = dimensao.slice(-params.limit);
  }

  return {
    tooltip: {
      appendToBody: true,
      confine: true
    },
    grid: {
      left: '4px',
      right: '6px',
      bottom: '0%',
      top: '46px',
      containLabel: true
    },
    legend: {
      show: params.series[0].showLegend ?? true,
      left: "right",
      top: 0,
      textStyle: {
        lineHeight: 14,
        fontSize: 9
      },
      itemGap: 6,
      itemWidth: 16,
      itemHeight: 12
    },
    xAxis: {
      type: 'category',
      data: dimensao,
      axisLabel: {
        fontSize: 10
      }
    },
    yAxis: {
      type: 'value',
      max: function (value) {
        return params.series[0].format === "percentage" ? Math.max(value.max, 1) : value.max;
      },
      axisLabel: {
        fontSize: 10,
        formatter: function (value) {
          let x;
          x = parseFloat(value);
          if (x === 0) {
            x = "";
          } else {
            x = formatNumber(params.series[0].format === "percentage" ? (x * 100) : x);
          }
          return x + (params.series[0].format === "percentage" ? "%" : "");
        }
      }
    },
    series: series
  };
}
function ChartCard(props) {
  return (
    <Skeleton visible={props.isLoading}>
      <Card withBorder p={6}>
        <Stack spacing={0} justify={"space-between"} h={props.h || 231}>
          {props.icon && <ThemeIcon
            size={24}
            style={{ position: "absolute" }}
            color={!props.data || props.data?.length === 0 ? "#ccc" : props.iconColor}>
            <props.icon
              size={20}
              color={"white"}
              stroke={1.5} />
          </ThemeIcon>}
          <div style={{ display: "flex", alignItems: "center", justifyContent: props.titleAlign || "center" }}>
            <Text color={!props.data || props.data?.length === 0 ? "#aaa" : "#495057"} size={14} fw={600} ml={props.icon ? 28 : 0} pt={22}
              style={{ position: "absolute" }}>{props.title}</Text>
          </div>
          {props?.data &&
            <ReactEChartsCore
              opts={{ renderer: 'svg' }}
              echarts={echarts}
              option={
                props.chart(props.data, props.params)
              } />
          }
          {!props?.data &&
            <Center><Text color={"#aaa"} size={14} p={10}>Sem dados disponíveis</Text></Center>
          }
        </Stack>
      </Card>
    </Skeleton>)
}
function sourceColor(source) {
  switch (source) {
    case "CAR":
      return "yellow.9";
    case "SIGEF":
      return "cyan";
    case "SNCI":
      return "blue";
    case "CIB":
      return "gray.8";
  }
}
function BadgeCustom(props) {
  let unit = props.unit || "ha"
  return (
    props.area > 0 &&
    <Badge
      size={"xs"}
      m={2}
      variant={"light"}
      color={props.color}
      sx={{ textTransform: "none" }}
      radius="md" >{props.label ? ("[" + props.label + "] ") : ""}{formatNumber(props.area / (unit !== "ha" ? 1 : 10000))} {unit}
      {unit !== "ha" ? ("≥" + formatNumber(props.totalArea / 1000)) + "km" : (" (" + formatNumber((props.area / props.totalArea) * 100) + "%)")}
    </Badge>
  )
}
function properties(data, type, equiv, parcela) {
  let threshold = 0.9
  let filtered =
    data[type] &&
    data[type].length > 0 &&
    data[type]?.
      filter((a) => a.cod !== data.cod || equiv).
      filter((c) => equiv ? (c.sobrep_contem >= threshold && c.sobrep_contido >= threshold) :
        (parcela ? (c.parcela) : true)).
      map((m) => m.cod === data.cod ? { ...m, principal: true } : m)
  return filtered || []
}
function carGeoType() {
  return {
    AREA_IMOVEL: ["Área do Imóvel", "#ffffff", 'none'],
    // AREA_IMOVEL_LIQUIDA: ["Área Líquida do Imóvel","#ffffff",'none'],
    // AREA_CONSOLIDADA: ["Área Consolidada até 22 de julho de 2008","#ffffff"],
    AREA_INFRAESTRUTURA_PUBLICA: ["Área de Infraestrutura Pública", "#a8a8a8", "solid"],
    AREA_UTILIDADE_PUBLICA: ["Área de Utilidade Pública", "#9a9a9a", "solid"],
    RESERVATORIO_ENERGIA: ["Reservatório para Abastecimento ou Geração de Energia", "#ffc351", "solid"],
    AREA_ENTORNO_RESERVATORIO_ENERGIA: ["Área de Entorno de Reservatório para Abastecimento ou Geração de Energia", "#ffc351", "solid"],
    AREA_SERVIDAO_ADMINISTRATIVA_TOTAL: ["Área de Servidão Administrativa Total", "#7957dd", "solid"],

    APP_TOTAL: ["Área de Preservação Permanente (APP)", "#00eaff", "cross"],

    RIO_ATE_10: ["Curso d’água natural de até 10 metros", "#000cff", "vertical"],
    RIO_10_A_50: ["Curso d’água natural de 10 a 50 metros", "#000cff", "vertical"],
    RIO_50_A_200: ["Curso d’água natural de 50 a 200 metros", "#000cff", "vertical"],
    RIO_200_A_600: ["Curso d’água natural de 200 a 600 metros", "#000cff", "vertical"],
    RIO_ACIMA_600: ["Curso d’água natural acima de 600 metros", "#000cff", "vertical"],
    NASCENTE_OLHO_DAGUA: ["Nascente ou olho d’água perene", "#00158b", "vertical"],
    LAGO_NATURAL: ["Lago ou lagoa natural", "#457eff", "vertical"],
    RESERVATORIO_ARTIFICIAL_DECORRENTE_BARRAMENTO: ["Reservatório artificial decorrente de barramento ou represamento de cursos d’água naturais", "#41c1e6", "vertical"],

    VEREDA: ["Vereda", "#ffc192", "backward-diagonal"],
    BANHADO: ["Banhado", "#ffc192", "backward-diagonal"],
    AREA_TOPO_MORRO: ["Área de topo de morro", "#ffa55c", "backward-diagonal"],
    AREA_DECLIVIDADE_MAIOR_45: ["Área de declividade maior que 45 graus.", "#fd8627", "backward-diagonal"],
    BORDA_CHAPADA: ["Borda de chapada", "#d35e00", "backward-diagonal"],
    RESTINGA: ["Restinga", "#b85200", "backward-diagonal"],
    MANGUEZAL: ["Manguezal", "#8e3f00", "backward-diagonal"],
    AREA_ALTITUDE_SUPERIOR_1800: ["Área com altitude superior a 1.800 metros", "#853900", "backward-diagonal"],


    AREA_USO_RESTRITO_PANTANEIRA: ["Área de Uso Restrito para regiões pantaneiras", "#e36000", "horizontal"],
    AREA_USO_RESTRITO_DECLIVIDADE_25_A_45: ["Área de Uso Restrito para declividade de 25 a 45 graus", "#e36000", "horizontal"],

    AREA_POUSIO: ["Área de Pousio", "#00ff53", "horizontal"],
    VEGETACAO_NATIVA: ["Remanescente de Vegetação Nativa (VN)", "#00ff54", "horizontal", "80"],

    ARL_PROPOSTA: ["Reserva Legal (RL) Proposta", "#ff2020", "solid", "70"],
    ARL_AVERBADA: ["Reserva Legal (RL) Averbada", "#ff2020", "solid", "70"],
    ARL_APROVADA_NAO_AVERBADA: ["Reserva Legal (RL) Aprovada e não Averbada", "#ff2020", "solid", "70"],
    ARL_AVERBADA_OUTRO_IMOVEL: ["Reserva Legal (RL) Averbada em outro imóvel", "#ff2020", "solid", "70"],
    // ARL_TOTAL: ["Área de Reserva Legal Total", "#4affe3","forward-diagonal"],

    ARL_A_RECUPERAR: ["Área de Reserva Legal a ser recuperada. Área calculada após análise pelo órgão responsável", "#ff5bf7", "diagonal-cross"],

    2008: ["Desmatamento - 2008", "#fab005", "solid", "70"],
    2009: ["Desmatamento - 2009", "#fab005", "solid", "70"],
    2010: ["Desmatamento - 2010", "#fab005", "solid", "70"],
    2011: ["Desmatamento - 2011", "#fab005", "solid", "70"],
    2012: ["Desmatamento - 2012", "#fab005", "solid", "70"],
    2013: ["Desmatamento - 2013", "#fab005", "solid", "70"],
    2014: ["Desmatamento - 2014", "#fab005", "solid", "70"],
    2015: ["Desmatamento - 2015", "#fab005", "solid", "70"],
    2016: ["Desmatamento - 2016", "#fab005", "solid", "70"],
    2017: ["Desmatamento - 2017", "#fab005", "solid", "70"],
    2018: ["Desmatamento - 2018", "#fab005", "solid", "70"],
    2019: ["Desmatamento - 2019", "#fab005", "solid", "70"],
    2020: ["Desmatamento - 2020", "#fab005", "solid", "70"],
    2021: ["Desmatamento - 2021", "#fab005", "solid", "70"],
    2022: ["Desmatamento - 2022", "#fab005", "solid", "70"],
    2023: ["Desmatamento - 2023", "#fab005", "solid", "70"],
    2024: ["Desmatamento - 2024", "#fab005", "solid", "70"],
    2025: ["Desmatamento - 2025", "#fab005", "solid", "70"]
  }
}
function SvgShape(props) {
  let svgSize = props.size || "60"
  return (
    <Center>
      <Tooltip withinPortal color={"rgba(0,0,0,0)"} label={
        <Card
          m={0}
          p={0}
          withBorder shadow={"sm"}>
          <svg xmlns="http://www.w3.org/2000/svg" height="300" width="300"
            viewBox={props.pathA && props.pathA[0]}>
            <path
              d={props.pathA && props.pathA[1]}
              fill="green"
              fillOpacity="0.5" />
            <path
              d={props.pathB && props.pathB[0]}
              style={{ fill: "red", strokeWidth: "1%", stroke: "red", strokeLinejoin: "round" }}
              fillOpacity="0.3" />
          </svg>
        </Card>}>
        <svg xmlns="http://www.w3.org/2000/svg" height={svgSize} width={svgSize}
          viewBox={props.pathA && props.pathA[0]}>
          <path
            d={props.pathA && props.pathA[1]}
            fill="green"
            fillOpacity="0.5" />
          <path
            d={props.pathB && props.pathB[0]}
            style={{ fill: "red", strokeWidth: "1%", stroke: "red", strokeLinejoin: "round" }}
            fillOpacity="0.3" />
        </svg>
      </Tooltip>
    </Center>
  )
}
function BadgeAreas(props) {
  let car = properties(props.data, 'car', props.equiv).length || 0
  let sigef = properties(props.data, 'sigef', props.equiv).length || 0
  let snci = properties(props.data, 'snci', props.equiv).length || 0
  return (
    props.data &&
    <>
      <Badge
        size={"xs"}
        // m={2}
        // mx={0}
        variant={"light"}
        color={"yellow.9"}
        sx={{ textTransform: "none" }}
        radius="md"> CAR: {car}
      </Badge>
      <Badge
        size={"xs"}
        m={2}
        variant={"light"}
        color={"cyan"}
        sx={{ textTransform: "none" }}
        radius="md"> SIGEF: {sigef}
      </Badge>
      <Badge
        size={"xs"}
        m={2}
        variant={"light"}
        color={"blue"}
        sx={{ textTransform: "none" }}
        radius="md"> SNCI: {snci}
      </Badge>
    </>
  )
}
function TextList(props) {
  let limit = props.limit || 1
  if (!props.text) return "";
  return (
    <>
      {props.text.filter((a, i) => i < limit)
        .map((t, i) =>
          props.link ? <Text fontSize={12}>
            <a
              href={props.link[i]}
              target={props.onClick ? undefined : "_blank"}
              title={props.linkTooltip}
              rel="noopener noreferrer"
              onClick={props.onClick && props.onClick[i]}
            >
              {t}
            </a>
          </Text> :
            <Text fontSize={12}>  {t}    </Text>
        )}

      {props.text.length - 1 === limit && <Text fontSize={12}>{props.text[limit]}</Text>}
      {props.text.length > limit && props.text.length - 1 > limit &&
        <Tooltip
          withArrow style={{ fontSize: 8 }}
          label={
            <List fw={300} size="xs" fontSize={8} style={{ color: "white" }}>
              {props.text.filter((a, i) => i >= limit).map((t) => {
                return <List.Item>{t}</List.Item>
              })}
            </List>
          }
          withinPortal>
          <Badge size={"xs"}>
            <Text fontSize={8}>+{props.text.length - limit}</Text>
          </Badge>
        </Tooltip>}
    </>
  )
}
function TableProperty(props) {
  const { addTab } = useTabs();

  return (<>
    {
      props.data?.length > 0 && <>
        <Card withBorder mx={0} my={6} p={"xs"} style={{ width: "1640px", backgroundColor: "transparent" }}>
          <Text size={14} mb={8} fw={700}>{props.title}</Text>
          {props.data && <Table
            horizontalSpacing={"xs"}
            verticalSpacing={4}
            fontSize={10}
            styles={{ table: { backgroundColor: "transparent" } }}>
            <thead>
              <tr style={{ backgroundColor: props.headerColor }}>
                <th style={{ width: 5 }}>#</th>
                <th style={{ width: 150 }}>Identificação</th>
                <th style={{ width: 150 }}>Nome</th>
                {props.data.filter((d) => d.nome || d.cib_nome).length > 0 && <th style={{ width: 150 }}>Nome</th>}
                <th style={{ width: 70, textAlign: "right" }}>Área (ha)</th>
                <th style={{ width: 240 }}>Proprietários/Posseiros/Concessionários</th>
                {props.data.filter((d) => d.Tipo).length > 0 && <th style={{ width: 80 }}>Tipo</th>}
                {props.data.filter((d) => d.status).length > 0 && <th style={{ width: 50, textAlign: "center" }}>Status</th>}
                {props.data.filter((d) => d.des_condic).length > 0 && <th style={{ width: 120 }}>Condição</th>}
                {props.data.filter((d) => d.sigef?.filter((s) => s.parcela).length > 0).length > 0 && <th style={{ width: 30, textAlign: "center" }}>Qtd. Parcelas</th>}
                {props.main && <th style={{ width: 80, textAlign: "center" }}>Sobreposições</th>}
                <th style={{ width: 80, textAlign: "center" }}>Ocorrências</th>
                {!props.main && <th style={{ width: 60, textAlign: "center" }}>% Sobrep. Área</th>}
                {!props.main && <th style={{ width: 40, textAlign: "center" }}>Sobreposição</th>}
              </tr>
            </thead>
            <tbody>
              {props.data
                .sort((a, b) => (a.sobrep_contem >= 0.9 && a.sobrep_contem >= 0.9) ? -1 : 1)
                .map((e, idx) => (
                  <tr style={{ background: (e.sobrep_contem >= 0.9 && e.sobrep_contido >= 0.9) ? "rgb(255,252,229)" : "" }} key={e.id}>
                    <td>{idx + 1}</td>

                    <td><a href="#" onClick={() => {
                      addTab({
                        component: "Map",
                        id: `Consulta/${e.cod}`,
                        label: `Consulta/${e.cod}`,
                        props: {
                          query: e.cod
                        }
                      })
                    }}>
                      <Badge
                        size={"xs"}
                        m={2}
                        variant={"light"}
                        color={sourceColor(e.origem?.toUpperCase())}
                        sx={{ textTransform: "none" }}
                        radius="md"> {e.cod}
                      </Badge>
                    </a>
                      {e.cib && e.cib.map((s) =>
                        <Badge
                          size={"xs"}
                          m={2}
                          variant={"light"}
                          color={sourceColor("CIB")}
                          sx={{ textTransform: "none" }}
                          radius="md"> CIB: {s.cod}
                        </Badge>)}
                      {e.parcela && <Badge
                        size={"xs"}
                        m={1}
                        variant={"light"}
                        color={"cyan.9"}
                        sx={{ textTransform: "none", fontSize: 10 }}
                        radius="md"> Parcela </Badge>}
                      {e.sigef && e.sigef.map((s) =>
                        (s.sobrep_contem >= 0.9 && s.sobrep_contem >= 0.9 && s.cod != e.cod) && <Badge
                          size={"xs"}
                          m={2}
                          variant={"light"}
                          color={sourceColor("SIGEF")}
                          sx={{ textTransform: "none" }}
                          radius="md"> SIGEF: {s.cod}
                        </Badge>)}
                      {e.snci && e.snci.map((s) =>
                        (s.sobrep_contem >= 0.9 && s.sobrep_contem >= 0.9 && s.cod != e.cod) && <Badge
                          size={"xs"}
                          m={2}
                          variant={"light"}
                          color={sourceColor("SNCI")}
                          sx={{ textTransform: "none" }}
                          radius="md"> SNCI: {s.cod}
                        </Badge>)}
                      {e.car && e.car.map((s) =>
                        (s.sobrep_contem >= 0.9 && s.sobrep_contem >= 0.9 && s.cod != e.cod && s.status === e.status) && <Badge
                          size={"xs"}
                          m={2}
                          variant={"light"}
                          color={sourceColor("CAR")}
                          sx={{ textTransform: "none" }}
                          radius="md"> CAR: {s.cod}
                        </Badge>)}
                    </td>

                    <td style={{}}>{e["title"]}</td>

                    {props.data.filter((d) => d.nome || d.cib_nome).length > 0 &&
                      <td>{(e.cib_nome || e.nome) && <TextList text={e.cib_nome || [e.nome]} />}</td>}
                    <td style={{ textAlign: "right" }}>{e["Área"]}</td>
                    <td style={{}}>{<TextList
                      limit={3}
                      text={e.owners}
                    />}</td>

                    {e.Tipo && <td style={
                      {
                        ...(e.Tipo !== "Imóvel Rural" ? { color: "red", fontWeight: 700 } : {}), textAlign: "center"
                      }
                    }>{e.Tipo}</td>}
                    {e.status && <td style={
                      {
                        ...((e.status === "Cancelado" || e.status === "Suspenso") ? { color: "red", fontWeight: 700 } : ((e.status === "Ativo") ? {
                          color: "green",
                          fontWeight: 700
                        } : {})), textAlign: "center"
                      }
                    }>{e.status}</td>}
                    {e.des_condic && <td>{e.des_condic}</td>}
                    {e.sigef?.filter((s) => s.parcela).length > 0 && <td style={{ textAlign: "center" }}>{e.sigef?.filter((s) => s.parcela).length}</td>}
                    {/*{props.main && <td style={{textAlign: "center"}}><BadgeAreas equiv={true} data={e}/></td>}*/}
                    {props.main && <td style={{ textAlign: "center" }}><BadgeAreas data={e} /></td>}
                    <td>
                      <BadgeCustom label={"Embargo Ibama"} color={"red.9"} area={e.ibama_embargos?.total_area} totalArea={e.area} />
                      <BadgeCustom label={"Embargo ICMBIO"} color={"red.6"} area={e.icmbio_embargos?.total_area} totalArea={e.area} />
                      <BadgeCustom label={"Desmatamento"} color={"cyan.9"} area={e.desmatamento?.total_area} totalArea={e.area} />
                      <BadgeCustom label={"Terra Indígena"} color={"yellow.9"} area={e.indigena?.total_area} totalArea={e.area} />
                      <BadgeCustom label={"Terra Quilombola"} color={"indigo.6"} area={e.quilombola?.total_area} totalArea={e.area} />
                      <BadgeCustom label={"Unidade de Conservação"} color={"green.9"} area={e.unidade_conservacao?.total_area} totalArea={e.area} />
                      {/*<BadgeCustom label={"Assentamento"} color={"gray.8"} area={e.sobreposicao_assentamento?.total_area} totalArea={e.area}/>*/}
                      <BadgeCustom label={"Assentamentos Prox."} color={"gray.8"} area={e.assentamento?.data?.filter((a) => a.dist <= 5000)?.length}
                        unit={"# "} totalArea={e.assentamento?.min_dist} />
                      {e.florestas_publicas && e.florestas_publicas?.data?.map((f) => <BadgeCustom label={"Floresta Pública " + f.tipo}
                        color={f.tipo.toUpperCase().includes("B") ? "lime.9" : "lime.9"}
                        area={f.area} totalArea={e.area} />)}
                    </td>
                    {!props.main && <td style={{ textAlign: "center" }}>
                      <Badge
                        size={"xs"}
                        m={2}
                        variant={"light"}
                        color={e.sobrep_contem >= 0.9 ? "green.8" : "gray"}
                        sx={{ textTransform: "none" }}
                        radius="md"> Contém: {formatNumber(e.sobrep_contem * 100)}%
                      </Badge>
                      <Badge
                        size={"xs"}
                        m={2}
                        variant={"light"}
                        color={e.sobrep_contido >= 0.9 ? "green.8" : "gray"}
                        sx={{ textTransform: "none" }}
                        radius="md"> Contido: {formatNumber(e.sobrep_contido * 100)}%
                      </Badge>
                    </td>}
                    {!props.main && <td>
                      <SvgShape pathA={props.path} pathB={e.path} size={60} />
                    </td>}
                  </tr>
                ))}
            </tbody>
            <tfoot>
              <tr>
                <td style={{ height: "80px" }}>

                </td>
              </tr>
            </tfoot>
          </Table>}
        </Card>
      </>
    }
  </>
  )
}
function TableCustom(props) {
  const { addTab } = useTabs();

  return (<>
    {
      props.data?.length > 0 && <Grid>
        <Card withBorder m={"xs"} style={{ width: "1640px", backgroundColor: "transparent" }}>
          <Text size={14} mb={8} fw={700}>{props.title}</Text>
          {props.data && <Table
            horizontalSpacing={"xs"}
            verticalSpacing={4}
            fontSize={10}
            styles={{ table: { backgroundColor: "transparent" } }}>
            <thead>
              <tr style={{ backgroundColor: props.headerColor }}>
                <th style={{ width: 5 }}>#</th>
                <th style={{ width: 40 }}>Origem</th>
                <th style={{ width: 90 }}>Cód. INCRA</th>
                <th style={{ width: 90 }}>Cód. CIB</th>
                <th style={{ width: 30, textAlign: "center" }}>Qtd. Parcelas</th>
                <th style={!props.bcb ? { width: 150 } : {}}>Nome</th>
                <th style={{ width: 70, textAlign: "right" }}>Área (ha)</th>
                <th style={{ width: 35 }}>UF</th>
                <th style={{ width: 120 }}>Município</th>
                {!props.bcb && <th style={{ width: 350 }}>Sobreposição CAR</th>}
                {!props.bcb && <th style={{ width: 150 }}>Sobreposições</th>}
                {props.bcb && <th style={{ width: 350 }}>Sobreposição e Status (Item 2)<br />CAR</th>}
                {props.bcb && <th style={{ width: 100, textAlign: "center" }}>Item 3 <br /> Unidade de Conservação</th>}
                {props.bcb && <th style={{ width: 100, textAlign: "center" }}>Item 5 <br /> Terra Indígena</th>}
                {props.bcb && <th style={{ width: 100, textAlign: "center" }}>Item 6 <br /> Terra Quilombola</th>}
                {props.bcb && <th style={{ width: 100, textAlign: "center" }}>Item 8 <br /> Embarbos Ibama/Icmbio</th>}
                {props.bcb && <th style={{ width: 100, textAlign: "center" }}>Item 10 <br /> Floresta Pública Tipo B</th>}
              </tr>
            </thead>
            <tbody>
              {props.data
                .map((e, idx) => (
                  <tr key={e.id}>
                    <td>{idx + 1}</td>
                    <td>{e.origem}</td>
                    <td><a href="#" onClick={() => {
                      addTab({
                        component: "Map",
                        id: `Consulta/${e.codigo_imo}`,
                        label: `Consulta/${e.codigo_imo}`,
                        props: {
                          query: e.codigo_imo
                        }
                      })
                    }}>{e.codigo_imo}</a></td>
                    <td><TextList text={e.cafir_cib} linkTooltip={"Consultar Certidão Conjunta"} link={e.cafir_cib?.map((c) => {
                      return "https://solucoes.receita.fazenda.gov.br/Servicos/certidaointernet/ITR/EmitirPGFN?Ni=" + c
                    })} /></td>
                    <td style={{ textAlign: "center" }}>{e.qtd_parcelas}</td>
                    <td><TextList text={e.nome} /></td>
                    <td style={{ textAlign: "right" }}>{formatNumber(e.area / 10000)}</td>
                    <td>{e.uf}</td>
                    <td><TextList limit={2} text={e.municipio} /></td>
                    <td><TextList limit={5} link={e.sobreposicao_car?.map(val => "#")} onClick={e.sobreposicao_car?.map(c => () => {
                      addTab({
                        component: "Map",
                        id: `Consulta/${c.car}`,
                        label: `Consulta/${c.car}`,
                        props: {
                          query: c.car
                        }
                      })
                    })} text={e.sobreposicao_car?.map((c) => {
                      return c.car + " (" + formatNumber(c.sobrep_car * 100) + "%) [" + c.status + "]"
                    })} /></td>

                    {!props.bcb && <td>
                      <BadgeCustom label={"Embargo Ibama"} color={"red.9"} area={e.sobreposicao_ibama_embargos?.total_area} totalArea={e.area} />
                      <BadgeCustom label={"Embargo ICMBIO"} color={"red.6"} area={e.sobreposicao_icmbio_embargos?.total_area} totalArea={e.area} />
                      <BadgeCustom label={"Desmatamento"} color={"cyan.9"} area={e.sobreposicao_desmatamento?.total_area} totalArea={e.area} />
                      <BadgeCustom label={"Terra Indígena"} color={"yellow.9"} area={e.sobreposicao_indigena?.total_area} totalArea={e.area} />
                      <BadgeCustom label={"Terra Quilombola"} color={"indigo.6"} area={e.sobreposicao_quilombola?.total_area} totalArea={e.area} />
                      <BadgeCustom label={"Unidade de Conservação"} color={"green.9"} area={e.sobreposicao_unidade_conservacao?.total_area} totalArea={e.area} />
                      {/*<BadgeCustom label={"Assentamento"} color={"gray.8"} area={e.sobreposicao_assentamento?.total_area} totalArea={e.area}/>*/}
                      <BadgeCustom label={"Assentamentos Prox."} color={"gray.8"} area={e.proximidade_assentamento?.data?.filter((a) => a.dist <= 5000)?.length}
                        unit={"# "} totalArea={e.proximidade_assentamento?.min_dist} />
                      {e.sobreposicao_florestas_publicas && e.sobreposicao_florestas_publicas?.data?.map((f) => <BadgeCustom
                        label={"Floresta Pública " + f.tipo}
                        color={f.tipo.toUpperCase().includes("B") ? "lime.9" : "lime.9"}
                        area={f.area} totalArea={e.area} />)}
                    </td>}
                    {props.bcb &&
                      <td style={{ textAlign: "center" }}><BadgeCustom color={"green.9"} area={e.sobreposicao_unidade_conservacao?.total_area}
                        totalArea={e.area} />
                      </td>}
                    {props.bcb &&
                      <td style={{ textAlign: "center" }}><BadgeCustom color={"yellow.9"} area={e.sobreposicao_indigena?.total_area} totalArea={e.area} /></td>}
                    {props.bcb &&
                      <td style={{ textAlign: "center" }}><BadgeCustom color={"indigo.6"} area={e.sobreposicao_quilombola?.total_area} totalArea={e.area} /></td>}
                    {props.bcb && <td style={{ textAlign: "center" }}>
                      <BadgeCustom color={"red.9"} area={e.sobreposicao_ibama_embargos?.total_area} totalArea={e.area} />
                      <BadgeCustom color={"red.6"} area={e.sobreposicao_icmbio_embargos?.total_area} totalArea={e.area} />
                    </td>}
                    {props.bcb && <td style={{ textAlign: "center" }}>{e.sobreposicao_florestas_publicas && e.sobreposicao_florestas_publicas?.data?.map((f) =>
                      <BadgeCustom label={""}
                        color={f.tipo.toUpperCase().includes("B") ? "lime.9" : "lime.9"}
                        area={f.area}
                        totalArea={e.area} />)}
                    </td>}
                  </tr>
                ))}
            </tbody>
          </Table>}
        </Card>
      </Grid>
    }
  </>
  )
}


function maskName(fullName) {
  return fullName
  if (!fullName) return '';
  const names = fullName.split(' ');
  const maskedNames = names.map((name, index) => {
    if (index === 0 || index === names.length - 1) {
      return name.charAt(0) + name.slice(1).replace(/./g, '*');
    }
    return name.replace(/./g, '*');
  });
  return maskedNames.join(' ');
}

function maskNameOrDocument(input) {
  return input
  if (!input) return '';

  // Máscara para CPF
  if (input.length === 11) {
    return input.replace(/(\d{3})(\d{6})(\d{2})/, '$1******$3');
  }

  // Máscara para CNPJ
  if (input.length === 14) {
    return input.replace(/(\d{2})(\d{9})(\d{3})/, '$1*********$3');
  }

  // Máscara para nomes
  const names = input.split(' ');
  const maskedNames = names.map((name, index) => {
    if (index === 0 || index === names.length - 1) {
      return name.charAt(0) + name.slice(1).replace(/./g, '*');
    }
    return name.replace(/./g, '*');
  });
  return maskedNames.join(' ');
}
function CardProperty(props) {
  const { addTab } = useTabs();
  const supabase = useSupabaseClient();
  const [isLoading, setIsLoading] = useState(false);

  function normalize(text) {
    switch (text) {
      case "IRU": return "Imóvel Rural"
      case "PCT": return "Território Tradicional de Povos e Comunidades Tradicionais"
      case "AST": return "Imóvel Rural de Assentamentos da Reforma Agrária"

      case "IN": return "Inscrito"
      case "RE": return "Retificado"
      case "AT": return "Ativo"
      case "PE": return "Pendente"
      case "CA": return "Cancelado"

      case "OFF": return "CAR - Módulo de Cadastro"
      case "FED": return "Sistema Federal"
      case "EST": return "Sistema Estadual"
      case "MUN": return "Sistema Municipal"

      default:
        return text
    }

  }

  let car = properties(props.data, 'car', true)
  let sigef = properties(props.data, 'sigef', true)
  let snci = properties(props.data, 'snci', true)
  let parcela = properties(props.data, 'sigef', false, true)
  let carDocumentosPropriedade = props.car?.jsonImovel?.documentos?.filter(f => f.detalheDocumentoPropriedade).map((m) => {
    return { ...m, ...m.detalheDocumentoPropriedade }
  });
  let carDocumentosPosse = props.car?.jsonImovel?.documentos?.filter(f => f.detalheDocumentoPosse).map((m) => {
    return { ...m, ...m.detalheDocumentoPosse }
  });
  let carCadastrante = props.car?.jsonImovel?.cadastrante;
  let carImovel = props.car != null && { ...props.car, ...props.car?.jsonImovel?.imovel };
  let carProprietarios = props.car?.props;
  /// remove duplicates from carProprietarios
  carProprietarios = carProprietarios?.filter((p, i, self) => self.findIndex((t) => t.cod_cpf_cnpj === p.cod_cpf_cnpj) === i);
  let carInformacoes = props.car?.jsonImovel?.informacoes;
  let all = [...car, ...sigef, ...snci].sort((a, b) => a.principal ? -1 : 1)
  let carField = [
    { label: 'Código', value: 'codigoImovel' },
    { label: 'Status', value: 'statusImovel' },

    { label: 'Tipo', value: 'tipo' },
    { label: 'Código do Projeto de Assentamento', value: 'codigoProjetoAssentamento' },
    { label: 'Nome do Território Tradicional', value: 'nomeTerritorio' },
    { label: 'Data Criação Assentamento', value: 'dataCriacaoAssentamento' },

    { label: 'Nome', value: 'nome' },
    { label: 'CPF', value: 'cpf' },
    { label: 'CPF/CNPJ', value: 'cpfCnpj' },
    { label: 'Data de Nascimento', value: 'dataNascimento' },
    // { label: 'Nome da Mãe', value: 'nomeMae'},

    { label: 'Detalhe Condição', value: 'condicaoAnalise' },
    { label: 'Data Protocolo', value: 'dataProtocolo' },
    { label: 'Data Registro', value: 'dataRegistro' },
    { label: 'Módulos Fiscais', value: 'modulosFiscais' },

    { label: 'Folha', value: 'folha' },
    { label: 'Livro', value: 'livro' },
    { label: 'Matricula', value: 'numeroMatricula' },
    { label: 'Municipio', value: 'municipioCartorio' },

    { label: 'CEP', value: 'cep' },
    { label: 'Email', value: 'email' },
    { label: 'Telefone', value: 'telefone' },

    { label: 'Descrição Acesso', value: 'descricaoAcesso' },
  ]
  let fields = [
    { label: 'Nome', value: 'nome' },
    { label: 'Código', value: 'cod' },
    { label: 'Parcela', value: 'cod_parcela' },
    { label: 'ART', value: 'art' },
    { label: 'Status', value: 'status' },
    { label: 'Tipo', value: 'tipo' },
    { label: 'Situação', value: 'situacao' },
    { label: 'Condição', value: 'des_condic' },
    { label: 'Matrícula', value: 'mat_reg' },
    { label: 'Data Aprovação', value: 'data_aprov' },
    { label: 'Data Submissão', value: 'data_sub' },
    { label: 'Núm. Certificação', value: 'num_cert' },
    { label: 'Data Certificação', value: 'data_cert' },
    { label: 'Núm. Processo', value: 'num_proces' },
    { label: 'Sobrep. Contém', value: 'sobrep_contem', perc: true },
    { label: 'Sobrep. Contido', value: 'sobrep_contido', perc: true },
  ]
  let basic = [
    { label: 'Área', value: 'area_texto' },
    { label: 'Localização', value: 'cidade', map: true },
    { label: 'Região', value: 'regiao' },
    { label: 'Matopiba', value: 'matopiba', perc: true },
    { label: 'Amazonia Legal', value: 'amazonia_legal', perc: true },
    { label: 'Bioma', value: 'bioma', map: true },
  ]
  let restriction = [
    { label: 'Embargos', value: 'embargo', icon: IconAlertTriangle, color: "#ff0000" },
    { label: 'Terra Indígena', value: 'indigena', icon: IconBow, color: "#FF9740" },
    { label: 'Terra Quilombola', value: 'quilombola', icon: IconSquareFilled, color: "#70380c" },
    { label: 'Floresta Pública Tipo B', value: 'floresta', icon: IconTrees, color: "#40bf8a" },
    { label: 'Unidade de Conservação', value: 'ucs', icon: IconLock, color: "#00ce03" }
  ]
  return (
    <Grid>
      {all
        .filter((c) => c.principal)
        .map((c) => {
          return (
            <Grid.Col span={(c.principal && props.car) ? 12 : 4}>
              <Card style={{ ...{ height: "100%" }, ...(c.principal ? { border: "2px solid" + "#044585" } : {}) }} withBorder p={"sm"}>
                <Group spacing={2} position={"apart"} noWrap style={{ height: "100%" }} >
                  <div style={{ height: "100%", alignContent: "space-between" }}>
                    <Box pb={"sm"}>
                      <Group spacing={2} noWrap position={"apart"}  >
                        <Badge color={sourceColor(c.origem)}>Informações {c.origem} - Área {c.principal ? "Consultada" : "Equivalente"}</Badge>
                        {c.origem === "CAR" &&
                          <div>
                            <Text fw={600} size={"xs"}>
                              <Button
                                loading={isLoading}
                                onClick={async () => {
                                  setIsLoading(true);
                                  try {
                                    const { data, error } = await supabase.rpc("f_car_recibo", {
                                      _car: c.cod,
                                    });

                                    if (error) {
                                      notifications.show({
                                        withCloseButton: true,
                                        autoClose: 3000,
                                        message: error.message,
                                        color: "red",
                                        icon: <IconX />,
                                        loading: false,
                                      });
                                    } else {
                                      function downloadURI(uri, name) {
                                        var link = document.createElement("a");
                                        link.download = name;
                                        link.href = uri;
                                        document.body.appendChild(link);
                                        link.click();
                                        document.body.removeChild(link);
                                      }

                                      downloadURI(data, "PDF - CAR RECIBO");
                                    }
                                  }
                                  finally {
                                    setIsLoading(false);
                                  }
                                }}
                                size={"xs"}
                                compact>
                                Gerar Recibo de Inscrição
                              </Button>
                            </Text>
                          </div>}
                      </Group>
                    </Box>
                    <div>
                      <Flex wrap="wrap" gap="xs">
                        {(!c.principal || (!props.car && c.principal)) && fields.map((f) => {
                          return (
                            c[f.value] && <div>
                              <Text size="xs" color="dimmed">
                                {f.label}
                              </Text>
                              <Text fw={600} size={"xs"}>
                                {f.perc ? formatNumber(c[f.value] * 100, 2) + "%" : c[f.value]}
                              </Text>
                            </div>
                          )
                        })}
                        {c.principal && props.car && carImovel && <Card maw={1000} p={"xs"} pt={2} withBorder>
                          <Text size={14} fw={600} >Imóvel</Text>
                          <Group>
                            {carField.map((f) => {
                              return (
                                carImovel && carImovel[f.value] && <div>
                                  <Text size="xs" color="dimmed">
                                    {f.label}
                                  </Text>
                                  <Text fw={600} size={"xs"}>
                                    {normalize(carImovel[f.value])}
                                  </Text>
                                </div>
                              )
                            })}
                          </Group>
                        </Card>}

                        {c.principal && props.car && carProprietarios && <Card w={600} style={{ heigth: "100%" }} p={"xs"} pt={2} withBorder>
                          <Text size={14} fw={600} >Proprietários / Posseiros / Concessionários - Total: {formatNumber(carProprietarios.length, 0)}</Text>
                          <Group>
                            {carProprietarios &&
                              <div>
                                <ScrollArea.Autosize mah={220} mx="auto" w={578}>
                                  <Table verticalSpacing={2} horizontalSpacing={4} fontSize="10px">
                                    <thead>
                                      <tr >
                                        <th rowSpan={2} style={{ backgroundColor: "#eaeaea", position: "sticky", top: 0, }}>Nome</th>
                                        <th rowSpan={2} style={{ backgroundColor: "#eaeaea", position: "sticky", textAlign: "center", top: 0, zIndex: 999 }}>CPF/CNPJ</th>
                                        {carProprietarios.filter((f) => f.dat_nascimento).length > 0 &&
                                          <th rowSpan={2} style={{ backgroundColor: "#eaeaea", position: "sticky", textAlign: "center", top: 0, }}>Data Nasc.</th>}
                                        <th colSpan={2} style={{ backgroundColor: "#eaeaea", position: "sticky", textAlign: "center", top: 0, }}>Outros Imóveis</th>
                                      </tr>
                                      <tr>
                                        <th style={{ backgroundColor: "#eaeaea", position: "sticky", textAlign: "center", top: 20, }}>Qtd.</th>
                                        <th style={{ backgroundColor: "#eaeaea", position: "sticky", textAlign: "center", top: 20, }}>Área</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {carProprietarios.map((m) => (
                                        <tr key={m.nom_completo}>
                                          <td>{maskName(m.nom_completo)}</td>
                                          <td style={{ textAlign: "center" }}>
                                            <Group spacing={6} noWrap>
                                              {maskNameOrDocument(m.cod_cpf_cnpj)}
                                              <ActionIcon
                                                p={0}
                                                m={0}
                                                size={"xs"}
                                                onClick={() => {
                                                  addTab({
                                                    component: "Map",
                                                    id: `Consulta/${m.cod_cpf_cnpj}`,
                                                    label: `Consulta/${m.cod_cpf_cnpj}`,
                                                    props: {
                                                      query: m.cod_cpf_cnpj
                                                    }
                                                  })
                                                }}
                                                variant="default"
                                              >
                                                <IconSearch size="0.7rem" stroke={1.5} />
                                              </ActionIcon>
                                              <ActionIcon
                                                p={0}
                                                m={0}
                                                size={"xs"}
                                                onClick={() => {
                                                  addTab({
                                                    label: `Relatório/${m.cod_cpf_cnpj}`,
                                                    id: `ESG/Report/${m.cod_cpf_cnpj}`,
                                                    component: "PdfPrinter",
                                                    subcomponent: "EsgReport",
                                                    disableRefresh: true,
                                                    props: {
                                                      id: m.cod_cpf_cnpj,
                                                      name: m.cod_cpf_cnpj,
                                                      input: m.cod_cpf_cnpj,
                                                      inputList: [{ codType: "doc", cod: m.cod_cpf_cnpj }],
                                                      inputType: "doc",
                                                    }
                                                  })
                                                }}
                                                variant="default"
                                              >
                                                <IconReportAnalytics size="0.7rem" stroke={1.5} />
                                              </ActionIcon>
                                            </Group>
                                          </td>
                                          {carProprietarios.filter((f) => f.dat_nascimento).length > 0 && <td>{maskNameOrDocument(m.dat_nascimento)}</td>}
                                          <td style={{ textAlign: "center" }}>{(m.qtd && formatNumber(m.qtd, 0)) || "-"}</td>
                                          <td style={{ textAlign: "center" }}>{m.area_texto || "-"}</td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </Table>
                                </ScrollArea.Autosize>
                              </div>}
                          </Group>
                        </Card>}

                        {c.principal && props.car && carCadastrante && <Card style={{ heigth: "100%" }} p={"xs"} pt={2} withBorder>
                          <Text size={14} fw={600} >Cadastrante</Text>
                          <Group>
                            {carField.map((f) => {
                              return (
                                carCadastrante && carCadastrante[f.value] && <div>
                                  <Text size="xs" color="dimmed">
                                    {(f.label)}
                                  </Text>
                                  <Text fw={600} size={"xs"}>
                                    {f.perc ? formatNumber(carCadastrante[f.value] * 100, 2) + "%" : maskNameOrDocument(carCadastrante[f.value])}
                                  </Text>
                                </div>
                              )
                            })}
                          </Group>
                        </Card>}

                        {c.principal && props.car && carDocumentosPropriedade?.length > 0 && <Card style={{ heigth: "100%" }} p={"xs"} pt={2} withBorder>
                          <Text size={14} fw={600} >Documento de Propriedade</Text>
                          <Group>
                            {carDocumentosPropriedade &&
                              <div>
                                <ScrollArea.Autosize mah={186} mx="auto">
                                  <Table verticalSpacing={2} horizontalSpacing={6} fontSize="10px">
                                    <thead>
                                      <tr>
                                        {carDocumentosPropriedade.filter((f) => f.ccir).length > 0 && <th style={{ backgroundColor: "#eaeaea", position: "sticky", top: 0 }}>CCIR</th>}
                                        {carDocumentosPropriedade.filter((f) => f.certificacaoIncra).length > 0 && <th style={{ backgroundColor: "#eaeaea", position: "sticky", top: 0 }}>Parcela</th>}
                                        {carDocumentosPropriedade.filter((f) => f.nirf).length > 0 && <th style={{ backgroundColor: "#eaeaea", position: "sticky", top: 0 }}>NIRF/CAFIR</th>}
                                        {carDocumentosPropriedade.filter((f) => f.denominacao).length > 0 && <th style={{ backgroundColor: "#eaeaea", position: "sticky", top: 0 }}>Nome</th>}
                                        {carDocumentosPropriedade.filter((f) => f.area).length > 0 && <th style={{ backgroundColor: "#eaeaea", position: "sticky", top: 0 }}>Área</th>}
                                        {carDocumentosPropriedade.filter((f) => f.dataRegistro).length > 0 && <th style={{ backgroundColor: "#eaeaea", position: "sticky", top: 0 }}>Data Registro</th>}
                                        {carDocumentosPropriedade.filter((f) => f.tipoDocumentoPropriedade).length > 0 && <th style={{ backgroundColor: "#eaeaea", position: "sticky", top: 0 }}>Tipo Documento</th>}
                                        <th style={{ backgroundColor: "#eaeaea", position: "sticky", top: 0 }}>Folha</th>
                                        <th style={{ backgroundColor: "#eaeaea", position: "sticky", top: 0 }}>Livro</th>
                                        <th style={{ backgroundColor: "#eaeaea", position: "sticky", top: 0 }}>Núm. Matricula</th>
                                        <th style={{ backgroundColor: "#eaeaea", position: "sticky", top: 0 }}>Município</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {carDocumentosPropriedade?.map((m) => (
                                        <tr key={m?.folha + m?.livro + m?.numeroMatricula}>
                                          {carDocumentosPropriedade.filter((f) => f.ccir).length > 0 && <td>{m?.ccir}</td>}
                                          {carDocumentosPropriedade.filter((f) => f.certificacaoIncra).length > 0 && <td>{m?.certificacaoIncra}</td>}
                                          {carDocumentosPropriedade.filter((f) => f.nirf).length > 0 && <td>{m?.nirf}</td>}
                                          {carDocumentosPropriedade.filter((f) => f.denominacao).length > 0 && <td>{m?.denominacao}</td>}
                                          {carDocumentosPropriedade.filter((f) => f.area).length > 0 && <td>{formatNumber(m?.area)}</td>}
                                          {carDocumentosPropriedade.filter((f) => f.dataRegistro).length > 0 && <td>{m?.dataRegistro}</td>}
                                          {carDocumentosPropriedade.filter((f) => f.tipoDocumentoPropriedade).length > 0 && <td>{format(m?.tipoDocumentoPropriedade)}</td>}
                                          <td>{m?.folha}</td>
                                          <td>{m?.livro}</td>
                                          <td>{m?.numeroMatricula}</td>
                                          <td>{props.car?.municipio?.nome}</td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </Table>
                                </ScrollArea.Autosize>
                              </div>}
                          </Group>
                        </Card>}

                        {c.principal && props.car && carDocumentosPosse?.length > 0 && <Card style={{ heigth: "100%" }} p={"xs"} pt={2} withBorder>
                          <Text size={14} fw={600} >Documentos de Posse</Text>
                          <Group>
                            {carDocumentosPosse &&
                              <div>
                                <ScrollArea.Autosize mah={186} mx="auto">
                                  <Table verticalSpacing={2} horizontalSpacing={4} fontSize="10px">
                                    <thead>
                                      <tr>
                                        {carDocumentosPosse.filter((f) => f.denominacao).length > 0 && <th style={{ backgroundColor: "#eaeaea", position: "sticky", top: 0 }}>Nome</th>}
                                        {carDocumentosPosse.filter((f) => f.area).length > 0 && <th style={{ backgroundColor: "#eaeaea", position: "sticky", top: 0 }}>Área</th>}
                                        <th style={{ backgroundColor: "#eaeaea", position: "sticky", top: 0 }}>Auto Declaração</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {carDocumentosPosse?.map((m, i) => (
                                        <tr key={i}>
                                          {carDocumentosPosse.filter((f) => f.denominacao).length > 0 && <td>{m?.denominacao}</td>}
                                          {carDocumentosPosse.filter((f) => f.area).length > 0 && <td>{formatNumber(m?.area)}</td>}
                                          <td>{m?.autodeclaracao}</td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </Table>
                                </ScrollArea.Autosize>
                              </div>}
                          </Group>
                        </Card>}

                      </Flex>
                    </div>
                  </div>
                </Group>
              </Card>
            </Grid.Col>)
        })}
      <Grid.Col span={4}>
        <Card style={{ height: "100%" }} withBorder p={"sm"}>
          <Group spacing={2} position={"apart"} noWrap style={{ height: "100%" }} >
            <div spacing={2} style={{ height: "100%", alignContent: "space-between" }}>
              <Box pb={"sm"}>
                <Badge color={"gray.9"}>Informações Básicas da Área</Badge>
              </Box>
              <div>
                <Group>
                  {basic.map((f) => {
                    return (
                      props.data[f.value] && <div>
                        <Text size="xs" color="dimmed">
                          {f.label}
                        </Text>
                        <Text fw={600} size={"xs"}>
                          {f.perc ? formatNumber(props.data[f.value] * 100, 2) + "%" :
                            (f.map ? props.data[f.value]?.data?.map((m) => m.nome_area + " (" + formatNumber(m.sobrep * 100, 1) + "%)").join(", ") :
                              props.data[f.value])}
                        </Text>
                      </div>
                    )
                  })}
                  {parcela.length > 1 && <div>
                    <Text size="xs" color="dimmed">
                      Qtd. Parcelas SIGEF
                    </Text>
                    <Text fw={600} size={"xs"}>
                      {parcela.length}
                    </Text>
                  </div>}
                  <div>
                    <Text size="xs" color="dimmed">
                      Sobreposições
                    </Text>
                    <Text fw={600} size={"xs"}>
                      <BadgeAreas data={props.data} />
                    </Text>
                  </div>
                </Group>
              </div>
            </div>
            <Group align={"center"} style={{ height: "100%" }}>
              {parcela.length > 1 &&
                <Tooltip withinPortal color={"rgba(0,0,0,0)"} label={
                  <Card
                    m={0}
                    p={0}
                    withBorder shadow={"sm"}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="300" width="300"
                      viewBox={props.data.path && props.data.path[0]}>
                      <path
                        d={props.data.path && props.data.path[1]}
                        fill="green"
                        fillOpacity="0.5" />

                      {parcela.map((p) =>
                        <path
                          d={p.path && p.path[0]}
                          style={{ fill: "red", strokeWidth: "1%", stroke: "red", strokeLinejoin: "round" }}
                          fillOpacity="0.3" />
                      )}

                    </svg>
                  </Card>}>
                  <svg xmlns="http://www.w3.org/2000/svg" height="120" width="120"
                    viewBox={props.data.path && props.data.path[0]}>
                    <path
                      d={props.data.path && props.data.path[1]}
                      fill="green"
                      fillOpacity="0.5" />
                    {parcela.map((p) =>
                      <path
                        d={p.path && p.path[0]}
                        style={{ fill: "red", strokeWidth: "1%", stroke: "red", strokeLinejoin: "round" }}
                        fillOpacity="0.3" />
                    )}
                  </svg>
                </Tooltip>}
            </Group>
          </Group>
        </Card>
      </Grid.Col>
      {/*<Grid.Col span={4}>*/}
      {/*  <Card style={{ height: "100%" }} withBorder p={"sm"}>*/}
      {/*    <Box pb={"sm"}>*/}
      {/*      <Badge color={"red.9"}>Sobreposições Restritivas - Conforme Manual de Crédito Rural do BCB</Badge>*/}
      {/*    </Box>*/}
      {/*    <Group spacing={2} style={{ height: "100%", alignContent: "space-between" }}>*/}
      {/*      <Group>*/}
      {/*        {restriction.map((f) => {*/}
      {/*          return (*/}
      {/*            <Card p={"xs"} withBorder style={{ textAlign: "center", minWidth: "158px" }}>*/}
      {/*              <Group spacing={2} position={"apart"} style={{ height: "100%", alignContent: "space-between", justifyContent: "center" }}>*/}
      {/*                <div style={{ justifyContent: "center" }}>*/}
      {/*                  <ThemeIcon*/}
      {/*                    size={30}*/}
      {/*                    color={props.data[f.value]?.data?.filter((a) => (f.value !== 'floresta' || a.tipo === 'Tipo B')).length > 0 ? f.color : "#ccc"}>*/}
      {/*                    <f.icon*/}
      {/*                      size={20}*/}
      {/*                      color={"white"}*/}
      {/*                      stroke={1.5} />*/}
      {/*                  </ThemeIcon>*/}
      {/*                  <Text size="xs" color="dimmed">{f.label}</Text>*/}
      {/*                  {props.data[f.value]?.data?.filter((a) => (f.value !== 'floresta' || a.tipo === 'Tipo B')).length > 0 ? <Text fw={600} size={"xs"}>*/}
      {/*                    {formatNumber((props.data[f.value]?.data?.filter((a) => (f.value !== 'floresta' || a.tipo === 'Tipo B')).reduce((acc, val) => acc + val.area, 0) / 10000))} ha*/}
      {/*                    ({formatNumber((props.data[f.value]?.data?.filter((a) => (f.value !== 'floresta' || a.tipo === 'Tipo B')).reduce((acc, val) => acc + val.area, 0) / props.data?.area) * 100)}%)*/}
      {/*                  </Text> : <Text fw={600} size={"xs"}>-</Text>}*/}
      {/*                </div>*/}
      {/*              </Group>*/}
      {/*            </Card>*/}
      {/*          )*/}
      {/*        })}*/}
      {/*      </Group>*/}
      {/*    </Group>*/}
      {/*  </Card>*/}
      {/*</Grid.Col>*/}
    </Grid>
  )
}

function RestrictionCard({ data, restrictionsData }) {

  let totalArea = data?.total_area / 10000;

  function areaCheck(data) {
    return data?.total_area > 100
  }

  function areaFormatter(data) {
    return (formatNumber(data?.total_area / 10000) + " ha") + " (" + (formatNumber(data?.total_sobrep * 100, 1) + "%)")
  }

  function qtdCheck(data) {
    return data?.qtd_listed > 0
  }

  function qtdFormatter(data) {
    return data?.qtd_listed + " / " + data?.qtd_total
  }

  function proportionalCheck(data, criteria) {
    let overlapArea = data?.total_area / 10000;

    let overlapPercentage = (overlapArea / totalArea) * 100;

    if (totalArea < 100 && (overlapPercentage > 10 || criteria)) {
      return "Área < 100 ha: Sobrep. < 10%";
    } else if (totalArea >= 100 && totalArea < 500 && (overlapPercentage > 8 || criteria)) {
      return "Área de 100 a 499 ha: Sobrep. < 8%";
    } else if (totalArea >= 500 && totalArea < 1000 && (overlapPercentage > 6 || criteria)) {
      return "Área de 500 a 999 ha: Sobrep. < 6%";
    } else if (totalArea >= 1000 && totalArea < 3000 && (overlapPercentage > 4 || criteria)) {
      return "Área de 1.000 a 2.999 ha: Sobrep. < 4%";
    } else if (totalArea >= 3000 && (overlapPercentage > 2 || criteria)) {
      return "Área ≥ 3.000 ha: Sobrep. < 2%";
    }
    return false;
  }

  function thresholdCheck(data, threshold, criteria) {
    let area = data?.total_area / 10000;

    if ((area > threshold) || criteria) {
      return "Área < " + formatNumber(threshold, 2) + " ha";
    }
    return false;

  }

  function indigenaCheck(data, criteria) {
    let filtered = data?.data?.filter(d => ['Declarada', 'Homologada', 'Regularizada', 'Interditada'].includes(d.fase));
    if (filtered) {
      filtered.total_area = filtered?.reduce((acc, val) => acc + val.area, 0)
      filtered.total_sobrep = (filtered?.total_area / 10000) / totalArea;
    }
    if (proportionalCheck(data) && !proportionalCheck(filtered) && criteria) {
      return "Em Estudo ou Delimitada"
    }
    return proportionalCheck(filtered, criteria)
  };

  let restriction = [
    {
      color: "#f8af05",
      value: 'desmatamento',
      icon: IconLeafOff,
      label: 'Desmatamento',
      check: areaCheck,
      formatter: areaFormatter
    },
    {
      color: "#f8af05", value: 'embargo', icon: IconAlertTriangle, label: 'Embargos Ibama (Área)',
      check: areaCheck,
      formatter: areaFormatter
    },
    {
      color: "#f8af05", value: 'embargo_doc', icon: IconAlertTriangle, label: 'Embargos Ibama (CPF/CNPJ)',
      check: qtdCheck,
      formatter: qtdFormatter
    },
    {
      color: "#ff0000", value: 'escravo', icon: IconLinkOff, label: 'Trabalho Escravo',
      check: qtdCheck,
      formatter: qtdFormatter
    },
    {
      color: "#ff0000", value: 'status_car', icon: IconInfoSquare, label: 'Status CAR',
      check: qtdCheck,
      formatter: qtdFormatter
    },
    {
      color: "#f8af05", value: 'ucs', icon: IconLock, label: 'Unidade de Conservação',
      check: areaCheck,
      formatter: areaFormatter
    },
    {
      color: "#ff0000", value: 'indigena', icon: IconBow, label: 'Terra Indígena',
      check: areaCheck,
      formatter: areaFormatter,
    },
    {
      color: "#ff0000", value: 'quilombola', icon: IconSquareFilled, label: 'Terra Quilombola',
      check: areaCheck,
      formatter: areaFormatter
    },
    {
      color: "#f8af05", value: 'floresta', icon: IconTrees, label: 'Floresta Pública Tipo B',
      check: (data) => {
        let tipoB = data?.data?.filter((d) => d.tipo === 'Tipo B')
        if (tipoB) {
          tipoB.total_area = tipoB?.reduce((acc, val) => acc + val.area, 0)
          tipoB.total_sobrep = (tipoB?.total_area / 10000) / totalArea;
        }
        return areaCheck(tipoB)
      },
      formatter: (data) => {
        let tipoB = data?.data?.filter((d) => d.tipo === 'Tipo B')
        if (tipoB) {
          tipoB.total_area = tipoB?.reduce((acc, val) => acc + val.area, 0)
          tipoB.total_sobrep = (tipoB?.total_area / 10000) / totalArea;
        }
        return areaFormatter(tipoB)
      }
    },
    {
      color: "#f8af05", value: 'alteracao_car', icon: IconResize, label: 'Alteração Limites CAR',
      check: (data) => {
        return data.filter((d) => d.imovel.changed).length > 1
      },
      formatter: (data) => {
        let qtd = data.filter((d) => d.imovel.changed).length - 1
        return qtd + (qtd === 1 ? " alteração" : " alterações")
      },
    }
  ]

  let commitments = {
    cerrado: {
      label: 'Protocolo de Monitoramento de Fornecedores de Gado no Cerrado',
      restrictions: ['status_car', 'desmatamento', 'indigena', 'quilombola', 'ucs', 'embargo', 'embargo_doc', 'alteracao_car', 'escravo'],
      filter: { bioma: ["Cerrado"] },
      check: {
        desmatamento: (data) => {
          let area = data?.data?.filter((d) => d.year > 2020);
          if (area) {
            area.total_area = area?.reduce((acc, val) => acc + val.area, 0)
            area.total_sobrep = (area?.total_area / 10000) / totalArea;
          }
          return thresholdCheck(area, 6.25, false)
        },
        ucs: proportionalCheck,
        indigena: (data) => indigenaCheck(data, false),
        quilombola: proportionalCheck,
      },
      formatter: {
        desmatamento: (data) => {
          let area = data.data?.filter((d) => d.year > 2020);
          if (area) {
            area.total_area = area?.reduce((acc, val) => acc + val.area, 0)
            area.total_sobrep = (area?.total_area / 10000) / totalArea;
          }
          return thresholdCheck(area, 6.25, true)
        },
        ucs: proportionalCheck,
        indigena: (data) => indigenaCheck(data, true),
        quilombola: proportionalCheck,
      }
    },
    amazonia: {
      label: 'Protocolo de Monitoramento de Fornecedores de Gado da Amazônia',
      restrictions: ['status_car', 'desmatamento', 'indigena', 'ucs', 'embargo', 'embargo_doc', 'alteracao_car', 'escravo'],
      filter: { bioma: ["Amazônia"] },
      check: {
        desmatamento: (data) => thresholdCheck(data, 6.25, false),
        ucs: proportionalCheck,
        indigena: (data) => indigenaCheck(data, false)
      },
      formatter: {
        desmatamento: (data) => thresholdCheck(data, 6.25, true),
        ucs: proportionalCheck,
        indigena: (data) => indigenaCheck(data, true)
      }
    },
    bcb: {
      label: 'Manual de Crédito Rural do BCB',
      restrictions: ['status_car', 'indigena', 'quilombola', 'ucs', 'floresta', 'embargo', 'embargo_doc', 'escravo'],
      check: {
        indigena: (data) => indigenaCheck(data, false)
      },
      formatter: {
        indigena: (data) => indigenaCheck(data, true)
      }
    },
    // anbima: {
    //   label: 'ANBIMA (?)',
    //   restrictions: ['status_car', 'indigena', 'quilombola', 'ucs', 'floresta', 'embargo', 'embargo_doc', 'escravo'],
    //   check: {
    //     indigena: (data) => indigenaCheck(data, false)
    //   },
    //   formatter: {
    //     indigena: (data) => indigenaCheck(data, true)
    //   }
    // },
    cpr_bndes: {
      label: 'CPR BNDES',
      restrictions: ['status_car', 'desmatamento', 'indigena', 'quilombola', 'ucs', 'floresta', 'embargo', 'embargo_doc', 'escravo'],
      check: {
        indigena: (data) => indigenaCheck(data, false)
      },
      formatter: {
        indigena: (data) => indigenaCheck(data, true)
      }
    },
    tac_carne: {
      label: 'TAC da Carne',
      filter: { bioma: ["Amazônia"] },
      restrictions: ['desmatamento', 'indigena', 'ucs', 'embargo', 'embargo_doc', 'escravo']
    },
    prot_graos: {
      label: 'Protocolo Verde de Grãos',
      filter: { uf: ["PA"] },
      restrictions: ['status_car', 'desmatamento', 'embargo', 'embargo_doc', 'escravo']
    },
    mo_soja: {
      label: 'Moratória da Soja',
      filter: { bioma: ["Amazônia"] },
      restrictions: ['desmatamento'],
      check: {
        desmatamento: (data) => thresholdCheck(data, 25, false),
      },
      formatter: {
        desmatamento: (data) => thresholdCheck(data, 25, true),
      }
    },
  }

  commitments = Object.fromEntries(Object.entries(commitments).filter(([key, value]) => {
    if (value.filter) {
      let filter = value.filter;
      return Object.entries(filter).every(([key, value]) => {
        return data[key] && data[key]?.some((v) => value.includes(v.name));
      })
    }
    return true
  }));

  restriction = restriction.filter((r) => {
    //filtrar para apenas deixas as restricoes que existem nos compromissos
    return Object.keys(commitments).some((key) => commitments[key].restrictions.includes(r.value))
  })

  return (
    <>
      <Grid gutter="xs" columns={24}>
        <Grid.Col span={4}>
          <Card p={"lg"} withBorder style={{ height: "110px", position: 'relative', overflow: 'hidden' }}>
            <div style={{
              content: '',
              position: 'absolute',
              left: 0,
              top: 0,
              width: '100%',
              height: '100%',
              background: 'linear-gradient(to bottom left, transparent calc(50% - 1px), #ccc, transparent calc(50% + 1px))'
            }}></div>
            <Text size={12} fw={700} p={"md"} style={{ position: 'absolute', right: 0, top: 0 }}>Critérios</Text>
            <Text size={12} fw={700} p={"md"} style={{ position: 'absolute', left: 0, bottom: 0 }}>Compromissos<br />Sócioambientais</Text>

          </Card>

        </Grid.Col>
        {restriction.map((f) => {
          let hasRestriction = f.check && f.check(restrictionsData[f.value])
          return (
            <Grid.Col span={"auto"}>
              <Card p={8} withBorder style={{ textAlign: "center", height: "110px" }}>
                <Stack spacing={2} justify={"space-between"} style={{ height: "100%" }}>
                  <div style={{ justifyContent: "center" }}>
                    <ThemeIcon
                      size={30}
                      color={hasRestriction ? "#f8af05" : "#58a11d"}>
                      <f.icon
                        size={20}
                        color={"white"}
                        stroke={1.5} />
                    </ThemeIcon>
                    <Text pt={4} size="xs" fw={hasRestriction ? 700 : 400} color={hasRestriction ? "#ff0000" : ""}>{f.label}</Text>
                  </div>
                  {hasRestriction && (
                    <Text fw={600} size={"xs"}>
                      {f.formatter ? f.formatter(restrictionsData[f.value]) :
                        (formatNumber((data[f.value]?.data?.filter((a) => (f.value !== 'floresta' || a.tipo === 'Tipo B')).reduce((acc, val) => acc + val.area, 0) / 10000)) + "ha" + <br /> +
                          (formatNumber((data[f.value]?.data?.filter((a) => (f.value !== 'floresta' || a.tipo === 'Tipo B')).reduce((acc, val) => acc + val.area, 0) / data?.area) * 100, 1) + "%"))}
                    </Text>
                  )}
                </Stack>
              </Card>
            </Grid.Col>
          )
        })}
      </Grid>

      {Object.entries(commitments).map(([type, commitment]) => (
        <>
          <Grid gutter="xs" columns={24}>
            <Grid.Col span={4}>
              <Card py={2} withBorder style={{ height: "64px", alignContent: "center" }}>
                <Text size={12} fw={700}>{commitment.label}</Text>
              </Card>
            </Grid.Col>
            {restriction.map(restrictionKey => {
              let hasRestriction = restrictionKey.check && restrictionKey.check(restrictionsData[restrictionKey.value]);
              let restrictedItem = (commitment.check && commitment.check[restrictionKey.value] && commitment.check[restrictionKey.value](restrictionsData[restrictionKey.value])) ||
                ((!commitment.check || !commitment.check[restrictionKey.value]) && hasRestriction);
              let isInCommitmentRestrictions = commitment.restrictions.includes(restrictionKey.value);
              return (
                <Grid.Col span={"auto"}>
                  <Card p={"xs"} withBorder style={{ textAlign: "center", height: "64px" }}>
                    <Stack spacing={2} position={"apart"} style={{ height: "100%", alignContent: "center", justifyContent: "center" }}>
                      <Center>
                        {isInCommitmentRestrictions ?
                          <Tooltip withinPortal label={restrictedItem ? "Restrição Identificada" : "Restrição Não Identificada"}>
                            {restrictedItem ?
                              <IconAlertCircle size={28} p={"xs"} color={"#ff0000"} stroke={1.5} /> :
                              <IconCircleCheck size={28} p={"xs"} color={"#58a11d"} stroke={1.5} />}
                          </Tooltip> :
                          <Tooltip withinPortal label="Não Aplicável">
                            <IconCircleOff size={24} p={"xs"} color={"#ccc"} stroke={1.5} />
                          </Tooltip>
                        }
                      </Center>
                      {(hasRestriction && !restrictedItem) && <Text fw={600} size={10} style={{ lineHeight: 1 }}>
                        {commitment.formatter && commitment.formatter[restrictionKey.value] && commitment.formatter[restrictionKey.value](restrictionsData[restrictionKey.value], true)}
                      </Text>}
                    </Stack>
                  </Card>
                </Grid.Col>
              );
            })}
          </Grid>
        </>
      ))}
      <Card p={0} m={0}>
        <Group p={"xs"} style={{ justifyContent: "end" }} >
          <Center>
            <IconCircleCheck size={22} p={"xs"} color={"#58a11d"} stroke={1.5} />
            <Text px={"xs"} size={"xs"}>Restrição não Identificada</Text>
          </Center>
          <Divider orientation={"vertical"}></Divider>
          <Center>
            <IconAlertCircle size={22} p={"xs"} color={"#ff0000"} stroke={1.5} />
            <Text px={"xs"} size={"xs"}>Restrição Identificada</Text>
          </Center>
          <Divider orientation={"vertical"}></Divider>
          <Center>
            <IconCircleOff size={22} p={"xs"} color={"#ccc"} stroke={1.5} />
            <Text px={"xs"} size={"xs"}>Não Aplicável</Text>
          </Center>
        </Group>
      </Card>
    </>
  )
}

export default function EsgReportPage({ isPrint, className, id, name, input, rfInfo, inputList, inputType, tabId }) {
  const [dateRef, setDateRef] = useState(new Date());
  const layerRef = useRef(null);
  const [legendState, setLegendState] = useState({})
  const [doc, setDoc] = useState(input)
  const [codType, setCodType] = useState(inputType)
  const [codList, setCodList] = useState(inputList)
  const [inputValue, setInputValue] = useState(input || "");

  const data = useNotifications();


  const { data: mptData, error: mptError, isLoading: mptIsLoading } = useSWR(id && false && {
    tableName: 'v_mpt',
    filters: [["company_id", "in", `(${id})`]],
    orders: [["id", { ascending: true }]],
  });

  const { data: colorMap } = useSWR({
    functionName: 'f_color_map'
  });

  const { data: rfData, error: rfError, isLoading: rfIsLoading } = useSWR(id && false && {
    tableName: 'esg_company',
    columns: 'rf_additional_info',
    filters: [["id", "in", `(${id})`]],
    orders: [["id", { ascending: true }]],
  });

  const { data: overlapData, error: overlapError, isLoading: overlapLoading, mutate: mutateOverlapData } = useSWR(doc && {
    functionName: "f_update_overlap",
    args: {
      _cod_list: codList.map((c) => {
        return {cod: ((c.type ===  'doc' && c.cod.length === 14) ? c.cod.substring(0,8) : c.cod), codType: c.codType || c.type}
      }),
    }
  });

  const { data: kpiData, error: kpiError, isLoading: kpiLoading } = useSWR(doc && !overlapLoading && {
    functionName: "f_report_kpi",
    args: {
      _cod_list: codList,
    }
  });

  const { data: rural, isLoading: isLoadingRural } = useSWR(doc && !overlapLoading && {
    functionName: "f_find_area",
    args: {
      _cod_list: codList,
      _scope: "property"
    }
  });

  const { data: propertyData, isLoading: isLoadingProperty } = useSWR(doc && codType !== 'doc' && !overlapLoading && {
    functionName: "f_property_info",
    args: {
      _cod_list: codType !== 'doc' ? codList : []
    }
  });

  const fetcher = async (url, options) => {
    const response = await fetch(url, { ...options, timeout: 30000 })
    return response.json()
  }

  const { data: carData, isLoading: isLoadingCar } = useSWR(
    doc && !overlapLoading && propertyData?.length > 0 && {
      functionName: "f_car",
      args: {
        _cod_list: codList
      },
    }
  );

  const { data: carTimeline, isLoading: isLoadingCarTimeline } = useSWR(doc && !overlapLoading && propertyData?.length > 0 && {
    functionName: "f_car_timeline",
    args: {
      _cod_list: codList
    }
  });

  const { data: carHistory, isLoading: isLoadingCarHistory } = useSWR(doc && carTimeline &&!overlapLoading && propertyData?.length > 0 && {
    functionName: "f_area_historica",
    args: {
      _cod_list: codList
    }
  });


  const { data: sicor, isLoading: isLoadingSicor } = useSWR(doc && {
    functionName: "f_sicor",
    args: {
      _docs: [doc]
    }
  });

  const { data: restrictionsData, isLoading: isLoadingRestrictionsData } = useSWR(doc && !overlapLoading && propertyData?.length > 0 && {
    functionName: "f_restrictions",
    args: {
      _cod_list: codList
    }
  });

  const { data: dpData, error: dpError, isLoading: dpIsLoading } = useSWR(doc && !overlapLoading && {
    functionName: "f_dados_publicos",
    args: {
      _doc: doc
    },
  });

  if (mptError || rfError) {
    debugger
    return JSON.stringify(mptError)
  };

  const loaded = !dpIsLoading && !isLoadingCar && !isLoadingProperty && !isLoadingRural && !kpiLoading && !overlapLoading && !rfIsLoading && !mptIsLoading;

  function docMask(doc) {
    return doc.length === 11 ?
      doc.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4') :
      doc.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
  }
  function InfoModal() {
    let info = rfData && rfData[0]?.rf_additional_info[0]
    if (!info) {
      return <></>
    };
    return <div style={{ width: "1640px" }}>
      <Grid >
        <Grid.Col my={0} py={0} span={8}>
          <Divider mt={2} mb={0} pb={0} pt={0} size={"md"} fw={700} label="Informações Gerais da Empresa" labelPosition="center" />
          <Grid my={0} py={0} gutter={"xs"} columns={18}>
            <Grid.Col my={0} py={0} span={2}>
              <TextInput size={"xs"} label="CNPJ" value={info.cnpj} readOnly />
            </Grid.Col>
            <Grid.Col my={0} py={0} span={7}>
              <TextInput size={"xs"} label="Razão Social" readOnly value={info.razao_social} />
            </Grid.Col>
            {info.descricao_matriz_filial && <Grid.Col my={0} py={0} span={4}>
              <TextInput size={"xs"} label="Descrição Matriz/Filial" readOnly value={info.descricao_matriz_filial} />
            </Grid.Col>}
            <Grid.Col my={0} py={0} span={5}>
              <TextInput size={"xs"} label="Nome Fantasia" readOnly value={info.nome_fantasia} />
            </Grid.Col>
            <Grid.Col my={0} py={0} span={2}>
              <TextInput size={"xs"} label="Capital Social" readOnly value={info.capital_social} />
            </Grid.Col>
            <Grid.Col my={0} py={0} span={2}>
              <TextInput size={"xs"} label="Porte" readOnly value={info.porte} />
            </Grid.Col>
            {info.descricao_porte && <Grid.Col my={0} py={0} span={4}>
              <TextInput size={"xs"} label="Descrição Porte" readOnly value={info.descricao_porte} />
            </Grid.Col>}
          </Grid>
        </Grid.Col>
        <Grid.Col my={0} py={0} span={4}>
          <Divider mt={2} mb={0} pb={0} pt={0} size={"md"} fw={700} label="Situação Cadastral" labelPosition="center" />
          <Grid my={0} py={0} gutter={"xs"} columns={8}>
            <Grid.Col my={0} py={0} span={3}>
              <TextInput size={"xs"} label="Descrição Sit. Cadastral" readOnly value={info.descricao_situacao_cadastral} />
            </Grid.Col>
            <Grid.Col my={0} py={0} span={2}>
              <DateInputISO label="Data Sit. Cadastral" readOnly value={info.data_situacao_cadastral} />
            </Grid.Col>
            <Grid.Col my={0} py={0} span={3}>
              <TextInput size={"xs"} label="Motivo Sit. Cadastral" readOnly value={info.motivo_situacao_cadastral} />
            </Grid.Col>
          </Grid>
        </Grid.Col>
      </Grid>

      <Grid>
        <Grid.Col my={0} py={0} span={6}>
          {/* Atividade Econômica */}
          <Divider mt={"xs"} mb={0} pb={0} pt={"xs"} size={"md"} fw={700} label="Atividade Econômica" labelPosition="center" />
          <Grid my={0} py={0} gutter={"xs"} columns={12}>
            <Grid.Col my={0} py={0} span={3}>
              <TextInput size={"xs"} label="Código Natureza Jurídica" readOnly value={info.codigo_natureza_juridica} />
            </Grid.Col>
            <Grid.Col my={0} py={0} span={2}>
              <DateInputISO label="Data Início Atividade" readOnly value={info.data_inicio_atividade} />
            </Grid.Col>
            <Grid.Col my={0} py={0} span={2}>
              <TextInput size={"xs"} label="CNAE Fiscal" readOnly value={info.cnae_fiscal} />
            </Grid.Col>
            <Grid.Col my={0} py={0} span={5}>
              <TextInput size={"xs"} label="Descrição CNAE Fiscal" readOnly value={info.cnae_fiscal_descricao} />
            </Grid.Col>
          </Grid>
        </Grid.Col>
        <Grid.Col my={0} py={0} span={6}>
          {/* Opções Tributárias */}
          <Divider mt={"xs"} mb={0} pb={0} pt={"xs"} size={"md"} fw={700} label="Opções Tributárias" labelPosition="center" />
          <Grid my={0} py={0} gutter={"xs"} columns={12}>
            <Grid.Col my={0} py={0} span={2}>
              <TextInput size={"xs"} label="Opção Pelo Simples" readOnly value={info.opcao_pelo_simples ? "Sim" : "Não"} />
            </Grid.Col>
            {info.data_opcao_pelo_simples && <Grid.Col my={0} py={0} span={4}>
              <DateInputISO label="Data Opção Pelo Simples" readOnly value={info.data_opcao_pelo_simples} />
            </Grid.Col>}
            {info.data_exclusao_do_simples && <Grid.Col my={0} py={0} span={4}>
              <DateInputISO label="Data Exclusão do Simples" readOnly value={info.data_exclusao_do_simples} />
            </Grid.Col>}
            <Grid.Col my={0} py={0} span={2}>
              <TextInput size={"xs"} label="Opção Pelo MEI" readOnly value={info.opcao_pelo_mei ? "Sim" : "Não"} />
            </Grid.Col>
          </Grid>
        </Grid.Col>
      </Grid>

      <Grid>
        <Grid.Col my={0} py={0} span={3}>
          {/* Contato */}
          <Divider mt={"xs"} mb={0} pb={0} pt={"xs"} size={"md"} fw={700} label="Contato" labelPosition="center" />
          <Grid my={0} py={0} gutter={"xs"} columns={6}>
            <Grid.Col my={0} py={0} span={2}>
              <TextInput size={"xs"} label="DDD Telefone 1" readOnly value={info.ddd_telefone_1} />
            </Grid.Col>
            <Grid.Col my={0} py={0} span={2}>
              <TextInput size={"xs"} label="DDD Telefone 2" readOnly value={info.ddd_telefone_2} />
            </Grid.Col>
            <Grid.Col my={0} py={0} span={2}>
              <TextInput size={"xs"} label="DDD Fax" readOnly value={info.ddd_fax} />
            </Grid.Col>
          </Grid>
        </Grid.Col>
        <Grid.Col my={0} py={0} span={9}>
          {/* Localização */}
          <Divider mt={"xs"} mb={0} pb={0} pt={"xs"} size={"md"} fw={700} label="Localização" labelPosition="center" />
          <Grid my={0} py={0} gutter={"xs"} columns={18}>
            {info.nome_cidade_exterior && <Grid.Col my={0} py={0} span={4}>
              <TextInput size={"xs"} label="Nome Cidade Exterior" readOnly value={info.nome_cidade_exterior} />
            </Grid.Col>}
            {info.descricao_tipo_logradouro && <Grid.Col my={0} py={0} span={4}>
              <TextInput size={"xs"} label="Descrição Tipo Logradouro" readOnly value={info.descricao_tipo_logradouro} />
            </Grid.Col>}
            <Grid.Col my={0} py={0} span={6}>
              <TextInput size={"xs"} label="Logradouro" readOnly value={info.logradouro} />
            </Grid.Col>
            <Grid.Col my={0} py={0} span={1}>
              <TextInput size={"xs"} label="Número" readOnly value={info.numero} />
            </Grid.Col>
            <Grid.Col my={0} py={0} span={2}>
              <TextInput size={"xs"} label="Complemento" readOnly value={info.complemento} />
            </Grid.Col>
            <Grid.Col my={0} py={0} span={3}>
              <TextInput size={"xs"} label="Bairro" readOnly value={info.bairro} />
            </Grid.Col>
            <Grid.Col my={0} py={0} span={3}>
              <TextInput size={"xs"} label="Município" readOnly value={info.municipio} />
            </Grid.Col>
            <Grid.Col my={0} py={0} span={1}>
              <TextInput size={"xs"} label="UF" readOnly value={info.uf} />
            </Grid.Col>
            <Grid.Col my={0} py={0} span={2}>
              <TextInput size={"xs"} label="CEP" readOnly value={info.cep} />
            </Grid.Col>
          </Grid>
        </Grid.Col>
      </Grid>

      {/* Quadro de Sócios e Administradores (QSA) */}
      <Divider m mt={"xs"} mb={0} pb={0} pt={"xs"} size={"md"} fw={700} label="Quadro de Sócios e Administradores (QSA)" labelPosition="center" />
      {info.qsa.map((socio, index) => (
        <Grid my={0} py={0} gutter={"xs"} columns={28} key={index} style={{ marginBottom: '10px' }}>
          <Grid.Col my={0} py={0} span={"content"}>
            <Flex
              justify="flex-end"
              align="flex-end"
              direction="column"
            >
              <Space h="xl" />
              <strong>{index + 1}:</strong>
            </Flex>
          </Grid.Col>
          <Grid.Col my={0} py={0} span={"auto"}>
            <TextInput size={"xs"} label="Nome do Sócio" readOnly value={socio.nome_socio} />
          </Grid.Col>
          <Grid.Col my={0} py={0} span={3}>
            <TextInput size={"xs"} label="CPF/CNPJ do Sócio" readOnly value={socio.cnpj_cpf_do_socio} />
          </Grid.Col>
          <Grid.Col my={0} py={0} span={3}>
            <TextInput size={"xs"} label="Qualif. do Sócio" readOnly value={socio.qualificacao_socio} />
          </Grid.Col>
          <Grid.Col my={0} py={0} span={2}>
            <DateInputISO label="Data de Entrada" readOnly value={socio.data_entrada_sociedade} />
          </Grid.Col>
          <Grid.Col my={0} py={0} span={3}>
            <TextInput size={"xs"} label="Faixa Etária do Sócio" readOnly value={socio.faixa_etaria} />
          </Grid.Col>
          <Grid.Col my={0} py={0} span={4}>
            <TextInput size={"xs"} label="Nome do Repr. Legal" readOnly value={socio.nome_representante_legal} />
          </Grid.Col>
          <Grid.Col my={0} py={0} span={3}>
            <TextInput size={"xs"} label="CPF do Repr. Legal" readOnly value={socio.cpf_representante_legal} />
          </Grid.Col>
          <Grid.Col my={0} py={0} span={3}>
            <TextInput size={"xs"} label="Qualif. do Repr. Legal" readOnly value={socio.qualificacao_representante_legal} />
          </Grid.Col>
        </Grid>
      ))}
      <Divider mt={"xs"} mb={0} pb={0} pt={"xs"} size={"md"} fw={700} label="CNAEs Secundários" labelPosition="center" />
      <Grid mt={0} mb={8} pb={8} pt={0} gutter={"xs"} columns={24}>
        {
          info.cnaes_secundarios.map(val => <>
            <Grid.Col my={0} py={0} span={2}>
              <TextInput size={"xs"} label="Código" readOnly value={val.codigo} />
            </Grid.Col>
            <Grid.Col my={0} py={0} span={10}>
              <TextInput size={"xs"} label="Descrição" readOnly value={val.descricao} />
            </Grid.Col>
          </>)
        }
      </Grid>

      {/* Situação Especial */}
      {info.situacao_especial && <>
        <Divider mt={"xs"} mb={0} pb={0} pt={"xs"} size={"md"} fw={700} label="Situação Especial" labelPosition="center" />
        <Grid my={0} py={0} gutter={"xs"} columns={24}>
          <Grid.Col my={0} py={0} span={4}>
            <TextInput size={"xs"} label="Situação Especial" readOnly value={info.situacao_especial} />
          </Grid.Col>
          <Grid.Col my={0} py={0} span={4}>
            <TextInput size={"xs"} label="Data Situação Especial" readOnly value={info.data_situacao_especial} />
          </Grid.Col>
        </Grid>
      </>}

    </div>
  }
  function imoveisRurais(ocorrencia) {
    let imoveisData = incraData;

    if (imoveisData?.length > 0) {
      if (ocorrencia) {
        imoveisData = imoveisData
          .filter((a) =>
            a.sobreposicao_ibama_embargos?.total_area > 0 ||
            a.sobreposicao_icmbio_embargos?.total_area > 0 ||
            a.sobreposicao_unidade_conservacao?.total_area > 0 ||
            a.sobreposicao_indigena?.total_area > 0 ||
            a.sobreposicao_quilombola?.total_area > 0 ||
            a.sobreposicao_florestas_publicas?.data?.length > 0 ||
            (a.sobreposicao_car?.filter((c) => c.status === 'Cancelado' || c.status === 'Suspenso')?.length > 0 &&
              a.sobreposicao_car?.filter((c) => c.status === 'Ativo')?.length === 0)
          )
      } else {
        imoveisData = imoveisData
          .filter((a) =>
            !a.sobreposicao_ibama_embargos?.data &&
            !a.sobreposicao_icmbio_embargos?.data &&
            !a.sobreposicao_unidade_conservacao?.data &&
            !a.sobreposicao_indigena?.data &&
            !a.sobreposicao_quilombola?.data &&
            (a.sobreposicao_florestas_publicas?.data?.length === 0 || !a.sobreposicao_florestas_publicas?.data) &&
            (!(a.sobreposicao_car?.filter((c) => c.status === 'Cancelado' || c.status === 'Suspenso')?.length > 0 &&
              a.sobreposicao_car?.filter((c) => c.status === 'Ativo')?.length === 0) || !a.sobreposicao_car))
      }
    }
    return imoveisData;
  }

  const handleClick = () => {
    if (inputValue) {
      let type = (inputValue.length === 11 || inputValue.length === 14) ? "doc" : "car";
      setDoc(inputValue);
      setCodType(type);
      setCodList([{ cod: inputValue.length === 14 ? inputValue.substring(0, 8) : inputValue, codType: type }]);
    }
  };

  const handleClickDoc = (doc) => {
    setInputValue(doc.TaxIdNumber);
  }

  return (
    <>
      {!doc &&
        <Card>
          <Input.Wrapper
            id={"doc"}
            label="Gerar Relatório Individual"
            description="Informe o CPF/CNPJ ou CAR para gerar o relatório individual."
            w={390}

            mx="auto">
            <Input
              placeholder="Digite o CPF/CNPJ/CAR"
              value={inputValue}
              onChange={(event) => setInputValue(event.currentTarget.value)}
              rightSectionWidth={64}
              rightSection={
                <>
                  <ActionIcon onClick={handleClick}>
                    <IconSearch />
                  </ActionIcon>
                  <Popover width={450} trapFocus position="bottom" withArrow shadow="md" withinPortal>
                    <Popover.Target>
                      <ActionIcon onClick={handleClick}>
                        <IconUserSearch />
                      </ActionIcon>
                    </Popover.Target>

                    <Popover.Dropdown>
                      <PesquisarDoc onClickDoc={handleClickDoc} />
                    </Popover.Dropdown>
                  </Popover>


                </>
              }
            />
          </Input.Wrapper>
        </Card>
      }
      {doc && <div style={{ position: "relative", width: "1640px" }} className={className}>
        <PrintHeader isPrint={isPrint} doc={doc} name={name} isTable={false} />

        {doc && (doc.length === 11 || doc.length === 14) && <BigDataComponent doc={doc} tabId={tabId} />}
        <>
          {restrictionsData && kpiData /*&& kpiData.bioma.filter((f)=> f.name ==='Amazônia').length > 0*/ &&
            <RestrictionCard data={kpiData} restrictionsData={restrictionsData} />
          }
        </>

        {rural?.layers?.length > 0 && <>
          <Divider size={16} my={"xs"} fw={700}
                   label={<Text size={16} fw={700}>Informações Básicas</Text>}/>

          <Grid m={0} gutter={"xs"} style={{width: "1640px", height: "1020px"}}>
            {kpiData && <Grid.Col pl={0} span={6}>
              <Grid columns={6} gutter={"xs"}>
                <Grid.Col span={3}>
                  <Grid columns={3} gutter={"xs"}>
                    <Grid.Col span={3}>
                      <ChartCard
                        title={"Área por Estados"}
                        data={kpiData?.uf}
                        isLoading={kpiLoading}
                        params={{}}
                        chart={chartPie}
                        iconColor={"#646464"}
                        icon={IconMapPin}
                      />
                    </Grid.Col>
                    <Grid.Col span={3}>
                      <ChartCard
                        title={"Área por Biomas"}
                        data={kpiData?.bioma}
                        isLoading={kpiLoading}
                        params={{
                          showSum: false
                        }}
                        chart={chartPie}
                        iconColor={"#8e4fd2"}
                        icon={IconPlant2}
                      />
                    </Grid.Col>
                    <Grid.Col span={3}>
                      <ChartCard
                        title={"Uso e Cobertura do Solo"}
                        data={kpiData?.uso_solo}
                        isLoading={kpiLoading}
                        params={{
                          showSum: false
                        }}
                        chart={chartPie}
                        iconColor={"#2caaaf"}
                        icon={IconGrowth}
                      />
                    </Grid.Col>
                    <Grid.Col span={3}>
                      <ChartCard
                        title={"Aptidão Agrícola"}
                        data={kpiData?.agricola}
                        params={{
                          showSum: false
                        }}
                        isLoading={kpiLoading}
                        chart={chartPie}
                        iconColor={"#bb8a3e"}
                        icon={IconTractor}
                      />
                    </Grid.Col>
                  </Grid>
                </Grid.Col>
                <Grid.Col span={3}>
                  <Grid columns={6} gutter={"xs"}>
                    <Grid.Col span={6}>
                      <ChartCard
                        title={"Qtd por Estados"}
                        data={kpiData?.uf_qtd}
                        isLoading={kpiLoading}
                        params={{
                          value: 'qtd'
                        }}
                        chart={chartPie}
                        iconColor={"#646464"}
                        icon={IconMapPin}
                      />
                    </Grid.Col>
                    <Grid.Col span={3}>
                      <InfoCard
                        total_area={kpiData?.total_area}
                        title={"Embargos Ibama e Icmbio"}
                        iconColor={"red"}
                        icon={IconAlertTriangle}
                        isLoading={kpiLoading}
                        qtd={kpiData?.embargos_qtd}
                        area={kpiData?.embargos_area}
                      />
                    </Grid.Col>
                    <Grid.Col span={3}>
                      <InfoCard
                        total_area={kpiData?.total_area}
                        title={"Desmatamento após 2008"}
                        iconColor={"yellow"}
                        icon={IconLeafOff}
                        isLoading={kpiLoading}
                        qtd={kpiData?.desmatamento_qtd}
                        area={kpiData?.desmatamento_area}
                      />
                    </Grid.Col>
                    <Grid.Col span={3}>
                      <InfoCard
                        total_area={kpiData?.total_area}
                        title={"Terras Indígenas"}
                        iconColor={"orange"}
                        icon={IconBow}
                        isLoading={kpiLoading}
                        qtd={kpiData?.indigena_qtd}
                        area={kpiData?.indigena_area}
                      />
                    </Grid.Col>
                    <Grid.Col span={3}>
                      <InfoCard
                        total_area={kpiData?.total_area}
                        title={"Terras Quilombola"}
                        iconColor={"#c0a590"}
                        icon={IconSquareFilled}
                        isLoading={kpiLoading}
                        qtd={kpiData?.quilombola_qtd}
                        area={kpiData?.quilombola_area}
                      />
                    </Grid.Col>
                    <Grid.Col span={3}>
                      <InfoCard
                        total_area={kpiData?.total_area}
                        title={"Unidades de Conservação"}
                        iconColor={"#005901"}
                        icon={IconLock}
                        isLoading={kpiLoading}
                        qtd={kpiData?.uc_qtd}
                        area={kpiData?.uc_area}
                      />
                    </Grid.Col>
                    <Grid.Col span={3}>
                      <InfoCard
                        title={"Assentamentos Próximos (1km)"}
                        iconColor={"#ba70c9"}
                        icon={IconUsersGroup}
                        unit={"Qtd."}
                        isLoading={kpiLoading}
                        qtd={kpiData?.assentamento_prox?.qtd}
                        area={kpiData?.assentamento_prox?.assent_qtd}
                      />
                    </Grid.Col>
                    <Grid.Col span={3}>
                      <InfoCard
                        total_area={kpiData?.total_area}
                        title={"Florestas Públicas Tipo B"}
                        iconColor={"green"}
                        icon={IconTrees}
                        isLoading={kpiLoading}
                        qtd={kpiData?.floresta_b_qtd}
                        area={kpiData?.floresta_b_area}
                      />
                      {/*<ChartCard*/}
                      {/*  title={"Florestas Públicas"}*/}
                      {/*  data={kpiData?.floresta}*/}
                      {/*  params={{}}*/}
                      {/*  isLoading={kpiLoading}*/}
                      {/*  chart={chartPie}*/}
                      {/*  iconColor={"green"}*/}
                      {/*  icon={IconTrees}*/}
                      {/*/>*/}
                    </Grid.Col>
                    <Grid.Col span={3}>
                      <InfoCard
                        total_area={kpiData?.total_area}
                        title={"Sobreposição com Terras Públicas"}
                        iconColor={"cyan"}
                        icon={IconComponents}
                        isLoading={kpiLoading}
                        qtd={kpiData?.publica_qtd}
                        area={kpiData?.publica_area}
                      />
                    </Grid.Col>

                  </Grid>
                </Grid.Col>
              </Grid>
            </Grid.Col>}
            <Grid.Col pr={0} span={6}>
              {rural?.extent && rural?.layers &&
                <MapLeaflet
                  tabId={tabId}
                  cluster={codType !== "doc"}
                  legend
                  uf
                  extent={rural.extent}
                  layers={rural.layers}
                  minimap
                >
                </MapLeaflet>}
            </Grid.Col>
          </Grid>
        </>}

        {(dpData?.cr?.data_emissao|| dpData?.aida || dpData?.app) && <>
          <div className="page-breaker">&nbsp;</div>
          <Divider size={16} my={"xs"} fw={700}
            label={<Text size={16} fw={700}>[IBAMA] Dados Públicos</Text>} />
          <Card py={10} my={8} withBorder style={{ width: "1640px" }}>
            <Grid columns={6} gutter={"xs"}>
              <Grid.Col span={6}>
                <Grid>
                  <Grid.Col span={12}>
                    <Divider mt={2} mb={0} pb={0} pt={0} size={"md"} fw={700} label="[IBAMA] Certificado de Regularidade" labelPosition="center" />
                    <Center><Text size={10} style={{ textAlign: "center", textJustify: "center" }}>Cadastros Técnicos Federal - Consulta Pública a Certificado de
                      Regularidade - CR</Text></Center>
                    <Group noWrap spacing="xs" align={"end"} position={"apart"}>
                      <TextInput w={180} size={"xs"} label="Número Registro" readOnly value={dpData?.cr?.num_registro} />
                      <DateInputISO w={180} size={"xs"} label="Data de Emissão" readOnly value={dpData?.cr?.data_emissao} />
                      <DateInputISO w={180} size={"xs"} label="Data de Validade" readOnly value={dpData?.cr?.data_validade} />
                      <DateInputISO w={180} size={"xs"} label="Data da Consulta" readOnly value={dpData?.cr?.data_consulta} />
                      <Button
                        w={35}
                        p={0}
                        size={"xs"}
                        component="a"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={"https://servicos.ibama.gov.br/ctf/publico/certificado_regularidade_consulta.php?cpfcnpj=" + doc}
                      >
                        <IconSearch />
                      </Button>
                    </Group>

                  </Grid.Col>
                </Grid>

                <Divider mt={2} mb={0} pb={2} pt={10} size={"md"} fw={700}
                  label="[IBAMA] Cadastro Técnico Federal de Atividades e Instrumentos de Defesa Ambiental– CTF/AIDA" labelPosition="center" />
                {!dpData?.aida && <Center><Text color={"#aaa"} size={14} p={10}>Sem dados disponíveis</Text></Center>}
                {dpData?.aida && <Table
                  horizontalSpacing={4}
                  verticalSpacing={4}
                  fontSize={10}
                  styles={{ table: { backgroundColor: "transparent" } }}>
                  <thead>
                    <tr style={{ backgroundColor: "#dddd" }}>
                      <th style={{ width: 10 }}>#</th>
                      <th style={{ width: 120 }}>Categoria</th>
                      <th style={{ width: 260 }}>Atividade</th>
                      <th style={{ width: 60 }}>UF</th>
                      <th style={{ width: 10, textAlign: "center" }}>Situação Cadastral</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dpData?.aida
                      .map((e, idx) => (
                        <tr key={e.id}>
                          <td>{idx + 1}</td>
                          <td>{e.categoria}</td>
                          <td><TextList limit={5} text={e.atividade} /></td>
                          <td>{e.uf.join(", ")}</td>
                          <td style={{ textAlign: "center" }}>{e.situacao_cadastral}</td>
                        </tr>
                      ))}
                  </tbody>
                </Table>}
                <Divider mt={2} mb={0} pb={2} pt={10} size={"md"} fw={700}
                  label="[IBAMA] Cadastro Técnico Federal de Atividades Potencialmente Poluidoras e Utilizadoras de Recursos Naturais – CTF/APP"
                  labelPosition="center" />

                {!dpData?.app && <Center><Text color={"#aaa"} size={14} p={10}>Sem dados disponíveis</Text></Center>}
                {dpData?.app && <Table
                  horizontalSpacing={4}
                  verticalSpacing={4}
                  fontSize={10}
                  styles={{ table: { backgroundColor: "transparent" } }}>
                  <thead>
                    <tr style={{ backgroundColor: "#dddd" }}>
                      <th style={{ width: 10 }}>#</th>
                      <th style={{ width: 120 }}>Categoria</th>
                      <th style={{ width: 260 }}>Atividade</th>
                      <th style={{ width: 60 }}>UF</th>
                      <th style={{ width: 10, textAlign: "center" }}>Situação Cadastral</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dpData?.app
                      .map((e, idx) => (
                        <tr key={e.id}>
                          <td>{idx + 1}</td>
                          <td>{e.categoria}</td>
                          <td><TextList limit={2} text={e.atividade} /></td>
                          <td>{e.uf.join(", ")}</td>
                          <td style={{ textAlign: "center" }}>{e.situacao_cadastral}</td>
                        </tr>
                      ))}
                  </tbody>
                </Table>}
              </Grid.Col>
              {/*  <Grid.Col span={6}>
            <Grid columns={6} gutter={"xs"}>
              <Grid.Col span={6}>
                <ChartCard
                  isLoading={dpIsLoading}
                  title={"Emissão de Poluentes | Quantidade por Estados"}
                  data={dpData?.emissoes_pa_estado}
                  chart={chartBar}
                  iconColor={"#131313"}
                  icon={IconBuildingFactory}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <ChartCard
                  isLoading={dpIsLoading}
                  title={"Emissão de Poluentes | Quantidade por Tipo"}
                  data={dpData?.emissoes_pa_tipo}
                  chart={chartBar}
                  iconColor={"#131313"}
                  icon={IconBuildingFactory}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <ChartCard
                  isLoading={dpIsLoading}
                  title={"Silvicutura | Área Cortada por Estados (ha)"}
                  data={dpData?.silvicultura_estado}
                  chart={chartBar}
                  iconColor={"#7c3b17"}
                  icon={IconWood}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <ChartCard
                  isLoading={dpIsLoading}
                  title={"Silvicutura | Área Cortada por Espécie (ha)"}
                  data={dpData?.silvicultura_tipo}
                  chart={chartBar}
                  iconColor={"#7c3b17"}
                  icon={IconWood}
                />
              </Grid.Col>
            </Grid>
          </Grid.Col>*/}
            </Grid>
          </Card>
        </>}

        <div className="content">
          {propertyData && propertyData.map((p) =>
            <>
              <div className="page-breaker">&nbsp;</div>

              <Divider size={16} my={"xs"} fw={700}
                label={<Text size={16} fw={700}>[{p.codType?.toUpperCase()}] {p.cod} - Área Consultada e
                  Equivalentes</Text>} />

              {isLoadingCar && <Skeleton visible={isLoadingCar} height={300}>
              </Skeleton>}

              {carData && <CardProperty data={p} car={carData}></CardProperty>}

              {(properties(p, 'car')?.length > 0 || properties(p, 'sigef')?.length > 0 || properties(p, 'snci')?.length > 0) && <>
                <Divider size={16} my={"xs"} fw={700}
                  label={<Text size={16} fw={700}>[{p.codType?.toUpperCase()}] {p.cod} - Áreas
                    Soprepostas</Text>} />
                <TableProperty
                  colorMap={colorMap}
                  data={properties(p, 'car')}
                  path={p?.path}
                  title={"CAR - Sobreposição"}
                  headerColor={"#ffecab"} />
                <TableProperty
                  colorMap={colorMap}
                  data={properties(p, 'sigef')}
                  path={p?.path}
                  title={"SIGEF - Sobreposição"}
                  headerColor={"#afebff"} />
                <TableProperty
                  colorMap={colorMap}
                  path={p?.path}
                  data={properties(p, 'snci')}
                  title={"SNCI - Sobreposição"}
                  headerColor={"#d6e4ff"} />
              </>}

              {p.embargo?.data && <>
                <Divider size={16} my={"xs"} fw={700}
                  label={<Text size={16} fw={700}>[IBAMA e ICMBIO] Termos de Embargo</Text>} />
                <Table
                  horizontalSpacing={4}
                  verticalSpacing={4}
                  fontSize={10}
                  styles={{ table: { backgroundColor: "transparent" } }}>
                  <thead>
                    <tr style={{ backgroundColor: "#dddd" }}>
                      <th style={{ width: 10 }}>#</th>
                      <th style={{ width: 20 }}>Origem</th>
                      <th style={{ width: 20 }}>Tipo</th>
                      <th style={{ width: 60 }}>Num. Auto Infração</th>
                      <th style={{ width: 40 }}>Valor Multa (R$)</th>
                      <th style={{ width: 50 }}>Data</th>
                      <th style={{ width: 80 }}>Processo</th>
                      <th style={{ width: 300 }}>Descrição</th>
                      {p.embargo?.data.filter((f) => f.descricao_adicional).length > 0 &&
                        <th style={{ width: 300 }}>Descrição Adicional</th>}
                      <th style={{ width: 40, textAlign: "center" }}>Área Embargada Total (ha)</th>
                      <th style={{ width: 80, textAlign: "center" }}>Área Embargada Sobreposição</th>
                      <th style={{ width: 80 }}>Sobreposição</th>
                    </tr>
                  </thead>
                  <tbody>
                    {p.embargo?.data?.map((e, idx) => (
                      <tr key={e.id}>
                        <td>{idx + 1}</td>
                        <td>{e.origem}</td>
                        <td>{e.tipo}</td>
                        <td>{e.num_auto_infracao}</td>
                        <td>{e.valor ? formatNumber(e.valor) : ""}</td>
                        <td>{e.data}</td>
                        <td>{e.processo}</td>
                        <td>{e.descricao}</td>
                        {p.embargo?.data.filter((f) => f.descricao_adicional).length > 0 &&
                          <td>{e.descricao_adicional}</td>}
                        <td
                          style={{ textAlign: "center" }}>{(e.tipo === 'Embargo' && !e.area_embargada_total) ? "Não Disponível" : (e.tipo !== 'Embargo' ? "" : formatNumber(e.area_embargada_total / 10000))}</td>
                        <td style={{ textAlign: "center" }}>{formatNumber(e.area / 10000)} ha
                          ({formatNumber(e.sobrep * 100)}%)
                        </td>
                        <td><SvgShape pathA={p.path} pathB={e.path} size={60} /></td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </>}
              {p.quilombola?.data && <>
                <Divider size={16} my={"xs"} fw={700}
                  label={<Text size={16} fw={700}>Terras Quilombola - Sobreposição</Text>} />
                <Table
                  horizontalSpacing={4}
                  verticalSpacing={4}
                  fontSize={10}
                  styles={{ table: { backgroundColor: "transparent" } }}>
                  <thead>
                    <tr style={{ backgroundColor: "#dddd" }}>
                      <th style={{ width: 10 }}>#</th>
                      <th style={{ width: 20 }}>Nome</th>
                      <th style={{ width: 20 }}>Nr. Familias</th>
                      <th style={{ width: 60 }}>Esfera</th>
                      <th style={{ width: 40 }}>Fase</th>
                      <th style={{ width: 50 }}>Responsável</th>
                      {p.quilombola?.data.filter((f) => f.dt_titulacao).length > 0 &&
                        <th style={{ width: 80 }}>Data Titulação</th>}
                      {p.quilombola?.data.filter((f) => f.dt_decreto).length > 0 &&
                        <th style={{ width: 80 }}>Data Decreto</th>}
                      <th style={{ width: 80, textAlign: "center" }}>Área Total (ha)</th>
                      <th style={{ width: 80, textAlign: "center" }}>Área Sobreposta</th>
                      <th style={{ width: 80, textAlign: "center" }}>Sobreposição</th>
                    </tr>
                  </thead>
                  <tbody>
                    {p.quilombola?.data?.map((e, idx) => (
                      <tr key={e.id}>
                        <td>{idx + 1}</td>
                        <td>{e.nome_area}</td>
                        <td>{e.nr_familia}</td>
                        <td>{e.esfera}</td>
                        <td>{e.fase}</td>
                        <td>{e.responsavel}</td>
                        {p.quilombola?.data.filter((f) => f.dt_titulacao).length > 0 && <td>{e.dt_titulacao}</td>}
                        {p.quilombola?.data.filter((f) => f.dt_decreto).length > 0 && <td>{e.dt_decreto}</td>}
                        <td style={{ textAlign: "center" }}>{e.area_total ? formatNumber(e.area_total / 10000) : ""}</td>
                        <td style={{ textAlign: "center" }}>{formatNumber(e.area / 10000)} ha
                          ({formatNumber(e.sobrep * 100)}%)
                        </td>
                        <td><SvgShape pathA={p.path} pathB={e.path} size={60} /></td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </>}
              {p.indigena?.data && <>
                <Divider size={16} my={"xs"} fw={700}
                  label={<Text size={16} fw={700}>Terras Indigena - Sobreposição</Text>} />
                <Table
                  horizontalSpacing={4}
                  verticalSpacing={4}
                  fontSize={10}
                  styles={{ table: { backgroundColor: "transparent" } }}>
                  <thead>
                    <tr style={{ backgroundColor: "#dddd" }}>
                      <th style={{ width: 10 }}>#</th>
                      <th style={{ width: 20 }}>Nome</th>
                      <th style={{ width: 20 }}>Etnia</th>
                      <th style={{ width: 40 }}>Fase</th>
                      <th style={{ width: 40 }}>Modalidade</th>
                      <th style={{ width: 50 }}>Responsável</th>
                      <th style={{ width: 80, textAlign: "center" }}>Área Total (ha)</th>
                      <th style={{ width: 80, textAlign: "center" }}>Área Sobreposta</th>
                      <th style={{ width: 80, textAlign: "center" }}>Sobreposição</th>
                    </tr>
                  </thead>
                  <tbody>
                    {p.indigena?.data?.map((e, idx) => (
                      <tr key={e.id}>
                        <td>{idx + 1}</td>
                        <td>{e.nome_area}</td>
                        <td>{e.etnia}</td>
                        <td>{e.fase}</td>
                        <td>{e.modalidade}</td>
                        <td>{e.adm}</td>
                        <td style={{ textAlign: "center" }}>{e.area_total ? formatNumber(e.area_total / 10000) : ""}</td>
                        <td style={{ textAlign: "center" }}>{formatNumber(e.area / 10000)} ha
                          ({formatNumber(e.sobrep * 100)}%)
                        </td>
                        <td><SvgShape pathA={p.path} pathB={e.path} size={60} /></td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </>}
              {p.ucs?.data && <>
                <Divider size={16} my={"xs"} fw={700}
                  label={<Text size={16} fw={700}>Unidades de Conservação - Sobreposição</Text>} />

                <Table
                  horizontalSpacing={4}
                  verticalSpacing={4}
                  fontSize={10}
                  styles={{ table: { backgroundColor: "transparent" } }}>
                  <thead>
                    <tr style={{ backgroundColor: "#dddd" }}>
                      <th style={{ width: 10 }}>#</th>
                      <th style={{ width: 20 }}>Nome</th>
                      <th style={{ width: 20 }}>Grupo</th>
                      <th style={{ width: 20 }}>Categoria</th>
                      <th style={{ width: 20 }}>CNUC</th>
                      <th style={{ width: 40 }}>Data Criação</th>
                      <th style={{ width: 60 }}>Ato Criação</th>
                      <th style={{ width: 50 }}>Plano de Manejo</th>
                      <th style={{ width: 20 }}>Esfera</th>
                      <th style={{ width: 100 }}>Gestor</th>
                      <th style={{ width: 20 }}>Categoria IUCN</th>
                      <th style={{ width: 50, textAlign: "center" }}>Área Sobreposta</th>
                      <th style={{ width: 80, textAlign: "center" }}>Sobreposição</th>
                    </tr>
                  </thead>
                  <tbody>
                    {p.ucs?.data?.map((e, idx) => {
                      let acceptPrivate = ['Monumento Natural', 'Refúgio de Vida Silvestre', 'Reserva Particular do Patrimônio Natural', 'Área de Proteção Ambiental', 'Área de Relevante Interesse Ecológico','Reserva de Desenvolvimento Sustentável']
                      let invalidSobrep = !acceptPrivate.includes(e.categoria)
                     return  (
                        <tr key={e.id}>
                          <td>{idx + 1}</td>
                          <td>{e.nome_area}</td>
                          <td>{e.grupo}</td>
                          <td style={invalidSobrep ? {color: "red", fontWeight: 700 } : {}} >{e.categoria}</td>
                          <td>{e.cod_cnuc}</td>
                          <td>{e.data_criacao}</td>
                          <td>{e.ato_criacao}</td>
                          <td>{e.pl_manejo}</td>
                          <td>{e.esfera}</td>
                          <td>{e.gestor}</td>
                          <td>{e.cat_iucn}</td>
                          <td style={{textAlign: "center"}}>{formatNumber(e.area / 10000)} ha
                            ({formatNumber(e.sobrep * 100)}%)
                          </td>
                          <td><SvgShape pathA={p.path} pathB={e.path} size={60}/></td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
                <Text px={"lg"} size={12}>
                  As Unidades de Conservação podem ser classificadas em relação à posse de terras: <b>Permitem Posse Privada:</b> Monumento Natural, Refúgio da Vida Silvestre, Reserva de Desenvolvimento Sustentável, Área de Relevante Interesse Ecológico, Área de Proteção Ambiental e RPPN (Reserva Particular do Patrimônio Natural) <b>Não Permitem Posse Privada:</b> Estação Ecológica, Reserva Biológica, Parque, Floresta e Reserva Extrativista.
                </Text>
              </>}
              {p.floresta?.data?.length > 0 && <>
                <Divider size={16} my={"xs"} fw={700}
                  label={<Text size={16} fw={700}>Florestas Públicas - Sobreposição</Text>} />
                <Table
                  horizontalSpacing={4}
                  verticalSpacing={4}
                  fontSize={10}
                  styles={{ table: { backgroundColor: "transparent" } }}>
                  <thead>
                    <tr style={{ backgroundColor: "#dddd" }}>
                      <th style={{ width: 10 }}>#</th>
                      <th style={{ width: 20 }}>Nome</th>
                      <th style={{ width: 20 }}>Tipo</th>
                      <th style={{ width: 40 }}>Proteção</th>
                      <th style={{ width: 80, textAlign: "center" }}>Área Sobreposta</th>
                      <th style={{ width: 80, textAlign: "center" }}>Sobreposição</th>
                    </tr>
                  </thead>
                  <tbody>
                    {p.floresta?.data?.map((e, idx) => (
                      <tr key={e.id}>
                        <td>{idx + 1}</td>
                        <td>{e.nome_area}</td>
                        <td>{e.tipo}</td>
                        <td>{e.protecao}</td>
                        <td style={{ textAlign: "center" }}>{formatNumber(e.area / 10000)} ha
                          ({formatNumber(e.sobrep * 100)}%)
                        </td>
                        <td><SvgShape pathA={p.path} pathB={e.path} size={60} /></td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </>}
              <>
                <Divider size={16} my={"xs"} fw={700}
                  label={<Text size={16} fw={700}>[{p.codType?.toUpperCase()}] {p.cod} </Text>} />
                <div className="content">
                  <Grid m={0} gutter={"xs"} style={{ width: "1640px", height: "1020px" }}>
                    <Grid.Col span={6}>
                      {isLoadingCar && <Skeleton visible={isLoadingCar} height={990}>
                      </Skeleton>}
                      {rural?.extent && rural?.layers  &&
                        <MapLeaflet
                          tabId={tabId}
                          extent={rural.extent}
                          layers={carData?.geo}
                          highlight={rural.layers}
                        >
                        </MapLeaflet>}
                    </Grid.Col>
                    <Grid.Col span={6}>

                      <Divider labelPosition={"center"} size={20} my={"xs"} fw={700} pt={0}
                        label={<Group>
                          <IconActivity />
                          <Text style={{ textAlign: "center" }} size={22} fw={700}>RAIO-X</Text>
                        </Group>} />

                      <Divider size={16} my={"xs"} fw={700}
                        label={<Text size={16} fw={700}>Tipo de Área (Conforme declarado no CAR)</Text>} />
                      <div>
                        <ScrollArea.Autosize mah={324} mx="auto">
                          <Skeleton visible={isLoadingCar} height={isLoadingCar ? 324 : "auto"} >
                            <Table verticalSpacing={4} fontSize="sm">
                              <thead>
                                <tr>
                                  <th style={{ backgroundColor: "#eaeaea", position: "sticky", top: 0, width: "100px", textAlign: "center", zIndex: isLoadingCar ? 0 : 999 }}>Legenda</th>
                                  <th style={{ backgroundColor: "#eaeaea", position: "sticky", top: 0, width: "520px" }}>Tipo</th>
                                  <th style={{ backgroundColor: "#eaeaea", position: "sticky", top: 0, width: "100px", textAlign: "center" }}>Área (ha)</th>
                                  <th style={{ backgroundColor: "#eaeaea", position: "sticky", top: 0, width: "100px", textAlign: "center" }}>%</th>
                                </tr>
                              </thead>
                              <tbody>
                                {carData?.geo && carData.geo?.length > 0 && carData.geo?.map((c) => c.features.map((d) => (

                                  <tr key={carGeoType()[d.properties?.tipo][0]}>
                                    <td>
                                      <Center>
                                        <ColorSwatch
                                          size={20}
                                          key={carGeoType()[d.properties?.tipo][1]}
                                          onClick={() => {
                                            let state = !layerRef[d.properties?.tipo]?.visible;

                                            setLegendState(legendState => ({
                                              ...legendState,
                                              ...{
                                                [d.properties?.tipo]: state
                                              }
                                            }));
                                            layerRef[d.properties?.tipo].visible = state;
                                          }}
                                          color={(legendState[d.properties?.tipo] ?? true) === true ? (carGeoType()[d.properties?.tipo]?.length > 0 && carGeoType()[d.properties?.tipo][1]) : "#f6f6f6"} />
                                      </Center>
                                    </td>
                                    <td>{carGeoType()[d.properties?.tipo][0]}</td>
                                    <td style={{ textAlign: "center" }}>{formatNumber(d.properties?.area / 10000)}</td>
                                    <td style={{ textAlign: "center" }}>{formatNumber(d.properties?.sobrep * 100)}%</td>
                                  </tr>
                                )))}
                              </tbody>
                            </Table>
                          </Skeleton>
                        </ScrollArea.Autosize>
                      </div>
                      <Grid mt={"xs"} gutter={"xs"}>
                        <Grid.Col span={12}>
                          <ChartCard
                            h={260}
                            title={"Desmatamento posterior a 01/08/2008 | Por Ano e Acumulado (ha) "}
                            titleAlign={"left"}
                            data={carData?.chart}
                            params={{
                              series: [
                                {
                                  name: "Por Ano", type: "bar", value: "value", color: "orange", labelPosition: "inside",
                                  markLine: {
                                    silent: true,
                                    symbol: "circle",
                                    symbolSize: 4,
                                    data: [{ lineStyle: { color: "#5a5a5a" }, name: "6.25 ha", yAxis: 6.25 }]
                                  }
                                },
                                { name: "Acumulado", type: "line", value: "value_acum", color: "red", labelPosition: "top" }]
                            }}
                            isLoading={isLoadingCar}
                            chart={chartBar}
                            iconColor={"yellow"}
                            icon={IconLeafOff}
                          />
                        </Grid.Col>
                      </Grid>
                      <Grid gutter={"xs"}>
                        <Grid.Col span={4}>
                          <ChartCard
                            h={260}
                            title={"% Vegetação Nativa"}
                            titleAlign={"center"}
                            data={carData?.chart_vn}
                            params={{
                              series: [
                                { name: "VN", type: "bar", value: "value", labelPosition: "top", format: "percentage", showLegend: false },
                              ]
                            }}
                            isLoading={isLoadingCar}
                            chart={chartBar}
                          />
                        </Grid.Col>
                        <Grid.Col span={8}>
                          <ChartCard
                            h={260}
                            title={"Histórico - ICCF e % Veg. Nativa em Reserva Legal"}
                            titleAlign={"left"}
                            data={carData?.chart}
                            params={{
                              series: [
                                { name: "ICCF", type: "line", value: "iccf", color: "blue", labelPosition: "top", format: "percentage" },
                                {
                                  name: "% Veg. Nat. Estimada",
                                  type: "line",
                                  value: "vn_sobre_area",
                                  color: "green",
                                  labelPosition: "bottom",
                                  format: "percentage"
                                }
                              ]
                            }}
                            isLoading={isLoadingCar}
                            chart={chartBar}
                            iconColor={"blue"}
                            icon={IconGauge}
                          />
                        </Grid.Col>
                      </Grid>
                    </Grid.Col>
                  </Grid>
                </div>
              </>
            </>
          )}
        </div>

        {codType !== "doc" && <div className="content">
          <Divider size={16} my={"xs"} fw={700}
            label={<Text size={16} fw={700}>Evolução - Imagens de Satélite</Text>} />
          <Group spacing={8} position={"apart"}>
            {[1, 2, 3, 6, 9, 12, 24, 36, 48, 60, 72, 84].map((date) => {
              return (
                <Card p={0} my={2} withBorder style={{ width: "404px", height: "500px" }}>
                  {rural?.extent && rural?.layers &&
                    <MapLeaflet
                      tabId={tabId}
                      lock
                      fullScreen
                      extent={rural.extent}
                      highlight={rural.layers}
                      history={true}
                      imageDate={getFormattedDate(date, dateRef)}
                    >
                    </MapLeaflet>}
                </Card>)
            })}
          </Group>

        </div>}
        {codType === "car" && <div className="content">
          <Divider size={16} my={"xs"} fw={700} mb={0} label={<Text size={16} fw={700}>Histórico de Alterações do CAR</Text>} />
          {carTimeline && carTimeline.length > 1 ?
            <Group spacing={8} position={carTimeline.length < 4 ? "left" : "apart"}>
              {carTimeline && carTimeline.map((e, idx) => (
                <Card p={"xs"} my={2} withBorder shadow={"sm"} style={{ ...{ width: "404px", minHeight: 600 }, ...(idx === 0 ? { border: "2px solid" + "#044585" } : {}) }}>
                  <Group position={"apart"} pb={"xs"} s>
                    <Text p={0} m={0} fw={700}>{e.dat_protocolo}</Text>
                    {idx === 0 && <Badge variant="filled">Atual</Badge>}
                    {idx === carTimeline.length - 1 && <Badge variant="filled" color={"gray"}>Cadastro</Badge>}
                  </Group>
                  {!e.imovel?.changed && !e.pessoas?.changed && <Badge variant="outline" color={"gray"}>Sem Alteração</Badge>}
                  {e.imovel?.changed &&
                    <Card withBorder p={"xs"} my={"xs"} grow >
                      <Badge variant="outline" color={"orange"}>{idx === carTimeline.length - 1 ? "" : "Alteração "}Área</Badge>
                      <Group p={"xs"} position={"center"} >
                        <Tooltip
                          zIndex={9999}
                          withinPortal color={"rgba(0,0,0,0)"} label={
                            <Card
                              p={"xs"}
                              shadow={"md"}
                              withBorder>
                              <div style={{ maxWidth: 600, maxHeight: 600 }} dangerouslySetInnerHTML={{ __html: e.imovel.svg }} />
                            </Card>}>
                          <div style={{ width: 200, height: 180 }} dangerouslySetInnerHTML={{ __html: e.imovel.svg }} />
                        </Tooltip>
                        <Stack pl={"sm"}>
                          {e.imovel.diff_add_area_prop > 0 && <div>
                            <Text size="xs" color="green" fw={700}>
                              Aumento
                            </Text>
                            <Text fw={600} size={"xs"}>
                              {formatNumber(e.imovel.diff_add_area_ha)} ha | {formatNumber(e.imovel.diff_add_area_prop * 100, 1)}%
                            </Text>
                          </div>}
                          {e.imovel.diff_remove_area_prop > 0 && <div>
                            <Text size="xs" color="red" fw={700}>
                              Redução
                            </Text>
                            <Text fw={600} size={"xs"}>
                              {formatNumber(e.imovel.diff_remove_area_ha)} ha | {formatNumber(e.imovel.diff_remove_area_prop * 100, 1)}%
                            </Text>
                          </div>}
                        </Stack>
                      </Group>
                    </Card>}
                  {e.pessoas?.changed &&
                    <Card withBorder p={"xs"} grow >
                      <Badge variant="outline" color={"orange"}>{idx === carTimeline.length - 1 ? "" : "Alteração "}Proprietários</Badge>
                      <Group m={0} p={6} position={"center"} >
                        {e.pessoas.changes.filter((f) => f.status === "added").length > 0 &&
                          <Text size="xs" color="green" fw={700}>
                            Inclusões: +{formatNumber(e.pessoas.changes.filter((f) => f.status === "added").length)}
                          </Text>}
                        {e.pessoas.changes.filter((f) => f.status === "removed").length > 0 &&
                          <Text size="xs" color="red" fw={700}>
                            Exclusões: -{formatNumber(e.pessoas.changes.filter((f) => f.status === "removed").length)}
                          </Text>}
                        {e.pessoas.changes.filter((f) => f.status !== "removed").length > 0 &&
                          <Text size="xs" color="gray" fw={700}>
                            Total: {formatNumber(e.pessoas.changes.filter((f) => f.status !== "removed").length)}
                          </Text>}
                      </Group>
                      <ScrollArea.Autosize mah={200} mx="auto" w={"100%"}>
                        <Table verticalSpacing={2} fontSize={11}>
                          <thead>
                            <tr>
                              <th style={{ backgroundColor: "#eaeaea", position: "sticky", top: 0, zIndex: 999 }}>CPF/CNPJ</th>
                              <th style={{ backgroundColor: "#eaeaea", position: "sticky", top: 0 }}>Nome</th>
                            </tr>
                          </thead>
                          <tbody>
                            {e.pessoas.changes?.filter((f) => f.status !== "ok" || idx === carTimeline.length - 1).map((d, idx) => (
                              <tr key={idx} style={d.status === "added" ? { color: "#083a00", backgroundColor: "#e8ffed" } : (d.status === "removed" ? {
                                color: "#970000",
                                backgroundColor: "#ffe8e8"
                              } : {})}>
                                <td>{maskNameOrDocument(d.doc)}</td>
                                <td>{maskNameOrDocument(d.nome)}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </ScrollArea.Autosize>
                    </Card>}
                </Card>))}
            </Group> :
            <Center>
              <Text pt={0} size={14}>Sem Alterações</Text>
            </Center>}
        </div>}
        {codType === "car" && <div className="content">
          <Divider size={16} my={"xs"} fw={700} mb={0} label={<Text size={16} fw={700}>Ocorrências de Embargos em áreas históricas e próxmas do CAR</Text>} />
          {carHistory &&  carHistory?.extent &&
            <Grid>
              <Grid.Col span={3}>
              <Card p={0} my={2} withBorder style={{ height: "500px" }}>
                <MapLeaflet
                  tabId={tabId}
                  cluster={false}
                  legend
                  uf
                  extent={carHistory.extent}
                  layers={carHistory.layers}
                  // minimap
                >
                </MapLeaflet>
              </Card>
              </Grid.Col>
              <Grid.Col span={9}>
                <div>
                  <ScrollArea.Autosize mah={324} mx="auto">
                    <Skeleton visible={isLoadingCar} height={isLoadingCar ? 324 : "auto"}>
                      <Table verticalSpacing={4} fontSize="sm">
                        <thead>
                        <tr>
                          <th style={{ backgroundColor: "#eaeaea", position: "sticky", top: 0, width: "150px" }}>Data Embargo</th>
                          <th style={{ backgroundColor: "#eaeaea", position: "sticky", top: 0, width: "300px" }}>Local Embargado</th>
                          <th style={{ backgroundColor: "#eaeaea", position: "sticky", top: 0, width: "150px" }}>Área Total Embargada</th>
                          <th style={{ backgroundColor: "#eaeaea", position: "sticky", top: 0, width: "150px" }}>Número Auto Infração</th>
                          <th style={{ backgroundColor: "#eaeaea", position: "sticky", top: 0, width: "150px" }}>Número Termo de Embargo</th>
                          <th style={{ backgroundColor: "#eaeaea", position: "sticky", top: 0, width: "150px" }}>Identificação Ordem de Fiscalização</th>
                        </tr>
                        </thead>
                        <tbody>
                        {carHistory?.layers && carHistory?.layers?.filter((f) => f?.source === 'embargos' && f.features)?.length > 0 ? carHistory.layers?.filter((f) => f.source === 'embargos' && f.features).map((c) => c.features.map((d) => (
                          <tr key={d.properties["Identificação Ordem de Fiscalização"]}>
                            <td>{dayjs(d.properties["Data Embargo"]).utc().format("DD/MM/YYYY")}</td>
                            <td>{d.properties["Local Embargado"]}</td>
                            <td>{d.properties["Área Total Embargada"]}</td>
                            <td>{d.properties["Número Auto Infração"]}</td>
                            <td>{d.properties["Número Termo de Embargo"]}</td>
                            <td>{d.properties["Identificação Ordem de Fiscalização"]}</td>
                          </tr>
                        ))) : <tr><td colSpan={6}>Embargos não localizados</td></tr>}
                        </tbody>
                      </Table>
                    </Skeleton>
                  </ScrollArea.Autosize>
                </div>
              </Grid.Col>
            </Grid>}
        </div>}

        <Divider size={16} my={"xs"} fw={700}
                 label={<Text size={16} fw={700}>Operações SICOR / PROAGRO</Text>} />

        {sicor?.data?.length > 0 && <Grid>
          <Grid.Col span={3}>
            <ChartCard
              h={260}
              title={"Valor Liberados por Instituição Financeira"}
              titleAlign={"left"}
              params={{
                sufix: " ",
                divBy: 1000,
              }}
              isLoading={isLoadingSicor}
              chart={chartPie}
              iconColor={"yellow"}
              icon={IconCoins}
              data={sicor?.chart?.per_if}
            />
          </Grid.Col>

          <Grid.Col span={9}>
            <ChartCard
              h={260}
              title={"Valor Liberados por Instituição Financeira por Ano"}
              titleAlign={"left"}
              params={{
                divBy: 1000,
                series: sicor?.chart?.per_if?.map((a) => {
                  return { name: a.name, value: a.name, type: "bar", labelPosition: "top" }
                }),
              }}
              isLoading={isLoadingSicor}
              chart={chartBar}
              iconColor={"yellow"}
              icon={IconCoins}
              data={sicor?.chart?.per_year}
            />
          </Grid.Col>

          <Grid.Col span={12}>
            <ChartCard
              h={260}
              title={"Saldo por Instituição Financeira por Mês"}
              titleAlign={"left"}
              params={{
                limit: 24,
                divBy: 1000,
                stack: true,
                series: sicor?.chart?.per_if?.map((a) => {
                  return { name: a.name, value: a.name, type: "bar", labelPosition: "inside" }
                }),
              }}
              isLoading={isLoadingSicor}
              chart={chartBar}
              iconColor={"yellow"}
              icon={IconCoins}
              data={sicor?.chart?.balance}
            />
          </Grid.Col>
        </Grid>}

        <GenericTableComponent
          fullHeight
          isLoading={isLoadingSicor}
          title={"Detalhamento | Operações SICOR / PROAGRO"}
          ExtraInfo={OpExtraInfoComponent}
          filterField={"if"}
          columns={["ref_bacen", "nu_ordem", "if", "dt_emissao", "dt_vencimento", "finalidade", "atividade", "instrumento_credito", "balance"]}
          //sort data by balance
          data={sicor?.data?.sort((a, b) => b.balance_vl - a.balance_vl)}
        />
        {/* <div className="page-breaker">&nbsp;</div>
      <Divider size={16} my={"xs"} fw={700}
               label={<Text size={16} fw={700}>[INCRA] Imóveis Rurais SIGEF (Sistema de Gestão Fundiária) e SNCI (Sistema Nacional de Cadastro de
                 Imóveis)</Text>}/>
      <div className="content">
        <TableCustom
          data={imoveisRurais(true)}
          bcb={true}
          title={"[OCORRÊNCIAS] Imóveis Rurais com Possíveis Impedimentos conforme Manual Crédito Rural BCB - Seção 9 - Capítulo 2"}
          headerColor={"#ffe26e"}/>
        <div className="page-breaker">&nbsp;</div>
        <TableCustom
          data={imoveisRurais(false)}
          title={"[SEM OCORRÊNCIAS] Imóveis Rurais sem Possíveis Impedimentos conforme Manual Crédito Rural BCB - Seção 9 - Capítulo 2"}
          headerColor={"#d0e5d0"}/>
      </div>*/}
      </div>}

      {loaded && <div className="loaded"/>}
    </>
  )
}