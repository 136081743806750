import { createContext, useContext, useState, useEffect, useRef } from "react";

const TabsContext = createContext(null);

function TabsProvider({ children }) {
  const lastTab = useRef(["Home", "Home"]);

  const [tabs, setTabs] = useState(() => {
    const tabsJson = localStorage.getItem("TABS");

    if (tabsJson) {
      return JSON.parse(tabsJson);
    } else {
      return [
        {
          label: "Painel",
          component: "Home",
          id: "Home",
        },
      ];
    }
  });

  const addTab = ({ id, component, label, props, disableRefresh, subcomponent }) => {
    if (id && component && label) {
      for (let tab of tabs) {
        if (tab.id == id) {
          setActiveTab(tab.id);
          return;
        }
      }

      setTabs([
        ...tabs,
        {
          id,
          disableRefresh,
          component,
          subcomponent,
          label,
          props
        },
      ]);

      setActiveTab(id);
    }
  };

  const removeTab = (id) => {
    const newTabs = tabs.filter((tab) => tab.id != id);

    setTabs(newTabs);

    if (activeTab == id) {
      let lt = lastTab.current[lastTab.current.length - 2];

      if (newTabs.includes(lt)) {
        setActiveTab(lt);
      }
      else {
        setActiveTab("Home");
      }
    }
  };

  const [activeTab, setActiveTab] = useState(() => {
    return localStorage.getItem("ACTIVE_TAB") || "Home";
  });

  useEffect(() => {
    localStorage.setItem("ACTIVE_TAB", activeTab);

    lastTab.current.push(activeTab);

    const tab = tabs.find(tab => tab.id == activeTab);

    if (tab.blinking) {
      const clone = [...tabs];

      const index = clone.indexOf(tab);

      if (index >= 0) {
        clone[index] = { ...clone[index], blinking: false }

        setTabs(clone);
      }

    }
  }, [activeTab]);

  useEffect(() => {
    localStorage.setItem("TABS", JSON.stringify(tabs));
  }, [tabs]);

  return (
    <TabsContext.Provider
      value={{
        tabs,
        activeTab,
        setTabs,
        addTab,
        removeTab,
        setActiveTab,
      }}
    >
      {children}
    </TabsContext.Provider>
  );
}

function useTabs() {
  return useContext(TabsContext);
}

export { TabsProvider, useTabs };
