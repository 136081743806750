import {
  Paper,
  createStyles,
  TextInput,
  Button,
  Title,
  Text,
  List,
  Group,
  rem,
  Flex,
  Center,
  Grid,
  Divider,
  Box,
  Switch,
  Select,
  NumberInput,
  Textarea,
  Table,
  FileInput,
  ActionIcon,
  LoadingOverlay, SegmentedControl, Tooltip,
} from "@mantine/core";

import React, { useState, useEffect } from "react";

import { useForm } from "@mantine/form";

import { Input } from '@mantine/core';
import { useId } from '@mantine/hooks';
import { IMaskInput } from 'react-imask';
import useSWR from "swr";
import {IconCheck, IconFile, IconHelp, IconPlus, IconTrash, IconX} from "@tabler/icons-react";
import { notifications } from "@mantine/notifications";
import { useSupabaseClient } from "@supabase/auth-helpers-react";
import { UploadFileSigned } from "../lib/UploaderSigned";

import { cpf, cnpj } from 'cpf-cnpj-validator';

const useStyles = createStyles((theme) => ({
  anchor: {
    width: "100%",
  },
  wrapper: {
  },
  patternContainer: {
    minHeight: "calc(100vh - 10px)",
    backgroundSize: "500px 500px",
    backgroundRepeat: "repeat",
    backgroundPosition: "center",
    backgroundColor: theme.colorScheme === "dark" ? "rgba(7,12,16,0.95)" : "rgba(251,253,255,0.95)",
    backgroundBlendMode: theme.colorScheme === "dark" ? "darken" : "lighten",
    backgroundImage: "url(https://www.cccfacilcontrole.com.br/c-color.svg)",
  },

  form: {
    maxWidth: rem(800),
    [theme.fn.smallerThan("sm")]: {
      maxWidth: "100%",
    },
  },

  title: {
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },
}));


function InputCreditor(props) {
  const { creditorDoc, setCreditorDoc, setListedCreditor, activeRj } = props;

  const { data, error, isLoading } = useSWR(activeRj && (creditorDoc?.length == 11 || creditorDoc?.length === 14) && {
    tableName: "rj_creditor",
    columns: "id,name,doc,rj_creditor_email(email),rj_creditor_address(address,city,state,zipcode),rj_creditor_phone(phone),rj_credit_request(request_type)",
    filters: [["doc", "eq", creditorDoc], ["project_id", "eq", activeRj]],
  });

  useEffect(() => {
    if (data && data.length > 0) {
      setListedCreditor(data[0]);
    }
    else if (data?.length === 0) {
      setListedCreditor(null);
    }
    else {
      setListedCreditor(undefined);
    }
  }, [data]);


  return (
    <Input
      onInput={(event) => {
        setCreditorDoc(event.target.value.replaceAll(/\D/g, ''))
      }}
      component={IMaskInput}
      value={creditorDoc}
      mask={[
        {
          mask: '000.000.000-00',
          regex: /^(\d{0,11})$/,
        },
        {
          mask: '00.000.000/0000-00',
          regex: /^(\d{11})(\d{0,14})$/,
        },
      ]}
      definitions={{
        '0': /[0-9]/,
      }}
    />
  );
}

function Value({ file }) {
  return (
    <Center
      inline
      sx={(theme) => ({
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[1],
        fontSize: theme.fontSizes.xs,
        padding: `${rem(3)} ${rem(7)}`,
        borderRadius: theme.radius.sm,
      })}
    >
      <IconFile size={rem(14)} style={{ marginRight: rem(5) }} />
      <span
        style={{
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          maxWidth: rem(200),
          display: 'inline-block',
        }}
      >
        {file.name}
      </span>
    </Center>
  );
}

function ValueComponent({ value }) {
  if (Array.isArray(value)) {
    return (
      <Group spacing="sm" py="xs">
        {value.map((file, index) => (
          <Value file={file} key={index} />
        ))}
      </Group>
    );
  }

  return <Value file={value} />;
};

function CompanyList({ activeRj, ...props }) {
  const { data, error, isLoading } = useSWR({
    tableName: "v_sample_company",
    columns: "value:id,label",
    filters: [["rj", "eq", activeRj]],
  });

  return (
    <>
      <Select
        required
        data={(!isLoading && data?.length > 0 && data) || []}
        {...props}
      />
    </>
  )
}

export default function RequestPage() {
  const cpfId = useId();

  const supabase = useSupabaseClient();

  const [creditorDoc, setCreditorDoc] = useState("");
  const [loading, setLoading] = useState(false);

  const [listedCreditor, setListedCreditor] = useState(undefined);

  const [activeRj, setActiveRj] = useState(null);

  function formatNumber(value, currency, plusSignal) {
    return parseFloat(value).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      signDisplay: plusSignal ? "exceptZero" : "auto"
    })
  }
  function CustomLabel({ labelText, tooltipText, withAsterisk }) {
    return (
      <Flex align="center" style={{ whiteSpace: "nowrap" }}>
        <Text mr={2}>
          {labelText}
          {withAsterisk && (
            <Text as="span" ml={1} color="red" fontWeight="bold" display="inline-block">
              *
            </Text>
          )}
        </Text>
        <Tooltip openDelay={200}  closeDelay={100} color="#185e9f" withArrow style={{ fontSize: 10 }} label={tooltipText}>
          <IconHelp color="#aaa" size="0.8rem" strokeWidth="2" />
        </Tooltip>
      </Flex>
    );
  }

  function CreditTable() {
    const { data, error, isLoading } = useSWR({
      tableName: "v_rj_credit_class",
      columns: "id,rj_credit_class_name,rj_company_name,amount,currency",
      filters: [["rj_creditor_id", "eq", listedCreditor?.id]],
    });

    return (
      <>
        <Divider
          mt={20}
          mb={10}
          mx="g"
          variant="dashed"
          labelPosition="center"
          label={
            <>
              <Box sx={{ fontWeight: 700, fontSize: 15 }}>Valores Listados</Box>
            </>
          }
        />
        <Table >
          <thead>
            <tr style={{ backgroundColor: "#e2eee3" }}>
              <th>Recuperanda</th>
              <th style={{ width: 110 }}>Classe</th>
              <th style={{ width: 70 }}>Moeda</th>
              <th>Valor Listado</th>
            </tr>
          </thead>
          <tbody>
            {data && data.length > 0 && data.map((e) => (
              <tr key={e.id}>
                <td>{e.rj_company_name}</td>
                <td>{e.rj_credit_class_name[0].name}</td>
                <td>{e.currency}</td>
                <td>{formatNumber(e.amount)}</td>
              </tr>))}
          </tbody>
        </Table>
      </>)
  }

  const { classes } = useStyles();

  const cpfId2 = useId();

  const brazil_states = [
    'AC',  // Acre
    'AL',  // Alagoas
    'AP',  // Amapá
    'AM',  // Amazonas
    'BA',  // Bahia
    'CE',  // Ceará
    'DF',  // Distrito Federal
    'ES',  // Espírito Santo
    'GO',  // Goiás
    'MA',  // Maranhão
    'MT',  // Mato Grosso
    'MS',  // Mato Grosso do Sul
    'MG',  // Minas Gerais
    'PA',  // Pará
    'PB',  // Paraíba
    'PR',  // Paraná
    'PE',  // Pernambuco
    'PI',  // Piauí
    'RJ',  // Rio de Janeiro
    'RN',  // Rio Grande do Norte
    'RS',  // Rio Grande do Sul
    'RO',  // Rondônia
    'RR',  // Roraima
    'SC',  // Santa Catarina
    'SP',  // São Paulo
    'SE',  // Sergipe
    'TO'   // Tocantins
  ]

  const { data: rjs, error, isLoading } = useSWR({
    tableName: 'v_sample_project',
    // filters: [["id","eq",projectId]],
    // orders: [["id", { ascending: true }]],
  });


  const debitor = [
  ]

  const creditClass = [
    {
      label: 'Classe 1 - Trabalhista',
      value: 1
    },
    {
      label: 'Classe 2 - Garantia Real',
      value: 2
    },
    {
      label: 'Classe 3 - Quirografário',
      value: 3
    },
    {
      label: 'Classe 4 - ME/EPP',
      value: 4
    },
    {
      label: 'Extraconcursal',
      value: 5
    },
  ]

  const currency = [
    {
      label: 'Real (R$)',
      value: 'BRL'
    },
    {
      label: 'Dólar (U$)',
      value: 'USD'
    },
    {
      label: 'Euro (€)',
      value: 'EUR'
    },
    {
      label: 'Outra',
      value: 'OUTRA'
    },
  ]

  const form = useForm({
    initialValues: {
      rj_id: null,

      creditorName: null,
      creditorDoc: null,
      creditorEmail: null,
      creditorPhone: null,
      creditorAddress: null,
      creditorState: null,
      creditorCity: null,
      creditorZipCode: null,
      creditorHaveAttorney: false,
      requestDivergence: false,
      rj_credit_request: [],

      representativeName: null,
      representativeDoc: null,
      representativeEmail: null,
      representativePhone: null,
      representativeAddress: null,
      representativeState: null,
      representativeCity: null,
      representativeZipcode: null,
      representativeAttachment: [],

      attorneyName: null,
      attorneyDoc: null,
      attorneyClassNumber: null,
      attorneyEmail: null,
      attorneyPhone: null,
      attorneyAttachment: [],

      divergency: [{
        debitor: null,
        creditClass: null,
        creditCurrency: null,
        creditAmount: 0,
        disputeDescription: null,
        disputeAttachment: [],
      }],
    },

    validate: {
      creditorEmail: (value) =>
        !new RegExp("([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\"\(\[\]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\[[\t -Z^-~]*])").test(value)
          ? "Formato de email inválido" : null,
      creditorName: (value) =>
        value.length < 3 ? "Nome precisa ter no minimo 3 caracteres." : null,
      creditorDoc: (value) => {
        return value.length === 14 ? (cnpj.isValid(value) ? null : "CNPJ Inválido") : (cpf.isValid(value) ? null : "CPF Inválido")
      },
    },
  });

  useEffect(() => {

    setActiveRj(form.values.rj_id);

  }, [form.values.rj_id]);


  useEffect(() => {
    if (listedCreditor?.doc) {
      form.setFieldValue('creditorName', listedCreditor.name)
      form.setFieldValue('creditorDoc', listedCreditor.doc)
      form.setFieldValue('creditorEmail', listedCreditor.rj_creditor_email[0]?.email)
      form.setFieldValue('creditorPhone', listedCreditor.rj_creditor_phone[0]?.phone)
      form.setFieldValue('creditorZipCode', listedCreditor.rj_creditor_address[0]?.zipcode)
      form.setFieldValue('creditorState', listedCreditor.rj_creditor_address[0]?.state)
      form.setFieldValue('creditorAddress', listedCreditor.rj_creditor_address[0]?.address)
      form.setFieldValue('creditorCity', listedCreditor.rj_creditor_address[0]?.city)
    }
    else {
      form.setFieldValue('creditorName', '')
      form.setFieldValue('creditorDoc', '')
      form.setFieldValue('creditorEmail', '')
      form.setFieldValue('creditorPhone', '')
      form.setFieldValue('creditorZipCode', '')
      form.setFieldValue('creditorState', '')
      form.setFieldValue('creditorAddress', '')
      form.setFieldValue('creditorCity', '')
    }
  }, [listedCreditor]);

  const onSaveHandler = async () => {

    if (form.validate().hasErrors) {
      return;
    }
    setLoading(true);
    try {

      const files = [];

      const values = structuredClone(form.values);

      for (const d of values.divergency) {
        for (const f of d.disputeAttachment) {
          const names = [];

          if (f instanceof File) {
            const id = crypto.randomUUID();

            const ext = f.name.split('.').pop();

            f.fileName = `${id}.${ext}`;

            files.push(f);

            names.push({
              original: f.name,
              name: f.fileName
            });
          }

          f.disputeAttachment = names;
        }
      }

      const { data, error } = await supabase.rpc("f_request", {
        _input: values
      });

      if (error) {
        notifications.show({
          withCloseButton: true,
          autoClose: 3000,
          message: error.message,
          color: "red",
          icon: <IconX />,
          loading: false,
        });

        return;
      }
      else {
        for (const file of files) {
          await UploadFileSigned(supabase, file, `9/requests/${file.fileName}`);
        }
      }
    }
    finally {
      setLoading(false);

      notifications.show({
        withCloseButton: true,
        autoClose: 3000,
        message: "Requisição enviada com sucesso!",
        color: "green",
        icon: <IconCheck />,
        loading: false,
      });
      form.reset()

    }
  };

  return (
    <Center className={classes.patternContainer} sx={{ maxWidth: "100vw", }}>
      <Paper shadow="md" withBorder className={classes.form} radius={"md"} px={30} py={5}>
        <Flex py={30} sx={{ justifyContent: "center" }}
          wrap="wrap" >
          <img src={"./japur.jpg"} width="330" />
        </Flex>
        <Grid>
          <Grid.Col span={12}>
            {rjs && <Select
              label="Escolha a Recuperação Judicial"
              required
              clearable
              data={rjs}
              {...form.getInputProps("rj_id")}
            />}
          </Grid.Col>


          {activeRj && <Grid.Col span={12}>
            <Input.Wrapper id={cpfId} key={cpfId} label="Consultar lista de Credores (CPF/CNPJ)" required>
              <InputCreditor
                creditorDoc={creditorDoc}
                setCreditorDoc={setCreditorDoc}
                setListedCreditor={setListedCreditor}
                activeRj={activeRj} />
            </Input.Wrapper>
          </Grid.Col>}
        </Grid>

        {activeRj && <>
          {listedCreditor === null &&
            <Center>
              <Text fw={500} color={"red.9"}>Credor não localizado na lista.</Text>
            </Center>}
          {listedCreditor === null &&
            <Center>
              <Text fw={700} size={20} color={"blue.9"}>Possui valores a serem habilitados na Recuperação Judicial?</Text>
            </Center>}
          {!listedCreditor && listedCreditor === null && <Flex py={10} sx={{ justifyContent: "center" }}>
            <SegmentedControl
              onChange={(event)=> {
                form.setFieldValue("requestDivergence",event == 'sim')
              }}
              color={form.values.requestDivergence ? "green.8" : "red.8" }
              size="lg"
              sx={{ fontWeight: 600 }}
              value={form.values.requestDivergence ? 'sim' : 'não'}
              data={[{label:'Sim', value: 'sim'},{label:'Não', value: 'não'}]}
            />
          </Flex>}
          {listedCreditor?.rj_credit_request?.length === 0 &&
            <Center>
              <Text fw={500} color={"green.9"}>Credor localizado na lista! Favor atualizar e preencher os dados abaixo</Text>
            </Center>}
          {listedCreditor?.rj_credit_request?.length > 0 &&
            <Center>
              <Text fw={500} color={"green.9"}>Credor já solicitou {listedCreditor?.rj_credit_request[0].request_type}.</Text>
            </Center>}
          {(listedCreditor?.rj_credit_request?.length === 0 || form.values.requestDivergence) && <div>
            <Divider
              my="xs"
              mx="g"
              size={"sm"}
              sx={{ backgroundColor: "#ddebf5" }}
              variant="dashed"
              labelPosition="center"
              label={
                <>
                  <Box sx={{ fontWeight: 700, fontSize: 15 }}>Informações do Credor</Box>
                </>
              }
            />
            <Grid>
              <Grid.Col xs={12} sm={8} md={8} lg={8}>
                <TextInput
                  required
                  withAsterisk={false}
                  label={
                    <CustomLabel
                      labelText="Nome/Razão Social"
                      withAsterisk
                      tooltipText="Preencha com o nome completo da pessoa física ou jurídica. Evite abreviações, use o nome conforme consta nos documentos."
                    />
                  }
                  {...form.getInputProps("creditorName")}
                />
              </Grid.Col>

              <Grid.Col xs={12} sm={4} md={4} lg={4}>
                <Input.Wrapper
                  id={cpfId}
                  withAsterisk={false}
                  label={
                     <CustomLabel
                       labelText="CPF/CNPJ"
                       withAsterisk
                       tooltipText="Insira apenas os dígitos sem pontuações."
                     />
                   }
                  required
                  {...form.getInputProps("creditorDoc")}>
                  <Input
                    {...form.getInputProps("creditorDoc")}
                    component={IMaskInput}
                    disabled={listedCreditor?.doc}
                    // value={listedCreditor.doc}
                    mask={[
                      {
                        mask: '000.000.000-00',
                        regex: /^(\d{0,11})$/,
                      },
                      {
                        mask: '00.000.000/0000-00',
                        regex: /^(\d{11})(\d{0,14})$/,
                      },
                    ]}
                    definitions={{
                      '0': /[0-9]/,
                    }}
                    id={cpfId2}
                  />
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col xs={12} sm={6} md={6} lg={6}>
                <TextInput
                  required
                  withAsterisk={false}
                  label={
                    <CustomLabel
                      labelText="Email"
                      withAsterisk
                      tooltipText="Insira um endereço de e-mail válido que possa ser utilizado para contato."
                    />
                  }
                  {...form.getInputProps("creditorEmail")}
                />
              </Grid.Col>

              <Grid.Col xs={12} sm={3} md={3} lg={3}>
                <TextInput
                  required
                  withAsterisk={false}
                  label={
                    <CustomLabel
                      labelText="Telefone"
                      withAsterisk
                      tooltipText="Insira um telefone válido que possa ser utilizado para contato."
                    />
                  }
                  {...form.getInputProps("creditorPhone")}
                />
              </Grid.Col>

              <Grid.Col xs={12} sm={3} md={3} lg={3}>
                <TextInput
                  required
                  label="CEP"
                  {...form.getInputProps("creditorZipCode")}
                />
              </Grid.Col>

              <Grid.Col xs={12} sm={6} md={6} lg={6}>
                <TextInput
                  required
                  withAsterisk={false}
                  label={
                    <CustomLabel
                      labelText="Endereço"
                      withAsterisk
                      tooltipText="Forneça o endereço completo, incluindo rua, número, complemento, bairro, etc. Eventualmente será utilizado para contato. Evite abreviações desnecessárias."
                    />
                  }
                  {...form.getInputProps("creditorAddress")}
                />
              </Grid.Col>

              <Grid.Col xs={12} sm={2} md={2} lg={2}>
                <Select
                  label="Estado"
                  searchable
                  nothingFound="Não Encontrado"
                  data={brazil_states}
                  {...form.getInputProps("creditorState")}
                />
              </Grid.Col>

              <Grid.Col xs={12} sm={4} md={4} lg={4}>
                <TextInput
                  required
                  label="Cidade"
                  {...form.getInputProps("creditorCity")}
                />
              </Grid.Col>
            </Grid>

          </div>}
          {/*todo criar estrutura do representante legal*/}
          {form.getInputProps("creditorDoc")?.value?.length > 11  && <div>
            <Divider
              mt={40}
              size={"sm"}
              sx={{ backgroundColor: "#ddebf5" }}
              variant="dashed"
              labelPosition="center"
              label={
                <>
                  <Box sx={{ fontWeight: 700, fontSize: 15 }}>Informações do Representante Legal</Box>
                </>
              }

            />
            <Grid>
              <Grid.Col xs={12} sm={8} md={8} lg={8}>
                <TextInput

                  required
                  label="Nome"
                  {...form.getInputProps("representativeName")}
                />
              </Grid.Col>

              <Grid.Col xs={12} sm={4} md={4} lg={4}>
                <Input.Wrapper id={cpfId} label="CPF" required>
                  <Input

                    {...form.getInputProps("attorneyDoc")}
                    component={IMaskInput}
                    mask={[
                      {
                        mask: '000.000.000-00',
                        regex: /^(\d{0,11})$/,
                      },
                      {
                        mask: '00.000.000/0000-00',
                        regex: /^(\d{11})(\d{0,14})$/,
                      },
                    ]}
                    definitions={{
                      '0': /[0-9]/,
                    }}
                    id={cpfId}
                  />
                </Input.Wrapper>
              </Grid.Col>


              <Grid.Col xs={12} sm={6} md={6} lg={6}>
                <TextInput
                  required
                  label="Email"
                  {...form.getInputProps("representativeEmail")}
                />
              </Grid.Col>

              <Grid.Col xs={12} sm={3} md={3} lg={3}>
                <TextInput
                  required
                  label="Telefone"
                  {...form.getInputProps("representativePhone")}
                />
              </Grid.Col>

              <Grid.Col xs={12} sm={3} md={3} lg={3}>
                <TextInput
                  required
                  label="CEP"
                  {...form.getInputProps("representativeZipcode")}
                />
              </Grid.Col>

              <Grid.Col xs={12} sm={6} md={6} lg={6}>
                <TextInput
                  required
                  label="Endereço"
                  {...form.getInputProps("representativeAddress")}
                />
              </Grid.Col>
              <Grid.Col xs={12} sm={2} md={2} lg={2}>
                <Select
                  label="Estado"
                  searchable
                  nothingFound="Não Encontrado"
                  data={brazil_states}
                  {...form.getInputProps("representativeState")}
                />
              </Grid.Col>

              <Grid.Col xs={12} sm={4} md={4} lg={4}>
                <TextInput
                  required
                  label="Cidade"
                  {...form.getInputProps("representativeCity")}
                />
              </Grid.Col>

              <Grid.Col xs={12} sm={12} md={12} lg={12}>
                <FileInput
                  multiple
                  clearable
                  label="Documentação Comprobatória"
                  withAsterisk
                  valueComponent={ValueComponent}
                  {...form.getInputProps("representativeAttachment")}
                />
              </Grid.Col>
            </Grid>
          </div>}

          <Flex sx={{ justifyContent: "right" }}>
            <Switch
              my={"sm"}
              size="sm"
              sx={{ fontWeight: 600 }}
              labelPosition="left"
              label={
                <CustomLabel
                  labelText="Possui Procurador?"
                  tooltipText="Caso o Credor venha a ser representado por um procurador."
                />
            }
              onLabel={<Box sx={{ fontWeight: 700, fontSize: 12 }}>Sim</Box>}
              offLabel={<Box sx={{ fontWeight: 700, fontSize: 12 }}>Não</Box>}
              {...form.getInputProps("creditorHaveAttorney")}
            />
          </Flex>
          {form.getInputProps("creditorHaveAttorney").value && <div>
            <Divider
              mt={10}
              variant="dashed"
              size={"sm"}
              sx={{ backgroundColor: "#ddebf5" }}
              labelPosition="center"
              label={
                <>
                  <Box sx={{ fontWeight: 700, fontSize: 15 }}>Informações do Procurador</Box>
                </>
              }

            />
            <Grid>
              <Grid.Col xs={12} sm={6} md={6} lg={6}>
                <TextInput

                  required
                  label="Nome"
                  {...form.getInputProps("attorneyName")}
                />
              </Grid.Col>

              <Grid.Col xs={12} sm={3} md={3} lg={3}>
                <Input.Wrapper id={cpfId} label="CPF" required>
                  <Input
                    component={IMaskInput}
                    mask={[
                      {
                        mask: '000.000.000-00',
                        regex: /^(\d{0,11})$/,
                      },
                      {
                        mask: '00.000.000/0000-00',
                        regex: /^(\d{11})(\d{0,14})$/,
                      },
                    ]}
                    definitions={{
                      '0': /[0-9]/,
                    }}
                    id={cpfId}
                  />
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col xs={12} sm={3} md={3} lg={3}>
                <TextInput
                  required
                  label="OAB"
                  {...form.getInputProps("attorneyClassNumber")}
                />
              </Grid.Col>

              <Grid.Col xs={12} sm={8} md={8} lg={8}>
                <TextInput
                  required
                  label="Email"
                  {...form.getInputProps("attorneyEmail")}
                />
              </Grid.Col>

              <Grid.Col xs={12} sm={4} md={4} lg={4}>
                <TextInput
                  required
                  label="Telefone"
                  {...form.getInputProps("attorneyPhone")}
                />
              </Grid.Col>

              <Grid.Col xs={12} sm={12} md={12} lg={12}>
                <FileInput
                  multiple
                  clearable
                  label="Documentação Comprobatória"
                  withAsterisk
                  valueComponent={ValueComponent}
                  {...form.getInputProps("attorneyAttachment")}
                />
              </Grid.Col>

            </Grid>
          </div>}
          {listedCreditor?.rj_credit_request?.length === 0 && <CreditTable />}
          {listedCreditor?.rj_credit_request?.length === 0 &&
            <Flex pt={10} sx={{ justifyContent: "center" }}>
              <Center>
                <Text fw={700} size={20} color={"blue.9"}>
                  Os valores listados estão corretos?
                </Text>
              </Center>
            <SegmentedControl
              onChange={(event)=> {
                form.setFieldValue("requestDivergence",event !== 'sim')
              }}
              color={!form.values.requestDivergence ? "green.8" : "red.8" }
              size="lg"
              sx={{ fontWeight: 600 }}
              value={!form.values.requestDivergence ? 'sim' : 'não'}
              data={[{label:'Sim', value: 'sim'},{label:'Não', value: 'não'}]}
            />
          </Flex>}
          {form.values.requestDivergence && <div>
            <Divider
              mt={20}
              size={"sm"}
              sx={{ backgroundColor: "#ddebf5" }}
              variant="dashed"
              labelPosition="center"
              label={
                <>
                  <Box sx={{ fontWeight: 700, fontSize: 15 }}> {listedCreditor ? "Manifestação de Divergência" : "Solicitação de Habilitação"}</Box>
                </>
              }
            />
            {form.values.divergency.map((val, index) =>
              <>
                <Grid>
                  <Grid.Col xs={12} sm={12} md={12} lg={12}>
                    <CompanyList
                      label={
                        <CustomLabel
                          labelText="Recuperanda"
                          withAsterisk
                          tooltipText="Selecione abaixo a Empresa com qual possui Créditos (Valores a receber)."
                        />}
                      withAsterisk={false}
                      activeRj={activeRj}
                      {...form.getInputProps(`divergency.${index}.debitor`)}
                      rightSection={index !== 0 &&
                      <ActionIcon variant="filled" color="red" onClick={() => {
                        form.removeListItem("divergency", index)
                      }}>
                        <IconTrash />
                      </ActionIcon>
                    } />
                  </Grid.Col>

                  <Grid.Col xs={12} sm={4} md={4} lg={4}>
                    <Select
                      required
                      label={
                        <CustomLabel
                          labelText="Classe"
                          withAsterisk
                          tooltipText="Selecione a classe que indica a natureza do Crédito."
                        />}
                      withAsterisk={false}
                      data={creditClass}
                      {...form.getInputProps(`divergency.${index}.creditClass`)}
                    />
                  </Grid.Col>

                  <Grid.Col xs={12} sm={4} md={4} lg={4}>
                    <Select
                      label="Moeda"
                      required
                      data={currency}
                      {...form.getInputProps(`divergency.${index}.creditCurrency`)}
                    />
                  </Grid.Col>

                  <Grid.Col xs={12} sm={4} md={4} lg={4}>
                    <NumberInput
                      required
                      hideControls
                      decimalSeparator=","
                      thousandsSeparator="."
                      precision={2}
                      parser={(value) => value.replace(/\s?|(\.*)/g, '')}
                      formatter={(value) =>
                        !Number.isNaN(parseFloat(value))
                          ? `${value}`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, '.')
                          : ''
                      }
                      label={
                        <CustomLabel
                          labelText="Valor"
                          withAsterisk
                          tooltipText="Indique o valor do crédito (valor a receber). Este valor deve estar corrigido (juros) apenas até a data do pedido de recuperação judicial."
                        />}
                      withAsterisk={false}
                      {...form.getInputProps(`divergency.${index}.creditAmount`)}
                    />
                  </Grid.Col>

                  <Grid.Col xs={12} sm={12} md={12} lg={12}>
                    <Textarea
                      required
                      minRows={2}
                      maxRows={20}
                      autosize
                      label={
                        <CustomLabel
                          labelText="Descrição da Solicitação"
                          withAsterisk
                          // tooltipText="Informe a origem do seu crédito (Ex: Fornecimento de produtos conforme NF # e ##, Contrato de Locação X, etc.) Em caso de cessão/transferência do crédito, insira as informações do novo detentor."
                          tooltipText={
                            <List fw={300} size="xs" style={{color:"white"}}>
                              <List.Item>
                                Informe a <u><b>origem do seu crédito</b></u>, exemplo:
                                <List fw={300} size="xs" withPadding  style={{color:"white"}}>
                                  <List.Item>Fornecimento de produtos conforme NF # e ##. </List.Item>
                                  <List.Item>Contrato de Locação X.</List.Item>
                                  <List.Item>Etc</List.Item>
                                </List>
                              </List.Item>
                              <List.Item> Informe qual era a data de vencimento original de seu crédito.</List.Item>
                              <List.Item> Em caso de <u><b>cessão/transferência</b></u> do crédito, insira as informações do novo detentor.</List.Item>
                            </List>}
                        />}
                      withAsterisk={false}
                      {...form.getInputProps(`divergency.${index}.disputeDescription`)}
                    />
                  </Grid.Col>

                  <Grid.Col xs={12} sm={12} md={12} lg={12}>

                    <FileInput
                      multiple
                      clearable
                      label={
                        <CustomLabel
                          labelText="Documentação Comprobatória"
                          withAsterisk
                          tooltipText="Envie documentos que servem decomprovação para o crédito informado."
                        />}
                      withAsterisk={false}
                      valueComponent={ValueComponent}
                      {...form.getInputProps(`divergency.${index}.disputeAttachment`)}
                    />
                  </Grid.Col>

                </Grid>

                <Divider
                  mt={20}
                  size={"sm"}
                  variant="dashed"
                />
              </>
            )}

            <ActionIcon mt="lg" variant="filled" onClick={() => {
              form.insertListItem("divergency", {
                debitor: '',
                creditClass: '',
                creditCurrency: '',
                creditAmount: '',
                disputeDescription: '',
                disputeAttachment: [],
              })
            }}>
              <IconPlus />
            </ActionIcon>

          </div>}

          <Flex pt={30} sx={{ justifyContent: "right" }}>
            <Button
              disabled={listedCreditor?.rj_credit_request?.length > 0 || (!form.values.requestDivergence && !listedCreditor)}
              onClick={onSaveHandler}
            >Enviar Solicitação</Button>
          </Flex>
        </>}
        <Flex sx={{ justifyContent: "center" }}>
          <Title size={11} weight={600} >- Desenvolvido por CCC Monitoramento -</Title>
        </Flex>

        <LoadingOverlay visible={loading} zIndex={1000} />
      </Paper>


    </Center>
  );
}