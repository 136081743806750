import { notifications } from "@mantine/notifications";
import { IconCheck } from '@tabler/icons-react';

export function UploadFileSigned(supabaseClient, file, path) {
  return new Promise((resolve, reject) => {
    notifications.show({
      id: path,
      loading: true,
      message: `${file.name} (${0}%)`,
      autoClose: false,
      withCloseButton: false,
    });

    // Typescript will give errors for accessing protected members of supabase
    const signUrl = `${supabaseClient.supabaseUrl}/storage/v1/object/upload/sign/${path}/${file.name}`;

    const headers = supabaseClient.auth.headers;

    const reqSign = new XMLHttpRequest();

    reqSign.onload = () => {
      const { url } = JSON.parse(reqSign.response);

      const req = new XMLHttpRequest();

      req.upload.onprogress = updateProgress;

      req.onload = () => {
        notifications.update({
          id: path,
          loading: false,
          message: `${file.name}`,
          icon: <IconCheck size="1rem" color="green" />,
          autoClose: 2000,
          withCloseButton: true,
        });

        resolve(JSON.parse(req.response));
      };

      req.onabort = reject;
      // You might want to also listen to onabort, onerror, ontimeout
      req.open("PUT", `${supabaseClient.supabaseUrl}/storage/v1/${url}`);

      for (const [key, value] of Object.entries(headers)) {
        req.setRequestHeader(key, value);
      }

      if (file.name.endsWith(".pdf")) {
        req.setRequestHeader("Content-Type", "application/pdf");
      }

      req.send(file);

      function updateProgress(e) {
        const pct = (e.loaded / e.total) * 100;

        if (pct <= 99.9) {
          notifications.update({
            id: path,
            loading: true,
            message: `Uploading ${file.name} (${pct.toFixed(2)}%)`,
            autoClose: false,
            withCloseButton: false,
          });
        }
        else {
          notifications.update({
            id: path,
            loading: false,
            message: `${file.name}`,
            autoClose: 2000,
            icon: <IconCheck size="1rem" color="green" />,
            withCloseButton: true,
          });
        }
      }
    };

    reqSign.onabort = reject;
    // You might want to also listen to onabort, onerror, ontimeout
    reqSign.open("POST", signUrl);

    for (const [key, value] of Object.entries(headers)) {
      reqSign.setRequestHeader(key, value);
    }

    reqSign.send("");
  })
}