import React, { Suspense, useEffect, memo, useState } from "react";
import { NavbarSection } from "./Navbar.jsx";
import { modals } from "@mantine/modals";
import {
  AppShell,
  Tabs,
  CloseButton,
  createStyles,
  rem,
  ActionIcon,
  LoadingOverlay,
  Grid,
  Group,
  TextInput,
  Button, Badge, Select, NumberInput,
} from "@mantine/core";

import { TabsProvider, useTabs } from "./lib/Tabs";

import { UploaderProvider } from "./Uploader";

import { notifications } from "@mantine/notifications";

import { useSupabaseClient, useUser } from "@supabase/auth-helpers-react";

import { openChangePassword } from "./ChangePassword";

import { NovaFonte } from "./pages/CreateEvent";

import QGCPage from "./pages/QGCPage.jsx";
import RVCPage from "./pages/RVCPage.jsx";
import GraphPage from "./pages/GraphPage.jsx";
import SummaryPage from "./pages/SummaryPage";
import EsgReportPage from "./pages/EsgReportPage";

import {
  IconRefresh, IconX, IconCalendarPlus, IconIdBadge2, IconReportSearch, IconHierarchy, IconReportAnalytics, IconMapPin2
} from "@tabler/icons-react";




import { HeaderSection } from "./Header";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import ConsultaPage from "./pages/ConsultaPage.jsx";
import { useSearchParams } from "react-router-dom";
import PdfPrinterPage from "./PdfPrinterPage.jsx";
import { NotificationProvider } from "./Notifications.jsx";
import LawsuitComponent from "./components/LawsuitComponent";
import OpBndesComponent from "./components/OpBndesComponent";
import CandidateDataComponent from "./components/CandidateDataComponent";

const TablePage = React.lazy(() => import("./TablePage"));
const HomePage = React.lazy(() => import("./pages/Home"));
const HelpPage = React.lazy(() => import("./pages/Help"));
const CreateEventPage = React.lazy(() => import("./pages/CreateEvent"));
const EventsPage = React.lazy(() => import("./pages/Events"));
const TimelinePage = React.lazy(() => import("./pages/Timeline"));
const CreditorInfoPage = React.lazy(() => import("./pages/CreditorInfoPage"));
const RequestAnalysePage = React.lazy(() => import("./pages/RequestAnalysePage"));
const SourceAnalysePage = React.lazy(() => import("./pages/SourceAnalysePage"));


const UsersPage = (props) => {
  const user = useUser();
  const supabase = useSupabaseClient();

  return (
    <TablePage
      {...props}
      tableName={"v_users"}
      canCreate={"admin"}
      canEdit={"user"}
      extraActions={[
        {
          Icon: IconRefresh,
          label: "Reenviar email de confirmação",
          async handler(row) {
            const { data, error } = await supabase.rpc("f_reenvite_user", {
              _user_id: row.id
            });

            if (error) {
              notifications.show({
                withCloseButton: true,
                autoClose: 3000,
                message: error.message,
                color: "red",
                icon: <IconX />,
                loading: false,
              });
            } else {
              notifications.show({
                withCloseButton: true,
                autoClose: 3000,
                message: "Email enviado com sucesso!",
                color: "green",
                icon: <IconCalendarPlus />,
                loading: false,
              });
            }
          }
        }
      ]}
      schema={[
        {
          header: "Email",
          accessorKey: "email",
          size: 170,
          minSize: 170,
          enableEditing: false,
          enableCreating: true,
        },
        {
          header: "Nome",
          size: 100,
          accessorKey: "name",
          enableEditing: true,
        },
        {
          header: "Grupos",
          accessorKey: "groups",
          type: "autocomplete",
          tableName: "v_groups",
          filters: [["is_user_group", "eq", false]],
          fieldName: "name",
          groupName: "type",
          enableEditing: user.user_metadata.is_admin
        },
        {
          header: "Projetos",
          accessorKey: "projects",
          type: "autocomplete",
          tableName: "project",
          fieldName: "name",
          groupName: "type",
        },
        {
          header: "Admin",
          size: 70,
          minSize: 70,
          enableEditing: user.user_metadata.is_admin,
          accessorKey: "is_admin",
          type: "boolean",
        },
        {
          header: "Email Diário",
          size: 90,
          minSize: 90,
          enableEditing: true,
          enableClickToCopy: false,
          tooltip: "Dias considerados pelo Lembrete",
          accessorKey: "daily_reminder",
          type: "remember_days",
        },
        {
          header: "Email Semanal",
          size: 90,
          minSize: 90,
          enableEditing: true,
          enableClickToCopy: false,
          tooltip: "Dias considerados pelo Lembrete",
          accessorKey: "weekly_reminder",
          type: "remember_days",
        },
        {
          header: "Email Mensal",
          size: 90,
          minSize: 90,
          enableEditing: true,
          enableClickToCopy: false,
          tooltip: "Dias considerados pelo Lembrete",
          accessorKey: "monthly_reminder",
          type: "remember_days",
        },
        {
          header: "Último login",
          size: 90,
          minSize: 90,
          maxSize: 90,
          enableColumnFilter: false,
          enableColumnActions: false,
          enableEditing: false,
          accessorKey: "last_sign_in_at",
          type: "datetime",
        },
      ]}
    />
  );
};

const GroupsPage = (props) => {
  return (
    <TablePage
      {...props}
      tableName={"v_groups"}
      canCreate={"admin"}
      canEdit={"admin"}
      filters={[["is_user_group", "eq", false]]}
      schema={[
        {
          header: "Nome",
          accessorKey: "name",
          size: 120,
          enableEditing: true,
        },
        {
          header: "Usuários",
          accessorKey: "users",
          size: 400,
          enableEditing: true,
          type: "autocomplete",
          tableName: "v_users",
          fieldName: "email",
        },
        {
          header: "Projetos Relacionados",
          accessorKey: "project_name",
          size: 400,
          enableEditing: true,
          type: "autocomplete",
          tableName: "project",
          fieldName: "name",
        },
      ]}
    />
  );
};

const ProjectsPage = (props) => {
  return (
    <TablePage
      {...props}
      tableName={"v_project"}
      canCreate={"admin"}
      canEdit={"admin"}
      schema={[
        {
          header: "Nome",
          accessorKey: "name",
          size: 150,
          enableEditing: true,
        },
        {
          header: "Recuperação Judicial",
          size: 100,
          accessorKey: "is_rj",
          enableEditing: true,
          type: "boolean",
        },
        {
          header: "Início",
          accessorKey: "start_date",
          enableEditing: true,
          type: "date",
        },
        {
          header: "Término",
          accessorKey: "end_date",
          enableEditing: true,
          type: "date",
        },
        {
          header: "Grupos/Usuários Associados",
          accessorKey: "groups",
          size: 300,
          enableEditing: true,
          type: "autocomplete",
          tableName: "v_groups",
          groupName: "type",
          fieldName: "name",
        },
      ]}
    />
  );
};

const CompanyPage = (props) => {

  const { addTab } = useTabs();
  return (
    <TablePage
      {...props}
      tableName={"v_rj_company"}
      canCreate={"all"}
      extraTableActions={[{
        Icon: IconHierarchy,
        label: "Visualizar Relacionamentos",
        async handler(rows, refresh) {
          addTab({
            label: `Relacionamento/1`,
            id: `Relacionamento/1`,
            component: "Graph",
            disableRefresh: true,
            props: {
              rows: rows.map((r) => r.original.doc)
            }
          });

        }
      }
      ]}
      canEdit={"creator"}
      schema={[
        {
          header: "Projeto",
          required: true,
          accessorKey: "project_name",
          enableEditing: false,
          type: "select",
          tableName: "project",
          fieldName: "name",
          groupName: "type",
        },
        {
          header: "Processo",
          accessorKey: "rj_process_number",
          enableEditing: true,
          type: "select",
          tableName: "v_rj_process",
          fieldName: "number",
          groupName: "project_name",
        },
        {
          header: "Razão Social",
          accessorKey: "name",
          enableEditing: true,
        },
        {
          header: "Doc",
          accessorKey: "doc",
          enableEditing: true,
        },
        {
          header: "Tags",
          accessorKey: "tag",
          enableEditing: true,
          creatable: true,
          type: "autocomplete",
          fieldName: "name",
        },
        {
          header: "Criado por",
          accessorKey: "user_name",
          size: 80,
          enableEditing: false,
        },
      ]}
    />
  );
};

const CreditorPage = (props) => {
  return (
    <TablePage
      {...props}
      tableName={"v_rj_creditor_list"}
      extendColumns={"file_path"}
      canCreate={"all"}
      canEdit={"creator"}
      schema={[
        {
          header: "Projeto",
          required: true,
          accessorKey: "project_name",
          enableEditing: false,
          type: "select",
          tableName: "project",
          fieldName: "name",
          groupName: "type",
        },
        {
          header: "Processo",
          accessorKey: "rj_process_number",
          enableEditing: true,
          type: "select",
          tableName: "v_rj_process",
          fieldName: "number",
          groupName: "project_name",
        },
        {
          header: "Descrição",
          accessorKey: "description",
          enableEditing: true,
        },
        {
          header: "Data de Criação",
          accessorKey: "created_at",
          type: "datetime",
        },
        {
          header: "Anexo",
          type: "file",
          accessorKey: "file_name",
          enableEditing: true,
        },
        {
          header: "Criado por",
          accessorKey: "user_name",
          size: 80,
          enableEditing: false,
        },
      ]}
    />
  );
};

const PlanPage = (props) => {
  return (
    <TablePage
      {...props}
      tableName={"v_rj_plan"}
      canCreate={"all"}
      canEdit={"creator"}
      schema={[
        {
          header: "Projeto",
          required: true,
          accessorKey: "project_name",
          enableEditing: false,
          type: "select",
          tableName: "project",
          fieldName: "name",
          groupName: "type",
        },
        {
          header: "Processo",
          accessorKey: "rj_process_number",
          enableEditing: true,
          type: "select",
          tableName: "v_rj_process",
          fieldName: "number",
          groupName: "project_name",
        },
        {
          header: "Descrição",
          accessorKey: "description",
          enableEditing: true,
        },
        {
          header: "Data de Protocolo",
          accessorKey: "protocol_date",
          enableEditing: true,
          type: "date",
        },
        {
          header: "Data de Homologação",
          accessorKey: "homologation_date",
          enableEditing: true,
          type: "date",
        },
        {
          header: "Criado por",
          accessorKey: "user_name",
          size: 80,
          enableEditing: false,
        },
      ]}
    />
  );
};

const AssemblyPage = (props) => {
  return (
    <TablePage
      {...props}
      tableName={"v_rj_assembly"}
      canCreate={"all"}
      canEdit={"creator"}
      schema={[
        {
          header: "Projeto",
          required: true,
          accessorKey: "project_name",
          enableEditing: false,
          type: "select",
          tableName: "project",
          fieldName: "name",
          groupName: "type",
        },
        {
          header: "Processo",
          accessorKey: "rj_process_number",
          enableEditing: true,
          type: "select",
          tableName: "v_rj_process",
          fieldName: "number",
          groupName: "project_name",
        },
        {
          header: "Descrição",
          accessorKey: "description",
          enableEditing: true,
        },
        {
          header: "Data",
          accessorKey: "date",
          enableEditing: true,
          type: "date",
        },
        {
          header: "Tags",
          accessorKey: "tag",
          enableEditing: true,
          type: "autocomplete",
          fieldName: "name",
        },
        {
          header: "Criado por",
          accessorKey: "user_name",
          size: 80,
          enableEditing: false,
        },
      ]}
    />
  );
};

const ProcessPage = (props) => {
  return (
    <TablePage
      {...props}
      tableName={"v_rj_process"}
      canCreate={"all"}
      canEdit={"creator"}
      schema={[
        {
          header: "Projeto",
          accessorKey: "project_name",
          enableEditing: true,
          type: "select",
          required: true,
          tableName: "project",
          fieldName: "name",
          groupName: "type",
        },
        {
          header: "Número",
          accessorKey: "number",
          enableEditing: true,
        },
        {
          header: "Tribunal",
          accessorKey: "court",
          enableEditing: true,
        },
        {
          header: "Data do Pedido",
          accessorKey: "request_date",
          enableEditing: true,
          type: "date",
        },
        {
          header: "Criado por",
          accessorKey: "user_name",
          size: 80,
          enableEditing: false,
        },
      ]}
    />
  );
};

const PdfViewer = (props) => {
  const [url, setUrl] = useState(null);
  const [loading, handlers] = useDisclosure(true);

  const user = useUser();
  const supabaseClient = useSupabaseClient();

  const onLoadHandler = () => {
    if (Notification?.permission === "granted" && document?.visibilityState == "hidden") {
      new Notification(`PDF Carregado com sucesso!`, { tag: "pdf" })
    }

    handlers.close();

    props?.onBlink();
  }

  useEffect(() => {
    if (props.internal) {
      setUrl(props.url);

      document.cookie = `sb-${window.location.host.substring(0, window.location.host.indexOf('.'))}-auth-token=` + btoa(localStorage.getItem(`sb-${window.location.host.substring(0, window.location.host.indexOf('.'))}-auth-token`)) + ";max-age=30;";
    }
    else {
      supabaseClient.storage
        .from(user.user_metadata.tenant_id.toString())
        .createSignedUrl(props.url, 60, {
          download: false,
        })
        .then(({ data }) => {
          if (props.url.indexOf("#") > 0) {
            setUrl(data.signedUrl + props.url.substring(props.url.indexOf("#")));
          }
          else {
            setUrl(data.signedUrl);
          }
        });

    }
  }, []);

  return <div style={{ margin: "4px", height: "calc(100vh - 68px)" }} className={props.className} >
    {url && <iframe onLoad={onLoadHandler} src={url} style={{ width: "100%", height: "100%" }} />}
    <LoadingOverlay visible={loading} />
  </div>
};

const SourcePage = (props) => {
  return (
    <TablePage
      {...props}
      tableName={"v_source_part"}
      extendColumns={"file_path"}
      createNew={() => {
        modals.open({
          size: "xl",
          title: "Nova Fonte",
          modalId: "new-event",
          withinPortal: true,
          children: <NovaFonte callback={async evt => {

          }} />,
        });
      }}
      initialState={{
        // expanded: true, //expand all groups by default
        // grouping: ['name'], //an array of columns to group by by default (can be multiple)
        // sorting: [{ id: 'name', desc: false }], //sort by state by default
      }}
      schema={[
        {
          header: "Projeto",
          accessorKey: "project_name",
          required: true,
          enableEditing: true,
          type: "select",
          minSize: 80,
          maxSize: 90,
          tableName: "project",
          fieldName: "name",
          groupName: "type",
        },
        {
          header: "Título",
          accessorKey: "name",
          enableEditing: true,
          type: "text",
          minSize: 100,
          maxSize: 150,
        },
        {
          header: "Descrição",
          accessorKey: "description",
          enableEditing: true,
          type: "text",
          minSize: 500,
          maxSize: 800,
        },
        {

          enableGlobalFilter: false,
          header: "Visualizar",
          accessorKey: "file_name",
          enableEditing: false,
          type: "file",
          maxSize: 100,
          minSize: 100,
        },
      ]}
    />
  );
};

const EventsCollectionPage = (props) => {
  return (
    <TablePage
      {...props}
      canEdit={"all"}
      canCreate={"all"}
      tableName={"v_event_collection"}
      schema={[
        {
          header: "Projeto",
          accessorKey: "project_name",
          enableEditing: true,
          required: true,
          type: "select",
          minSize: 90,
          tableName: "project",
          fieldName: "name",
          groupName: "type",
        },
        {
          header: "Título",
          accessorKey: "event_collection_title",
          enableEditing: true,
          minSize: 120,
        },
        {
          header: "Qtd Eventos Relacionados",
          accessorKey: "related_event_count",
          enableEditing: false,
          enableColumnFilter: false,
          enableColumnActions: false,
          minSize: 60,
          maxSize: 70,
        },
      ]}
    />
  );
};

const EventsTablePage = (props) => {
  return (
    <TablePage
      {...props}
      navBarFilter
      canEdit={"all"}
      canCreate={"all"}
      sort={[["event_end_date", { ascending: true }]]}
      extendColumns={"event_id"}
      tableName={"v_event"}
      schema={[
        {
          header: "Projeto",
          required: true,
          accessorKey: "project_name",
          size: 120,
          minSize: 120,
          type: "text",
          enableEditing: false,
        },
        {
          header: "Título",
          accessorKey: "event_title",
          size: 150,
          minSize: 150,
          type: "text",
          enableEditing: false,
        },
        {
          header: "Descrição",
          accessorKey: "event_description",
          size: 400,
          minSize: 400,
          enableEditing: false,
          type: "text",
        },
        {
          header: "Tags",
          accessorKey: "tag",
          minSize: 180,
          maxSize: 180,
          type: 'tag',
          filterFn: (row, id, filterValue) => {
            return row.getValue(id)?.filter((val) => val?.toLowerCase().includes(filterValue.toLowerCase())).length > 0;
          },
          enableEditing: false,
          enableClickToCopy: false
        },
        {
          header: "Início",
          minSize: 110,
          maxSize: 110,
          accessorKey: "event_start_date",
          enableEditing: false,
          type: "date",
          highlightDelayed: true,
          enableClickToCopy: false
        },
        {
          header: "Fim",
          minSize: 110,
          maxSize: 110,
          accessorKey: "event_end_date",
          enableEditing: false,
          type: "date",
          highlightDelayed: true,
          enableClickToCopy: false
        },
        {
          header: "Relacionados",
          accessorKey: "event_related",
          enableEditing: false,
          size: 150,
          minSize: 150,
          type: "related",
          filterFn: (row, id, filterValue) => {
            return row.getValue(id)?.filter((val) => val?.l?.toLowerCase().includes(filterValue.toLowerCase())).length > 0;
          },
          enableClickToCopy: false
        },
        {
          header: "Status",
          accessorKey: "schedule_status_object",
          size: 90,
          minSize: 90,
          type: 'status',
          enableEditing: false,
          enableClickToCopy: false
        },
      ]}
      withStatus
      isEvent
    />
  );
};

const RequestHDPage = (props) => {
  const { addTab } = useTabs();
  return (
    <TablePage
      {...props}
      canEdit={"none"}
      canCreate={"all"}
      tableName={"v_rj_credit_request"}
      extendColumns={"rj_creditor_id"}
      initialState={{
        // expanded: true, //expand all groups by default
        // grouping: ['creditor_name'], //an array of columns to group by by default (can be multiple)
        // sorting: [{ id: 'name', desc: false }], //sort by state by default
      }}
      // filters={[["type", "not.eq", "I"]]}
      extraActions={[
        {
          Icon: IconIdBadge2,
          label: "Informações do Credor",
          async handler(row) {
            addTab({
              label: `Credor/${row.original.rj_creditor_id}`,
              id: `Event/${row.original.rj_creditor_id}`,
              component: "CreditorInfo",
              disableRefresh: true,
              props: {
                creditorId: row.original.rj_creditor_id
              }
            });
          }
        },
      ]}

      schema={[
        {
          header: "Tipo",
          accessorKey: "request_type",
          enableEditing: true,
          format(val) {
            return `${val.type}`;
          },
          type: "json",
          minSize: 120,
          maxSize: 120,
        },
        {
          header: "Nome",
          accessorKey: "creditor_name",
          enableEditing: true,
          minSize: 80,
        },
        {
          header: "Doc",
          accessorKey: "creditor_doc",
          type: "doc",
          enableEditing: true,
          minSize: 140,
          maxSize: 160,
        },
        {
          header: "Solicitado em",
          accessorKey: "requested_at",
          enableEditing: false,
          type: "datetime",
          minSize: 100,
          maxSize: 130,
        },
        {
          header: "Recuperanda",
          accessorKey: "rj_company_name",
          enableEditing: false,
          type: "text",
        },
        {
          header: "Classe",
          accessorKey: "rj_credit_class_name",
          enableEditing: false,
          type: "json",
          filterFn: (row, id, filterValue) => {
            return (row.getValue(id)[0].formatted.toLowerCase().includes(filterValue.toLowerCase()));
          },
          format(val) {
            return `${val.name}`;
          },
          minSize: 96,
          maxSize: 96,
        },
        {
          header: "Descrição Origem",
          accessorKey: "source",
          enableEditing: false,
          type: "text",
        },
        {
          header: "Moeda",
          accessorKey: "currency",
          enableEditing: false,
          type: "text",
          minSize: 90,
          maxSize: 90,
        },
        {
          header: "Valor Solicitado",
          accessorKey: "amount",
          enableEditing: false,
          type: "number",
          maxSize: 120,
        },
        {
          header: "Status",
          accessorKey: "status",
          type: 'json',
          enableEditing: true,
          format(val) {
            return `${val.type}`;
          },
          minSize: 80,
          maxSize: 80,
        }
      ]}

    />
  );
};

const RequestIPage = (props) => {
  const { addTab } = useTabs();

  return (
    <TablePage
      {...props}
      canEdit={"none"}
      canCreate={"all"}
      tableName={"v_request"}
      filters={[["type", "eq", "I"]]}
      extraActions={[
        {
          Icon: IconReportSearch,
          label: "Análise",
          async handler(row) {
            addTab({
              label: `Análise/${row.id}`,
              id: `Event/${row.id}`,
              component: "RequestAnalyse",
              disableRefresh: true,
              props: {
                requestId: row.id
              }
            });
          }
        }
      ]}

      schema={[
        {
          header: "Tipo",
          accessorKey: "request_type",
          enableEditing: true,
          format(val) {
            return `${val.type}`;
          },
          type: "json",
          minSize: 100,
          maxSize: 100,
        },
        {
          header: "Nome",
          accessorKey: "creditor_name",
          enableEditing: true,
          minSize: 100,
        },
        {
          header: "Doc",
          accessorKey: "creditor_doc",
          type: "doc",
          enableEditing: true,
          minSize: 140,
          maxSize: 140,
        },
        {
          header: "Solicitado em",
          accessorKey: "requested_at",
          enableEditing: true,
          type: "datetime",
          minSize: 110,
          maxSize: 130,
        },
        {
          header: "Processo",
          accessorKey: "process_number",
          enableEditing: true,
          type: "text",
          minSize: 140,
          maxSize: 140,
        },
        {
          header: "Status",
          accessorKey: "status",
          type: 'json',
          enableEditing: true,
          format(val) {
            return `${val.type}`;
          },
          minSize: 100,
          maxSize: 100,
        },
        {
          header: "Conclusão",
          accessorKey: "conclusion_type",
          type: 'text',
          minSize: 120,
          maxSize: 120,
        },
        {
          header: "Valor QGC",
          accessorKey: "qgc_credit_amount",
          enableEditing: true,
          type: "number",
          showTotal: false,
          minSize: 140,
          maxSize: 140,
        },
        {
          header: "Valor Solicitado",
          accessorKey: "requested_amount",
          enableEditing: true,
          type: "number",
          showTotal: false,
          minSize: 140,
          maxSize: 140,
        },
      ]}

    />
  );
};

const EsgCompanyPage = (props) => {
  const { addTab } = useTabs();

  return (
    <TablePage
      {...props}
      canEdit={"none"}
      canCreate={"all"}
      tableName={"esg_company"}
      // filters={[["type", "eq", "I"]]}

      extraActions={[
        {
          Icon: IconReportAnalytics,
          label: "Relatório",
          async handler(row) {
            addTab({
              label: `Relatório/${row.id}`,
              id: `ESG/Report/${row.id}`,
              component: "PdfPrinter",
              subcomponent: "EsgReport",
              disableRefresh: true,
              props: {
                id: row.id,
                landscape: true,
                waitFor: ".loaded",
                // pageWidth: 1682,
                // pageHeight: 1190,
                name: row.original.name,
                input: row.original.doc,
                inputType: "doc"
              }
            });
          }
        }
      ]}

      schema={[
        {
          header: "CPF/CNPJ",
          accessorKey: "doc",
          enableEditing: true,
          type: "doc",
          minSize: 100,
          maxSize: 100,
        },
        {
          header: "Nome",
          accessorKey: "name",
          enableEditing: true,
          minSize: 100,
        },
        {
          header: "Tipo",
          accessorKey: "entity_type",
          type: "text",
          enableEditing: false,
          minSize: 140,
          maxSize: 140,
        }
      ]}

    />
  );
};

const EsgRegistryPage = (props) => {
  const { addTab } = useTabs();

  const tipos = [
    { "type": "legal", "color": "gray.9", "label": "Pessoa Jurídica" },
    { "type": "natural", "color": "blue", "label": "Pessoa Física" },
    { "type": "property", "color": "orange.9", "label": "Imovél Rural" },
  ];

  return (
    <TablePage
      {...props}
      canCreate={"all"}
      tableName={"esg_registry"}
      // filters={[["type", "eq", "I"]]}

      extraActions={[
        {
          Icon: IconMapPin2,
          label: "Visualizar no Mapa",
          async handler(row) {
            addTab({
              component: "Map",
              id: `Consulta/${row.original.name}`,
              label: `Consulta/${row.original.name}`,
              props: {
                query: row.original.cod_list[0].cod,
              }
            });
          }
        },
        {
          Icon: IconReportAnalytics,
          label: "Relatório",
          async handler(row) {
            addTab({
              label: `Relatório/${row.id}`,
              id: `ESG/Report/${row.id}`,
              component: "PdfPrinter",
              subcomponent: "EsgReport",
              disableRefresh: true,
              props: {
                id: row.id,
                landscape: true,
                waitFor: ".loaded",
                inputList: row.original.cod_list,
                name: row.original.name,
                input: row.original.cod_list[0].cod,
                inputType: row.original.cod_list[0].codType,
              }
            });
          }
        },
        {
          Icon: IconHierarchy,
          label: "Visualizar Relacionamentos",
          async handler(row, refresh) {
            addTab({
              label: `Relacionamento/` + row.original.name,
              id: `Relacionamento/` + row.original.name,
              component: "Graph",
              disableRefresh: true,
              props: {
                rows: row.original.cod_list.filter((f) => f.codType === 'doc').map((r) => r.cod)
              }
            });

          }
        }
      ]}

      schema={[
        {
          header: "Nome",
          accessorKey: "name",
          enableEditing: true,
          minSize: 100,
          maxSize: 100,
        },
        {
          header: "Portfólio",
          accessorKey: "collection",
          enableEditing: true,
          minSize: 100,
          maxSize: 100,
        },
        {
          header: "Identificadores",
          accessorKey: "cod_list",
          format(val) {
            return `${val.codType}: ${val.cod}`;
          },
          schema: [
            {
              header: "Cod Type",
              accessorKey: "codType",
              enableEditing: true,
              minSize: 100,
              maxSize: 100,
              type: "cod_types"
            },
            {
              header: "Cod",
              accessorKey: "cod",
              enableEditing: true,
              minSize: 100,
              maxSize: 100,
            },
          ],
          EditEl({ initialValues, handleSubmit }) {
            const form = useForm({
              initialValues: initialValues || {
                codType: null,
                cod: ""
              }
            });

            return <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
              <Grid>
                <Grid.Col span={12}>
                  <TextInput
                    required
                    label="Cod"
                    {...form.getInputProps("cod")}
                  />
                </Grid.Col>

                <Grid.Col span={12}>
                  <Select
                    required
                    label="CodType"
                    withinPortal
                    data={[
                      { value: 'doc', label: 'DOC' },
                      { value: 'incra', label: 'INCRA' },
                      { value: 'car', label: 'CAR' },
                      { value: 'cib', label: 'CIB' },
                    ]}
                    {...form.getInputProps("codType")}
                  />
                </Grid.Col>

                <Grid.Col span={12}>
                  <Button type="submit" mt="md">
                    Salvar
                  </Button>
                </Grid.Col>
              </Grid>
            </form>
          },
          enableEditing: true,
          type: "json",
          minSize: 100,
        },
        /*
         {
           header: "Proprietários",
           accessorKey: "owner",
           format(val) {
             return `${val.name} - ${val.perc}`;
           },
           EditEl({ initialValues, handleSubmit }) {
             const form = useForm({
               initialValues: initialValues || {
                 name: "",
                 perc: 1
               }
             });
 
             return <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
               <Grid>
                 <Grid.Col span={12}>
                   <TextInput
                     required
                     label="Nome"
                     {...form.getInputProps("name")}
                   />
                 </Grid.Col>
 
                 <Grid.Col span={12}>
                   <NumberInput
                     required
                     label="Percentual"
                     withinPortal
                     {...form.getInputProps("perc")}
                   />
                 </Grid.Col>
 
                 <Grid.Col span={12}>
                   <Button type="submit" mt="md">
                     Salvar
                   </Button>
                 </Grid.Col>
               </Grid>
             </form>
           },
           enableEditing: true,
           type: "json",
           minSize: 100,
         },
         */
      ]}

    />
  );
};

const components = {
  Home: HomePage,
  Map: ConsultaPage,
  Help: HelpPage,
  Users: UsersPage,
  Groups: GroupsPage,
  Projects: ProjectsPage,
  Events: EventsPage,
  EventsTable: EventsTablePage,
  EventsCollection: EventsCollectionPage,
  Company: CompanyPage,
  Creditor: CreditorPage,
  Plan: PlanPage,
  Assembly: AssemblyPage,
  Process: ProcessPage,
  Source: SourcePage,
  CreateEvent: CreateEventPage,
  Timeline: TimelinePage,
  PdfViewer: PdfViewer,
  QGC: QGCPage,
  Graph: GraphPage,
  Summary: SummaryPage,
  RVC: RVCPage,
  RequestHD: RequestHDPage,
  RequestI: RequestIPage,
  RequestAnalyse: RequestAnalysePage,
  SourceAnalyse: SourceAnalysePage,
  CreditorInfo: CreditorInfoPage,
  Lawsuit: LawsuitComponent,
  OpBndes: OpBndesComponent,
  CandidateData: CandidateDataComponent,
  EsgCompany: EsgCompanyPage,
  EsgRegistry: EsgRegistryPage,
  PdfPrinter: PdfPrinterPage,
  EsgReport: EsgReportPage
};

const useStyles = createStyles((theme) => ({
  tabs: {
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },

  tabsList: {
    borderBottom: "0 !important",
    marginLeft: 12,
    marginRight: 60,
    flexWrap: "nowrap !important",
  },

  tab: {
    fontWeight: 500,
    height: rem(32),
    backgroundColor: "transparent",

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[5]
          : theme.colors.gray[1],
    },

    "&[data-active]": {
      backgroundColor:
        theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
      borderColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[7]
          : theme.colors.gray[4],
    },
  },

  content: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,

    border: `${rem(1)} solid ${theme.colorScheme === "dark" ? "transparent" : theme.colors.gray[4]
      }`,
  },
}));


function InnerLayout() {
  const { tabs, activeTab, setActiveTab, removeTab, setTabs } = useTabs();
  const { classes } = useStyles();

  const [searchParams] = useSearchParams();

  let tab = searchParams.get("tab");

  if (tab) {
    tab = decodeURIComponent(tab);
    tab = JSON.parse(tab);

    const { id, component, subcomponent, props, refreshRequested } = tab;

    const Component = components[component];
    const SubComponent = components[subcomponent];

    if (SubComponent) {
      return <SubComponent
        className={"active"}
        tabId={id}
        refreshRequested={refreshRequested}
        header={tab.label}
        isPrint={true}
        {...props}
      />
    }
    else
      if (Component) {
        return <AppShell
          padding={"xs"}
          navbar={<NavbarSection alwaysCollapsed />}
          header={<HeaderSection />}
          styles={(theme) => ({
            main: {
              paddingTop: 14,
              backgroundColor:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[6]
                  : theme.colors.gray[2],
            },
          })}
        >
          <Suspense
            key={id}
            fallback={
              <div className={"active"}>
                <LoadingOverlay visible></LoadingOverlay>
              </div>
            }
          >
            <Component
              className={"active"}
              tabId={id}
              refreshRequested={refreshRequested}
              header={tab.label}
              onBlink={() => {
                if (activeTab == tab.id)
                  return;

                const clone = [...tabs];

                const index = clone.indexOf(tab);

                if (index >= 0) {
                  clone[index] = { ...clone[index], blinking: true }

                  setTabs(clone);
                }
              }}
              onRefresh={() => {
                const clone = [...tabs];

                const index = clone.indexOf(tab);

                if (index >= 0) {
                  clone[index] = { ...clone[index], refreshRequested: false }

                  setTabs(clone);
                }
              }}
              {...props}
            />
          </Suspense>
        </AppShell>
      }
  }
  else {
    return (
      <AppShell
        padding={"xs"}
        navbar={<NavbarSection />}
        header={<HeaderSection />}
        styles={(theme) => ({
          main: {
            paddingTop: 14,
            backgroundColor:
              theme.colorScheme === "dark"
                ? theme.colors.dark[6]
                : theme.colors.gray[2],
          },
        })}
      >
        <Tabs
          value={activeTab}
          onTabChange={setActiveTab}
          variant="outline"
          classNames={{
            root: classes.tabs,
            tabsList: classes.tabsList,
            tab: classes.tab,
          }}
          styles={{
            tabLabel: { overflow: "hidden", textOverflow: "ellipsis" },
            tabIcon: { marginRight: "0px !important" },
            tabRightSection: { marginLeft: "4px !important" },
          }}
        >
          <Tabs.List>
            {tabs.map((tab) => (
              <Tabs.Tab
                key={tab.id}
                px={4}
                onDoubleClickCapture={() => {
                  window.open(`https://cccfacilcontrole.com.br?tab=${encodeURIComponent(JSON.stringify(tab))}`, '_blank').focus();
                }}
                style={{ maxWidth: `calc(100% / ${tabs.length})` }}
                icon={
                  <ActionIcon variant="subtle"
                    disabled={tab.disableRefresh}
                    color="primary"
                    loading={tab.refreshRequested}
                    mr="xs"
                    onClick={() => {
                      const clone = [...tabs];

                      const index = clone.indexOf(tab);

                      if (index >= 0) {
                        clone[index] = { ...clone[index], refreshRequested: true }

                        setTabs(clone);
                      }
                    }}><IconRefresh size="0.8rem" /></ActionIcon>}
                rightSection={
                  <CloseButton
                    ml="xs"
                    disabled={tab.id === "Home"}
                    color="dark"
                    variant="subtle"
                    radius="xs"
                    onClick={(e) => {
                      removeTab(tab.id);

                      e.stopPropagation();
                    }}
                  />
                }
                value={tab.id}
              >
                <span className={tab.blinking ? "blinking" : ""}>{tab.label}</span>
              </Tabs.Tab>
            ))}
          </Tabs.List>
        </Tabs>

        <div className={classes.content}>
          {tabs.map((tab) => {
            const { id, component, subcomponent, props, refreshRequested } = tab;

            const Component = components[component];
            const SubComponent = components[subcomponent];

            if (Component) {
              return (
                <Suspense
                  key={id}
                  fallback={
                    <div className={id === activeTab ? "active" : "hidden"}>
                      <LoadingOverlay visible></LoadingOverlay>
                    </div>
                  }
                >
                  <Component
                    className={id === activeTab ? "active" : "hidden"}
                    tabId={id}
                    header={tab.label}
                    refreshRequested={refreshRequested}
                    component={component}
                    subcomponent={subcomponent}
                    onBlink={() => {
                      if (activeTab == tab.id)
                        return;

                      const clone = [...tabs];

                      const index = clone.indexOf(tab);

                      if (index >= 0) {
                        clone[index] = { ...clone[index], blinking: true }

                        setTabs(clone);
                      }
                    }}
                    onRefresh={() => {
                      const clone = [...tabs];

                      const index = clone.indexOf(tab);

                      if (index >= 0) {
                        clone[index] = { ...clone[index], refreshRequested: false }

                        setTabs(clone);
                      }
                    }}
                    {...props}
                  >
                    {SubComponent && <Suspense
                      key={id}
                      fallback={
                        <div className={id === activeTab ? "active" : "hidden"}>
                          <LoadingOverlay visible></LoadingOverlay>
                        </div>
                      }
                    ><SubComponent
                        className={id === activeTab ? "active" : "hidden"}
                        tabId={id}
                        header={tab.label}
                        {...props}
                      />
                    </Suspense>
                    }
                  </Component>
                </Suspense>
              );
            }
          })}
        </div>
      </AppShell>
    );
  }
}

/**
 * EnrollMFA shows a simple enrollment dialog. When shown on screen it calls
 * the `enroll` API. Each time a user clicks the Enable button it calls the
 * `challenge` and `verify` APIs to check if the code provided by the user is
 * valid.
 * When enrollment is successful, it calls `onEnrolled`. When the user clicks
 * Cancel the `onCancelled` callback is called.
 */
export function EnrollMFA({
  onEnrolled,
  onCancelled,
}) {
  const supabase = useSupabaseClient();
  const user = useUser();
  const [factorId, setFactorId] = useState('')
  const [qr, setQR] = useState('') // holds the QR code image SVG
  const [verifyCode, setVerifyCode] = useState('') // contains the code entered by the user
  const [error, setError] = useState('') // holds an error message

  const onEnableClicked = () => {
    setError('')
      ; (async () => {
        const challenge = await supabase.auth.mfa.challenge({ factorId })
        if (challenge.error) {
          setError(challenge.error.message)
          throw challenge.error
        }

        const challengeId = challenge.data.id

        const verify = await supabase.auth.mfa.verify({
          factorId,
          challengeId,
          code: verifyCode,
        })
        if (verify.error) {
          setError(verify.error.message)
          throw verify.error
        }

        onEnrolled()
      })()
  }

  useEffect(() => {
    ; (async () => {
      const { data, error } = await supabase.auth.mfa.enroll({
        factorType: 'totp',
      })

      if (error) {
        throw error
      }

      setFactorId(data.id)

      // Supabase Auth returns an SVG QR code which you can convert into a data
      // URL that you can place in an <img> tag.
      setQR(data.totp.qr_code)
    })()
  }, [])

  return (
    <>
      {error && <div className="error">{error}</div>}
      <img src={qr} />
      <input
        type="text"
        value={verifyCode}
        onChange={(e) => setVerifyCode(e.target.value.trim())}
      />
      <input type="button" value="Enable" onClick={onEnableClicked} />
      <input type="button" value="Cancel" onClick={onCancelled} />
    </>
  )
}

function LayoutComponent() {
  const user = useUser();

  useEffect(() => {
    if (user?.user_metadata?.force_change_password) {
      openChangePassword(false);

      return;
    }
  }, [user]);

  return (
    <>
      <TabsProvider>
        <UploaderProvider>
          <NotificationProvider>
            <InnerLayout />
          </NotificationProvider>
        </UploaderProvider>
      </TabsProvider>
    </>
  );
}

const Layout = memo(LayoutComponent);

export { Layout };
