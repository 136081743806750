import React, {lazy, memo, useEffect, useMemo, useState} from 'react';
import ReactJson from 'react-json-view';
import {useSupabaseClient} from '@supabase/auth-helpers-react';
import ReactECharts from 'echarts-for-react';
import {ActionIcon, Card, Center, ColorSwatch, Divider, Grid, Group, Loader, Menu, ScrollArea, Skeleton, Stack, Text, ThemeIcon} from "@mantine/core";
import {
  IconAffiliateFilled,
  IconAlertTriangleFilled,
  IconArrowBack,
  IconCircleCheckFilled,
  IconDotsVertical,
  IconEye,
  IconEyeOff,
  IconGavel,
  IconHelpCircleFilled,
  IconInfoCircleFilled,
  IconLeafOff,
  IconListDetails,
  IconPlant,
  IconReportAnalytics,
  IconZoomScan
} from "@tabler/icons-react";
import GenericTableComponent from './GenericTableComponent';
import {default_empresas, default_pessoas, format, translate} from '../lib/bigdata';
import {useDebouncedValue} from "@mantine/hooks";
import OpBndesComponent from './OpBndesComponent';
import LawsuitComponent from './LawsuitComponent';
import MapLeaflet from "./MapLeaflet";
import {useTabs} from "../lib/Tabs";
import ReactEChartsCore from "echarts-for-react/lib/core";
import * as echarts from "echarts/core";

function mergeRelationships(data) {
  return data.reduce((acc, current) => {
    const x = acc.find(item => item.RelatedEntityTaxIdNumber === current?.RelatedEntityTaxIdNumber);
    if (!x) {
      return acc.concat([current]);
    } else {
      if (typeof x.RelationshipType === 'string') {
        x.RelationshipType = [x?.RelationshipType];
      }
      x.RelationshipType = [...new Set([...x?.RelationshipType,current.RelationshipType])];
      // get the first min RelationshipLevel, considering that  is a string
      if (x.RelationshipLevel > current.RelationshipLevel) {
        x.RelationshipLevel = current.RelationshipLevel;
      }

      return acc;
    }
  }, [])
}

const InfoText = memo(function InfoText({ label, data }) {
  if ((typeof data[label] !== 'boolean' && !data[label])) {
    return null;
  }
  return (
    <div>
      <Text size="xs" color="dimmed">
        {translate(label)}
      </Text>
      <Text fw={600} size={"xs"}>
        {format(data[label])}
      </Text>
    </div>
  )
});

const KycHighlights = memo(function KycHighlights({ kycData, title, doc, name, qsa }) {

  let kyc = kycData?.kyc?.SanctionsHistory;

  let extraKyc = kycData?.extraKyc?.SanctionsHistory;

  let sources = [
    "MTE", "Ibama", "Comissao de Valores Mobiliarios", "Banco Central do Brasil", "ceis", "cnep", "cepim", "CEAF", "Conselho Nacional de Justiça", "Tribunal de Contas da União",
    "ofac", "United Nations Security Council", "interpol", "fbi", "ca-sanctions"
    // "Bank Hindered Suppliers", "uk","govuk","ukgov","eu","unsc",    "Tribunal de Contas do Estado de São Paulo",
  ]
  return (
    <Card my={"xs"} p={"xs"} withBorder>
      <Group position={"apart"} >
        <Text pb={"xs"} size={"s"} fw={700}>
          {title}
        </Text>
        <Text pb={"xs"} size={"s"} fw={700}>
          Status: {name} | {doc.length === 11 ? "Pessoas Relacionadas" : "1º Grau"}
        </Text>
      </Group>
      <Group grow spacing={"xs"} position={"apart"} >
        {sources?.map((item, index) => {
          let sanctioned = kyc?.some((i) => i.Source === item);
          let extraSanctioned = extraKyc?.some((i) => i.Source === item);
          return (
            <Card key={index + "c"} p={4} withBorder style={{ ...(sanctioned || extraSanctioned) ? { borderColor: "#be3939", background: "#ffebeb" } : {}, ...{ textAlign: "center", minWidth: "310px", height: "100%" } }}>
              <Group key={index + "f"} spacing={2} position={"apart"} style={{ height: "100%", alignContent: "space-between", justifyContent: "center" }}>
                <div style={{ justifyContent: "center", height: "70px" }}>
                  <Text size="xs" fw={(sanctioned || extraSanctioned) && 700} color={(sanctioned || extraSanctioned) && "#be3939"}>
                    {translate(item)}
                  </Text>
                  <div key={index + "d"} style={{ position: "absolute", bottom: 10, left: "50%", transform: "translateX(-50%)" }} >
                    <Group position={"apart"}  >
                      {!kyc ? <Loader size={"xs"} /> :
                        sanctioned ? <IconAlertTriangleFilled color={"#be3939"} /> : <IconCircleCheckFilled color={"#86be70"} />}
                      <Divider size={"md"} orientation={"vertical"}></Divider>
                      {!extraKyc ? <Loader size={"xs"} /> :
                        extraSanctioned ? <IconAlertTriangleFilled color={"#be3939"} /> : <IconCircleCheckFilled color={"#86be70"} />}
                    </Group>
                  </div>

                </div>
              </Group>
            </Card>
          )
        })}
      </Group>
    </Card>
  )

});

const DonutChart = memo(function DonutChart({ data, percentage, title }) {
  const total = parseFloat(Object.values(data || {})?.reduce((a, b) => a + b, 0));

  const seriesData =
    Object.entries(data || {})
      ?.map(([key, value]) => ({
        name: key,
        value: value / (percentage ? total : 1)
      }))
      .sort((a, b) => b.value - a.value);


  const options = {
    legend: {
      formatter: function (name) {
        let size = 18
        return name.length > size ? (name.substring(size - 1, size) === " " ? name.substring(0, size - 1) : name.substring(0, size)) + "..." : name;
      },
      textStyle: { fontSize: 10 },
      pageIconSize: 10,
      pageButtonGap: 8,
      pageButtonPosition: "end",
      itemWidth: 18,
      itemGap: 9,
      orient: 'vertical',
      type: 'scroll',
      left: 'right',
      top: 'center',
      padding: [0, 0, 0, 0]
    },
    title: [
      {
        id: 1,
        top: "0px",
        left: "left",
        show: true,
        text: title,
        textStyle: {
          color: "#000000",
          fontSize: 16,
          fontWeight: 800,
        },
      },
      {
        textAlign: "center",
        textVerticalAlign: "middle",
        text: percentage ? "" : `${total.toLocaleString("pt-BR", {
          style: "decimal",
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
        })}`,
        textStyle: { fontSize: 24 },
        top: "54%",
        left: "29%",
      }],
    series: [
      {
        type: 'pie',
        radius: [percentage ? '20%' : '55%', '88%'],
        center: ["30%", "55%"],
        avoidLabelOverlap: false,
        emphasis: {
          focus: "self",
          minShowLabelAngle: 0,
          label: {
            fontSize: 18,
            minShowLabelAngle: 0,
          }
        },
        itemStyle: {
          borderRadius: 0,
          borderColor: '#fff',
          borderWidth: 0
        },
        label: {
          show: true,
          fontWeight: 700,
          fontSize: 13,
          position: "inside",
          formatter: function (params) {
            let x;
            x = parseFloat(params.value) * (percentage ? 100 : 1);
            if (x === 0) {
              x = "";
            } else {
              x = x.toLocaleString("pt-BR", {
                style: "decimal",
                maximumFractionDigits: percentage ? 1 : 0,
                minimumFractionDigits: percentage ? 1 : 0,
              });
            }
            return x + (percentage ? "%" : "");
          },

        },
        labelLayout: { hideOverlap: true },
        labelLine: {
          show: true,
          length: 6,
          length2: 6,
        },
        minAngle: 2,
        minShowLabelAngle: 8,
        data: seriesData || [],
      }
    ],
  };

  return (
    <Card p={"xs"} my={"xs"} withBorder h={220} >
      <ReactECharts option={options} style={{ height: "100%" }} />
    </Card>
  );
});

function ChartCard(props) {
  return (
    <Card withBorder p={6} my={6}>
        {props.isLoading ? <Center h={150} ><Loader size={"md"} /></Center> :
          <Stack spacing={0} justify={"space-between"} h={props.h || 231}>
          {props.icon && <ThemeIcon
            size={24}
            style={{position: "absolute"}}
            color={!props.data || props.data?.length === 0 ? "#ccc" : props.iconColor}>
            <props.icon
              size={20}
              color={"white"}
              stroke={1.5}/>
          </ThemeIcon>}
          <div style={{display: "flex", alignItems: "center", justifyContent: props.titleAlign || "center"}}>
            <Text color={!props.data || props.data?.length === 0 ? "#aaa" : "#495057"} size={14} fw={600} ml={props.icon ? 28 : 0} pt={22}
                  style={{position: "absolute"}}>{props.title}</Text>
          </div>
          {props?.data &&
            <ReactEChartsCore
              opts={{renderer: 'svg'}}
              echarts={echarts}
              option={
                props.chart(props.data, props.params)
              }/>
          }
          {!props?.data &&
            <Center><Text color={"#aaa"} size={14} p={10}>Sem dados disponíveis</Text></Center>
          }
        </Stack>}
      </Card>
  )
}
function formatNumber(value, fixedDecimal) {
  let decimal = fixedDecimal ? (parseFloat(value) % 1 === 0 ? 0 : fixedDecimal) : 0;
  return parseFloat(value).toLocaleString('pt-BR', {
    style: 'decimal',
    maximumFractionDigits: decimal,
    minimumFractionDigits: decimal,
    currency: 'BRL',
    signDisplay: "auto"
  })
}
function chartBar(data, params) {

  if (!params?.series){
    return {}
  }

  let series = params?.series?.map((d) => {

    let serieData = data.map((v) => {
      return ({ name: v.name, value: v[d.value] / (params.divBy || 1), itemStyle: v.itemStyle || {} });
    });

    if (params.limit) {
      //slice data to limit, from the end to the start
      serieData = serieData.slice(-params.limit);
    }

    return {
      name: d.name,
      type: d.type,
      stack: !!params.stack,
      symbolSize: 10,
      itemStyle: { color: d.color },
      data: serieData,
      emphasis: {
        label: { fontSize: 18 },
      },
      label: {
        show: true,
        position: (d.labelPosition) || "inside",
        fontWeight: 700,
        fontSize: 10,
        formatter: function (params) {
          let x;
          x = parseFloat(params.value);
          if (x === 0) {
            x = "";
          } else {
            x = formatNumber(d.format === "percentage" ? (x * 100) : x, 1);
          }
          return x + ((x && x !== 0 && d.format === "percentage") ? "%" : "");
        },
      },
      markLine: { ...d.markLine }
    }
      ;
  })

  let dimensao = data.map((d) => d.name)
  if (params.limit) {
    //slice data to limit, from the end to the start
    dimensao = dimensao.slice(-params.limit);
  }

  return {
    tooltip: {
      appendToBody: true,
      confine: true
    },
    grid: {
      left: '4px',
      right: '6px',
      bottom: '0%',
      top: '46px',
      containLabel: true
    },
    legend: {
      show: params.series[0].showLegend ?? true,
      left: "right",
      top: 0,
      textStyle: {
        lineHeight: 14,
        fontSize: 9
      },
      itemGap: 6,
      itemWidth: 16,
      itemHeight: 12
    },
    xAxis: {
      type: 'category',
      data: dimensao,
      axisLabel: {
        fontSize: 10
      }
    },
    yAxis: {
      type: 'value',
      max: function (value) {
        return params.series[0].format === "percentage" ? Math.max(value.max, 1) : (params.yMax || value.max);
      },
      axisLabel: {
        fontSize: 10,
        formatter: function (value) {
          let x;
          x = parseFloat(value);
          if (x === 0) {
            x = "";
          } else {
            x = formatNumber(params.series[0].format === "percentage" ? (x * 100) : x);
          }
          return x + (params.series[0].format === "percentage" ? "%" : "");
        }
      }
    },
    series: series
  };
}

// const GenericTableComponent = lazy(() => import('./GenericTableComponent'));
const RelationshipsGraph = lazy(() => import('./RelationshipsGraph'));

const GenericFieldComponent = memo(({ data, fields, title }) => {
  fields = [...new Set(fields)]
  return (
    <div style={{ paddingBottom: 8, paddingTop: 8, height: "100%" }}>
      <Card withBorder p={"xs"} w={"100%"} h={"100%"}>
        <Text size={"s"} fw={700}>{title}</Text>
        <Group position={"apart"}>
          {data && fields.map((key, index) => {
            return (
              <InfoText key={index} label={key} data={data} />
            )
          })}
        </Group>
      </Card>
    </div>
  );
})

function GenericDivider({ color, label, Icon }) {
  return (

    <Divider
      color={color || "#919191"}
      size={14}
      mt={"md"}
      fw={700}
      label={
        <Group spacing={6} >
          <Icon size={28} />
          <Text size={20} color={"#1a1a1a"} fw={700}>{label}</Text>
        </Group>
      }
    />
  );
}

const BigDataComponent = memo(function BigDataComponent({ doc, bigData, tabId, reservaLegal}) {
  const { addTab } = useTabs();
  const [relationships, setRelationships] = useState(null);
  const [processesData, setProcessesData] = useState(null)
  const [ocuppationData, setOcuppationData] = useState(null)
  const [onlineCertificatesData, setOnlineCertificatesData] = useState(null)
  const [kycData, setKycData] = useState(null)
  const [relationshipsGraphData, setRelationshipsGraphData] = useState(null)
  const [ruralProperty, setRuralProperty] = useState(null)
  const [ruralPropertyHistory, setRuralPropertyHistory] = useState(null)
  const kycDataRef = React.useRef(null);
  const relationshipsRef = React.useRef({});

  const isCPF = doc.length === 11;
  const isCNPJ = doc.length === 14;


  useEffect(() => {
    if (bigData && bigData.basic_data && (bigData.economic_group_relationships || (bigData.business_relationships /*&& bigData.political_relationships*/)) /*&& kycDataRef.current */) {

      let economicRelationships = [];

      if (bigData?.business_relationships?.BusinessRelationships?.BusinessRelationships) {
        for (let m of bigData.business_relationships.BusinessRelationships.BusinessRelationships.filter(f => bigData?.basic_data?.BasicData?.TaxIdNumber !== f.RelatedEntityTaxIdNumber)) {
          let relation = { ...m }
          let entityDoc = m.RelatedEntityTaxIdNumber;
          relation.PreviousLevelRelatedEntityTaxIdNumber = bigData?.basic_data?.BasicData?.TaxIdNumber;
          relation.RelationshipLevel = "1st-LEVEL";
          relation.filial = (entityDoc?.length === 14 && entityDoc.substring(8, 12) !== '0001') ? "Filial" : (entityDoc?.length === 14 && entityDoc?.substring(8, 12) === '0001') ? "Matriz" : entityDoc?.length === 11 ? "Pessoa Física" : "Outros";
          economicRelationships.push(relation);
        }
      }

      if (bigData?.economic_group_relationships?.EconomicGroupRelationship?.Relationships) {
        economicRelationships = economicRelationships.concat(bigData.economic_group_relationships.EconomicGroupRelationship.Relationships);
      }

      let economicRelationshipsClean = economicRelationships
        .map((item) => {
          let entityDoc = item?.RelatedEntityTaxIdNumber;
          return {
            ...item,
            PreviousLevelRelatedEntityName:
              bigData?.basic_data?.BasicData?.TaxIdNumber === item?.PreviousLevelRelatedEntityTaxIdNumber ?
                (bigData?.basic_data?.BasicData?.OfficialName || bigData?.basic_data?.BasicData?.Name) :
                economicRelationships.find((item2) => item2?.RelatedEntityTaxIdNumber === item?.PreviousLevelRelatedEntityTaxIdNumber)?.RelatedEntityName,
            filial: (entityDoc?.length === 14 && entityDoc.substring(8, 12) !== '0001') ? "Filial" : (entityDoc?.length === 14 && entityDoc?.substring(8, 12) === '0001') ? "Matriz" : entityDoc?.length === 11 ? "Pessoa Física" : "Outros"
          }
        })

      function addChildrenRecursively(items, filtered) {
        const itemMap = new Map();
        items.forEach(item => itemMap.set(item?.RelatedEntityTaxIdNumber, item));

        function buildTree(node, path = "") {
          const entityDoc = node?.RelatedEntityTaxIdNumber;
          const children = items.filter(c => {
            const childrenDoc = c?.RelatedEntityTaxIdNumber;
            return (
              c.PreviousLevelRelatedEntityTaxIdNumber === entityDoc &&
              ((childrenDoc.length === 14 && childrenDoc.substring(8, 12) === '0001') || childrenDoc.length === 11) &&
              (node.PreviousLevelRelatedEntityTaxIdNumber !== c?.RelatedEntityTaxIdNumber)
            );
          });

          if (children.length > 0) {
            node.children = children.map(child => buildTree(child, node.relationshipPath));
          }
          return node;
        }

        return filtered.map(node => buildTree(node));
      }

      function addRelationshipPath(economicRelationships) {
        const itemMap = new Map();
        economicRelationships.forEach(item => itemMap.set(item.RelatedEntityTaxIdNumber, item));

        function buildPath(node) {
          let path = [];
          let currentNode = node;
          let level = 0
          while (currentNode) {
            if (level === 0) {
              path.push(currentNode.RelationshipName || currentNode.RelationshipType);
              level++;
            } else {
              let previousNode = currentNode;
              currentNode = itemMap.get(currentNode.PreviousLevelRelatedEntityTaxIdNumber);
              path.push(currentNode ? `${previousNode.RelatedEntityName}[${currentNode.RelationshipName}]` : previousNode.RelatedEntityName);
            }
          }

          node.relationshipPath = path.join(' -> ');
        }

        economicRelationships.forEach(node => buildPath(node));
        return economicRelationships;
      }

      // economicRelationships = addRelationshipPath(economicRelationships);
      economicRelationshipsClean = economicRelationshipsClean.filter((item) => ((item.RelatedEntityTaxIdNumber.length === 14 && item.RelatedEntityTaxIdNumber.substring(8, 12) === '0001') || item.RelatedEntityTaxIdNumber.length === 11))

      let root = economicRelationshipsClean
        .filter((item) => item.RelatedEntityTaxIdNumber !== doc)
        .filter((item) => item.RelationshipLevel === "1st-LEVEL")
        .filter((item) => ((item.RelatedEntityTaxIdNumber.length === 14 && item.RelatedEntityTaxIdNumber.substring(8, 12) === '0001') || item.RelatedEntityTaxIdNumber.length === 11))

      economicRelationshipsClean = addChildrenRecursively(economicRelationshipsClean,root);

      // economicRelationshipsClean = economicRelationshipsClean
      //   .filter((item) => item.RelatedEntityTaxIdNumber !== doc)
      //   .filter((item) => (item.RelationshipLevel === "1st-LEVEL"))
      //   .filter((item) => ((item.RelatedEntityTaxIdNumber.length === 14 && item.RelatedEntityTaxIdNumber.substring(8, 12) === '0001') || item.RelatedEntityTaxIdNumber.length === 11))

      if (kycData ) {
        economicRelationshipsClean = economicRelationshipsClean?.map((item) => {
          let entityDoc = item.RelatedEntityTaxIdNumber;
          let kyc = kycData?.economicGroupKyc?.[entityDoc];
          if (kyc?.PEPHistory?.length > 0) {
            let pepLevel = kyc.PEPHistory.reduce((min, p) => p.Level < min ? p.Level : min, kyc.PEPHistory[0].Level);
            item.IsPep = pepLevel + " Grau"
          } else {
            item.IsPep = "-"
          };

          let sanctions = kyc?.SanctionsHistory?.filter(f => f.MatchRate === 100);
          if (sanctions?.length > 0) {
            item.IsSanctioned = sanctions.length === 1 ? translate(sanctions[0].Source) : "Sim";
          }
          let amountDonated = Object.keys(kyc?.ElectoralDonations || {}).reduce((acc, year) => {
            return acc + kyc?.ElectoralDonations[year].AmountDonated;
          }, 0);

          return { ...item, pep: kyc?.PEPHistory, sanctions: sanctions, AmountDonated: amountDonated }
        })

      }
      economicRelationshipsClean = economicRelationshipsClean.map((item) => {
          return { ...item, openReport: () => {
              addTab({
                label:`Rel./${item.RelatedEntityTaxIdNumber}`,
                id: `ESG/Report/${item.RelatedEntityTaxIdNumber}`,
                component: "PdfPrinter",
                subcomponent: "EsgReport",
                disableRefresh: true,
                props: {
                  id: item.CAR,
                  landscape: true,
                  inputList: [{cod: item.RelatedEntityTaxIdNumber, codType: 'doc'}],
                  name: item.RelatedEntityName,
                  input: item.RelatedEntityTaxIdNumber,
                  inputType: 'doc',
                }
              })
            }}
      })

      relationshipsRef.current = {
        ...relationshipsRef.current,
        economic: economicRelationshipsClean && mergeRelationships(economicRelationshipsClean).sort((a, b) => a.RelationshipStartDate > b.RelationshipStartDate ? 1 : -1).sort((a, b) => b.RelationshipEndDate > a.RelationshipEndDate ? 1 : -1),
        economicFull: economicRelationships && economicRelationships.sort((a, b) => a.RelationshipStartDate > b.RelationshipStartDate ? 1 : -1).sort((a, b) => b.RelationshipEndDate > a.RelationshipEndDate ? 1 : -1),
        // political: bigData?.political_relationships?.PoliticalRelationships?.RelatedPoliticians
      }

      setRelationships(relationshipsRef.current);
    }

    //setRelationships(relationships);
  }, [bigData?.basic_data, bigData?.economic_group_relationships, bigData?.business_relationships, /*bigdata?.political_relationship,*/ kycDataRef.current]);

  useEffect(() => {
    if (bigData?.kyc && ((bigData?.economic_group_relationships && bigData?.economic_group_kyc) || (bigData?.related_people && bigData?.business_relationships && bigData?.first_level_relatives_kyc))) {
      let personal = [...bigData?.related_people?.RelatedPeople?.PersonalRelationships || [], ...bigData?.economic_group_relationships?.EconomicGroupRelationship?.Relationships || []]
      let kyc = {
        ...bigData.kyc?.KycData,
        PEPHistory: bigData.kyc?.KycData?.PEPHistory.map((item) => {
          let taxId = item.TaxId || doc
          return {
            ...item,
            RelatedEntityTaxIdNumber: taxId,
            RelatedEntityName:
              taxId === bigData?.basic_data?.BasicData?.TaxIdNumber ?
                (bigData?.basic_data?.BasicData?.OfficialName || bigData?.basic_data?.BasicData?.Name) :
                personal?.find((i) => i.RelatedEntityTaxIdNumber === taxId)?.RelatedEntityName,
            Status: item.EndDate > new Date().toISOString() ? "Ativo" : "Inativo",
          }
        }),
        SanctionsHistory: bigData.kyc?.KycData?.SanctionsHistory
          .filter((item) => item.MatchRate === 100)
          .map((item) => {
            return {
              ...item,
              ...item.NormalizedDetails,
              RelatedEntityTaxIdNumber: doc,
              RelatedEntityName: bigData?.basic_data?.BasicData?.OfficialName || bigData?.basic_data?.BasicData?.Name
            }
          })
      }

      let relativesKyc = bigData?.first_level_relatives_kyc?.RelatedPeopleKycData[0]?.RelatedPeopleKycData.reduce((acc, current) => {
        acc[current.DocNumber] = current.KycData;
        return acc;
      }, {});

      let economicGroupKyc = bigData?.economic_group_kyc?.EconomicGroupKycData?.EconomicGroupKycData.reduce((acc, current) => {
        acc[current.DocNumber] = current.KycData;
        return acc;
      }, {});

      let OwnerKycData = { ...(relativesKyc || []), ...(economicGroupKyc || []) };

      let extraKycSanctions = OwnerKycData && Object.keys(OwnerKycData).reduce((acc, key) => {
        const sanctionsHistory = OwnerKycData?.[key]?.SanctionsHistory?.map(m => {
          return {
            ...m,
            ...m.NormalizedDetails,
            RelatedEntityTaxIdNumber: key,
            RelatedEntityName:
              personal?.find((i) => i.RelatedEntityTaxIdNumber === key)?.RelatedEntityName
          }
        }).filter((item) => item.MatchRate === 100);
        return acc.concat(sanctionsHistory);
      }, []);


      let extraKycPep = OwnerKycData && Object.keys(OwnerKycData).reduce((acc, key) => {
        const pepHistory = OwnerKycData?.[key]?.PEPHistory?.map(m => {
          return {
            ...m,
            Status: m.EndDate > new Date().toISOString() ? "Ativo" : "Inativo",
            RelatedEntityTaxIdNumber: key,
            EntityName:
            personal?.find((i) => i.RelatedEntityTaxIdNumber === m.TaxId)?.RelatedEntityName,
            RelatedEntityName:
              personal?.find((i) => i.RelatedEntityTaxIdNumber === key)?.RelatedEntityName
          }
        });
        return acc.concat(pepHistory);
      }, []);

      kyc.SanctionsHistory = kyc.SanctionsHistory.filter((value, index, self) => {
        return self.findIndex(item => item.ProcessNumber === value.ProcessNumber) === index;
      }).map((item) => {
        if (item.Source === "Tribunal de Contas da União" && item.NormalizedDetails?.Process) {
          item.Url = `https://pesquisa.apps.tcu.gov.br/redireciona/processo/${item.NormalizedDetails?.Process.replaceAll(/\D/g, '')}`
        }else if (item.Source === "Comissao de Valores Mobiliarios" && item.NormalizedDetails?.ProcessNumber ) {
          //check if the process number is valid matching the regex (two letters, four digits, /, one or more digits)
          if (item.NormalizedDetails?.ProcessNumber.match(/[A-Z]{2}\d{4}\/\d+/)) {
            let result = item.NormalizedDetails?.ProcessNumber.match(/[A-Z]{2}\d{4}\/\d+/);
            let uf = result[0].substring(0, 2);
            let year = result[0].substring(2, 6);
            let number = result[0].substring(7);
            item.Url = `https://sistemas.cvm.gov.br/asp/cvmwww/inqueritos/DetPasAndamento.asp?sg_uf=${uf}&Ano=${year}&NumProc=${number}`
          }
        }

        return { ...item, ...item.NormalizedDetails, ProcessNumber: item.ProcessNumber || item.NormalizedDetails?.Process }
      })

      extraKycSanctions = extraKycSanctions.filter((value, index, self) => {
        return self.findIndex(item => item.ProcessNumber === value.ProcessNumber) === index;
      }).map((item) => {
        if (item.Source === "Tribunal de Contas da União" && item.NormalizedDetails?.Process) {
          item.Url = `https://pesquisa.apps.tcu.gov.br/redireciona/processo/${item.NormalizedDetails?.Process.replaceAll(/\D/g, '')}`
        } else if (item.Source === "Comissao de Valores Mobiliarios" && item.NormalizedDetails?.ProcessNumber ) {
          //check if the process number is valid matching the regex (two letters, four digits, /, one or more digits)
          if (item.NormalizedDetails?.ProcessNumber.match(/[A-Z]{2}\d{4}\/\d+/)) {
            let result = item.NormalizedDetails?.ProcessNumber.match(/[A-Z]{2}\d{4}\/\d+/);
            let uf = result[0].substring(0, 2);
            let year = result[0].substring(2, 6);
            let number = result[0].substring(7);
            item.Url = `https://sistemas.cvm.gov.br/asp/cvmwww/inqueritos/DetPasAndamento.asp?sg_uf=${uf}&Ano=${year}&NumProc=${number}`
          }
        }
        return { ...item, ...item.NormalizedDetails, ProcessNumber: item.ProcessNumber || item.NormalizedDetails?.Process }
      })

      kycDataRef.current = {
        kyc: kyc,
        economicGroupKyc: OwnerKycData,
        extraKyc: {
          SanctionsHistory: extraKycSanctions,
          PEPHistory: extraKycPep
        },
        sanctions: [...kyc.SanctionsHistory, ...extraKycSanctions],
        PEPHistory: [...kyc.PEPHistory, ...extraKycPep]
      };

      setKycData(kycDataRef.current)
    }
  }, [bigData?.kyc, bigData?.economic_group_kyc, bigData?.first_level_relatives_kyc, bigData?.economic_group_relationships, bigData?.business_relationships, bigData?.related_people, bigData?.basic_data]);

  useEffect(() => {
    if (bigData?.basic_data && bigData?.related_people && kycData) {
      let personal = bigData?.related_people?.RelatedPeople?.PersonalRelationships.filter((item) => {
        let entityDoc = item.RelatedEntityTaxIdNumber;
        return (
          entityDoc !== doc &&
          !(item.RelationshipLevel === "INDIRECT" &&
            bigData?.business_relationships?.BusinessRelationships?.BusinessRelationships?.some(businessItem => businessItem.RelatedEntityTaxIdNumber === entityDoc)))
      })

      if (bigData?.political_involvement?.PoliticalInvolvement?.PoliticalInvolvementEntitities) {
        personal = personal?.map((item) => {
          let entityDoc = item.RelatedEntityTaxIdNumber;
          let political = bigData?.political_involvement?.PoliticalInvolvement?.PoliticalInvolvementEntitities?.[entityDoc];
          return { ...item, ...political }
        })
      }

      if (bigData?.kyc?.KycData?.PEPHistory) {
        personal = personal?.map((item) => {
          let entityDoc = item.RelatedEntityTaxIdNumber;
          let pep = bigData?.kyc?.KycData?.PEPHistory?.find((item) => item.TaxId === entityDoc);
          if (parseInt(pep?.Level) > 0) item.IsPep = pep?.Level + " Nível";
          return { ...item, ...pep }
        })
      }

      if (kycData) {
        personal = personal?.map((item) => {
          let entityDoc = item.RelatedEntityTaxIdNumber;
          let kyc = kycData?.economicGroupKyc?.[entityDoc];
          if (kyc?.PEPHistory?.length > 0) {
            let pepLevel = kyc.PEPHistory.reduce((min, p) => p.Level < min ? p.Level : min, kyc.PEPHistory[0].Level);
            item.IsPep = pepLevel + " Grau"
          } else {
            item.IsPep = "-"
          }
          ;

          let sanctions = kyc?.SanctionsHistory?.filter(f => f.MatchRate === 100);
          if (sanctions?.length > 0) {
            item.IsSanctioned = sanctions.length === 1 ? translate(sanctions[0].Source) : "Sim";
          }
          let amountDonated = Object.keys(kyc?.ElectoralDonations || {}).reduce((acc, year) => {
            return acc + kyc?.ElectoralDonations[year].AmountDonated;
          }, 0);

          return { ...item, pep: kyc?.PEPHistory, sanctions: sanctions, AmountDonated: amountDonated }
        })
      }
      setRelationships({ ...relationshipsRef.current, personal: personal && mergeRelationships(personal) })
      relationshipsRef.current.personal = personal && mergeRelationships(personal);
    }
  }, [bigData?.basic_data, bigData?.related_people, kycData]);

  useEffect(() => {
    if (relationshipsRef?.current?.economicFull || relationshipsRef?.current?.personal) {
      let rootEntity = {
        RelatedEntityTaxIdNumber: bigData?.basic_data?.BasicData?.TaxIdNumber,
        RelatedEntityName: bigData?.basic_data?.BasicData?.OfficialName || bigData?.basic_data?.BasicData?.Name,
        RelationshipType: "Consultado",
        RelationshipLevel: "Consultado",
        RuralProperty: bigData?.basic_data?.BasicData?.RuralProperty,
        RuralPropertyHistory: bigData?.basic_data?.BasicData?.RuralPropertyHistory,
      }
      let economicMainPerson = relationshipsRef?.current?.economicFull?.filter((r) =>((r.RelatedEntityTaxIdNumber.length === 14 && r.RelatedEntityTaxIdNumber.substring(8, 12) === '0001') ||
       ( relationshipsRef?.current.economicFull?.some(r2 => r2.PreviousLevelRelatedEntityTaxIdNumber === r.RelatedEntityTaxIdNumber) &&
         !relationshipsRef?.current.economicFull?.some(r2 => r2.RelatedEntityTaxIdNumber.substring(0,12) === (r.RelatedEntityTaxIdNumber.substring(0, 8)+'0001')))))
      let filteredData = [
        rootEntity,
        ...(relationshipsRef?.current.personal?.map((item) => {
          return { ...item, RelationshipLevel: "Pessoais", PreviousLevelRelatedEntityTaxIdNumber: bigData?.basic_data?.BasicData?.TaxIdNumber }
        })
          || []),
        ...(economicMainPerson
          .sort((a, b) => a.RelationshipLevel > b.RelationshipLevel ? 1 : -1) || [])]
      if (filteredData.length > 0) {
        setRelationshipsGraphData(filteredData?.map((item) => {
          return { ...item, RelationshipLevel: translate(item.RelationshipLevel), RelationshipType: translate(item.RelationshipType) }
        }))
      }
    }
  }, [relationshipsRef?.current?.economicFull, relationshipsRef?.current?.personal]);

  useEffect(() => {
    if(relationshipsGraphData){
      let ruralData = relationshipsGraphData?.filter((f) => f.RuralProperty).map((item) => {
        let properties = item.RuralProperty?.map((p) => {
          let property = {
            RelatedEntityTaxIdNumber: item.RelatedEntityTaxIdNumber,
            RelatedEntityName: item.RelatedEntityName,
            RelatedEntity: item.RelatedEntityName + " - " + item.RelatedEntityTaxIdNumber,
            RelationshipLevel: item.RelationshipLevel,
            RelationshipType: item.RelationshipType,
            RelationshipName: item.RelationshipName,
            "Área (ha)": p?.area,
            "CAR": p?.subtitle,
            "Sobrep. Terra Pública": p.PublicLandOverlapTxt,
            "Área Embargada": p.EmbargoAreaTxt,
            ...p
          }
          return property
        })

        return properties
      }).flat()
      // reduce ruralData to unique (same "CAR"), aggregate RelatedEntity to a array of distinct values
      ruralData = ruralData?.reduce((acc, current) => {
        const x = acc.find(item => item.CAR === current.CAR);
        if (!x) {
          return acc.concat([current]);
        } else {
          //check if RelatedEntity is an array
          if (typeof x.RelatedEntity === 'string') {
            x.RelatedEntity = [x.RelatedEntity];
          }
          x.RelatedEntity = [...new Set([...x.RelatedEntity, current.RelatedEntity])];
          return acc;
        }
      }, [])

      ruralData = ruralData.map((item) => {
        return { ...item, openReport: () => {
          addTab({
          label:`Rel./${item.CAR}`,
          id: `ESG/Report/${item.CAR}`,
          component: "PdfPrinter",
          subcomponent: "EsgReport",
          disableRefresh: true,
          props: {
            id: item.CAR,
            landscape: true,
            inputList: [{cod: item.CAR, codType: 'car'}],
            name: item.title,
            input: item.CAR,
            inputType: 'car',
          }
        })
      }}
      })
      setRuralProperty(ruralData)

      if (relationshipsGraphData?.filter((f) => f.RuralPropertyHistory).length > 0) {

        let ruralDataHistory = relationshipsGraphData?.filter((f) => f.RuralPropertyHistory).map((item) => {
          let properties = item.RuralPropertyHistory?.map((p) => {
            let property = {
              RelatedEntityTaxIdNumber: item.RelatedEntityTaxIdNumber,
              RelatedEntityName: item.RelatedEntityName,
              RelatedEntity: item.RelatedEntityName + " - " + item.RelatedEntityTaxIdNumber,
              RelationshipLevel: item.RelationshipLevel,
              RelationshipType: item.RelationshipType,
              RelationshipName: item.RelationshipName,
              "Área (ha)": p?.area,
              "CAR": p?.subtitle,
              "Sobrep. Terra Pública": p.PublicLandOverlapTxt,
              "Área Embargada": p.EmbargoAreaTxt,
              "Atual Proprietário": p?.actual_owner,
              ...p
            }
            return property
          })

          return properties
        }).flat()
        // reduce ruralData to unique (same "CAR"), aggregate RelatedEntity to a array of distinct values
        ruralDataHistory = ruralDataHistory?.reduce((acc, current) => {
          const x = acc.find(item => item.CAR === current.CAR);
          if (!x) {
            return acc.concat([current]);
          } else {
            //check if RelatedEntity is an array
            if (typeof x.RelatedEntity === 'string') {
              x.RelatedEntity = [x.RelatedEntity];
            }
            x.RelatedEntity = [...new Set([...x.RelatedEntity, current.RelatedEntity])];
            return acc;
          }
        }, [])

        ruralDataHistory = ruralDataHistory.map((item) => {
          return { ...item, openReport: () => {
              addTab({
                label:`Rel./${item.CAR}`,
                id: `ESG/Report/${item.CAR}`,
                component: "PdfPrinter",
                subcomponent: "EsgReport",
                disableRefresh: true,
                props: {
                  id: item.CAR,
                  landscape: true,
                  inputList: [{cod: item.CAR, codType: 'car'}],
                  name: item.title,
                  input: item.CAR,
                  inputType: 'car',
                }
              })
            }}
        })
        setRuralPropertyHistory(ruralDataHistory)

      }
    }

  }, [relationshipsGraphData]);

  const basicData = useMemo(() => {
    if (bigData?.basic_data) {
      let data = {
        ...bigData?.basic_data?.BasicData,
        ...bigData?.basic_data?.BasicData?.MaritalStatusData,
        ...bigData?.basic_data?.BasicData?.AlternativeIdNumbers,
        ...bigData?.basic_data?.BasicData?.AdditionalOutputData,
        // ...(isCNPJ ? { Filiais: [...new Set(bigData?.economic_group_relationships?.EconomicGroupRelationship?.Relationships?.filter((f) => f?.RelatedEntityTaxIdNumber?.substring(0, 7) === doc.substring(0, 7)))].length || 0 } : {})
      };
      if (bigData?.economic_group_relationships && isCNPJ) {
        data = {
          ...data,
          Filiais: [...new Set(bigData?.economic_group_relationships?.EconomicGroupRelationship?.Relationships?.filter((f) => f?.RelatedEntityTaxIdNumber?.substring(0, 8) === doc.substring(0, 8)))].length || 0
        };
      }
      return data;
    }
    return null;
  }, [bigData?.basic_data, bigData?.economic_group_relationships]);

  const addressesData = useMemo(() => {
    if (bigData?.addresses_extended) {
      let data = {};
      data.data = bigData?.addresses_extended?.ExtendedAddresses.Addresses?.map((item) => {
        return { ...item, Address: item.Typology + " " + item.AddressMain + ", " + item.Number + ", " + item.Complement }
      });
      data.loaded = true;
      return data;
    }
    return null;
  }, [bigData?.addresses_extended]);

  const phonesData = useMemo(() => {
    if (bigData?.phones_extended) {
      let data = {};
      data.data = bigData?.phones_extended?.ExtendedPhones.Phones?.map((p) => { return { ...p, PhoneNumber: p.AreaCode + "-" + p.Number } });
      data.loaded = true;
      return data;
    }
    return null;
  }, [bigData?.phones_extended]);

  const emailsData = useMemo(() => {
    if (bigData?.emails_extended) {
      let data = {};
      data.data = bigData?.emails_extended?.ExtendedEmails?.Emails;
      data.loaded = true;
      return data;
    }
    return null;
  }, [bigData?.emails_extended]);

  const classOrganizationsData = useMemo(() => {
    if (bigData?.class_organization) {
      let data = {};
      data.data = bigData?.class_organization?.Memberships?.Memberships;
      data.loaded = true;
      return data;
    }
    return null;
  }, [bigData?.class_organization]);

  const socialAssistanceData = useMemo(() => {
    if (bigData?.social_assistance_extended) {
      let data = {};
      data.data = bigData?.social_assistance_extended?.ExtendedSocialAssistancePrograms?.SocialAssistances;
      data.loaded = true;
      return data;
    }
    return null;
  }, [bigData?.social_assistance_extended]);

  const familySocialAssistanceData = useMemo(() => {
    if (bigData?.family_social_assistance) {
      let data = {};
      data.data = bigData?.family_social_assistance?.FamilySocialAssistance?.SocialAssistances;
      data.loaded = true;
      return data;
    }
    return null;
  }, [bigData?.family_social_assistance]);

  const governmentDebtorsData = useMemo(() => {
    if (bigData?.government_debtors) {
      let data = {};
      data.data = bigData?.government_debtors?.GovernmentDebtors?.Debts;
      data.loaded = true;
      return data;
    }
    return null;
  }, [bigData?.government_debtors]);

  const mediaProfileData = useMemo(() => {
    if (bigData?.media_profile_and_exposure) {
      let data = {};
      data.data = bigData?.media_profile_and_exposure?.MediaProfileAndExposure?.NewsItems?.
        filter((f) => {return !f.Categories.includes("sport")}).
        map((m) => { return { ...m, SentimentAnalysis: m.SentimentAnalysis?.Label } });
      // remove duplicates (same title)
      data.data = data.data?.filter((item, index, self) =>
        index === self.findIndex((t) => (
          t.Title === item.Title
        ))
      )
      data.loaded = true;
      return data;
    }
    return null;
  }, [bigData?.media_profile_and_exposure]);

  const syndicateAgreementsData = useMemo(() => {
    if (bigData?.syndicate_agreements) {
      let data = {};
      data.data = bigData?.syndicate_agreements?.SyndicateAgreements?.Agreements;
      data.loaded = true;
      return data;
    }
    return null;
  }, [bigData?.syndicate_agreements]);

  useEffect(() => {
    if (
      bigData?.ondemand_pf_antecedente_person ||
      bigData?.ondemand_nada_consta_person ||
      bigData?.ondemand_cert_labor_debt_absence_person ||
      bigData?.ondemand_pgfn_person ||
      bigData?.ondemand_cdcit_company ||
      bigData?.ondemand_fgts_company ||
      bigData?.ondemand_cert_labor_debt_absence_company ||
      bigData?.ondemand_ibama_cert_negativa_company ||
      bigData?.ondemand_pgfn_company ||
      bigData?.ondemand_cert_siproquim_company
    ) {
      let data = {};
      data.data = (isCPF ? [
        'ondemand_pf_antecedente_person',
        'ondemand_nada_consta_person',
        'ondemand_cert_labor_debt_absence_person',
        'ondemand_pgfn_person'
      ] : [
        'ondemand_cdcit_company',
        'ondemand_fgts_company',
        'ondemand_cert_labor_debt_absence_company',
        'ondemand_ibama_cert_negativa_company',
        'ondemand_pgfn_company',
        'ondemand_cert_siproquim_company'
      ])
        .map((key) => {
          let data = bigData && bigData[key]?.OnlineCertificates[0];
          return { ...data, ...data?.AdditionalOutputData, Origin: key, color: data?.BaseStatus && ((["NADA CONSTA", "REGULAR", "NEGATIVA", "NAO CONSTA", "ATIVO"]).some((item) => data?.BaseStatus?.toUpperCase().includes(item)) ? "green" : "red") };
        });
      data.loaded = true;
      setOnlineCertificatesData(data)
    }
  }, [
    bigData?.ondemand_pf_antecedente_person,
    bigData?.ondemand_nada_consta_person,
    bigData?.ondemand_cert_labor_debt_absence_person,
    bigData?.ondemand_pgfn_person,
    bigData?.ondemand_cdcit_company,
    bigData?.ondemand_fgts_company,
    bigData?.ondemand_cert_labor_debt_absence_company,
    bigData?.ondemand_ibama_cert_negativa_company,
    bigData?.ondemand_pgfn_company,
    bigData?.ondemand_cert_siproquim_company
  ]);

  useEffect(() => {
    if (bigData?.occupation_data) {
      let data = {};
      data.data = bigData?.occupation_data?.ProfessionData?.Professions
        ?.filter((item) => {
          let entityDoc = item.CompanyIdNumber;
          return (entityDoc !== doc &&
            ((entityDoc.length === 14 && entityDoc.substring(8, 12) === '0001') || entityDoc.length !== 14))
        })
        .map((item) => { return { ...item, ...item.AdditionalDetails } });
      data.loaded = true;
      setOcuppationData(data)
    }
  }, [bigData?.occupation_data]);

  useEffect(() => {
    if (bigData?.processes) {
      let data = {};
      data.data = [...(bigData?.processes?.Processes?.Lawsuits || []), ...(bigData?.processes?.Lawsuits?.Lawsuits || [])]
        .map((item) => {
          let fullText = JSON.stringify(item).toLowerCase();
          let slavery = /(an[a|à]log[ao]s? (ao? )?(a|à|de) (escravo|escravid[aã]o))|(combate ao trabalho escravo)/.test(fullText);
          return {
            ...item,
            slavery: slavery,
            color: slavery ? "red" : null,
            MainSubject: item.MainSubject + (slavery ? " [POSSÍVEL TRABALHO ESCRAVO]" : ""),
          }
        })
        .sort((a, b) => a.LastMovementDate < b.LastMovementDate ? 1 : -1)
        .sort((a, b) => (a.slavery) ? -1 : 1)
        ;
      data.loaded = true;
      setProcessesData(data)
    }
  }, [bigData?.processes]);

  const pal = [
    '#4363d8', '#e6194b', '#3cb44b', '#ffea00',
    '#f58231', '#911eb4', '#46f0f0', '#f032e6',
    '#bcf60c', '#fabebe', '#008080', '#e6beff',
    '#9a6324', '#fffac8', '#800000', "#d87c7c",
    "#6e7074", "#61a0a8", "#724e58", "#c14089",
    "#b5c334", "#60c0dd", "#d6625f", '#5470c6',
    "#b6a2de", "#5ab1ef", "#ffb980", "#e5cf0d",
    "#97b552", "#95706d", "#dc69aa", "#07a2a4",
    "#9a7fd1", "#588dd5", "#f5994e", "#c05050",
    "#59678c", "#c9ab00", "#7eb00a", "#6f5553",
    '#91cc75', '#fac858', '#ee6666', '#73c0de',
    '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc',
    "#63b598", "#ce7d78", "#ea9e70", "#a48a9e",
    "#c6e1e8", "#648177", "#0d5ac1", "#f205e6",
    "#1c0365", "#4ca2f9", "#a4e43f", "#f2510e",
    "#d298e2", "#6119d0", "#c0a43c", "#61da5e",
    "#cd2f00", "#9348af", "#b0d87b", "#51aed9",
    "#996635", "#b11573", "#75d89e", "#5bb32d",
    "#2f3f94", "#2f7b99", "#34891f", "#21538e",
    "#e0eeb8", "#11dec1", "#566ca0", "#ca4751",
    "#ffdbe1", "#2f1179", "#935b6d", "#916988",
    "#513d98", "#aead3a", "#9e6d71", "#4b5bdc",
    "#0cd36d", "#250662", "#cb5bea", "#228916",
    "#ac3e1b", "#df514a", "#539397", "#880977",
    "#f697c1", "#ba96ce", "#679c9d", "#c6c42c",
    "#5d2c52", "#48b41b", "#e1cf3b", "#5be4f0",
    "#57c4d8", "#a4d17a", "#be608b", "#96b00c",
    "#088baf", "#f158bf", "#e145ba", "#ee91e3",
    "#05d371", "#5426e0", "#4834d0", "#802234",
    "#6749e8", "#0971f0", "#8fb413", "#b2b4f0",
    "#c3c89d", "#c9a941", "#41d158", "#fb21a3",
    '#4363d8', '#e6194b', '#3cb44b', '#ffea00',
    '#f58231', '#911eb4', '#46f0f0', '#f032e6',
    '#bcf60c', '#fabebe', '#008080', '#e6beff',
    '#9a6324', '#fffac8', '#800000', "#d87c7c",
    "#6e7074", "#61a0a8", "#724e58", "#c14089",
    "#b5c334", "#60c0dd", "#d6625f", '#5470c6',
    "#b6a2de", "#5ab1ef", "#ffb980", "#e5cf0d",
    "#97b552", "#95706d", "#dc69aa", "#07a2a4",
    "#9a7fd1", "#588dd5", "#f5994e", "#c05050",
    "#59678c", "#c9ab00", "#7eb00a", "#6f5553",
    '#91cc75', '#fac858', '#ee6666', '#73c0de',
    '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc',
    "#63b598", "#ce7d78", "#ea9e70", "#a48a9e",
    "#c6e1e8", "#648177", "#0d5ac1", "#f205e6",
    "#1c0365", "#4ca2f9", "#a4e43f", "#f2510e",
    "#d298e2", "#6119d0", "#c0a43c", "#61da5e",
    "#cd2f00", "#9348af", "#b0d87b", "#51aed9",
    "#996635", "#b11573", "#75d89e", "#5bb32d",
    "#2f3f94", "#2f7b99", "#34891f", "#21538e",
    "#e0eeb8", "#11dec1", "#566ca0", "#ca4751",
    "#ffdbe1", "#2f1179", "#935b6d", "#916988",
    "#513d98", "#aead3a", "#9e6d71", "#4b5bdc",
    "#0cd36d", "#250662", "#cb5bea", "#228916",
    "#ac3e1b", "#df514a", "#539397", "#880977",
    "#f697c1", "#ba96ce", "#679c9d", "#c6c42c",
    "#5d2c52", "#48b41b", "#e1cf3b", "#5be4f0",
    "#57c4d8", "#a4d17a", "#be608b", "#96b00c",
    "#088baf", "#f158bf", "#e145ba", "#ee91e3",
    "#05d371", "#5426e0", "#4834d0", "#802234",
    "#6749e8", "#0971f0", "#8fb413", "#b2b4f0",
    "#c3c89d", "#c9a941", "#41d158", "#fb21a3",
];

  function RelatedRuralProperties() {
    const [hidded, setHidded] = useState([]);
    const [relatedList, setRelatedList] = useState([]);
    const [mapList, setMapList] = useState([]);

    useEffect(() => {
      if (basicData && relationships?.economicFull) {
        let data = [
          {...basicData,
            RelatedEntityTaxIdNumber: basicData.TaxIdNumber,
            RelationshipName: "Consultado",
            RelationshipLevel: "Consultado",
            RelatedEntityName: basicData.OfficialName || basicData.Name},
          ...mergeRelationships(relationships?.economicFull).filter((item) => item.RuralPropertyQtd > 0)]
          .sort((a, b) => a.RuralPropertyArea > b.RuralPropertyArea ? 1 : -1)
          .sort((a, b) => a.RelationshipLevel > b.RelationshipLevel ? 1 : -1)
          .sort((a, b) => a.RelationshipLevel === "Consultado" ? -1 : 1)


        setRelatedList(data)

      }
    }, [relationships?.economicFull, basicData, hidded]);

    useEffect(() => {
      if (relatedList) {
        setMapList(
          relatedList.filter((item) => !hidded.includes(item.RelatedEntityTaxIdNumber)).map((x) => x.RelatedEntityTaxIdNumber.length === 14 ? x.RelatedEntityTaxIdNumber.substring(0, 8) : x.RelatedEntityTaxIdNumber)
        )
      }
    }, [relatedList, hidded])

    let totalArea = relatedList.reduce((acc, item) => acc + item.RuralPropertyArea, 0);
    let totalProperties = relatedList.reduce((acc, item) => acc + item.RuralPropertyQtd, 0);

    return (
      <Grid m={0} gutter={"xs"}>
        <Grid.Col span={3} pl={0}>
          <Card withBorder my={"xs"} py={4} px={2} style={{ height: "900px" }}>
            <Group>
              <Text p={"xs"} size={"s"} fw={700}>Imóveis Rurais - Consultado e Relacionados [{format(totalProperties)} Imóveis]</Text>
              {hidded.length > 0 &&
                <ActionIcon
                  size="lg"
                  style={{position: "absolute", right: "0px", top: "0px"}}
                  onClick={() => setHidded([])}>
                  <IconArrowBack size={18} />
                </ActionIcon>}
            </Group>
            <ScrollArea h={"840px"}>
              {relatedList.map((item, idx) => {
                let header = false
                let levelArea = 0
                let levelProperties = 0
                if (idx >0 && item.RelationshipLevel !== relatedList[idx-1].RelationshipLevel) {
                  header = true
                  levelArea = relatedList.filter((i) => i.RelationshipLevel === item.RelationshipLevel).reduce((acc, item) => acc + item.RuralPropertyArea, 0);
                  levelProperties = relatedList.filter((i) => i.RelationshipLevel === item.RelationshipLevel).reduce((acc, item) => acc + item.RuralPropertyQtd, 0);
                }
                return (
                  <>

                  {header &&
                    <Group  py={4} px={8} spacing={0}>
                      <ColorSwatch
                        p={"xs"}
                        // style={{position: "absolute", left: 4, bottom: "50%", transform: "translateY(50%)"}}
                      onClick={() => {
                        let related = relatedList.filter((i) => i.RelationshipLevel === item.RelationshipLevel).map((i) => i.RelatedEntityTaxIdNumber.length === 14 ? i.RelatedEntityTaxIdNumber.substring(0, 8) : i.RelatedEntityTaxIdNumber);
                        if (hidded.includes(item.RelationshipLevel)) {
                          /// hide all related to this level
                          setHidded(hidded.filter((i) => !related.includes(i) && i !== item.RelationshipLevel));
                        } else {
                          setHidded([...hidded, ...related, item.RelationshipLevel]);
                        }
                      }}

                      key={pal[idx]}
                      color={hidded.includes(item.RelationshipLevel) ? "#fff" : "#000"}
                      >
                        {hidded.includes(item.RelationshipLevel) ?
                          <IconEyeOff size={18} color={"#ccc"} strokeWidth={1.5}/> :
                          <IconEye size={18} color={"#fff"}  strokeWidth={1.5} />}
                      </ColorSwatch>
                      <Text p={"xs"} size={"s"} fw={700}>
                        {translate(item.RelationshipLevel)} - {format(levelProperties)} Imóveis [{format(levelArea)} ha]
                      </Text>

                    </Group>
                  }
                  {/*card with red border */}
                  <Card withBorder m={"xs"} py={4} px={8} key={item.RelatedEntityTaxIdNumber} style={item.PublicLandOverlap > 0 ?  { border: "2px solid" + (hidded.includes(item.RelatedEntityTaxIdNumber.length === 14 ? item.RelatedEntityTaxIdNumber.substring(0, 8) : item.RelatedEntityTaxIdNumber) ? "#ccc" : "#ff0000") } : {}} >
                    <ColorSwatch
                      style={{position: "absolute", left: 4, bottom: "50%", transform: "translateY(50%)"}}
                      onClick={() => {
                        let clicked = item.RelatedEntityTaxIdNumber.length === 14 ? item.RelatedEntityTaxIdNumber.substring(0, 8) : item.RelatedEntityTaxIdNumber;
                        if (hidded.includes(clicked)) {
                          setHidded(hidded.filter((i) => i !== clicked));
                        } else {
                          setHidded([...hidded, clicked]);
                        }
                      }}

                      key={pal[idx]}
                      color={hidded.includes(item.RelatedEntityTaxIdNumber.length === 14 ? item.RelatedEntityTaxIdNumber.substring(0, 8) : item.RelatedEntityTaxIdNumber) ? "#fff" : pal[idx]}
                    >
                      {hidded.includes(item.RelatedEntityTaxIdNumber.length === 14 ? item.RelatedEntityTaxIdNumber.substring(0, 8) : item.RelatedEntityTaxIdNumber) ?
                        <IconEyeOff size={18} color={"#ccc"} strokeWidth={1.5}/> :
                        <IconEye size={18}  strokeWidth={1.5} />}
                    </ColorSwatch>
                    <div style={{paddingLeft: 30}}>
                      <Text color={hidded.includes(item.RelatedEntityTaxIdNumber.length === 14 ? item.RelatedEntityTaxIdNumber.substring(0, 8) : item.RelatedEntityTaxIdNumber) ? "#ccc" : ""} size={"xs"} fw={700}> {item.RelatedEntityName}</Text>
                      <Text color={hidded.includes(item.RelatedEntityTaxIdNumber.length === 14 ? item.RelatedEntityTaxIdNumber.substring(0, 8) : item.RelatedEntityTaxIdNumber) ? "#ccc" : ""} size={"xs"}>{item.RelatedEntityTaxIdNumber} | {format(item.RuralPropertyQtd)} Imóveis [{format(item.RuralPropertyArea)} ha]</Text>
                      {/*<Text color={hidded.includes(item.RelatedEntityTaxIdNumber) ? "#ccc" : ""} size={"xs"}>{translate(item.RelationshipType)}</Text>*/}
                      {/*<Text color={hidded.includes(item.RelatedEntityTaxIdNumber) ? "#ccc" : ""} size={"xs"}>{translate(item.RelationshipName)}</Text>*/}
                      {/*<Text color={hidded.includes(item.RelatedEntityTaxIdNumber.length === 14 ? item.RelatedEntityTaxIdNumber.substring(0, 8) : item.RelatedEntityTaxIdNumber) ? "#ccc" : ""} size={"xs"}>{translate(item.relationshipPath)} </Text>*/}
                      {item.PublicLandOverlap > 0 && <Text fw={700} color={hidded.includes(item.RelatedEntityTaxIdNumber.length === 14 ? item.RelatedEntityTaxIdNumber.substring(0, 8) : item.RelatedEntityTaxIdNumber) ? "#ccc" : "#930000"} size={"xs"}> Sobrep. Terras
                        Públicas: {format(item.PublicLandOverlap * 100) + "%"} </Text>}
                    </div>
                      <Menu
                        style={{position:"absolute", right:"0px",top:"0px"}}
                        withinPortal
                        withArrow
                        zIndex={9999}
                        position={"bottom-end"}
                        withPositioning
                        trigger={"hover"}
                        offset={-5}
                        arrowOffset={14}
                        shadow="md" >
                        <Menu.Target>
                          <ActionIcon
                            size="lg">
                            <IconDotsVertical size={16} />
                          </ActionIcon>
                        </Menu.Target>
                        <Menu.Dropdown>
                          <Menu.Item
                            onClick={(e) => {
                              let allDocs = relatedList.map((i) => i.RelatedEntityTaxIdNumber.length === 14 ? i.RelatedEntityTaxIdNumber.substring(0, 8) : i.RelatedEntityTaxIdNumber);
                              let hidded = allDocs.filter((i) => i !== (item.RelatedEntityTaxIdNumber.length === 14 ? item.RelatedEntityTaxIdNumber.substring(0, 8) : item.RelatedEntityTaxIdNumber));
                              setHidded(hidded)
                            }}
                            icon={<IconZoomScan size={20} />}>Focar</Menu.Item>
                          <Menu.Item
                            onClick={(e) => {
                              addTab({
                                label: `Rel./${item.RelatedEntityTaxIdNumber}`,
                                id: `ESG/Report/${item.RelatedEntityTaxIdNumber}`,
                                component: "PdfPrinter",
                                subcomponent: "EsgReport",
                                disableRefresh: true,
                                props: {
                                  id: item.RelatedEntityTaxIdNumber,
                                  landscape: true,
                                  inputList: [{ cod: item.RelatedEntityTaxIdNumber, codType: 'doc'}],
                                  name: item.RelatedEntityName,
                                  input: item.RelatedEntityTaxIdNumber,
                                  inputType: 'doc',
                                }
                              });
                            }}
                            icon={<IconReportAnalytics size={20} />}>Abrir Relatório</Menu.Item>

                          {/*<Menu.Item onClick={(e) => {e.stopPropagation()}}*/}
                          {/*           icon={<IconLayersIntersect size={20} />}>Visualizar Sobreposições</Menu.Item>*/}

                          {/*<Menu.Item onClick={(e) => {e.stopPropagation()}}*/}
                          {/*           icon={<IconTextPlus  size={20} />}>Adicionar ao Cadastro</Menu.Item>*/}
                        </Menu.Dropdown>
                      </Menu>
                  </Card>
                </>)
          })}
            </ScrollArea>
          </Card>
        </Grid.Col>
        <Grid.Col span={9} pr={0}>
          <Card withBorder style={{ height: "900px" }} my={"xs"} py={0} px={0} mx={0}>
            {mapList.length > 0 && <MapLeaflet
              tabId={tabId}
              hideCluster={true}
              search
              hideButtons
              interactive
              edgeBufferTiles
              uf
              baseMap={"arcgis/light-gray/base"}
              query={mapList}
              hidded={hidded}
              colorPallet={pal}
              minimap
            />}
          </Card>
        </Grid.Col>-
      </Grid>
    );
  }

  return (
    <div className="content">
      <GenericDivider color={"#185e9e"} label={"Informações Básicas"} Icon={IconInfoCircleFilled}/>

      <GenericFieldComponent
        title={""}
        fields={[
          "Name", "OfficialName", "TaxIdNumber", "TaxIdStatus", "Age", "BirthDate", "FoundedDate", "MotherName", "FatherName", "MaritalStatus", "HasObitIndication", "BirthCountry", "VoterId", "SocialSecurityNumber",
          "TaxRegime", "CompanyType_ReceitaFederal", "CapitalRS", "NIRE", "Filiais", "HeadquarterState", "RuralPropertyQtd", "RuralPropertyArea", , "RuralPropertyQtdHistory", "RuralPropertyAreaHistory",
          ...Object.keys(basicData?.AlternativeIdNumbers || {})]}
        data={basicData}/>

      <Grid>
        <Grid.Col span={7}>
          <GenericTableComponent
            error={bigData?.error?.addresses_extended}
            isLoading={!addressesData?.loaded}
            columns={["Address", "Neighborhood", "ZipCode", "City", "State", "Type"]}
            data={addressesData?.data}/>
        </Grid.Col>

        <Grid.Col span={2}>
          <GenericTableComponent
            isLoading={!phonesData?.loaded}
            columns={["PhoneNumber", "Type"]}
            data={phonesData?.data}/>
        </Grid.Col>

        <Grid.Col span={3}>
          <GenericTableComponent
            isLoading={!emailsData?.loaded}
            columns={["EmailAddress", "Type"]}
            data={emailsData?.data}/>
        </Grid.Col>
      </Grid>

      {isCPF && <>

        <GenericTableComponent
          fullHeight
          isLoading={!classOrganizationsData?.loaded}
          title={"Registros em Conselhos de Classe"}
          columns={["Category", "OrganizationChapter", "OrganizationName", "RegistrationId", "Status", "MembershipStartDate", "MembershipEndDate"]}
          data={classOrganizationsData?.data}/>

        <GenericTableComponent
          fullHeight
          title={"Dados Profissionais"}
          columns={["Source", "CompanyName", "CompanyIdNumber", "Sector", "Level", "Status", "StartDate", "EndDate"]}
          isLoading={!ocuppationData?.loaded}
          data={ocuppationData?.data}/>

        <Grid>
          <Grid.Col span={8}>
            <GenericTableComponent
              isLoading={!socialAssistanceData?.loaded}
              title={"Assistência Social"}
              columns={["ProgramName", "ProgramState", "AssistanceAmount", "AssistanceStartDate", "AssistanceEndDate", "TotalInstallments", "AssistanceTotalAmount"]}
              data={socialAssistanceData?.data}/>
          </Grid.Col>

          <Grid.Col span={4}>
            <GenericTableComponent
              isLoading={!familySocialAssistanceData?.loaded}
              title={"Assistência Social | Familiares"}
              columns={["ProgramName", "Beneficiaries", "TotalAssistanceAmount"]}
              data={familySocialAssistanceData?.data}/>
          </Grid.Col>
        </Grid>


        <GenericTableComponent
          isLoading={!relationships}
          fullHeight
          search
          filterField={"RelationshipType"}
          title={"Relacionamentos Pessoais"}
          columns={["RelationshipType", "RelatedEntityName", "RelatedEntityTaxIdNumber", "RuralPropertyQtd", "RuralPropertyArea", "PublicLandOverlapTxt", "EmbargoAreaTxt",  "AmountDonated", "IsPep", "IsSanctioned"]}
          data={relationships?.personal}/>
      </>}

      <div style={{pageBreakInside: 'avoid'}}>
        <GenericDivider color={"#ff8721"} label={"Relacionamentos"} Icon={IconAffiliateFilled}/>
        <GenericTableComponent
          fullHeight
          search
          actions={[{action: "openReport", icon: IconReportAnalytics, label: "Abrir Relatório", tooltip:"Abrir Relatório"}]}
          isLoading={!relationships}
          // filterField={"filial"}
          title={"Relacionamentos Empresariais"}
          columns={["RelationshipLevel", "RelatedEntityName", "RelatedEntityTaxIdNumber", "RelationshipType", "RelationshipName", "RelationshipStartDate", "RelationshipEndDate", "RuralPropertyQtd", "RuralPropertyArea", "PublicLandOverlapTxt", "EmbargoAreaTxt", "IsPep", "IsSanctioned"]}
          data={relationships?.economic}/>
      </div>

      {/*<GenericTableComponent*/}
      {/*  search*/}
      {/*  isLoading={!relationships}*/}
      {/*  title={"Relacionamentos Políticos"}*/}
      {/*  columns={["RelationshipType", "TaxIdNumber", "Nome", "LastPoliticalRole", "LastWonElectionYear", "LastPoliticalPartyName"]}*/}
      {/*  data={relationships?.political} />*/}

      {relationshipsGraphData && <RelationshipsGraph relationships={relationshipsGraphData}/>}

      {relationships && basicData && ruralProperty?.length > 0 && <RelatedRuralProperties/>}

      {ruralProperty?.length > 0 && <GenericTableComponent
        fullHeight
        search
        actions={[{action: "openReport", icon: IconReportAnalytics, label: "Abrir Relatório", tooltip:"Abrir Relatório"}]}
        isLoading={!relationships}
        filterField={"RelationshipLevel"}
        title={"Imóveis Rurais | Lista"}
        columns={["RelationshipLevel", "CAR",  "title", "Área (ha)", "RelatedEntity", "PublicLandOverlapTxt", "EmbargoAreaTxt", "sobrep"]}
        data={ruralProperty.sort((a, b) => (a.PublicLandOverlap > 0) > (b.PublicLandOverlap > 0) ? 1 : -1).sort((a, b) => (a.EmbargoArea > 0) < (b.EmbargoArea > 0) ? 1 : -1)}
      />}

      {ruralPropertyHistory?.length > 0 && <GenericTableComponent
        fullHeight
        search
        actions={[{action: "openReport", icon: IconReportAnalytics, label: "Abrir Relatório", tooltip:"Abrir Relatório"}]}
        isLoading={!relationships}
        filterField={"RelationshipLevel"}
        title={"Imóveis Rurais [Histórico] | Lista"}
        columns={["RelationshipLevel", "CAR",  "title", "Área (ha)", "RelatedEntity", "PublicLandOverlapTxt", "EmbargoAreaTxt", "actual_owner"]}
        data={ruralPropertyHistory.sort((a, b) => (a.PublicLandOverlap > 0) > (b.PublicLandOverlap > 0) ? 1 : -1).sort((a, b) => (a.EmbargoArea > 0) < (b.EmbargoArea > 0) ? 1 : -1)}
      />}

      {reservaLegal?.all.length > 0 && <>
        <GenericDivider color={"#427e00"} label={"Reserva Legal"} Icon={IconPlant}/>
        <Grid>
          {[
            {title: "Consultado e Relacionados 1º Grau", data: reservaLegal?.all},
            {title: "Consultado", data: reservaLegal?.doc},
            {title: "Relacionados 1º Grau", data: reservaLegal?.level1}
          ].map((col, index) => {
            return (
              <Grid.Col span={4} key={index}>
                <Center><Text fw={700} p={"xs"}>{col.title}</Text></Center>
                {[
                  {
                    title: "Prevista, Declarada e Estimada (%)",
                    params: {
                      yMax: 100,
                      series: [
                        {name: "Prevista", type: "bar", value: "perc_rl_c", color: "#464cb6", labelPosition: "top", format: "percentage"},
                        {name: "Declarada", type: "bar", value: "perc_vn_declarada", color: "#427e00", labelPosition: "top", format: "percentage"},
                        {name: "Estimada", type: "bar", value: "perc_vn_estimada", color: "#939393", labelPosition: "top", format: "percentage"}
                      ]
                    },
                    iconColor: "#427e00",
                    icon: IconPlant
                  },
                  {
                    title: "Prevista, Declarada e Estimada (ha)",
                    params: {
                      yMax: reservaLegal?.maxVal,
                      series: [
                        {name: "Prevista", type: "bar", value: "area_rl_minima", color: "#464cb6", labelPosition: "top"},
                        {name: "Declarada", type: "bar", value: "vn_declarada_area", color: "#427e00", labelPosition: "top"},
                        {name: "Estimada", type: "bar", value: "vn_estimada_area", color: "#939393", labelPosition: "top"}
                      ]
                    },
                    iconColor: "#427e00",
                    icon: IconPlant
                  },
                  {
                    title: "Saldo/Déficit (ha)",
                    params: {
                      yMax: reservaLegal?.maxVal,
                      stack: true,
                      series: [
                        {name: "Déficit", type: "bar", value: "dif_neg", color: "red", stack: 1, labelPosition: "top"},
                        {name: "Saldo", type: "bar", value: "dif_pos", color: "#427e00", stack: 1, labelPosition: "top"}
                      ]
                    },
                    iconColor: "#04b2ba",
                    icon: IconPlant
                  },
                  {
                    title: "Desmatamento (ha)",
                    params: {
                      yMax: reservaLegal?.maxVal,
                      stack: true,
                      series: [
                        {name: "Desmatamento", type: "bar", value: "desmatamento_area", color: "orange", stack: 1, labelPosition: "top"}
                      ]
                    },
                    iconColor: "yellow",
                    icon: IconLeafOff
                  }
                ].map((config, index) => (
                  <ChartCard
                    key={index}
                    h={260}
                    title={config.title}
                    titleAlign={"left"}
                    data={col.data}
                    params={config.params}
                    chart={chartBar}
                    isLoading={!reservaLegal}
                    iconColor={config.iconColor}
                    icon={config.icon}
                  />
                ))}
              </Grid.Col>
            )
          })}
        </Grid>
      </>}
      <GenericDivider color={"#00959b"} label={"Reputação"} Icon={IconHelpCircleFilled}/>


      <KycHighlights kycData={kycData} doc={doc} qsa={relationships?.economic}
                     name={bigData?.basic_data?.BasicData?.OfficialName || bigData?.basic_data?.BasicData?.Name} title={"Cadastros Restritivos"}/>

      {(kycData?.sanctions.length > 0) &&
        <GenericTableComponent
          isLoading={!kycData}
          fullHeight
          showCount
          hideEmpty
          title={"Cadastros Restritivos - Detalhamento"}
          columns={[
            "RelatedEntityName", "RelatedEntityTaxIdNumber",
            "Source", "Type", "ProcessNumber", "Status", "ImprisonmentKind", "Decision", "SanctioningBody", "SanctioningBodyState", "State", "PublicationDate", "ActionYear",
            "Establishment", "IrrecorribilityDate", "AmountOfWorkersInvolved", "JudgementType",
            "TadNumber", "Infraction", "PropertyUF", "DeforestedAreaHa", "PropertyLocation",
            "QueryDate",
            "Url"
          ]}
          data={kycData?.sanctions}/>}

      <GenericTableComponent
        fullHeight
        // ExtraInfo={"CandidateData"}
        isLoading={!kycData}
        title={"PEP - Pessoa Politicamente Exposta"}
        columns={["Level", "EntityName","RelatedEntityName","Status",  "JobTitle","Motive", "Department", "StartDate", "EndDate", "Source"]}
        data={kycData?.PEPHistory}/>

      <GenericTableComponent
        isLoading={!governmentDebtorsData?.loaded}
        fullHeight
        showCount
        sumColumns={["ConsolidatedValue"]}
        title={"Débitos com o Governo"}
        columns={[
          "DebtOrigin", "DebtorType", "RegistrationDate", "ResponsibleUnityUF", "ConsolidatedValue", "RegistrationNumber", "RegistrationSituation", "RegistrationSituationType"
        ]}
        data={governmentDebtorsData?.data}/>

      <GenericTableComponent
        title={"Certidões"}
        hideIndex
        fullHeight
        columns={["Origin", "BaseStatus", "ProtocolNumber", "QueryDate", "RawResultFile"]}
        data={onlineCertificatesData?.data}/>

      <GenericTableComponent
        fullHeight
        isLoading={!mediaProfileData?.loaded}
        title={"Exposição na Mídia - Meio Ambiente"}
        columns={["SourceName", "Title", "PublicationDate", "SentimentAnalysis", "Url"]}
        data={mediaProfileData?.data?.filter((x) => x.Categories.includes("environment") ||  x.Categories.includes("weather"))}/>

      <GenericTableComponent
        fullHeight
        isLoading={!mediaProfileData?.loaded}
        title={"Exposição na Mídia - Outros"}
        columns={["SourceName", "Title", "PublicationDate", "SentimentAnalysis", "Url"]}
        data={mediaProfileData?.data?.filter((x) => !x.Categories.includes("environment") && !x.Categories.includes("weather"))}/>

      <GenericDivider color={"#a26d58"} label={"Processos"} Icon={IconGavel}/>
      <Grid>
        <Grid.Col span={3}>
          <DonutChart title={"Por Status"} data={bigData?.lawsuits_distribution_data?.LawsuitsDistributionData?.StatusDistribution}/>
        </Grid.Col>
        <Grid.Col span={3}>
          <DonutChart title={"Por UF"} percentage data={bigData?.lawsuits_distribution_data?.LawsuitsDistributionData?.StateDistribution}/>
        </Grid.Col>
        <Grid.Col span={3}>
          <DonutChart title={"Por Côrte"} percentage data={bigData?.lawsuits_distribution_data?.LawsuitsDistributionData?.CourtTypeDistribution}/>
        </Grid.Col>
        <Grid.Col span={3}>
          <DonutChart title={"Por Tipo"} percentage data={bigData?.lawsuits_distribution_data?.LawsuitsDistributionData?.PartyTypeDistribution}/>
        </Grid.Col>
      </Grid>

      <GenericTableComponent
        isLoading={!processesData?.loaded}
        fullHeight
        search
        ExtraInfo={LawsuitComponent}
        title={"Processos - Detalhamento"}
        columns={["Number", "CourtType", "CourtName", "CourtLevel", "JudgingBody", "CourtDistrict", "MainSubject", "Status", "Value", "NumberOfParties", "NumberOfUpdates", "PublicationDate", "LastMovementDate"]}
        data={processesData?.data}/>

      {<GenericTableComponent
        fullHeight
        isLoading={!bigData?.mpt}
        title={"Ministério Público do Trabalho"}
        columns={["uf","type","region","date","number","proc","url"]}
        data={bigData?.mpt}/>}

      <GenericDivider label={"Outras Informações"} Icon={IconListDetails}/>

      {<GenericTableComponent
        fullHeight
        isLoading={!bigData?.op_bndes}
        sumColumns={["valorContratacao", "valorDesembolsado"]}
        title={"Operações BNDES"}
        ExtraInfo={OpBndesComponent}
        filterField={"liquidada"}
        columns={["numeroContrato", "liquidada", "dataContratacao", "tipoOperacao", "descricaoProjeto", "tope", "fonteRecursos", "valorContratacao", "valorDesembolsado"]}
        data={bigData?.op_bndes?.docs}/>}

      {isCNPJ && <GenericTableComponent
        fullHeight
        isLoading={!syndicateAgreementsData?.loaded}
        title={"Acordos Sindicais"}
        columns={["TypeOfInstrument", "Role", "Categories", "RegistryNumber", "AgreementStartDate", "AgreementEndDate", "InfoURL"]}
        data={syndicateAgreementsData?.data}/>}

      {bigData && false && Object.keys(bigData).map((key) => {
        return (
          <div key={key}>
            <h3>{key}</h3>
            <ReactJson src={bigData[key]} displayDataTypes={false} collapsed={true} theme="monokai"/>
          </div>
        )
      })}
    </div>
  );
});

export default memo(function BigDataComponentDebounced({doc, tabId}) {
  const bigDataRef = React.useRef(null);
  const fetchRef = React.useRef(null);
  const intermediateDataRef = React.useRef({});
  const [bigData, setBigData] = useState(null);
  const [reservaLegal, setReservaLegal] = useState(null);
  const [debouncedBigData] = useDebouncedValue(bigData, 2000);
  const supabase = useSupabaseClient();


  async function fetchBigData(doc, dataset, PageId) {
    let datasets;
    if (doc.length === 11) {
      datasets = dataset || default_pessoas;
    } else if (doc.length === 14) {
      datasets = dataset || default_empresas;
    } else {
      throw new Error('Invalid doc length');
    }

    function debounce(func, wait) {
      let timeout;
      return function(...args) {
        const context = this;
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(context, args), wait);
      };
    }

    const debouncedFetchBigData = debounce(fetchBigData, 100);


    const promises = datasets.map(dataset =>
      supabase.rpc("f_get_bigdata", { ...{ _dataset: dataset, _doc: doc }, ...(PageId ? { _next_page_id: PageId } : {}) })
        .then(({ data, error }) => {
          if (error) {
            return { error: { [dataset]: true } };
          }
          let result = data.Result[0];

          if ((dataset === "processes" ) && intermediateDataRef.current[dataset]) {
            result.Lawsuits.Lawsuits = [...intermediateDataRef.current[dataset].Lawsuits.Lawsuits, ...(result?.Lawsuits?.Lawsuits || [])];
            intermediateDataRef.current[dataset].Lawsuits.Lawsuits = result.Lawsuits?.Lawsuits;
          } else if (( dataset === 'media_profile_and_exposure') && intermediateDataRef.current[dataset]) {
            result.MediaProfileAndExposure.NewsItems = [...intermediateDataRef.current[dataset].MediaProfileAndExposure.NewsItems, ...(result.MediaProfileAndExposure.NewsItems || [])];
            intermediateDataRef.current[dataset].MediaProfileAndExposure.NewsItems = result.MediaProfileAndExposure.NewsItems;
          } else {
            intermediateDataRef.current[dataset] = result;
          }
          setBigData({ ...intermediateDataRef.current });

          if ((dataset === "processes") && result.Lawsuits?.NextPageId) {
            debouncedFetchBigData(doc, ["processes"], result.Lawsuits?.NextPageId);
          }

          if ((dataset === "media_profile_and_exposure") && result.MediaProfileAndExposure?.Next) {
            debouncedFetchBigData(doc, [dataset], result.MediaProfileAndExposure?.Next);
          }
          return { [dataset]: result };
        })
    );

    if (!intermediateDataRef?.current?.op_bndes) {
      const op_bndes =
        supabase.rpc("f_get_op_bndes", { _doc: doc })
          .then(({ data, error }) => {
            if (error) {
              return { error: { op_bndes: true } };
            }
            let result = data.response;
            intermediateDataRef.current.op_bndes = result;
            setBigData({ ...intermediateDataRef.current });
            return { op_bndes: result };
          });
      promises.push(op_bndes);
    }

    if (!intermediateDataRef?.current?.mpt) {
      const mpt =
        supabase.rpc("f_mpt", { _doc: doc })
          .then(({ data, error }) => {
            if (error) {
              return { error: { mpt: true } };
            }
            let result = data;
            intermediateDataRef.current.mpt = result;
            setBigData({ ...intermediateDataRef.current });
            return { mpt: result };
          });
      promises.push(mpt);
    }

    const results = await Promise.all(promises);
    const result = Object.assign({}, ...results);

    setBigData({ ...bigDataRef.current, ...result })
    bigDataRef.current = { ...bigDataRef.current, ...result };
  }

  useEffect(() => {
    if (doc && !bigDataRef.current && !fetchRef.current && (doc.length === 11 || doc.length === 14)) {
      try {
        fetchBigData(doc).then((result) => {
        });
      } catch (error) {
        console.error("Ocorreu um erro ao buscar os dados: ", error);
      }
      fetchRef.current = true;
    }
  }, []);


  useEffect(() => {
    if (
      (intermediateDataRef?.current?.economic_group_relationships &&
      intermediateDataRef?.current?.economic_group_relationships?.EconomicGroupRelationship?.Relationships?.filter((f) => f.RuralProperty?.length > 0).length > 0) ||
      ((intermediateDataRef?.current?.related_people && intermediateDataRef?.current?.business_relationships) &&
        (intermediateDataRef?.current?.related_people?.RelatedPeople?.PersonalRelationships?.filter((f) => f.RuralProperty?.length > 0).length > 0 || intermediateDataRef?.current?.business_relationships?.BusinessRelationships?.BusinessRelationships?.filter((f) => f.RuralProperty?.length > 0).length > 0))
    ){
      /// fetch api f_reserva_legal using promisses,  passing a array of docs from econimicFull where RelationshipLevel is 1st-LEVEL
      async function fetchDataRl() {
        let docsLevel1 = [];
        if (intermediateDataRef?.current?.economic_group_relationships) {
          docsLevel1 = intermediateDataRef?.current?.economic_group_relationships?.EconomicGroupRelationship?.Relationships?.filter((f) => f.RelationshipLevel === "1st-LEVEL" && f.RuralProperty?.length > 0).map((m) =>  m.RelatedEntityTaxIdNumber.length === 11 ?  m.RelatedEntityTaxIdNumber :  m.RelatedEntityTaxIdNumber.substring(0, 8));
        }
        if (intermediateDataRef?.current?.related_people) {
          docsLevel1 = [...docsLevel1, ...intermediateDataRef?.current?.related_people?.RelatedPeople?.PersonalRelationships?.filter((f) =>  f.RuralProperty?.length > 0).map((m) =>  m.RelatedEntityTaxIdNumber.length === 11 ?  m.RelatedEntityTaxIdNumber :  m.RelatedEntityTaxIdNumber.substring(0, 8))];
        }
        if (intermediateDataRef?.current?.business_relationships) {
          docsLevel1 = [...docsLevel1, ...intermediateDataRef?.current?.business_relationships?.BusinessRelationships?.BusinessRelationships?.filter((f) => f.RuralProperty?.length > 0).map((m) =>  m.RelatedEntityTaxIdNumber.length === 11 ?  m.RelatedEntityTaxIdNumber :  m.RelatedEntityTaxIdNumber.substring(0, 8))];
        }

        docsLevel1 = [...new Set(docsLevel1)];
        const promisesRl =
          [supabase.rpc("f_reserva_legal", {...{_doc: [doc.length === 11 ? doc : doc.substring(0, 8)]}})
            .then(({data, error}) => {
              return {doc: data.reserva_legal};
            }),
            supabase.rpc("f_reserva_legal", {...{_doc: docsLevel1}})
              .then(({data, error}) => {
                return  {level1: data.reserva_legal};
              }),
            supabase.rpc("f_reserva_legal", {...{_doc: [doc.length === 11 ? doc : doc.substring(0, 8),...docsLevel1]}})
              .then(({data, error}) => {
                return  {all: data.reserva_legal};
              }),
          ]

        const data = await Promise.all(promisesRl);
        return  Object.assign({}, ...data);
      }

      fetchDataRl().then((result) => {
        result.maxVal = Math.max(
          ...result.all.map((m) => m.rl_est_area),
          ...result.all.map((m) => m.vn_estimada_area),
          ...result.all.map((m) => m.vn_declarada_area),
          ...result.all.map((m) => m.desmatamento_area),
        );

        // round maxVal to the nearest 10 and add 10
        result.maxVal = Math.ceil(result.maxVal / 10) * 10;

        setReservaLegal(result)
      });


    }
  }, [intermediateDataRef?.current?.economic_group_relationships,intermediateDataRef?.current?.related_people,intermediateDataRef?.current?.business_relationships]);


  return <BigDataComponent doc={doc} bigData={debouncedBigData} reservaLegal={reservaLegal} tabId={tabId} />
});