import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import dayjsBusinessDays from 'dayjs-business-days';
import relativeTime from 'dayjs/plugin/relativeTime';
import {
    Accordion,
    ActionIcon,
    Box,
    Button,
    Card,
    Center,
    createStyles,
    FileInput,
    Flex,
    Grid,
    CloseButton,
    Group,
    LoadingOverlay,
    MultiSelect,
    NumberInput,
    Paper,
    Popover,
    rem,
    SegmentedControl,
    Select,
    Stepper,
    Switch,
    Text,
    Textarea,
    TextInput,
    Timeline,
    Title,
    Tooltip, ScrollArea
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { modals } from "@mantine/modals";
import { DateInput } from "@mantine/dates";
import { UploadFile } from "../lib/Uploader";

import { useTabs } from "../lib/Tabs";

import { notifications } from "@mantine/notifications";

import useSWR, { useSWRConfig } from "swr";

import { useSupabaseClient, useUser } from "@supabase/auth-helpers-react";

import {
    IconCalendar,
    IconCalendarPlus,
    IconChevronUp,
    IconCopy,
    IconHelp,
    IconPdf,
    IconPlus,
    IconSubtask,
    IconTrash,
    IconX
} from '@tabler/icons-react';

import { MRT_Localization_PT_BR } from "mantine-react-table/locales/pt-BR";
import { MantineReactTable } from "mantine-react-table";
import Checklist from "../components/Checklist";
import { useClipboard } from "@mantine/hooks";
import MultiDateInput from "../components/MultiDateInput";

dayjs.extend(dayjsBusinessDays);

const useStyles = createStyles((theme) => ({

    root: {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
        borderRadius: theme.radius.sm,
    },


    item: {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
        borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2],
        borderRadius: theme.radius.sm,
        position: 'relative',
        zIndex: 0,
        transition: 'transform 150ms ease',

        '&[data-active]': {
            transform: 'scale(1.03)',
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
            boxShadow: theme.shadows.md,
            borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2],
            borderRadius: theme.radius.sm,
            zIndex: 1,
        },
    },

    chevron: {
        '&[data-rotate]': {
            transform: 'rotate(-90deg)',
        },
    },
}));

function Value({ file }) {
    return (
        <Center
            inline
            sx={(theme) => ({
                backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[1],
                fontSize: theme.fontSizes.xs,
                padding: `${rem(3)} ${rem(7)}`,
                borderRadius: theme.radius.sm,
            })}
        >
            <IconPdf size={rem(14)} style={{ marginRight: rem(5) }} />
            <span
                style={{
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    maxWidth: rem(200),
                    display: 'inline-block',
                }}
            >
                {file.name}
            </span>
        </Center>
    );
}

const ValueComponent = ({ value }) => {
    if (Array.isArray(value)) {
        return (
            <Group spacing="sm" py="xs">
                {value.map((file, index) => (
                    <Value file={file} key={index} />
                ))}
            </Group>
        );
    }

    return <Value file={value} />;
};

function SelectEdit(props) {
    const { value, onChange } = props;

    const { data, error, isLoading } = useSWR(props.disabled ? null : {
        tableName: props.tablename,
        columns: props.columns,
        functionName: props.functionName,
        args: props.args,
        filters: props.filters,
        orders: [["id", { ascending: true }]],
    });

    return (
        <Select
            {...props}
            searchable
            withinPortal
            error={error?.message || props.error}
            loading={isLoading.toString()}
            nothingFound="Não encontrado"
            data={data || []}
            value={value}
            onChange={onChange}
        />
    );
}

function MultiSelectEdit(props) {
    const { value, onChange } = props;

    const { data, error, isLoading } = useSWR(props.disabled ? null : {
        tableName: props.tablename,
        columns: props.columns,
        filters: props.filters,
        orders: [["id", { ascending: true }]],
    });

    return (
        <MultiSelect
            {...props}
            searchable
            withinPortal
            valueComponent={props.valueComponent}
            error={error?.message || props.error}
            loading={isLoading.toString()}
            nothingFound="Não encontrado"
            data={data || []}
            value={value}
            onChange={onChange}
        />
    );
}

const columns = [
    {
        accessorKey: 'name', //access nested data with dot notation
        header: 'Nome',
        size: 120
    },
    {
        accessorKey: 'page',
        header: 'Página',
        size: 90
    },
    {
        accessorKey: 'description', //normal accessorKey
        header: 'Descrição',
    },
];

function estimatedStartingDate(data, value) {
    if (!data) return null;
    let interval = value?.interval || 0;
    let type = value?.interval_type;
    let unit = value?.interval_unit || "day";
    let start = data;

    if (type === 'business_day') {
        return start.businessDaysAdd(interval || 0).toDate();
    }

    start = start.add(interval, unit);

    if (type === 'last_business_month_day') {
        return start.endOf('month').add(1).prevBusinessDay().toDate();
    }

    if (type === 'last_month_day') {
        return start.endOf('month').toDate();
    }

    if (type === 'last_business_year_day') {
        return start.endOf('year').add(1).prevBusinessDay().toDate();
    }

    if (type === 'last_year_day') {
        return start.endOf('year').toDate();
    }
    return start.toDate();
}
function Event({ value, onChange, form, readOnly, endTrigger, index }) {
    const { data, isValidating } = useSWR(value.event_id && {
        functionName: "f_get_trigger_date",
        args: {
            _id: value.event_id,
            _include_predecessors: true,
            _is_event: true
        }
    }
    );


    useEffect(() => {
        if (data && !isValidating) {
            if (value.category === 'event') {
                onChange({
                    ...value,
                    estimated_start_date: estimatedStartingDate(dayjs((value.condition === 'start') ? data && data[0] && data[0].trg_start_date : data && data[0] && data[0].trg_end_date),
                        value)
                })
            }
        }
    }, [value?.interval_unit, value?.interval_type, value?.condition, value?.interval, data, isValidating]);

    useEffect(() => {
        let minStartDate = form.values.triggers.map((t) => t.estimated_start_date)
        form.setFieldValue('estimated_start_date', minStartDate.sort((a, b) => a - b)[0]);
    }, [value?.estimated_start_date?.toDateString()]);

    return (
        <>
            <Grid gutter={"xl"}>
                {value.category === "event" && <Grid.Col span={5}>
                    <SelectEdit
                        {...form.getInputProps(`triggers.${index}.event_id`)}
                        readOnly={readOnly}
                        tablename="event"
                        placeholder={endTrigger ? "Indique o evento que altera a duração ou data limite deste evento" : "Indique o evento que precede este evento"}
                        columns="value:id,label:title"
                        withAsterisk
                        value={value.event_id}
                        onChange={val => onChange({
                            ...value,
                            event_id: val
                        })}
                        filters={
                            [
                                ["is_collection ", "is", false],
                                ["is_condition  ", "is", false],
                                ["project_id", "eq", form.values.project_id]
                            ]
                        }

                        label={endTrigger ? "Evento" : "Evento Precedente"}
                    />

                    {data && <Timeline
                        reverseActive
                        active={data.filter((item) => item.trg_start_date <= ((new Date()).toISOString().substring(0, 10))).length - 1}
                        bulletSize={24}
                        lineWidth={2} mt="lg"
                    >
                        {
                            data.map((item) =>
                                <Timeline.Item bullet={<IconChevronUp size={12} />} title={item.event_title}>
                                    <Text color="dimmed" size="sm">{item.trg_description}</Text>
                                    {item.trg_start_date && !item.recurrent_event && <Text color="dimmed" size="sm">
                                        {item.trg_start_date === item.trg_end_date || item.recurrent_event ?
                                            dayjs(item.trg_start_date).format('DD/MM/YYYY') :
                                            dayjs(item.trg_start_date).format('DD/MM/YYYY') + ' a ' + dayjs(item.trg_end_date).format('DD/MM/YYYY')}
                                    </Text>}
                                    {item.recurrent_event && <Text fw={500} size="sm">Evento com Recorrência</Text>}
                                </Timeline.Item>)
                        }
                    </Timeline>}
                </Grid.Col>
                }
                <Grid.Col span={value.category === "event" ? 7 : 12}>
                    <Grid gutter={"md"}>
                        {value.category === "event" && (
                            <>
                                <Grid.Col span={12}>
                                    <Select
                                        readOnly={readOnly}
                                        label={endTrigger ? "Condição para alteração da Duração ou Data Limite" : "Condição para Inicio"}
                                        disabled={value.event_id == null}
                                        value={value.condition}
                                        onChange={val => onChange({
                                            ...value,
                                            condition: val
                                        })}

                                        data={endTrigger ? [
                                            { value: "conclusion", label: 'Conclusão do Evento Selecionado' },
                                            { value: "duration", label: 'Duração/Data Limite do Evento Selecionado' }
                                        ] : [
                                            { value: "conclusion", label: 'Conclusão do Evento Precedente' },
                                            { value: "duration", label: 'Duração/Data Limite do Evento Precedente' },
                                            { value: "start", label: 'Inicio do Evento Precedente' }
                                        ]}
                                    />
                                </Grid.Col>


                                <Grid.Col span={3}>
                                    <NumberInput
                                        readOnly={readOnly}
                                        disabled={value.event_id == null}
                                        hideControls
                                        value={value.interval}
                                        onChange={val => onChange({
                                            ...value,
                                            interval: val
                                        })}
                                        max={9999}
                                        min={0}
                                        step={1}
                                        label="Intervalo de tempo"
                                    />
                                </Grid.Col>

                                <Grid.Col span={2}>
                                    <Select
                                        readOnly={readOnly}
                                        label="Unidade"
                                        disabled={value.event_id == null}
                                        value={value.interval_unit}
                                        onChange={val => onChange({
                                            ...value,
                                            interval_unit: val
                                        })}

                                        data={[
                                            { value: "day", label: 'Dias' },
                                            { value: "month", label: 'Meses' },
                                            { value: "year", label: 'Anos' }
                                        ]}
                                    />
                                </Grid.Col>

                                <Grid.Col span={3}>
                                    {value.interval_unit === "day" && <Select
                                        readOnly={readOnly}
                                        value={value.interval_type}
                                        disabled={value.event_id == null}
                                        label="Dias Úteis"
                                        data={[
                                            { value: 'business_day', label: 'Sim' },
                                            { value: 'calendar_day', label: 'Não' }
                                        ]}
                                        onChange={val => onChange({
                                            ...value,
                                            interval_type: val
                                        })}
                                    />}
                                    {value.interval_unit === "month" && <Select
                                        readOnly={readOnly}
                                        value={value.interval_type}
                                        label="Último dia do mês"
                                        data={[
                                            { value: 'last_business_month_day', label: 'Sim - Útil' },
                                            { value: 'last_month_day', label: 'Sim - Corrido' },
                                            { value: 'calendar_day', label: 'Não' }
                                        ]}
                                        onChange={val => onChange({
                                            ...value,
                                            interval_type: val
                                        })
                                        }
                                    />}
                                    {value.interval_unit === "year" && <Select
                                        readOnly={readOnly}
                                        value={value.interval_type}
                                        label="Último dia do ano"
                                        data={[
                                            { value: 'last_business_year_day', label: 'Sim - Útil' },
                                            { value: 'last_year_day', label: 'Sim - Corrido' },
                                            { value: 'calendar_day', label: 'Não' }
                                        ]}
                                        onChange={val => onChange({
                                            ...value,
                                            interval_type: val
                                        })}
                                    />}
                                </Grid.Col>
                                <Grid.Col span={4}>
                                    <DateInput
                                        valueFormat="DD/MM/YYYY"
                                        readOnly
                                        color={"teal"}
                                        sx={{ input: { backgroundColor: "#dff2fd" }, textAlign: "center", width: '180px' }}
                                        styles={{ input: { textAlign: "center" } }}
                                        variant="filled"
                                        label={endTrigger ? "Data Limite Prevista" : "Data Prevista para Início"}
                                        value={value.estimated_start_date}
                                    />
                                </Grid.Col>
                            </>
                        )}


                        {value.category === "date" && (
                            <>
                                <Grid.Col span={12}>
                                    <Group position={"center"}>
                                        <DateInput
                                            {...form.getInputProps(`triggers.${index}.start_date`)}
                                            readOnly={readOnly}
                                            hideWeekdays
                                            sx={{ textAlign: "center", width: '180px' }}
                                            styles={{ input: { textAlign: "center" } }}
                                            placeholder="Data de Início"
                                            valueFormat="DD/MM/YYYY"
                                            label="Data de Início"
                                            dateParser={(input) => {
                                                return dayjs(input, 'DD/MM/YYYY').toDate();
                                            }}
                                            onChange={val => onChange({
                                                ...value,
                                                start_date: val,
                                                estimated_start_date: val,
                                            })} />
                                    </Group>
                                </Grid.Col>

                            </>
                        )}

                        {value.category === "condition" && (
                            <>
                                <Grid.Col span={12}>
                                    <TextInput
                                        readOnly={readOnly}
                                        label="Descrição"
                                        placeholder={"Descreva a condição necessária pra iniciar este evento"}
                                        value={value.description}
                                        onChange={val => onChange({
                                            ...value,
                                            description: val.target.value
                                        })} />
                                </Grid.Col>
                            </>
                        )}

                    </Grid>
                </Grid.Col>
            </Grid>
        </>
    )
}

function DeleteTriggerButton({ onClick, disabled }) {
    const [opened, setOpened] = useState(false);

    const { theme } = useStyles();

    return (
        <Popover
            width={260}
            mx="sm"
            position="bottom-center"
            withArrow
            opened={opened}
            onChange={setOpened}
            transitionProps={{ transition: "pop-top-right" }}
            withinPortal
            disabled={disabled}
        >
            <Popover.Target>
                <Tooltip withArrow label={"Excluir Gatilho"}>
                    <ActionIcon size="lg" onClick={() => setOpened(true)} disabled={disabled}>
                        <IconTrash
                            size="1rem"
                            stroke={1.5}
                            color={theme.colors.red[6]}
                        />
                    </ActionIcon>
                </Tooltip>
            </Popover.Target>

            <Popover.Dropdown>
                <Box mb="md">Excluir este Gatilho?</Box>


                <Group position="right" >
                    <Button variant="default" onClick={() => { setOpened(false); }}>
                        Cancelar
                    </Button>

                    <Button color="red" onClick={() => {
                        onClick();

                        setOpened(false);
                    }}>
                        Excluir
                    </Button>
                </Group>
            </Popover.Dropdown>
        </Popover>
    )
}

function DeleteEventButton({ onClick }) {
    const [opened, setOpened] = useState(false);

    const { theme } = useStyles();

    return (
        <Popover
            width={260}
            mx="sm"
            position="bottom-center"
            withArrow
            opened={opened}
            onChange={setOpened}
            transitionProps={{ transition: "pop-top-right" }}
            withinPortal
        >
            <Popover.Target>
                <Tooltip withArrow label={"Excluir evento"} position="left">
                    <ActionIcon size="lg" onClick={() => setOpened(true)} style={{ position: "absolute", "right": "0px", top: "0px" }}>
                        <IconTrash
                            size="1rem"
                            stroke={1.5}
                            color={theme.colors.red[6]}
                        />
                    </ActionIcon>
                </Tooltip>
            </Popover.Target>

            <Popover.Dropdown>
                <Box mb="md">Excluir este evento?</Box>


                <Group position="right" >
                    <Button variant="default" onClick={() => { setOpened(false); }}>
                        Cancelar
                    </Button>

                    <Button color="red" onClick={() => {
                        onClick();

                        setOpened(false);
                    }}>
                        Excluir
                    </Button>
                </Group>
            </Popover.Dropdown>
        </Popover>
    )
}

export function NovaFonte({ project_id, callback }) {
    const [loading, setLoading] = useState(false);

    const supabase = useSupabaseClient();
    const user = useUser();

    const form = useForm({
        initialValues: {
            project_id: project_id,
            name: "",
            description: "",
            file: null,
            parts: []
        },
        validate: {
            project_id: (value) =>
                !value ? "O projeto precisa ser selecionado." : null,
            name: (value) =>
                value.length < 3 ? "Nome precisa ter no minimo 3 caracteres." : null,
            file: (value) =>
                !value
                    ? "Um arquivo PDF precisa ser selecionado."
                    : null,
        },
    });

    const handleCreateNewRow = async () => {
        form.setFieldValue("parts", [...form.values.parts, {
            name: "",
            page: 1,
            description: ""
        }]);
    };

    const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
        const parts = [...form.values.parts];

        parts[row.index] = values;

        form.setFieldValue("parts", parts);

        exitEditingMode(); //required to exit editing mode and close modal
    };

    const handleCancelRowEdits = () => {

    };

    const save = async () => {
        if (form.validate().hasErrors) {
            return;
        }

        setLoading(true);

        try {
            const file = form.values.file;
            const filename = file.name.replace(/[^0-9a-zA-Z-_.]/gi, '');

            const { data, error } = await supabase.rpc("f_new_source", {
                _new_source: {
                    part: form.values.parts,
                    project_id: form.values.project_id,
                    name: form.values.name,
                    description: form.values.description
                }
            });

            if (error) {
                notifications.show({
                    withCloseButton: true,
                    autoClose: 3000,
                    message: error.message,
                    color: "red",
                    icon: <IconX />,
                    loading: false,
                });

                return;
            }

            const result = await UploadFile(supabase, file, `${user.user_metadata.tenant_id
                }/source/${data.value}/${filename}`);

            if (result.statusCode && result.statusCode !== 200) {
                notifications.show({
                    withCloseButton: true,
                    autoClose: 3000,
                    message: result.message,
                    color: "red",
                    icon: <IconX />,
                    loading: false,
                });

            }

            callback && callback(data);

            modals.close("new-event");
        }
        finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Grid gutter="sm">
                {!project_id &&
                    <Grid.Col span={12}>
                        <SelectEdit
                            tablename="project"
                            columns="value:id,label:name"
                            label={
                                <CustomLabel
                                    labelText="Projeto"
                                    withAsterisk
                                    tooltipText="Indique a qual projeto o evento pertence."
                                />
                            }
                            {...form.getInputProps("project_id")}
                        />
                    </Grid.Col>
                }
                <Grid.Col span={12}>
                    <TextInput
                        readOnly={!form.values.project_id}
                        label="Nome"
                        withAsterisk
                        {...form.getInputProps("name")}
                    />
                </Grid.Col>
                <Grid.Col span={12}>
                    <Textarea
                        readOnly={!form.values.project_id}
                        label="Descrição"
                        {...form.getInputProps("description")}
                    />
                </Grid.Col>

                <Grid.Col span={12}>
                    <FileInput
                        readOnly={!form.values.project_id}
                        accept="application/pdf"
                        label="PDF"
                        withAsterisk
                        valueComponent={ValueComponent}
                        {...form.getInputProps("file")}
                    />
                </Grid.Col>
                <Grid.Col span={12}>
                    <MantineReactTable
                        columns={columns}
                        data={form.getInputProps("parts")["value"]}
                        enableRowSelection //enable some features
                        enableColumnOrdering={false}
                        enableColumnDragging={false}
                        enableMultiSort={false}
                        enablePinning
                        enableGrouping={false}
                        enableColumnVirtualization
                        editingMode="row"
                        enableFullScreenToggle={false}
                        enableStickyHeader
                        enableSorting={false}
                        enableFilters={false}
                        enableEditing
                        enableClickToCopy
                        enableColumnFilterModes={false}
                        enablePagination={false}
                        positionToolbarAlertBanner="bottom" //show selected rows count on bottom toolbar
                        enableRowVirtualization={false}
                        mantineTableContainerProps={{ sx: { maxHeight: "340px" } }}
                        mantinePaperProps={{
                            shadow: "none",
                            withBorder: false,
                            sx: {
                                background: "transparent",
                            },
                        }}
                        rowVirtualizerProps={{ overscan: 5 }} //optionally customize the row virtualizer
                        renderTopToolbarCustomActions={({ table }) => {
                            const { rows } = table.getSelectedRowModel();

                            return (
                                <Flex gap="md">
                                    <Button
                                        color="primary"
                                        onClick={() => handleCreateNewRow({ table })}
                                        variant="filled"
                                    >
                                        Novo
                                    </Button>

                                    <Button
                                        color="red"
                                        disabled={rows.length === 0}
                                        onClick={() => {
                                            const indexList = rows.map(row => row.index);

                                            form.setFieldValue("parts", form.values.parts.filter((val, index) => !indexList.includes(index)));

                                            table.resetRowSelection();
                                        }}
                                        variant="filled"
                                    >
                                        Excluir {rows.length > 0 ? ` (${rows.length})` : ``}
                                    </Button>
                                </Flex>
                            );
                        }}
                        localization={MRT_Localization_PT_BR}
                        onEditingRowSave={handleSaveRowEdits}
                        onEditingRowCancel={handleCancelRowEdits}
                        positionActionsColumn="last"
                        initialState={{ density: "xs" }}
                        displayColumnDefOptions={{
                            "mrt-row-actions": {
                                header: "", //change header text
                                size: 80, //make actions column wider
                            },
                        }}
                    />
                </Grid.Col>
            </Grid>

            <Group position="right" mt="xl">
                <Button variant="default" onClick={() => modals.close("new-event")}>
                    Cancelar
                </Button>
                <Button onClick={save}>Salvar</Button>
            </Group>

            <LoadingOverlay visible={loading} overlayBlur={2} />
        </>
    );
}

function CustomLabel({ labelText, tooltipText, withAsterisk }) {
    return (
        <Flex align="center" style={{ whiteSpace: "nowrap" }}>
            <Text mr={2}>
                {labelText}
                {withAsterisk && (
                    <Text as="span" ml={1} color="red" fontWeight="bold" display="inline-block">
                        *
                    </Text>
                )}
            </Text>
            <Tooltip withArrow style={{ fontSize: 10 }} label={tooltipText}>
                <IconHelp color="#aaa" size="0.8rem" strokeWidth="2" />
            </Tooltip>
        </Flex>
    );
}

export function Review(props) {
    return (
        <>
            <Card shadow="sm" padding="md" my="sm" radius="md" withBorder>
                <Description {...props} />
            </Card>

            <Card shadow="sm" padding="md" my="sm" radius="md" withBorder>
                <Triggers {...props} />
            </Card>

            <Card shadow="sm" padding="md" my="sm" radius="md" withBorder>
                <Recurrence {...props} />
            </Card>

            <Card shadow="sm" padding="md" my="sm" radius="md" withBorder>
                <Duration {...props} />
            </Card>

            <Card shadow="sm" padding="md" my="sm" radius="md" withBorder>
                <Checklist {...props} />
            </Card>
        </>
    );
}

function Triggers({ form, readOnly }) {
    return (
        <>
            <Title order={3} weight={600} align="center">
                Gatilhos para Início
            </Title>
            <Title order={6} color={"dimmed"} weight={400} mb="md" align="center">
                (O que ocorrer primeiro)
            </Title>
            {
                form.values.triggers.map((ev, index) => (
                    <>
                        <Paper shadow="sm" m={10} p="md" radius="md" withBorder>

                            {ev && <Event value={ev} readOnly={readOnly} index={index} onChange={(newValue) => {
                                const newTriggers = [...form.values.triggers];
                                newTriggers[index] = newValue;
                                form.setFieldValue("triggers", newTriggers);
                            }} form={form} />}
                            <Group
                                position={"right"}>
                                <DeleteTriggerButton disabled={readOnly} position="bottom-end" onClick={() => {
                                    form.setFieldValue("triggers", form.values.triggers.filter(v => ev !== v));
                                }} />
                            </Group>
                        </Paper>
                        {/*{index !== form.values.triggers.length-1 && <Text weight={500} align={"center"}>OU</Text>}*/}
                    </>
                ))
            }

            <Group mt={14} position={"center"} >
                <Tooltip
                    withArrow
                    style={{ fontSize: 10 }}
                    label={"O Gatilho de Evento Precedente deve ser utilizado quando o evento que esta sendo criado acontece após a realização de outro evento."}>
                    <Button
                        disabled={readOnly}
                        variant="light"
                        leftIcon={<IconSubtask size={rem(16)} stroke={1.5} />}
                        rightIcon={<IconPlus size={rem(12)} stroke={3} />}
                        onClick={() => {
                            form.setFieldValue("triggers", [...form.values.triggers, {
                                id: window.crypto.getRandomValues(new Uint32Array(1))[0].toString(16),
                                category: "event",
                                type: "start",
                                interval_type: "calendar_day",
                                interval_unit: "day",
                                interval: 0,
                                condition: "conclusion"
                            }]);
                        }}
                    >
                        Evento Precedente
                    </Button>
                </Tooltip>

                {form.values.triggers.filter((e) => e.category === "date").length > 0 &&
                    <Tooltip
                        withArrow
                        style={{ fontSize: 10 }}
                        label={"Apenas é possivel um gatilho de Data de Início por Evento"}>
                        <Button
                            disabled={readOnly}
                            leftIcon={<IconCalendar size={rem(16)} stroke={1.5} />}
                            rightIcon={<IconPlus size={rem(12)} stroke={3} />}
                            data-disabled
                            sx={{ '&[data-disabled]': { pointerEvents: 'all' } }}
                            onClick={(event) => event.preventDefault()}
                        >
                            Data de Início
                        </Button>
                    </Tooltip>}
                {form.values.triggers.filter((e) => e.category === "date").length === 0 &&
                    <Tooltip
                        withArrow
                        style={{ fontSize: 10 }}
                        label={"O Gatilho de Data de Início deve ser utilizado quando o evento possui uma data de inicio pré estabelecida. Um por Evento"}>
                        <Button
                            disabled={readOnly}
                            variant="light"
                            leftIcon={<IconCalendar size={rem(16)} stroke={1.5} />}
                            rightIcon={<IconPlus size={rem(12)} stroke={3} />}
                            onClick={() => {
                                form.setFieldValue("triggers", [...form.values.triggers, {
                                    id: window.crypto.getRandomValues(new Uint32Array(1))[0].toString(16),
                                    category: "date",
                                    interval_type: "calendar_day",
                                    interval_unit: "day",
                                    condition: "conclusion",
                                    type: "start",
                                }]);
                            }}>
                            Data de Início
                        </Button>
                    </Tooltip>
                }
            </Group>
        </>
    )
}

function Recurrence({ form, readOnly }) {

    const [frequencyList, setFrequencyList] = useState([]);

    const options =
        ((((form.values?.frequency_day?.length > 0 || (form.values?.frequency_type === 'last_month_day' ||
            form.values?.frequency_type === 'last_business_month_day'
        )) &&
            form.values?.frequency_type) || form.values?.frequency_specific_date.length > 0) &&
            form.values.frequency) &&

        {
            functionName: "f_frequency_date_api",
            args: {
                _params: {
                    type: form.values.frequency_type,
                    days: form.values.frequency_day || [],
                    months: form.values.frequency_month || [],
                    years: form.values.frequency_year || [],
                    specific_date: form.values.frequency_specific_date || [],
                },
                _start: form.values.estimated_start_date,
                _end: form.values.limit_date
            }
        };

    const { data, error, mutate, isValidating } = useSWR(options);

    useEffect(() => {
        if (data && !isValidating && ((form.values?.frequency_day?.length > 0 || (form.values?.frequency_type === 'last_month_day' ||
            form.values?.frequency_type === 'last_business_month_day'
        )) || form.values?.frequency_specific_date?.length > 0) && data?.dates?.length > 0) {
            setFrequencyList(data.dates);
        } else {
            setFrequencyList([]);
        }
    }, [form.values.frequency_type, form.values.frequency_day, form.values.frequency_month, form.values.frequency_year, form.values.specific_date, data, isValidating]);

    return (
        <>
            <Title order={3} weight={600} mb="md" align="center">
                Recorrência
            </Title>

            <Grid gutter="sm" >
                <Grid.Col span={12}>
                    <Group spacing={"sm"} position="center">
                        <Switch
                            {...form.getInputProps("frequency")}
                            checked={form.values.frequency}
                            size={"lg"}
                            labelPosition="left"
                            onLabel="Sim" offLabel="Não"
                            label="O Evento é Recorrente?"
                            {...(readOnly ? { onChange: undefined } : {})}
                        />
                    </Group>
                </Grid.Col>
                <Grid.Col span={12}>
                    <Accordion
                        disableChevronRotation
                        variant="contained"
                        // backgroundColor={"grey"}
                        radius="md"
                        chevronPosition="left"
                        value={form.values.frequency ? "frequency" : form.values.frequency}
                        onChange={val => ({ ...form.values, frequency: val === "frequency" })}>
                        <Accordion.Item value="frequency">
                            <Accordion.Control
                                disabled={(form.values.event_id == null && form.values.category === "event") || !form.values.frequency}
                            >
                                Parâmetros da  Recorrência
                            </Accordion.Control>
                            <Accordion.Panel>
                                <Grid gutter="md" >
                                    <Grid.Col span={12}>
                                        <Group spacing={"sm"} position="center">

                                            <DateInput

                                                {...form.getInputProps("estimated_start_date")}
                                                valueFormat="DD/MM/YYYY"
                                                readOnly
                                                color={"teal"}
                                                sx={{ input: { backgroundColor: "#dff2fd" }, textAlign: "center", width: '180px' }}
                                                variant="filled"
                                                label="Data Prevista para Início"
                                                styles={{ width: '180px', input: { textAlign: "center" } }}

                                            />
                                            <DateInput
                                                {...form.getInputProps("limit_date")}
                                                valueFormat={"DD/MM/YYYY"}
                                                hideWeekdays
                                                minDate={dayjs(form.values.estimated_start_date)}
                                                excludeDate={(d) => {
                                                    return d < dayjs(form.values.estimated_start_date)
                                                }}
                                                label="Data Limite da Recorrência"
                                                readOnly={readOnly}
                                                dateParser={(input) => {
                                                    return dayjs(input, 'DD/MM/YYYY').toDate();
                                                }}
                                                sx={{ textAlign: "center", width: '180px' }}
                                                styles={{ input: { textAlign: "center" } }}
                                            />
                                        </Group>
                                    </Grid.Col>
                                    <Grid.Col span={4}>
                                        <Select
                                            {...form.getInputProps("frequency_type")}
                                            checked={form.values.frequency_type || "working_day"}
                                            label="Tipo"
                                            readOnly={readOnly}
                                            data={[
                                                { value: "bussines_day", label: 'Dia útil' },
                                                { value: "week_day", label: 'Dia da semana' },
                                                { value: "calendar_day", label: 'Dia corrido' },
                                                { value: "last_month_day", label: 'Último dia do mês' },
                                                { value: "last_business_month_day", label: 'Ultimo dia útil do mês' },
                                            ]}
                                        />
                                    </Grid.Col>
                                    {form.values.frequency_type == null || form.values.frequency_type === "bussines_day" ? (
                                        <Grid.Col span={8}>
                                            <MultiSelect
                                                readOnly={readOnly}
                                                {...form.getInputProps("frequency_day")}
                                                disabled={form.values.frequency_type == null}
                                                checked={form.values.frequency_day}
                                                placeholder="Selecione os dias"
                                                label="Dias"
                                                data={Array.from(Array(20)).map((_, index) => ({
                                                    value: index + 1,
                                                    label: `${index + 1} º`
                                                }))}
                                            />
                                        </Grid.Col>
                                    ) : (form.values.frequency_type === "week_day" ?
                                        <Grid.Col span={8}>
                                            <MultiSelect
                                                readOnly={readOnly}
                                                {...form.getInputProps("frequency_day")}
                                                checked={form.values.frequency_day}
                                                placeholder="Selecione os dias"
                                                label="Dias"
                                                data={Array.from(Array(7)).map((_, index) => ({
                                                    value: index,
                                                    label: dayjs().startOf("week").add(index, "day").format("dddd")
                                                }))}
                                            />
                                        </Grid.Col> : form.values.frequency_type === "calendar_day" &&
                                        <Grid.Col span={8}>
                                            <MultiSelect
                                                readOnly={readOnly}
                                                {...form.getInputProps("frequency_day")}
                                                checked={form.values.frequency_day}
                                                placeholder="Selecione os dias"
                                                label="Dias"
                                                data={Array.from(Array(28)).map((_, index) => ({
                                                    value: index + 1,
                                                    label: `${index + 1}`
                                                }))}
                                            />
                                        </Grid.Col>)}

                                    <Grid.Col span={7}>
                                        <MultiSelect
                                            readOnly={readOnly}
                                            {...form.getInputProps("frequency_month")}
                                            checked={form.values.frequency_month}
                                            placeholder="Todos os Meses"
                                            label="Meses"
                                            data={Array.from(Array(12)).map((_, index) => ({
                                                value: index + 1,
                                                label: dayjs().startOf("year").add(index, "month").format("MMMM")
                                            }))}
                                        />
                                    </Grid.Col>
                                    <Grid.Col span={5}>
                                        <MultiSelect
                                            readOnly={readOnly}
                                            {...form.getInputProps("frequency_year")}
                                            checked={form.values.frequency_year}
                                            placeholder="Todos os Anos"
                                            label="Anos"
                                            data={Array.from(Array(60)).map((_, index) => ({
                                                value: +('20' + (index + 18)),
                                                label: +('20' + (index + 18))
                                            }))}
                                        />
                                    </Grid.Col>
                                    <Grid.Col span={12}>
                                        <MultiDateInput
                                            {...form.getInputProps("frequency_specific_date")}
                                            label={"Datas Especificas"}
                                        />
                                    </Grid.Col>
                                    <Grid.Col span={12}>
                                        <MultiSelect
                                            label={"Datas Previstas de Recorrência"}
                                            readOnly
                                            styles={{ input: { backgroundColor: "#dff2fd" } }}
                                            value={frequencyList.map((item) => dayjs(item).format('DD/MM/YY'))}
                                            data={frequencyList.map((item) => dayjs(item).format('DD/MM/YY'))}
                                        />
                                    </Grid.Col>

                                </Grid>
                            </Accordion.Panel>
                        </Accordion.Item>
                    </Accordion>
                </Grid.Col>
                <Grid.Col span={4}>

                </Grid.Col>
            </Grid>
        </>
    );
}

function Duration({ form, readOnly }) {

    useEffect(() => {
        form.setFieldValue('estimated_end_date',
            estimatedStartingDate(
                dayjs(form.values.estimated_start_date),
                {
                    interval: form.values.duration,
                    interval_type: form.values.duration_type,
                    interval_unit: form.values.duration_unit
                }));
    }, [form.values.duration_unit, form.values.duration_type, form.values.duration]);


    return (
        <>
            <Title order={3} weight={600} mb="md" align="center">
                Duração ou Data Limite
            </Title>

            <Grid gutter="sm"  >
                <Grid.Col span={12}>
                    <Group position="center">
                        <SegmentedControl
                            {...form.getInputProps('interval_or_date')}
                            value={(form.values.frequency ? 'date' : form.values.interval_or_date) || 'interval'}
                            data={[
                                { label: 'Duração', value: 'interval', disabled: form.values.frequency },
                                { label: 'Data Limite', value: 'date' },
                            ]}
                            disabled={form.values.frequency}
                            readOnly={readOnly}
                            onChange={(value) => {
                                form.setFieldValue("interval_or_date", value)
                                if (value === 'interval') {
                                    form.setFieldValue("limit_date", null)
                                    form.setFieldValue('estimated_end_date',
                                        estimatedStartingDate(
                                            dayjs(form.values.estimated_start_date),
                                            {
                                                interval: form.values.duration,
                                                interval_type: form.values.duration_type,
                                                interval_unit: form.values.duration_unit
                                            }));
                                }
                                if (value === 'date') {
                                    form.setFieldValue("estimated_end_date", null)
                                    form.setFieldValue("limit_date", form.values.estimated_start_date)
                                }
                            }}
                        />
                    </Group>
                </Grid.Col>
                <Grid.Col span={12}>
                    <Group spacing={"sm"} position="center" style={{ alignItems: "flex-end" }}>

                        <DateInput
                            valueFormat="DD/MM/YYYY"
                            readOnly
                            color={"teal"}
                            sx={{ input: { backgroundColor: "#dff2fd" }, textAlign: "center", width: '180px' }}
                            styles={{ input: { textAlign: "center" } }}
                            variant="filled"
                            label={"Data Prevista de Início"}
                            value={form.values.estimated_start_date}
                        />
                        {form.values.duration_unit !== "forever" && <NumberInput
                            {...form.getInputProps("duration")}
                            hideControls
                            disabled={(form.values.project_id == null || form.values.frequency) || form.values.interval_or_date !== 'interval'}
                            max={9999}
                            min={0}
                            step={1}
                            readOnly={readOnly}
                            label={
                                <CustomLabel
                                    labelText="Duração do Evento"
                                    tooltipText="Indique a Duração do evento, em Dias, Meses ou Anos."
                                />
                            }
                        />
                        }
                        <Select
                            disabled={(form.values.project_id == null || form.values.frequency) || form.values.interval_or_date !== 'interval'}
                            {...form.getInputProps("duration_unit")}
                            label={
                                <CustomLabel
                                    labelText="Unidade de Medida"
                                    tooltipText="Indique a Unidade de Medida da Duração do Evento. Perpétuo indica um evento onde a data de término não é definida."
                                />
                            }
                            readOnly={readOnly}
                            data={[
                                { value: "day", label: 'Dias' },
                                { value: "month", label: 'Meses' },
                                { value: "year", label: 'Anos' }
                            ]}
                        />

                        {form.values.duration_unit === "day" && <Select
                            {...form.getInputProps("duration_type")}
                            value={form.values.duration_type}
                            readOnly={readOnly}
                            disabled={(form.values.project_id == null || form.values.frequency) || form.values.interval_or_date !== 'interval'}
                            label="Dias Úteis"
                            data={[
                                { value: 'business_day', label: 'Sim' },
                                { value: 'calendar_day', label: 'Não' }
                            ]}
                        />}
                        {form.values.duration_unit === "month" && <Select
                            {...form.getInputProps("duration_type")}
                            value={form.values.duration_type}
                            disabled={(form.values.project_id == null || form.values.frequency) || form.values.interval_or_date !== 'interval'}
                            label="Último dia do mês"
                            readOnly={readOnly}
                            data={[
                                { value: 'last_business_month_day', label: 'Sim - Útil' },
                                { value: 'last_month_day', label: 'Sim - Corrido' },
                                { value: 'calendar_day', label: 'Não' }
                            ]}
                        />}
                        {form.values.duration_unit === "year" && <Select
                            {...form.getInputProps("duration_type")}
                            value={form.values.duration_type}
                            disabled={(form.values.project_id == null || form.values.frequency) || form.values.interval_or_date !== 'interval'}
                            label="Último dia do ano"
                            readOnly={readOnly}
                            data={[
                                { value: 'last_business_year_day', label: 'Sim - Útil' },
                                { value: 'last_year_day', label: 'Sim - Corrido' },
                                { value: 'calendar_day', label: 'Não' }
                            ]}
                        />}
                        <DateInput
                            valueFormat="DD/MM/YYYY"
                            readOnly
                            color={"teal"}
                            disabled={(form.values.project_id == null || form.values.frequency) || form.values.interval_or_date !== 'interval'}
                            sx={{ input: { backgroundColor: "#dff2fd" }, textAlign: "center", width: '180px' }}
                            styles={{ input: { textAlign: "center" } }}
                            variant="filled"
                            label={"Data Limite Prevista"}
                            value={form.values.estimated_end_date}
                        />


                        <Text weight={600} pb={8} >ou</Text>
                        <DateInput
                            {...form.getInputProps("limit_date")}
                            valueFormat={"DD/MM/YYYY"}
                            hideWeekdays
                            minDate={dayjs(form.values.estimated_start_date)}
                            excludeDate={(d) => {
                                return d < dayjs(form.values.estimated_start_date)
                            }}
                            disabled={form.values.frequency || form.values.interval_or_date !== 'date'}
                            label="Data Limite"
                            dateParser={(input) => {
                                return dayjs(input, 'DD/MM/YYYY').toDate();
                            }}
                            readOnly={readOnly}
                            sx={{ textAlign: "center", width: '180px' }}
                            styles={{ input: { textAlign: "center" } }}
                        />
                    </Group>
                </Grid.Col>
                <Grid.Col span={12}>
                    <Group spacing="xl" position="center">
                        <Switch

                            {...form.getInputProps("have_end_trigger")}
                            checked={form.values.have_end_trigger}
                            size={"lg"}
                            labelPosition="left"
                            onLabel="Sim" offLabel="Não"
                            label="A Duração ou a Data Limite pode ser alterada por outro Evento?"
                            {...(readOnly ? { onChange: undefined } : {})}
                        />
                    </Group>
                </Grid.Col>
                <Grid.Col span={12}>
                    {form.values.have_end_trigger && <Paper shadow="sm" m={10} p="md" radius="md" withBorder>
                        <Event
                            endTrigger
                            value={
                                form.values.end_trigger.id ?
                                    form.values.end_trigger :
                                    {
                                        id: window.crypto.getRandomValues(new Uint32Array(1))[0].toString(16),
                                        category: "event",
                                        type: "end",
                                        interval_type: "calendar_day",
                                        interval_unit: 'day',
                                        interval: 0,
                                        condition: "conclusion"
                                    }}
                            readOnly={readOnly} onChange={(newValue) => {
                                form.setFieldValue("end_trigger", newValue);
                            }} form={form} />
                    </Paper>}
                </Grid.Col>
            </Grid>
        </>
    )
}

function Description({ form, supabase, mutate, novaFonte, readOnly }) {
    const ValueComponent = ({
        value,
        label,
        onRemove,
        classNames,
        ...others
    }) => {
        const clipboard = useClipboard({ timeout: 1000 });

        return (
            <div {...others}>
                <Box
                    sx={(theme) => ({
                        display: 'flex',
                        cursor: 'default',
                        alignItems: 'center',
                        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
                        border: `${rem(1)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[4]
                            }`,
                        paddingLeft: theme.spacing.xs,
                        borderRadius: theme.radius.sm,
                    })}
                >
                    <Box mr={10}>
                        <Tooltip label={clipboard.copied ? "Descrição copiada para área de transferência!" : "Copiar para área de transferência"}>
                            <ActionIcon
                                color={"primary"}
                                onMouseDown={ev => {
                                    ev.preventDefault();
                                    ev.stopPropagation();
                                }}
                                onClick={async (ev) => {
                                    ev.preventDefault();
                                    ev.stopPropagation();

                                    const result = await supabase
                                        .from("source_part")
                                        .select("description")
                                        .filter("id", "eq", value)
                                        .single();

                                    clipboard.copy(result.data.description);
                                }}>
                                <IconCopy variant="subtle" size="1.125rem" />
                            </ActionIcon>
                        </Tooltip>
                    </Box>
                    <Box sx={{ lineHeight: 1, fontSize: rem(12) }}>{label}</Box>
                    <CloseButton
                        onMouseDown={onRemove}
                        variant="transparent"
                        size={22}
                        iconSize={14}
                        tabIndex={-1}
                    />
                </Box>
            </div>
        );
    }

    return (
        <>
            <Title order={3} weight={600} align="center">
                Descrição do Evento
            </Title>

            <Grid gutter="sm">
                <Grid.Col span={2}>
                    <SelectEdit
                        tablename="project"
                        columns="value:id,label:name"
                        label={
                            <CustomLabel
                                labelText="Projeto"
                                withAsterisk
                                tooltipText="Indique a qual projeto o evento pertence."
                            />
                        }
                        readOnly={readOnly}
                        {...form.getInputProps("project_id")}
                    />
                </Grid.Col>

                <Grid.Col span={5}>
                    <TextInput
                        disabled={form.values.project_id == null}
                        label={
                            <CustomLabel
                                labelText="Nome"
                                tooltipText="Defina um Nome para o Evento. Este nome será utilizado para identificar o evento."
                                withAsterisk={true}
                            />
                        }
                        readOnly={readOnly}
                        {...form.getInputProps("title")}
                    />
                </Grid.Col>

                <Grid.Col span={4}>
                    <SelectEdit
                        tablename="event_collection"
                        columns="value:id,label:title"
                        filters={[["project_id", "eq", `${form.values.project_id}`]]}
                        label={
                            <CustomLabel
                                labelText="Grupo de Eventos"
                                tooltipText="Indique o Grupo de Evento a qual o Evento que esta sendo criado pertence. Eventos do mesmo grupo podem ser visualizados de forma agrupada."
                            />
                        }
                        readOnly={readOnly}
                        creatable
                        clearable
                        disabled={form.values.project_id == null}
                        getCreateLabel={(query) => `+ Criar "${query}"`}
                        onCreate={async (query) => {
                            const { data } = await supabase
                                .from("event_collection")
                                .insert({
                                    project_id: form.values.project_id,
                                    title: query,
                                })
                                .select("id");

                            const item = { value: data[0].id, label: query };

                            mutate(
                                {
                                    tableName: "event_collection",
                                    columns: "value:id,label:title",
                                    filters: [["project_id", "eq", `${form.values.project_id}`]],
                                    orders: [["id", { ascending: true }]],
                                },
                                (values) => {
                                    return [...values, item];
                                }
                            );

                            form.setFieldValue("event_collection_id", item.value);
                        }}
                        {...form.getInputProps("event_collection_id")}
                    />
                </Grid.Col>

                <Grid.Col span={1}>
                    <Select
                        {...form.getInputProps("confidential")}
                        disabled={form.values.project_id == null}
                        label={
                            <CustomLabel
                                labelText="Confidencial"
                                tooltipText="Indique se o Evento é Confidencial. Um Evento Confidencial só pode ser visualizado pelos Responsáveis e Relacionados."
                            />
                        }
                        data={[
                            { value: true, label: 'Sim' },
                            { value: false, label: 'Não' }
                        ]}
                        readOnly={readOnly}
                    />
                </Grid.Col>


                <Grid.Col span={12}>
                    <MultiSelectEdit
                        tablename="v_groups_extended"
                        columns="value:id,label:name,group:type"
                        readOnly={readOnly}
                        label={
                            <CustomLabel
                                labelText="Responsáveis"
                                tooltipText="Indique quais os Usuários ou Grupos de Usuários será o Responsável pelo Evento. Apenas os responsáveis poderão editar e concluir o evento."
                                withAsterisk={true}
                            />
                        }
                        disabled={form.values.project_id == null}
                        filters={[["project_id", "ov", `{ ${form.values.project_id} }`]]}
                        {...form.getInputProps("event_owners")}
                    />
                </Grid.Col>

                <Grid.Col span={12}>
                    <MultiSelectEdit
                        tablename="v_groups_extended"
                        columns="value:id,label:name,group:type"
                        readOnly={readOnly}
                        label={
                            <CustomLabel
                                labelText="Grupos/Usuários Relacionados"
                                tooltipText="Indique quais os Usuários ou Grupos de Usuários estão relacionados a este Evento. Os Relacionados podem acompanhar o evento, realizar comentários/followups, mas não podem concluir ou editar o evento."
                            />
                        }
                        disabled={form.values.project_id == null}
                        filters={[["project_id", "ov", `{ ${form.values.project_id} }`]]}
                        {...form.getInputProps("event_related")}
                    />
                </Grid.Col>

                <Grid.Col span={12}>
                    <Textarea
                        disabled={form.values.project_id == null}
                        label={
                            <CustomLabel
                                labelText="Descrição"
                                tooltipText="Descreva o Evento, as ações necessárias, condições, ações, etc."
                            />
                        }
                        {...form.getInputProps("description")}
                        autosize
                        readOnly={readOnly}
                        minRows={2}
                        maxRows={4}
                    />
                </Grid.Col>

                <Grid.Col span={12}>
                    <MultiSelectEdit
                        tablename="source_part"
                        columns="value:id,label:name,group:type"
                        readOnly={readOnly}
                        label={
                            <CustomLabel
                                labelText="Fontes"
                                tooltipText="Selecione o Documento/Cláusula que da origem ao evento que esta sendo criado"
                            />
                        }
                        rightSection={
                            <Tooltip label="Nova fonte" withArrow>
                                <ActionIcon variant="filled" onClick={novaFonte} color="primary" disabled={readOnly || form.values.project_id == null}>
                                    <IconPlus
                                        size="1rem"
                                    />
                                </ActionIcon>
                            </Tooltip>
                        }
                        valueComponent={(props) => <ValueComponent {...props} />}
                        disabled={form.values.project_id == null}
                        filters={[["project_id", "eq", `${form.values.project_id}`]]}
                        {...form.getInputProps("event_source")}
                    />

                </Grid.Col>

                <Grid.Col span={2}>

                </Grid.Col>

            </Grid>
        </>
    )
}

export default function CreateEvent({ className, eventId, tabId }) {
    const { removeTab } = useTabs();
    const { mutate: mutateGlobal } = useSWRConfig();

    const supabase = useSupabaseClient();

    const { mutate } = useSWRConfig();

    const { data: defaultValue, isValidating } = useSWR(eventId && {
        functionName: "f_event",
        args: {
            _event_id: eventId
        }
    }
    );

    const [active, setActive] = useState(0);

    const form = useForm({
        initialValues: {
            title: "",
            project_id: null,
            event_collection_id: null,
            event_owners: [],
            event_related: [],
            limit_date: null,
            estimated_start_date: null,
            estimated_end_date: null,
            frequency: false,
            frequency_day: [],
            frequency_type: null,
            frequency_month: [],
            frequency_year: [],
            frequency_specific_date: [],
            confidential: false,
            duration_bussines_day: false,
            duration: 0,
            duration_unit: "day",
            duration_type: "calendar_day",
            interval_or_date: "interval",
            triggers: [],
            end_trigger: {
                id: window.crypto.getRandomValues(new Uint32Array(1))[0].toString(16),
                category: "event",
                type: "end",
                interval_type: "calendar_day",
                interval_unit: 'day',
                interval: 0,
                condition: "conclusion"
            },
            checklist: [],
            event_source: [],
        },
        validate: {
            title: (value) =>
                value.length < 3 && active >= 0
                    ? "Nome precisa ter no minimo 3 caracteres." : null,
            project_id: (value) =>
                !value && active >= 0
                    ? "Indique o Projeto que o Evento fará parte."
                    : null,
            event_owners: (value) =>
                value.length < 1 && active >= 0
                    ? "Indique ao menos 1 Usuário ou Grupo de Usuários como Responsável pelo Evento."
                    : null,
            triggers: {
                event_id: (value, values, path) => {
                    let index = path.split(".")[1];

                    return values.triggers[index].category !== "date" && !value && active >= 1
                        ? "O evento que precede este evento é obrigatório."
                        : null;
                },
                start_date:
                    (value, values, path) => {
                        let index = path.split(".")[1];

                        return values.triggers[index].category === "date" && !value && active >= 1
                            ? "A Data de Início é obrigatória."
                            : null;
                    },
            },
            frequency_type: (value, values) =>
                values.frequency && (!value && values.frequency_specific_date?.length === 0) && active >= 2
                    ? "Defina o Tipo da Recorrência"
                    : null,
            frequency_day: (value, values) =>
                values.frequency && values.frequency_type &&
                    (value?.length === 0 &&
                        values.frequency_type !== 'last_month_day' &&
                        values.frequency_type !== 'last_business_month_day')
                    && active >= 2
                    ? "Selecione pelo menos um dia"
                    : null,
            limit_date: (value, values) =>
                values.interval_or_date === 'date' && !value && active >= 3
                    ? "Defina a Data Limite"
                    : null,
            checklist: {
                description: (value) => {
                    return !value && active >= 4
                        ? "A descrição é obrigatória."
                        : null;
                },
            },
        },
    });

    useEffect(() => {
        if (defaultValue) {
            if (!defaultValue.triggers) {
                defaultValue.triggers = [];
            }

            for (let trigger of defaultValue.triggers) {
                if (trigger.start_date) {
                    trigger.start_date = dayjs(trigger.start_date).toDate();
                }
                if (trigger.estimated_start_date) {
                    trigger.estimated_start_date = dayjs(trigger.estimated_start_date).toDate();
                }
            }

            let minStartDate = defaultValue.triggers.map((t) => t.estimated_start_date);
            defaultValue.estimated_start_date = minStartDate.sort((a, b) => a - b)[0];

            if (defaultValue.limit_date) {
                defaultValue.limit_date = dayjs(defaultValue.limit_date).toDate();
            }

            form.setValues(defaultValue);
        }
    }, [defaultValue]);

    useEffect(() => {
        if (defaultValue?.is_condition && !isValidating) {
            removeTab(tabId);
            notifications.show({
                withCloseButton: true,
                autoClose: 3000,
                message: "Não é possivel editar diretamente um checklist, favor editar o Evento a qual o checklist pertence.",
                color: "red",
                icon: <IconX />,
                loading: false,
            })
        }
    }, [defaultValue?.is_condition]);


    useEffect(() => {
        if (form?.values?.project_id && form?.values?.project_id !== defaultValue?.project_id) {
            form.setFieldValue("event_owners", []);

            form.setFieldValue("event_related", []);

            form.setFieldValue("event_source", []);

            form.setFieldValue("event_collection_id", null);
        }
    }, [form?.values?.project_id, defaultValue?.project_id]);

    useEffect(() => {
        if (!form?.values?.frequency && form.isDirty()) {
            form.setFieldValue("frequency_day", []);

            form.setFieldValue("frequency_month", []);

            form.setFieldValue("frequency_year", []);

            form.setFieldValue("frequency_specific_date", []);

            form.setFieldValue("frequency_type", null);
        }
    }, [form?.values?.frequency]);

    const nextStep = () =>
        setActive((current) => {
            if (form.validate().hasErrors) {
                return current;
            }
            if (!(form.values.triggers && form.values.triggers.length > 0) && current === 1) {
                return current;
            }

            return current < 5 ? current + 1 : current;
        });

    const saveEvent = async () => {

        // console.log('validate form')
        if (form.validate().hasErrors) {

            // console.log('form has errors')
            return;
        }
        // console.log('form validated')
        try {

            // console.log('saving event')
            const { error } = await supabase.rpc("f_new_event", {
                _new_event: { ...form.values }
            });

            // console.log('event saved')
            if (error) {
                notifications.show({
                    withCloseButton: true,
                    autoClose: 3000,
                    message: error.message,
                    color: "red",
                    icon: <IconX />,
                    loading: false,
                });
            } else {
                notifications.show({
                    withCloseButton: true,
                    autoClose: 3000,
                    message: "Evento \"" + form.values.title + "\"" + (form.values.event_id ? " atualizado" : " criado") + " com sucesso!",
                    color: "green",
                    icon: <IconCalendarPlus />,
                    loading: false,
                });
            }

        }
        finally {
            removeTab(tabId);

            mutateGlobal({
                tableName: "v_project_date_range",
                columns: "id, name, start_date, end_date",
            })
        }

    };

    const nextTooltip = () => {
        if (active === 1) {
            if (!(form.values.triggers && form.values.triggers.length > 0)) {
                return "É necessário inserir ao menos um gatilho de inicio";
            }
        }

        return "Ir para próxima etapa";
    };

    const isNextDisabled = () => {
        if (active === 1) {
            return !(form.values.triggers && form.values.triggers.length > 0);
        }

        return false;
    };

    const prevStep = () =>
        setActive((current) => (current > 0 ? current - 1 : current));

    const novaFonte = () => {
        modals.open({
            size: "xl",
            title: "Nova Fonte",
            modalId: "new-event",
            withinPortal: true,
            children: <NovaFonte project_id={form.values.project_id} callback={async evt => {
                const evtSource = [...form.values.event_source, evt.value];

                await mutateGlobal({
                    tableName: "source_part",
                    columns: "value:id,label:name,group:type",
                    filters: [["project_id", "eq", `${form.values.project_id}`]],
                    orders: [["id", { ascending: true }]],
                }, data => {
                    return [...data, evt];
                }, { revalidate: false });

                form.setFieldValue("event_source", evtSource);
            }} />,
        });
    };

    return (
        <>
            <div className={className}>
                {eventId && <DeleteEventButton onClick={async () => {

                    const { error } = await supabase
                        .from("event")
                        .delete()
                        .eq("id", eventId);
                    removeTab(tabId);

                    if (error) {
                        notifications.show({
                            withCloseButton: true,
                            autoClose: 3000,
                            message: error.message,
                            color: "red",
                            icon: <IconX />,
                            loading: false,
                        });
                    }
                }} />}

                <Stepper active={active} onStepClick={eventId ? setActive : undefined} breakpoint="sm" p="lg">
                    <Stepper.Step label="Descrição">
                        <Description form={form} mutate={mutate} novaFonte={novaFonte} supabase={supabase} />
                    </Stepper.Step>

                    <Stepper.Step label="Gatilhos para Início">
                        <Triggers form={form} mutate={mutate} novaFonte={novaFonte} supabase={supabase} />
                    </Stepper.Step>

                    <Stepper.Step label="Recorrência" >
                        <Recurrence form={form} mutate={mutate} novaFonte={novaFonte} supabase={supabase} />
                    </Stepper.Step>

                    <Stepper.Step label="Duração ou Data Limite" >
                        <Duration form={form} mutate={mutate} novaFonte={novaFonte} supabase={supabase} />
                    </Stepper.Step>

                    <Stepper.Step label="Checklist">
                        <Checklist form={form} />
                    </Stepper.Step>

                    <Stepper.Step label="Revisão">
                        <Title order={3} weight={600} mb="md" align="center">
                            Revisão
                        </Title>
                        <ScrollArea h={"calc(100vh - 280px)"}>
                            <Review form={form} mutate={mutate} novaFonte={novaFonte} supabase={supabase} readOnly={true} />
                        </ScrollArea>
                    </Stepper.Step>
                </Stepper>

                <Group position="right" m={"lg"}>
                    {active !== 0 && (
                        <Button variant="default" onClick={prevStep}>
                            Voltar
                        </Button>
                    )}

                    {active !== 5 && isNextDisabled() &&
                        <Tooltip label={nextTooltip()}>
                            <Button
                                onClick={nextStep}
                                data-disabled
                                sx={{ '&[data-disabled]': { pointerEvents: 'all' } }}
                            >
                                Próximo
                            </Button>
                        </Tooltip>}

                    {active !== 5 && !isNextDisabled() &&
                        <Tooltip label={nextTooltip()}>
                            <Button
                                onClick={nextStep}
                            >
                                Próximo
                            </Button>
                        </Tooltip>}


                    {active === 5 && <Button onClick={saveEvent}>Salvar</Button>}
                </Group>

                <LoadingOverlay visible={isValidating} overlayBlur={2} />
            </div>
        </>
    );

}
