import React from "react";

import ReactECharts from 'echarts-for-react';

import {
    Container
} from "@mantine/core";

import useSWR, { useSWRConfig } from "swr";

import {useTabs} from "../lib/Tabs";

export default function GraphPage({ className, refreshRequested, onRefresh, projectId, rows }) {
    const { addTab } = useTabs();


    const { data: chartData } = useSWR({
        functionName: "f_relationship_graph",
        args: {
            _doc: rows
        }
    });
    let nodeData =  chartData && chartData.nodes || []

    ;  // Data from node.json
    let linkData = chartData && chartData.links || []
    const options = {
        title: {
            text: 'Consulta de Relacionamentos'
        },
        tooltip: {},
        animationDurationUpdate: 1500,
        animationEasingUpdate: 'quinticInOut',
        series: [
            {
                draggable: true,
                type: 'graph',
                layout: 'force',
                force: {
                    repulsion: [1000, 2000],
                    edgeLength: 200,
                    rotateLabel: true,
                },
                roam: true,
                label: {
                    position:'bottom',
                    formatter: "{b}",
                    show: true,
                    overflow: "break",
                    width: 100,
                    fontSize: 10,
                    emphasis: {
                        overflow: "break",
                    },
                },
                edgeLabel: {
                    show: true,
                    fontSize: 8,
                    distance:1,
                    emphasis: {
                        fontSize: 12,
                    },
                },
                symbolSize: 30,
                edgeSymbol: ['circle', 'arrow'],
                data: nodeData.map((n)=> n ={...n,
                    symbol: n.id.replace(/\D/g, "").length >= 14 ?
                        n.id.replace(/\D/g, "").length > 20 ?
                            "path://M528 336c-48.6 0-88 39.4-88 88s39.4 88 88 88 88-39.4 88-88-39.4-88-88-88zm0 112c-13.2 0-24-10.8-24-24s10.8-24 24-24 24 10.8 24 24-10.8 24-24 24zm80-288h-64v-40.2c0-14.1 4.7-27.8 13.2-38.8 4.4-5.8 3.6-14.1-1.3-19.5L534.2 37.3c-6.7-7.5-18.3-6.9-24.7 .8C490.6 60.9 480 89.8 480 119.8V160H377.7L321.6 29.1A47.9 47.9 0 0 0 277.5 0H144c-26.5 0-48 21.5-48 48v146.5c-8.6-6.7-21-6.5-28.9 1.5L36 227.1c-8.6 8.6-8.6 22.5 0 31.1l5.1 5.1c-5 9.3-9 18.8-11.9 28.7H22c-12.2 0-22 9.9-22 22v44c0 12.2 9.9 22 22 22h7.1c3 9.9 6.9 19.5 11.9 28.7l-5.1 5.1c-8.6 8.6-8.6 22.5 0 31.1L67.1 476c8.6 8.6 22.5 8.6 31.1 0l5.1-5.1c9.3 5 18.8 9 28.7 11.9V490c0 12.2 9.9 22 22 22h44c12.2 0 22-9.9 22-22v-7.1c9.9-3 19.5-6.9 28.7-11.9l5.1 5.1c8.6 8.6 22.5 8.6 31.1 0l31.1-31.1c8.6-8.6 8.6-22.5 0-31.1l-5.1-5.1c5-9.3 9-18.8 11.9-28.7H330c12.2 0 22-9.9 22-22v-6h80.5c21.9-29 56.3-48 95.5-48 18.6 0 36.1 4.6 51.8 12.2l50.8-50.8c6-6 9.4-14.1 9.4-22.6V192c0-17.7-14.3-32-32-32zM176 416c-44.2 0-80-35.8-80-80s35.8-80 80-80 80 35.8 80 80-35.8 80-80 80zm22-256h-38V64h106.9l41.2 96H198z" :
                            "path://M436 480h-20V24c0-13.255-10.745-24-24-24H56C42.745 0 32 10.745 32 24v456H12c-6.627 0-12 5.373-12 12v20h448v-20c0-6.627-5.373-12-12-12zM128 76c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12V76zm0 96c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12v-40zm52 148h-40c-6.627 0-12-5.373-12-12v-40c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40c0 6.627-5.373 12-12 12zm76 160h-64v-84c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v84zm64-172c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12v-40c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40zm0-96c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12v-40c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40zm0-96c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12V76c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40z"
                        : "path://M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z",
                    // ...(n.id.replace(/\D/g, "").length > 20 ? {
                    //     itemStyle: {
                    //         color:"green"
                    //     }
                    // } : {}),
                }),
                links: linkData,
                emphasis: {
                    label:{show: true},
                    focus: 'adjacency',
                    lineStyle: {
                        width: 6
                    }
                },
                lineStyle: {
                    opacity: 0.9,
                    width: 1,
                    curveness: 0
                }
            }
        ]
    };

    return (
        <Container fluid my="md" className={className}>
            <ReactECharts
                style={{height:`calc(100vh - 96px)`}}
                option={options}
            />
        </Container>
    );
};


