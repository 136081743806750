'use strict';

async function fetchImage(url, callback, headers, abort) {
  let _headers = {};
  if (headers) {
    headers.forEach(h => {
      _headers[h.header] = h.value;
    });
  }
  const controller = new AbortController();
  const signal = controller.signal;
  if (abort) {
    abort.subscribe(() => {
      controller.abort();
    });
  }
  const f = await fetch(url, {
    method: "GET",
    headers: _headers,
    mode: "cors",
    signal: signal
  });
  const blob = await f.blob();
  callback(blob);
}

L.TileLayerWithHeaders = L.TileLayer.extend({
  initialize: function (url, options, headers, abort) {
    L.TileLayer.prototype.initialize.call(this, url, options);
    this.headers = headers;
    this.abort = abort;
  },
  createTile(coords, done) {
    let url = this.getTileUrl(coords);
    const img = document.createElement("img");
    img.setAttribute("role", "presentation");

    fetchImage(
      url,
      resp => {
        const reader = new FileReader();
        if (resp.type !== "image/png" && resp.type !== "image/jpeg") {
          // If the response is not an image, set the source to a transparent image
          img.src = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7";
          done(null, img);
          return;
        }
        reader.onload = () => {
          img.src = reader.result;
        };
        reader.readAsDataURL(resp);
        done(null, img);
      },
      this.headers,
      this.abort
    );
    return img;
  }

});

L.tileLayer = function (url, options, headers, abort) {
  return new L.TileLayerWithHeaders(url, options, headers, abort);
};

L.TileLayerWithHeadersWMS = L.TileLayer.WMS.extend({
  initialize: function (url, options, headers, abort) {
    L.TileLayer.WMS.prototype.initialize.call(this, url, options);
    this.headers = headers;
    this.abort = abort;
  },
  createTile(coords, done) {
    let url = this.getTileUrl(coords);
    const img = document.createElement("img");
    img.setAttribute("role", "presentation");

    fetchImage(
      url,
      resp => {
        const reader = new FileReader();
        if (resp.type !== "image/png" && resp.type !== "image/jpeg") {
          // If the response is not an image, set the source to a transparent image
          img.src = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7";
          done(null, img);
          return;
        }
        reader.onload = () => {
          img.src = reader.result;
        };
        reader.readAsDataURL(resp);
        done(null, img);
      },
      this.headers,
      this.abort
    );
    return img;
  }
});

L.tileLayer.wms = function (url, options, headers, abort) {
  return new L.TileLayerWithHeadersWMS(url, options, headers, abort);
};

L.TileLayerWithHeaders.prototype.getFeatureInfo = async function (url, latlng, params, headers) {
  // Construct the GetFeatureInfo URL
  const point = this._map.latLngToContainerPoint(latlng, this._map.getZoom());
  const size = this._map.getSize();

  const viewparams = [
    'SERVICE=WMS',
    'VERSION=1.1.1',
    `REQUEST=GetFeatureInfo`,
    `LAYERS=${params.layers}`,
    `QUERY_LAYERS=${params.layers}`,
    `STYLES=`,
    `BBOX=${this._map.getBounds().toBBoxString()}`,
    `FEATURE_COUNT=5`,
    `HEIGHT=${size.y}`,
    `WIDTH=${size.x}`,
    `FORMAT=image/png`,
    `INFO_FORMAT=text/html`,
    `SRS=EPSG:4326`,
    `X=${point.x}`,
    `Y=${point.y}`
  ].join('&');

  const getFeatureInfoUrl = `${url}?${viewparams}`;

  // Send the GetFeatureInfo request using fetch API
  const response = await fetch(getFeatureInfoUrl, {
    method: 'GET',
    headers: headers
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  } else {
    const data = await response.text();
    return data;
  }
};