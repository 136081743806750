import {
  MultiSelect,
  Box,
  CloseButton,
  rem,
} from '@mantine/core';

import dayjs from "dayjs";
import { useEffect, useState, useRef } from "react";

function Value({
    value,
    label,
    onRemove,
    classNames,
    ...others
}) {
    return (
        <div {...others}>
            <Box
                sx={(theme) => ({
                    display: 'flex',
                    cursor: 'default',
                    alignItems: 'center',
                    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
                    border: `${rem(1)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[4]
                        }`,
                    paddingLeft: theme.spacing.xs,
                    borderRadius: theme.radius.sm,
                })}
            >
                <Box sx={{ lineHeight: 1, fontSize: rem(12) }}>{dayjs(label).format('DD/MM/YYYY')}</Box>
                <CloseButton
                    onMouseDown={onRemove}
                    variant="transparent"
                    size={22}
                    iconSize={14}
                    tabIndex={-1}
                />
            </Box>
        </div>
    );
}

export default function MultiDateInput(props) {
    const [query, setQuery] = useState("");
    let { value, onChange } = props;

    if (value == null) {
        value = [];
    }

    const ref = useRef(null);

    useEffect(() => {
        if (ref.current) {

            setTimeout(() => {
                ref.current.focus({ focusVisible: true });
            }, 500);

        }
    }, [value]);

    return <MultiSelect
        {...props}
        ref={ref}
        creatable={query.length == 10 && dayjs(query, 'DD/MM/YYYY', true).isValid()}
        searchable
        searchValue={query}
        onSearchChange={setQuery}
        getCreateLabel={(query) => query.length == 10 && dayjs(query, 'DD/MM/YYYY', true).isValid() ? query : null}
        withinPortal
        valueComponent={Value}
        onKeyDown={ev => {
            if (ev.key == "Enter") {
                if (query.length == "10") {
                    setQuery("");

                    let dt = dayjs(query, 'DD/MM/YYYY').toISOString().substring(0, 10);

                    if (!value.includes(dt)) {
                        onChange([...value, dt]);
                    }
                }
            }
        }}
        data={value}
        value={value}
        onChange={onChange}
        onCreate={(query) => {
            return dayjs(query, 'DD/MM/YYYY').toISOString().substring(0, 10);
        }}
    />
}