import {
    createStyles,
    Group,
    ActionIcon,
    Menu,
    rem,
    Center,
    Indicator,
    Highlight,
    TextInput,
    UnstyledButton,
    ScrollArea,
} from "@mantine/core";

import { notifications } from '@mantine/notifications';

import dayjs from "dayjs";

import useSWRSubscription from 'swr/subscription';

import { useUser, useSupabaseClient } from "@supabase/auth-helpers-react";

import {
    IconCalendarEvent,
    IconStatusChange,
    IconUserExclamation,
    IconBell,
    IconSearch,
    IconCheck,
} from "@tabler/icons-react";

import relativeTime from "dayjs/plugin/relativeTime";
import customParseFormat from "dayjs/plugin/customParseFormat";

import React, { createContext, useState, useEffect, useRef, useContext } from 'react';

dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);

const useStyles = createStyles((theme) => ({
    action: {
        position: 'relative',
        display: 'block',
        width: '100%',
        padding: `${rem(10)} ${rem(12)}`,
        borderRadius: "12",
        ...theme.fn.hover({
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[1],
        }),

        '&[data-hovered]': {
            backgroundColor: theme.fn.primaryColor(),
            color: theme.white,
            ...theme.fn.hover({
                backgroundColor: theme.fn.primaryColor(),
            }),
        },
    },

    actionDescription: {
        color: theme.fn.dimmed(),

        '&[data-hovered]': {
            color: theme.white,
            opacity: 0.7,
        },
    },

    actionIcon: {
        color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[6],

        '&[data-hovered]': {
            color: theme.white,
            opacity: 0.7,
        },
    },

    actionBody: { flexGrow: "1 !important" },

    actionDate: {
        fontSize: 11,
        color: theme.fn.dimmed(),

        '&[data-hovered]': {
            color: theme.white,
            opacity: 0.7,
        },
    },

    actionHighlight: {
        '& [data-highlight]': {
            color: theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.black,
        },
    },

    content: {
        position: 'relative',
        overflow: 'hidden',
    },

    searchInput: {
        border: 0,
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,

        '&, &:focus-within': {
            borderBottom: `${rem(1)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
                }`,
        },
    },
}));

function DefaultAction({
    Icon,
    styles,
    classNames,
    hovered,
    onTrigger,
    title,
    highlightQuery,
    highlightColor,
    description,
    query,
    date,
    radius,
    ...others
}) {
    const { classes } = useStyles();

    return (
        <UnstyledButton
            className={classes.action}
            data-hovered={hovered || undefined}
            tabIndex={-1}
            onMouseDown={(event) => event.preventDefault()}
            onClick={onTrigger}
            {...others}
        >
            <Group noWrap>
                {Icon && (
                    <Center className={classes.actionIcon} data-hovered={hovered || undefined}>
                        {Icon}
                    </Center>
                )}

                <div className={classes.actionBody}>
                    <Highlight
                        highlightColor={highlightColor}
                        className={classes.actionHighlight}
                        highlight={highlightQuery ? query : null}
                    >
                        {title}
                    </Highlight>

                    {description && (
                        <Highlight
                            highlightColor={highlightColor}
                            className={classes.actionDescription}
                            size="xs"
                            highlight={highlightQuery ? query : null}
                            data-hovered={hovered || undefined}
                        >
                            {description}
                        </Highlight>
                    )}
                </div>

                <div className={classes.actionDate}>
                    {date}
                </div>
            </Group>
        </UnstyledButton>
    );
}

function filterActions(query, actions) {
    query = query.trim().toLowerCase();

    const priorityMatrix = [[], []];

    actions.forEach((action) => {
        if (action.title?.toLowerCase().includes(query)) {
            priorityMatrix[0].push(action);
        } else if (
            action.description?.toLowerCase().includes(query)
        ) {
            priorityMatrix[1].push(action);
        }
    });

    return priorityMatrix.flat();
}

const NotificationContext = createContext([]);


export function NotificationProvider({ children }) {
    const user = useUser();

    return (
        user && user.id ? <InnerNotificationProvider user={user}>
            {children}
        </InnerNotificationProvider>
            :
            { children }
    )
}

function InnerNotificationProvider({ children, user }) {
    const supabase = useSupabaseClient();

    const { data } = useSWRSubscription(user.id, (user_id, { next }) => {
        if (user_id) {
            let result = [];

            supabase
                .from('notification')
                .select('*')
                .order('id', {
                    ascending: false
                })
                .limit(10)
                .then(response => {
                    for (let item of response.data) {
                        result.push(item);
                    }

                    result = [...result];

                    next(null, result);
                })

            const channel = supabase
                .channel('custom-all-channels')
                .on(
                    'postgres_changes',
                    {
                        event: "*",
                        schema: "public",
                        table: "notification",
                    },
                    (payload) => {
                        if (payload.eventType === "INSERT") {
                            result = [payload.new].concat(result || []);
                            next(null, result);
                        }
                        else if (payload.eventType === "UPDATE") {
                            result = [...result] || [];

                            for (let i = 0; i < result.length; ++i) {
                                if (result[i].id == payload.new.id) {
                                    result[i] = payload.new;

                                    break;
                                }
                            }

                            next(null, result);
                        }
                    }
                )
                .subscribe();

            return () => {
                channel.unsubscribe;
            }
        }
        else
            return () => { }
    });

    return <NotificationContext.Provider value={data}>
        {children}
    </NotificationContext.Provider>
}

export function useNotifications() {
    return useContext(NotificationContext);
}

function UserNotifications({ user }) {
    const [query, setQuery] = useState("");
    const { classes } = useStyles(null);

    const nts = useRef({});

    const supabase = useSupabaseClient();

    const data = useNotifications();

    const [filteredData, setFilteredData] = useState([]);

    const [count, setCount] = useState(0);
    const [seen, setSeen] = useState(0);

    const onClickHandler = async () => {
        const { error } = await supabase
            .rpc('f_update_notification');

        if (!error) {
            setSeen(count);
        }
    };

    useEffect(() => {
        if (data) {
            setFilteredData(filterActions(query, data));
        }
    }, [query, data]);

    useEffect(() => {
        if (data) {
            setCount(data.filter(item => item.visualized_at == null).length);
        }
    }, [data]);

    useEffect(() => {
        if (data) {
            for (const row of data) {
                const row_id = row.id.toString();

                if (!row.finished) {
                    if (nts.current[row_id]) {
                        notifications.update({
                            id: row_id,
                            loading: true,
                            title: row.title,
                            message: row.description,
                            autoClose: false,
                            withCloseButton: false,
                        });
                    }
                    else {
                        notifications.show({
                            id: row_id,
                            loading: true,
                            title: row.title,
                            message: row.description,
                            autoClose: false,
                            withCloseButton: false,
                        });

                        nts.current[row_id] = row_id;
                    }

                }
                else if (nts.current[row_id]) {
                    notifications.update({
                        id: row_id,
                        color: 'teal',
                        title: row.title,
                        message: row.description,
                        icon: <IconCheck style={{ width: rem(18), height: rem(18) }} />,
                        loading: false,
                        withCloseButton: true
                    });

                    nts[row_id] = undefined;
                }
            }
        }
    }, [data]);

    return (
        <Menu shadow="lg" width={400} position="bottom-end" withArrow zIndex={9999} withinPortal onOpen={onClickHandler}>
            <Menu.Target>
                <Indicator
                    offset={5}
                    label={(count - seen) > 99 ? "99+" : (count - seen)}
                    disabled={(count - seen) === 0}
                    size="xs"
                    color="red"
                    style={{ position: "absolute", right: 15, top: 12 }}>
                    <ActionIcon variant="subtle" radius="xl">
                        <IconBell size="1.4rem" stroke={1.5} />
                    </ActionIcon>
                </Indicator>

            </Menu.Target>

            <Menu.Dropdown>
                <TextInput
                    size="lg"
                    value={query}
                    onInput={event => setQuery(event.target.value)}
                    classNames={{ input: classes.searchInput }}
                    placeholder={"Pesquisar"}
                    icon={<IconSearch />}
                />
                <ScrollArea h={250}>
                    {filteredData && filteredData.map(action => <DefaultAction key={action.id} highlightQuery={true} query={query} highlightColor={"yellow"} Icon={GetIcon(action.type)} date={dayjs(new Date(`${action.created_at}Z`))?.fromNow()} description={action.description} title={action.title} />)}
                </ScrollArea>
            </Menu.Dropdown>
        </Menu>
    )
}

function GetIcon(type) {
    switch (type) {
        case "new_event":
            return <IconCalendarEvent />
        case "new_status":
            return <IconStatusChange />
        case "event_assignment":
            return <IconUserExclamation />
        default:
            return <IconBell />
    }
}


export default function Notifications() {
    const user = useUser();

    return (
        user && user.id && <UserNotifications user={user} />
    )
}